import { tw } from "../../../../../../utils/tw";
import { Accordion, AccordionSummary } from "@mui/material";
import { memo, useCallback } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadLink from "../../../../../../components/common/File/DownloadLink";
import {
  handleDownloadBlob,
  handleDownloadStatic,
} from "../../../../../../utils/downloadFile";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import DownloadLinkWithDelete from "../../../../../../components/common/File/DownloadLinkWithDelete";
import { getDownloadUrl } from "../../../../../../services/CreditFeed.service";
import axios from "axios";

interface FileBeforeUpload {
  fileName: string;
  fileSize: number;
  id: string;
}
interface IAccordionInformation {
  idData: string;
  accordionTitle?: string;
  data: {
    label: string;
    value: string;
  }[];
  isPDF?: boolean;
  pdfFile?: File[];
  fileBeforeUpload?: FileBeforeUpload[];
  contract?: FileBeforeUpload[];
  openFirstTime?: boolean;
}

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] max-w-[1247px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(
    `text-[#7E7E7E] font-["IBM Plex Sans Thai"] font-medium text-[14px]`
  ),
  text: tw(`text-[#1B2128] font-["IBM Plex Sans Thai"] font-bold text-[14px]`),
};

export const AccordionInformation = memo((props: IAccordionInformation) => {
  const {
    openFirstTime,
    accordionTitle,
    data,
    isPDF,
    pdfFile,
    fileBeforeUpload,
    idData,
    contract,
  } = props;

  const handleDownload = useCallback(
    (file: File) => async () => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [pdfFile]
  );

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [fileBeforeUpload]
  );

  return (
    <Accordion
      sx={{
        backgroundColor: "#68C184",
        borderRadius: "10px",
        margin: "0px !important",
        boxShadow: "none",
        "&.MuiAccordion-root::before": {
          backgroundColor: "#FFFFFF",
        },
        width: "100%",
      }}
      defaultExpanded={openFirstTime}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
        className="text-[14px] font-bold"
        style={{
          color: "#FFFFFF",
        }}
        sx={{
          minHeight: "36px !important",
          height: "36px !important",
        }}
      >
        {accordionTitle}
      </AccordionSummary>
      <div className="w-full bg-[#FFFFFF]">
        <div className={classes.space2}></div>
        <div className="flex flex-row flex-wrap w-full gap-y-[24px]">
          {data.map(({ label, value }, index) => {
            return (
              <div key={`${index}`} className="w-[25%] pr-[24px]">
                <div className={classes.title}>{label}</div>
                <div className={classes.text}>{value}</div>
              </div>
            );
          })}
        </div>
        {isPDF && (
          <>
            <div className={classes.space2}></div>
            <div className="flex flex-row gap-6">
              <div className="flex flex-col gap-3 w-[50%]">
                <div className={tw(classes.title, "text-[#000000]")}>
                  เอกสารการคำนวณเครดิต
                </div>
                {pdfFile &&
                  pdfFile?.map((row) => {
                    return (
                      <DownloadLinkWithDelete
                        hideDeleteBtn
                        onDownload={handleDownload(row)}
                        name={row.name}
                      />
                    );
                  })}
                {fileBeforeUpload &&
                  fileBeforeUpload.map((row) => {
                    return (
                      <DownloadLinkWithDelete
                        hideDeleteBtn
                        onDownload={handleDownloadByUrl(
                          idData,
                          row.id,
                          row.fileName
                        )}
                        name={row.fileName}
                      />
                    );
                  })}
              </div>
              {contract && contract.length > 0 && (
                <div className="flex flex-col gap-3 w-[50%]">
                  <div className={tw(classes.title, "text-[#000000]")}>
                    เอกสารสัญญา
                  </div>
                  {contract.map((row) => {
                    return (
                      <DownloadLinkWithDelete
                        hideDeleteBtn
                        onDownload={handleDownloadByUrl(
                          idData,
                          row.id,
                          row.fileName
                        )}
                        name={row.fileName}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Accordion>
  );
});

import { useQuery } from "@tanstack/react-query"
import { getAllContract } from "../../services/Financial.service"

export const useFarmController = () => {
  const { data: financialDetail, error, isLoading } = useQuery({
    queryKey: ['financialDetail'],
    queryFn: async () => {
      const result = await getAllContract()
      return result
    },
  })
    
  return {
    financialDetail,
  }
}

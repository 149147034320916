import OldLogin from "./index_old";
import NewLogin from "./index_new";

const IS_OLD_LOGIN = false;

const Login = () => {
  if (IS_OLD_LOGIN) return <OldLogin />;
  return <NewLogin />;
};

export default Login;

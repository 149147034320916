import { useEffect, useMemo, useState } from "react";

import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import { useShowButtonByRoles } from "../../../../hooks/useActionButtonByRoles";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import { getEmployee } from "../../../../services/admin/user-management/employee.service";
import { UserRole } from "../../../../utils/enum/user-role.enum";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import EditOrderCustomModal from "../../../components/Modal/EditOrderCustomModal";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../components/Table/TableCustom";
import { usePaginationFilter } from "../../../hooks/usePaginationFilter.hook";
import UserManagementSearch from "../components/UserManagementSearch";
import CreateEmployeeModal from "./CreateEmployeeModal";

type Props = {};

type TEmployeeStatus = "true" | "false";

export interface IEmployee {
  id: string;
  employeeCode: string;
  username: string;
  name: string;
  email: string;
  role: string;
  status: TEmployeeStatus;
}

function createData(
  id: string,
  employeeCode: string,
  username: string,
  name: string,
  email: string,
  role: string,
  status: TEmployeeStatus
): IEmployee {
  return {
    id,
    employeeCode,
    username,
    name,
    email,
    role,
    status: status as TEmployeeStatus,
  };
}
const headCells: ITableCustomHeadCell<keyof IEmployee>[] = [
  {
    id: "employeeCode",
    numeric: false,
    disablePadding: false,
    label: "รหัสพนักงาน",
    width: "120px",
    isSorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "ชื่อพนักงาน",
    width: "180px",
    isSorting: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "อีเมล",
    width: "180px",
    isSorting: false,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
    width: "100px",
    isSorting: true,
  },

  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "สถานะ",
    width: "80px",
    isSorting: false,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
    isSorting: false,
  },
];

const EmployeeStatusMap: Record<
  TEmployeeStatus,
  { color: string; bgColor: string; label: string }
> = {
  true: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "เปิดใช้งาน",
  },
  false: {
    color: "#3F3748",
    bgColor: "#E4E4E4",
    label: "ปิดใช้งาน",
  },
};
const EmployeePanel = (props: Props) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const { page, limit, setPage } = usePaginationFilter();
  const [countRows, setCountRows] = useState<number>(0);
  const [list, setList] = useState<IEmployee[]>([]);
  const [direction, setDirection] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IEmployee>("employeeCode");

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [toggleFetch, setToggleFetch] = useState(false);
  const { showSnackBar } = useSnackBar();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const MapOrderByWithFilter: { [K in keyof IEmployee]?: string } = {
    employeeCode: "employee_code",
    role: "type",
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchText);
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("direction", direction);
    searchParams.append("orderBy", MapOrderByWithFilter[orderBy] ?? "");
    setList([]);
    const data = await getEmployee(searchParams);
    if (data?.result) {
      const newList = data.result.map((row) => {
        return createData(
          row.id,
          row.employeeCode,
          row.username,
          row.name,
          row.email,
          row.userRole,
          row.status as TEmployeeStatus
        );
      });
      setCountRows(data.count);
      setList(newList);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [orderBy, direction, page, toggleFetch]);

  const { showButton } = useShowButtonByRoles([
    UserRole.Admin,
    UserRole.Management,
  ]);

  const filterHeaderCell = useMemo(
    () =>
      showButton
        ? headCells
        : headCells.filter((_, idx) => idx <= headCells.length - 2),
    [showButton]
  );

  const onSearchText = () => {
    setPage(1);
    setToggleFetch((prev) => !prev);
  };

  return (
    <div className="flex flex-col w-full pt-6">
      <UserManagementSearch
        searchText={searchText}
        onSearch={onSearchText}
        setSearchText={setSearchText}
        itemSelected={itemSelected}
        placeholder={"ค้นหาโดยรหัสพนักงาน, ชื่อพนักงาน"}
        feature="employee"
        onClickChangeStatus={() => setItemSelected([])}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={direction}
          orderBy={orderBy}
          rowsPerPage={limit}
          itemsSelected={itemSelected}
          page={page}
          headerItems={filterHeaderCell}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          rows={list}
          currentPageItems={list.map((row) => row.id)}
          onChangePage={(val) => setPage(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={countRows}
          onChangeOrder={(val) => setDirection(val)}
          onChangeOrderBy={(val) => setOrderBy(val as keyof IEmployee)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.employeeCode}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.role}
                    </TableCell>

                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={EmployeeStatusMap[row.status]["bgColor"]}
                          fontColor={EmployeeStatusMap[row.status].color}
                          label={EmployeeStatusMap[row.status].label}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {showButton && (
                        <ButtonFillCustom
                          title={"แก้ไข"}
                          onClick={() => {
                            setSelectedId(row.id);
                            setIsOpenEditModal(true);
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <EditOrderCustomModal
        title="ยืนยันการลบ"
        subtitleFirstRow="คุณต้องการลบรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้?"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="ยกเลิก"
        open={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        onSubmit={() => {
          showSnackBar("ลบพาร์ทเนอร์สำเร็จ");
          setIsOpenDeleteModal(false);
          onSearchText();
        }}
      />
      <CreateEmployeeModal
        key={selectedId}
        isOpen={isOpenEditModal}
        handleClose={() => setIsOpenEditModal(false)}
        selectedRow={selectedId}
        handleFetch={onSearchText}
      />
    </div>
  );
};

export default EmployeePanel;

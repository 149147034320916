import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import BasicDetailTable, {
  IDataTable,
} from "../../../components/common/Table/BasicDetailTable";
import DashboardInfoCard from "../../../components/common/DashboardInfoCard/DashboardInfoCard";
import PigsSVG from "../../../assets/svg/Pigs";
import HistoryTimeSVG from "../../../assets/svg/HistoryTime";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { thousandComma } from "../../../utils/common/string";
import { IMoveOutPigHouseDetail } from "../../../types/MoveOutPig.type";

export function HouseMoveOutPig() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { house } = useLoaderData() as { house: IMoveOutPigHouseDetail };
  const [tableData, setTableData] = useState<IDataTable[]>([]);

  useEffect(() => {
    if (!house) return;
    setTableData([
      {
        detailName: t("moveOutPig.tableDetail.farmName"),
        detailValue: house.farmName ?? "",
        unit: "",
        doFormat: false,
      },
      {
        detailName: t("moveOutPig.tableDetail.houseName"),
        detailValue: house.houseName,
        unit: "",
        doFormat: false,
      },
      {
        detailName: t("moveOutPig.tableDetail.typeOfPig"),
        detailValue: t("common.fattenerPig"),
        unit: "",
        doFormat: false,
      },
      {
        detailName: t("moveOutPig.tableDetail.startHouseDate"),
        detailValue: dayjs(house.startHouseDate).format("DD/MM/BBBB"),
        unit: "",
        doFormat: false,
      },
      {
        detailName: t("moveOutPig.tableDetail.ageOfPig"),
        detailValue: house.ageOfPig,
        unit: t("moveOutPig.tableDetail.unit.day"),
        doFormat: false,
      },
      {
        detailName: t("moveOutPig.tableDetail.amountOfPig"),
        detailValue: thousandComma(house.fattenerPigCount),
        unit: t("moveOutPig.tableDetail.unit.pig"),
        doFormat: false,
      },
      {
        detailName: t("moveOutPig.tableDetail.totalCarcassPigAmount"),
        detailValue: thousandComma(house.amountOfCarcass),
        unit: t("moveOutPig.tableDetail.unit.pig"),
        doFormat: false,
      },
      {
        detailName: t("moveOutPig.tableDetail.totalPriceCarCassAmount"),
        detailValue: thousandComma(Number(house.totalCarcassPrice), 2),
        unit: t("moveOutPig.tableDetail.unit.bath"),
        doFormat: false,
      },
    ]);
  }, [house]);

  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#FFFFF]">
      <div className="p-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(`/house/${house?.houseId}`)}
          title={t("moveOutPig.titleBar")}
        />
      </div>
      <div className="flex p-4">
        <div className="flex flex-col grow gap-4">
          <div className="pb-1">
            <div className="bg-[#D9EBFE] flex items-center p-[16px] rounded-t-lg justify-between">
              <h5 className="text-[24px] font-medium">
                {t("moveOutPig.title")}
              </h5>
            </div>
            <BasicDetailTable
              tableData={tableData}
              tableProps={{
                size: "small",
              }}
              isNewFormat={true}
              showBottomLine={true}
            />
            <div className="bg-[#D9EBFE] rounded-b-lg h-[20px]"></div>
          </div>

          <div className="mt-1">
            <DashboardInfoCard
              title={t("moveOutPig.button.moveOutPigTitle")}
              subTitle={t("moveOutPig.button.moveOutPigSubTitle")}
              icon={<PigsSVG />}
              removeBgIcon={true}
              bgColor="#F0FDF4"
              onPress={() => {
                navigate(`/house/${house?.houseId}/move-out-pig-selling`);
              }}
            />
          </div>
          <div className="mt-1">
            <DashboardInfoCard
              title={t("moveOutPig.button.sellingHistoryTitle")}
              subTitle={t("moveOutPig.button.sellingHistorySubTitle")}
              icon={<HistoryTimeSVG />}
              removeBgIcon={true}
              bgColor="#EEF2FF"
              onPress={() =>
                navigate(`/house/selling-history/${house?.houseId}`, {
                  state: {
                    from: `/house/${house?.houseId}/move-out-pig`,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

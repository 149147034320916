import { Link, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import { IGetStocks } from "../../../../../types/ManageStock.type";

interface Data {
  id: string;
  nameTh: string;
  skuTh: string;
  feedBrandId: string;
  feedBrandItemId: string;
  remainingProduct: string;
}

const headCells: ITableCustomHeadCell<keyof Data>[] = [
  {
    id: "nameTh",
    numeric: false,
    disablePadding: false,
    label: "แบรนด์อาหารสัตว์",
    width: "150px",
    isSorting: true,
  },
  {
    id: "skuTh",
    numeric: false,
    disablePadding: false,
    label: "เบอร์อาหารสัตว์",
    width: "200px",
    isSorting: false,
  },
  {
    id: "remainingProduct",
    numeric: false,
    disablePadding: false,
    label: "จำนวนสินค้า",
    width: "150px",
    isSorting: false,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "150px",
    isSorting: false,
  },
];

interface props {
  stock?: IGetStocks[];
}

const mockData: Data[] = [
  {
    id: "1",
    feedBrandId: "2",
    feedBrandItemId: "3",
    remainingProduct: "3 ถุง",
    nameTh: "Betagro",
    skuTh: "SKU-OOO1",
  },
  {
    id: "2",
    feedBrandId: "2",
    feedBrandItemId: "4",
    remainingProduct: "4 ถุง",
    nameTh: "Betagro",
    skuTh: "SKU-OOO2",
  },
  {
    id: "3",
    feedBrandId: "1",
    feedBrandItemId: "5",
    remainingProduct: "5 ถุง",
    nameTh: "Betagro",
    skuTh: "SKU-OOO3",
  },
  {
    id: "4",
    feedBrandId: "2",
    feedBrandItemId: "6",
    remainingProduct: "20 กิโลกรัม",
    nameTh: "Farm",
    skuTh: "SKU-OOO1A",
  },
  {
    id: "5",
    feedBrandId: "2",
    feedBrandItemId: "3",
    remainingProduct: "10 กิโลกรัม",
    nameTh: "Farm",
    skuTh: "SKU-OOO2A",
  },
];

function ProductPanel(props: props) {
  const { stock } = props;

  return (
    <TableCustom
      order={"asc"}
      orderBy={""}
      totalItemSize={0}
      rowsPerPage={999}
      page={1}
      headerItems={headCells}
      rows={[]}
      onChangePage={(_) => {}}
      onChangeOrder={(_) => {}}
      onChangeOrderBy={(_) => {}}
      currentPageItems={[]}
      itemsSelected={[]}
      onSelectItem={(_) => {}}
      onSelectAllClick={(_) => {}}
      hideSelect
      hidePaginator
      rowChildren={stock?.map((row) => {
        const translatedUnits =
          row.fbi.unit === "bag"
            ? "ถุง"
            : row.fbi.unit === "kg"
            ? "กิโลกรัม"
            : row.fbi.unit;

        let count = `${row.amount} ${translatedUnits}`;

        return (
          <TableRow key={row.id} hover>
            <TableCell>{row.fb.nameTh}</TableCell>
            <TableCell>{row.fbi.skuTh}</TableCell>
            <TableCell>{count}</TableCell>
            <TableCell align="right">
              <Link
                variant="body2"
                // href={`/admin/manage-stock/${row.id}`}
                className="cursor-pointer"
                color={"secondary"}
                fontWeight={500}
                paddingRight={"15px"}
              >
                {"ดูรายละเอียด"}
              </Link>
            </TableCell>
          </TableRow>
        );
      })}
    />
  );
}

export default ProductPanel;

import {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableCell, TableRow } from "@mui/material";
import get from "lodash/get";
import { tw } from "../../../../utils/tw";
import TableCustom from "../../../components/Table/TableCustom";
import { IFeedFoodListResponse } from "../../../../types/FoodFeed.type";
import { getCommaNumber } from "../../../../utils/number";
import { TDropdownValue } from "../../group-area/views/Dropdown";

interface ITableProps {
  data?: IFeedFoodListResponse["listOfFeeds"];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalTableSize: number;
  currentOrderByKey: "name_th" | "sku_th" | "name_en" | "sku_en";
  setCurrentOrderByKey: Dispatch<
    SetStateAction<"name_th" | "sku_th" | "name_en" | "sku_en">
  >;
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
  brand: TDropdownValue[];
}

const classes = {
  container: tw(`mt-6 min-h-[350px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const headerItems = [
  {
    id: "name_th",
    numeric: false,
    disablePadding: false,
    label: "เเบรนด์อาหารสัตว์ (TH)",
    width: "auto",
    isSorting: true,
    className: "whitespace-pre",
  },
  {
    id: "name_en",
    numeric: false,
    disablePadding: false,
    label: "เเบรนด์อาหารสัตว์ (EN)",
    width: "auto",
    isSorting: true,
    className: "whitespace-pre",
  },
  {
    id: "sku_th",
    numeric: false,
    disablePadding: false,
    label: "เบอร์อาหารสัตว์ (TH)",
    width: "auto",
    isSorting: true,
    className: "whitespace-pre",
  },
  {
    id: "sku_en",
    numeric: false,
    disablePadding: false,
    label: "เบอร์อาหารสัตว์ (EN)",
    width: "auto",
    isSorting: true,
    className: "whitespace-pre",
  },
  {
    id: "unitPrice",
    numeric: false,
    disablePadding: false,
    label: "ราคาต่อหน่วย",
    width: "auto",
    isSorting: false,
    className: "!text-center whitespace-pre",
  },
  {
    id: "startWeight",
    numeric: false,
    disablePadding: false,
    label: "น้ำหนักเริ่มต้น",
    width: "auto",
    isSorting: false,
    className: "!text-center whitespace-pre",
  },
  {
    id: "endWeight",
    numeric: false,
    disablePadding: false,
    label: "น้ำหนักสิ้นสุด",
    width: "auto",
    isSorting: false,
    className: "!text-center whitespace-pre",
  },
  {
    id: "protein",
    numeric: false,
    disablePadding: false,
    label: "โปรตีน",
    width: "auto",
    isSorting: false,
    className: "!text-center whitespace-pre",
  },
  {
    id: "consumption",
    numeric: false,
    disablePadding: false,
    label: "การกินได้",
    width: "auto",
    isSorting: false,
    className: "!text-center whitespace-pre",
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    label: "หน่วย",
    width: "auto",
    isSorting: false,
    className: "!text-center whitespace-pre",
  },
];

// @@@ BASE TABLE SHOULD FOLLOW THIS @@@
const Table = (props: ITableProps) => {
  const {
    data = [],
    currentPage,
    setCurrentPage,
    totalTableSize,
    currentOrderByKey,
    orderByType,
    setCurrentOrderByKey,
    setOrderByType,
    brand,
  } = props;

  const rowChildren = useMemo(() => {
    return data.map((row) => {
      return (
        <TableRow key={row.id} hover className="border-x-[1px] border-x-[#ddd]">
          <TableCell>{row.feed_brand.nameTh}</TableCell>
          <TableCell>{row.feed_brand.nameEn}</TableCell>
          <TableCell>{row.skuTh}</TableCell>
          <TableCell>{row.skuEn}</TableCell>
          <TableCell className="!text-right">
            {getCommaNumber(row.pricePerUnit || 0)}
          </TableCell>
          <TableCell className="!text-right">
            {getCommaNumber(row.startWeight || 0)}
          </TableCell>
          <TableCell className="!text-right">
            {getCommaNumber(row.endWeight || 0)}
          </TableCell>
          <TableCell className="!text-right">
            {getCommaNumber(row.protein || 0)}
          </TableCell>
          <TableCell className="!text-right">
            {getCommaNumber(row.consumption || 0)}
          </TableCell>
          <TableCell className="!text-center">
            {row.unit === "bag"
              ? "ถุง"
              : row.unit === "kg"
              ? "กิโลกรัม"
              : row.unit}
          </TableCell>
        </TableRow>
      );
    });
  }, [data]);

  useEffect(() => {
    if (brand.length > 0) {
      setCurrentPage(1);
    }
  }, [brand]);

  return (
    <div className={classes.container}>
      <TableCustom
        orderBy={currentOrderByKey}
        order={orderByType}
        onChangeOrder={setOrderByType}
        onChangeOrderBy={setCurrentOrderByKey as any}
        totalItemSize={totalTableSize}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={10}
        rows={data}
        EmptyComponent={
          data.length === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        hideSelect
        rowChildren={rowChildren}
        currentPageItems={[]}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
      />
    </div>
  );
};

export default memo(Table);

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton, MenuItem } from "@mui/material";
import { ErrorMessage, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import { useLocation, useNavigate } from "react-router-dom";
import { array, number, object } from "yup";
import CustomButton from "../../../components/common/Button";
import TextInputFS from "../../../components/common/Input/TextInput";
import UploadImage from "../../../components/common/uploadImage";
import DailyLogContext from "../../../provider/DailyLogProvider/DailyLogContext";
import { setDailyLog } from "../../../services/DailyLogService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  formError,
  formErrorMessage,
  twoDecimalPlacesValidator,
} from "../../../utils/form-validator";

type TreatmentDetailsType = {
  onSubmit?: (form: any) => void;
  finalDailyLogData?: any;
  isEdit: boolean;
  houseId: any;
  farmId: any;
  date: any;
  totalPigs: number;
  breederPigCount: number;
  currentDeadPigsCount: number;
};

const Treatment = ({
  onSubmit,
  finalDailyLogData,
  isEdit,
  houseId,
  farmId,
  date,
  totalPigs,
  breederPigCount,
  currentDeadPigsCount,
}: TreatmentDetailsType) => {
  // const imageRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Image_Resize_limit = 2097152;
  const MAX_FILE_SIZE = 5242880; //5mb
  const [imageUrl, setImageUrl] = useState<Array<Array<string>>>([]);
  const [medicineForms, setMedicineForms] = useState([1]);
  const { setDailyLogData } = useContext(DailyLogContext);
  const [isFormError, setFormError] = useState<string>("");
  const { state } = useLocation();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  let isError: any = false;

  // const medicineUnitData = ['kg', 'gm', 'Litre', 'pound', 'ml', 'tablets', 'lb', 'pack', 'box', 'gallon', 'ml/can', 'cc']
  const medicineUnitData = [
    t("common.kg"),
    t("common.gm"),
    t("common.litre"),
    t("common.pound"),
    t("common.ml"),
    t("common.tablets"),
    t("common.lb"),
    t("common.pack"),
    t("common.box"),
    t("common.gallon"),
    t("common.ml/can"),
    t("common.cc"),
  ];
  const [errorIndex, setErrorIndex] = useState<{
    numberOfPig: number[];
    totalOfmedicine: number[];
    medicineData: number[];
  }>({
    numberOfPig: [],
    totalOfmedicine: [],
    medicineData: [],
  });

  type MyFormValues = {
    cageNo: any[];
    numberOfPig: any[];
    symptom: string[];
    medicineData: any[]; // Replace 'any' with the actual type
    TotalofMedicine: number[];
    medicineUnit: any[];
    pictureofUnwellPig: any[][]; // Replace 'any' with the actual type
    nameOfMedicine: any[];
  };
  const getTreatmentData = (values: any) => {
    let treatmentData: any = [];
    values?.cageNo?.forEach((item: any, i: number) => {
      let data: any = {};
      if (values?.cageNo?.length > 0 && values?.cageNo[i])
        data.cageNumber = Number(values?.cageNo[i]);
      if (values?.numberOfPig?.length > 0 && values?.numberOfPig[i])
        data.treatmentPigCount = Number(values?.numberOfPig[i]);
      if (values?.symptom?.length > 0 && values?.symptom[i])
        data.symptom = values.symptom[i];
      if (values?.medicineData?.length > 0 && values?.medicineData[i])
        data.medicineDetailId = values?.medicineData[i];
      if (values?.TotalofMedicine?.length > 0 && values?.TotalofMedicine[i])
        data.medicineCount = parseFloat(values?.TotalofMedicine[i]);
      if (values?.medicineUnit?.length > 0 && values?.medicineUnit[i])
        data.unit = values?.medicineUnit[i];
      if (values?.nameOfMedicine?.length > 0 && values?.nameOfMedicine[i])
        data.medicineName = values?.nameOfMedicine[i];
      data.unwellPigPicture = "";

      treatmentData.push(data);
    });

    return treatmentData;
  };

  const formik = useFormik({
    initialValues: {
      cageNo: finalDailyLogData?.cageNo || [],
      numberOfPig: finalDailyLogData?.numberOfPig || [],
      symptom: finalDailyLogData?.symptom || [],
      medicineData: finalDailyLogData?.medicineData || [],
      TotalofMedicine: finalDailyLogData?.TotalofMedicine || [],
      medicineUnit: finalDailyLogData?.medicineUnit || ["kg"],
      pictureofUnwellPig: finalDailyLogData?.pictureofUnwellPig || [[]],
      nameOfMedicine: finalDailyLogData?.nameOfMedicine || [],
    },
    validationSchema: object({
      cageNo: array().of(
        number()
          .integer(`${t("dailyLog.shouldBeIntegerErrorMessage")}`)
          .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
          .optional()
      ),
      numberOfPig: array().of(
        number()
          .integer(`${t("dailyLog.shouldBeIntegerErrorMessage")}`)
          .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
          .test(
            "max-value",
            `${t("dailyLog.shouldNotBeMoreThanTotalHousePigsErrorMessage")}`,
            function (value) {
              if (
                value &&
                value >
                  totalPigs -
                    breederPigCount -
                    currentDeadPigsCount +
                    (isEdit
                      ? finalDailyLogData?.deathData?.numberOfDeadPigs
                        ? finalDailyLogData?.deathData?.numberOfDeadPigs
                        : 0
                      : 0)
              ) {
                return false;
              }

              return true;
            }
          )
          .test(
            "max-100000",
            `${t("dailyLog.shouldBeMoreThan100000ErrorMessage")}`,
            function (value) {
              if (totalPigs > 100000 && value && value > 100000) {
                return false;
              }
              return true;
            }
          )
          .optional()
      ),

      TotalofMedicine: array().of(
        number()
          .integer(`${t("dailyLog.shouldBeIntegerErrorMessage")}`)
          .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
          .test(
            "two-decimal",
            `${t("dailyLog.onlyTwoDecimialErrorMessage")}`,
            twoDecimalPlacesValidator
          )
          .optional()
      ),
      pictureofUnwellPig: array(),
    }),
    onSubmit: async (values) => {
      try {
        if (setDailyLogData)
          setDailyLogData({ ...finalDailyLogData, treatmentData: values });
        let isError: any = false;
        formik?.values?.cageNo &&
          formik?.values?.cageNo?.map((val: number, index: number) => {
            if (val) {
              if (!formik.values?.numberOfPig[index]) {
                setErrorIndex((prevErrors) => ({
                  ...prevErrors,
                  numberOfPig: [...prevErrors["numberOfPig"], index],
                }));
                formik.setFieldError(
                  "numberOfPig",
                  `${t("dailyLog.numberOfPigsRequiredErrorMessage")}`
                );
                isError = 1;
              } else {
                if (errorIndex.numberOfPig.includes(index)) {
                  setErrorIndex((prevErrors) => ({
                    ...prevErrors,
                    numberOfPig: prevErrors.numberOfPig.filter(
                      (i) => i !== index
                    ),
                  }));
                }
              }
              if (!formik.values?.TotalofMedicine[index]) {
                setErrorIndex((prevErrors) => ({
                  ...prevErrors,
                  totalOfmedicine: [...prevErrors["totalOfmedicine"], index],
                }));
                formik.setFieldError(
                  `TotalofMedicine`,
                  `${t("dailyLog.totalOfMedicineRequiredErrorMessage")}`
                );
                isError += 1;
              } else {
                if (errorIndex.totalOfmedicine.includes(index)) {
                  setErrorIndex((prevErrors) => ({
                    ...prevErrors,
                    totalOfmedicine: prevErrors.totalOfmedicine.filter(
                      (i) => i !== index
                    ),
                  }));
                }
              }
              if (!formik.values?.medicineData[index]) {
                setErrorIndex((prevErrors) => ({
                  ...prevErrors,
                  medicineData: [...prevErrors["medicineData"], index],
                }));
                isError += 1;
              } else {
                if (errorIndex.medicineData.includes(index)) {
                  setErrorIndex((prevErrors) => ({
                    ...prevErrors,
                    medicineData: prevErrors.medicineData.filter(
                      (i) => i !== index
                    ),
                  }));
                }
              }
            }
          });
        const options: any = {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        };
        formik?.values?.numberOfPig &&
          formik?.values?.numberOfPig?.map((val: number, index: number) => {
            if (
              !formik.values?.cageNo[index] &&
              formik.values?.numberOfPig[index]
            ) {
              formik.setFieldError(
                `cageNo`,
                `${t("dailyLog.numberOfCageIsRequiredErrorMessage")}`
              );
              isError = 567;
            }
          });

        formik?.values?.medicineData &&
          formik?.values?.medicineData?.map((val: number, index: number) => {
            if (
              !formik.values?.cageNo[index] &&
              formik.values?.medicineData[index]
            ) {
              formik.setFieldError(
                `cageNo`,
                `${t("dailyLog.numberOfCageIsRequiredErrorMessage")}`
              );
              isError = 909;
            }
          });

        if (isError) {
          setIsSubmit(false);
          return;
        }

        const logDate = new Date(finalDailyLogData?.feedData?.date);
        //logDate.setUTCHours(0, 0, 0, 0)

        const data: any = {
          farmId: finalDailyLogData?.feedData?.farmId,
          houseId: finalDailyLogData?.feedData?.houseId,
          feedBrandId: parseInt(finalDailyLogData?.feedData?.feedBrand),
          feedBrandItemId: parseInt(finalDailyLogData?.feedData?.SKUOfFeed),
          // logDate: new Date(finalDailyLogData?.feedData?.date).toLocaleDateString('en-US', options).replace(/\//g, '-'), //mm-dd-yyyy
          logDate: logDate.toISOString(),
          feedIntake: parseFloat(finalDailyLogData?.feedData?.feedIntake),
        };
        const treatMentData: any = getTreatmentData(values);
        if (treatMentData && treatMentData.length > 0)
          data.treatment = treatMentData;

        if (finalDailyLogData?.deathData?.numberOfDeadPigs) {
          data.deadPigCount = parseInt(
            finalDailyLogData?.deathData?.numberOfDeadPigs
          ); // optional
          data.deadPigWeight = parseFloat(
            finalDailyLogData?.deathData?.totalWeightDeadPigs
          );
        }

        if (finalDailyLogData?.deathData?.reasonOfDeath) {
          data.deathReason = finalDailyLogData?.deathData?.reasonOfDeath; // optional
        }
        if (finalDailyLogData?.deathData?.numberOfPigsSellingCarcass) {
          data.pigCarcassSellingCount = parseInt(
            finalDailyLogData?.deathData?.numberOfPigsSellingCarcass
          ); // optional
          data.pigCarcassSellingWeight = parseFloat(
            finalDailyLogData?.deathData?.weightOfPigsSellingCarcass
          ); // required if pigCarcassSellingCount is entered
          data.pigCarcassSellingPrice = parseInt(
            finalDailyLogData?.deathData?.sellingPriceCarcass
          ); // required if pigCarcassSellingCount is entered
        }
        if (finalDailyLogData?.vaccineData?.vaccineId) {
          data.vaccineId = finalDailyLogData?.vaccineData?.vaccineId; // optional
          data.vaccinePigCount = parseInt(
            finalDailyLogData?.vaccineData?.numberOfPigsVaccine
          ); // required if vaccineId is entered
          data.vaccineQuantity = parseInt(
            finalDailyLogData?.vaccineData?.vaccinePerPig
          ); // required if vaccineId is entered
          data.vaccineBottleCount = parseInt(
            finalDailyLogData?.vaccineData?.totalBottleOfVaccine
          ); // required if vaccineId is entered
        }

        if (isSubmit) {
          if (!isEdit) {
            const resp = await setDailyLog(data);
            if (resp?.data?.success) {
              setDailyLogData && setDailyLogData(null);
              navigate(`/house/${data?.houseId}`);
            } else {
              const err = resp?.data?.message
                ? resp.data?.message
                : "Internal Server Error";
              setFormError(err);
            }
          } else {
            onSubmit?.(values);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  useEffect(() => {
    if (finalDailyLogData?.treatmentData) {
      formik.setValues(finalDailyLogData.treatmentData);
      if (finalDailyLogData?.treatmentData?.cageNo?.length > 0) {
        setMedicineForms(
          Array.from(
            { length: finalDailyLogData?.treatmentData.cageNo.length },
            (_, index) => index + 1
          )
        );
      }
    }
  }, [finalDailyLogData?.treatmentData]);
  useEffect(() => {
    if (!finalDailyLogData.treatmentData)
      if (setDailyLogData)
        setDailyLogData({
          ...finalDailyLogData,
          treatmentData: {
            cageNo: [],
            numberOfPig: [],
            symptom: [],
            medicineData: [],
            TotalofMedicine: [],
            medicineUnit: ["kg"],
            pictureofUnwellPig: [[]],
            nameOfMedicine: [],
          },
        });
  }, []);
  useEffect(() => {
    if (state?.data) {
      if (setDailyLogData)
        setDailyLogData({ ...finalDailyLogData, treatmentData: state.data });
    }
  }, [state?.data]);
  const handleRemoveimage = (ele: any, i: number) => {
    let index = imageUrl[i].indexOf(ele);
    imageUrl[i].splice(index, 1);
    const updatedArray: any = [...formik.values["pictureofUnwellPig"]];
    if (imageUrl[i].length === 0) {
      updatedArray[i] = [];
    } else {
      updatedArray[i].splice(index, 1);
    }
    formik.setFieldValue("pictureofUnwellPig", updatedArray);
  };
  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    formikValue: keyof MyFormValues,
    index: number
  ) => {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement?.files) {
      const file: File = inputElement.files[0];
      if (file.size > Image_Resize_limit) {
        Resizer.imageFileResizer(
          file,
          300, // maxWidth
          300, // maxHeight
          "JPEG", // compressFormat
          80, // quality
          0, // rotation
          async (uri: any) => {
            // Convert data URL to Blob
            const response = await fetch(uri);
            const blob = await response.blob();

            // Create a File object from the Blob
            const compressedFile = new File([blob], "compressed.jpg", {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            const updateImageUrl = [...imageUrl];
            updateImageUrl[index] = updateImageUrl[index]
              ? [...updateImageUrl[index], uri]
              : [uri];
            setImageUrl(updateImageUrl);
            const fieldName = formikValue;
            const updatedArray: any = [...formik.values[fieldName]];
            updatedArray[index] = updatedArray[index]
              ? [...updatedArray[index], compressedFile]
              : [compressedFile];
            formik.setFieldValue(fieldName, updatedArray);
          },
          "base64" // outputType
        );
      } else {
        const url = URL.createObjectURL(file);
        if (file?.size < MAX_FILE_SIZE) {
          const updateImageUrl = [...imageUrl];
          updateImageUrl[index] = updateImageUrl[index]
            ? [...updateImageUrl[index], url]
            : [url];
          setImageUrl(updateImageUrl);

          if (e && e.target && e.target.files !== null) {
            const fieldName = formikValue;
            const updatedArray: any = [...formik.values[fieldName]];
            updatedArray[index] = updatedArray[index]
              ? [...updatedArray[index], e.target.files[0]]
              : [e.target.files[0]];
            formik.setFieldValue(fieldName, updatedArray);
          }
        } else {
          formik.setFieldError(
            "pictureofUnwellPig",
            "File size should be less than 2mb"
          );
        }
      }
    }
  };

  const handleAddForm = (e: any) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    setMedicineForms([...medicineForms, medicineForms.length + 1]);
  };

  const handleRemoveForm = (index: number) => {
    const updatedForms = JSON.parse(JSON.stringify(medicineForms));
    updatedForms.splice(index, 1);
    setMedicineForms(updatedForms);
    imageUrl.splice(index, 1);
    const newCageNo = [...formik.values.cageNo];
    newCageNo.splice(index, 1);

    const newNumberOfPig = [...formik.values.numberOfPig];
    newNumberOfPig.splice(index, 1);

    const newSymptom = [...formik.values.symptom];
    newSymptom.splice(index, 1);

    const newMedicineData = [...formik.values.medicineData];
    newMedicineData.splice(index, 1);

    const newTotalofMedicine = [...formik.values.TotalofMedicine];
    newTotalofMedicine.splice(index, 1);

    const newMedicineUnit = [...formik.values.medicineUnit];
    newMedicineUnit.splice(index, 1);

    const newPictureofUnwellPig = [...formik.values.pictureofUnwellPig];
    newPictureofUnwellPig.splice(index, 1);

    // // Update the formik.values object with the new arrays
    formik.setValues({
      ...formik.values,
      cageNo: newCageNo,
      numberOfPig: newNumberOfPig,
      symptom: newSymptom,
      medicineData: newMedicineData,
      TotalofMedicine: newTotalofMedicine,
      medicineUnit: newMedicineUnit,
      pictureofUnwellPig: newPictureofUnwellPig,
    });
  };

  const handleChange = (
    _value: any,
    formikValue: keyof MyFormValues,
    index: number
  ) => {
    setFormError("");
    const fieldName = formikValue;
    const updatedArray: any = [...formik.values[fieldName]];
    updatedArray[index] = _value;
    formik.setFieldValue(fieldName, updatedArray);
    const updatedData = { ...finalDailyLogData };
    if (updatedData.treatmentData && updatedData.treatmentData[fieldName]) {
      updatedData.treatmentData[fieldName][index] = updatedArray[index];
    }
    if (setDailyLogData) setDailyLogData(updatedData);
  };
  const submit = () => {
    setIsSubmit(true);
  };
  return (
    <form
      onSubmit={formik.handleSubmit}
      onChange={formik.handleSubmit}
      className="flex flex-col grow gap-4 justify-between"
    >
      <div className="flex flex-col gap-4">
        {medicineForms.map((medicineForm: any, index: number) => (
          <div className="flex flex-col gap-4" key={index}>
            {medicineForms.length > 1 && (
              <div className="flex justify-end text-[#DC5B5B] items-center">
                <span className="tetx-[16px] font-[500]">
                  {t("treatment.deletebutton")}
                </span>
                <IconButton onClick={() => handleRemoveForm(index)}>
                  <DeleteIcon className="text-[#DC5B5B]" />
                </IconButton>
              </div>
            )}
            <TextInputFS
              fullWidth
              type="tel"
              helperText={formErrorMessage(formik, "cageNo")}
              error={formError(formik, "cageNo")}
              label={t("treatment.cagenoLabel")}
              value={formik?.values?.cageNo[index] || null}
              onChange={(e) => {
                handleChange(e.target.value, "cageNo", index);
              }}
            ></TextInputFS>

            <TextInputFS
              fullWidth
              type="tel"
              helperText={formErrorMessage(formik, `numberOfPig`)}
              error={formError(formik, `numberOfPig`)}
              label={t("treatment.numberOfPig")}
              value={formik?.values.numberOfPig[index] || null}
              onChange={(e) =>
                handleChange(e.target.value, "numberOfPig", index)
              }
            />

            <TextInputFS
              fullWidth
              helperText={
                formErrorMessage(formik, "symptom")
                  ? formErrorMessage(formik, "symptom")
                  : t("treatment.helpertextforSymptom")
              }
              error={formError(formik, "symptom")}
              label={t("treatment.symptomLabel")}
              value={formik?.values?.symptom[index] || null}
              onChange={(e) => handleChange(e.target.value, "symptom", index)}
            />

            <div className="m-[20px]">
              <div className="flex justify-between ">
                <span className="text-[#3777BC] text-[16px] font-[500]">
                  {t("treatment.selectMedicineLabel")}
                </span>
                <div className="w-[60px] flex justify-between">
                  {formik?.values?.cageNo[index] &&
                  formik?.values?.medicineData[index] ? (
                    <CheckCircleIcon sx={{ color: "#68C184" }} />
                  ) : (
                    <></>
                  )}
                  <NavigateNextIcon
                    onClick={() =>
                      isEdit
                        ? navigate("/edit-select-medicine", {
                            state: {
                              id: index,
                              data: formik?.values,
                              selectedFarm: farmId,
                              houseId: houseId,
                              logDate: date,
                              index: index,
                            },
                          })
                        : navigate("/select-medicine", {
                            state: {
                              id: index,
                              data: formik?.values,
                              selectedFarm: farmId,
                              houseId: houseId,
                              logDate: date,
                              index: index,
                            },
                          })
                    }
                  />
                </div>
              </div>

              {formik?.values?.cageNo[index] &&
                errorIndex.medicineData.includes(index) && (
                  <span className="text-[#DC5B5B] text-[13px]">
                    {t("dailyLog.medicineIsRequiredErrorMessage")}
                  </span>
                )}
            </div>

            <div className="flex">
              <TextInputFS
                className="totalOfMedicineValue"
                fullWidth
                helperText={
                  errorIndex.totalOfmedicine.includes(index) &&
                  formErrorMessage(formik, "TotalofMedicine")
                }
                error={
                  errorIndex.totalOfmedicine.includes(index) &&
                  formError(formik, "TotalofMedicine")
                }
                label={t("treatment.totalOfMedicineLabel")}
                value={formik?.values?.TotalofMedicine[index] || null}
                onChange={(e) =>
                  handleChange(e.target.value, "TotalofMedicine", index)
                }
                InputProps={{
                  inputProps: {
                    inputMode: "decimal",
                  },
                }}
              />
              <TextInputFS
                // defaultValue={medicineUnitData[0]}
                className="toalOfMedicineDropdown !w-[150px]"
                select
                defaultValue={"CC"}
                value={formik?.values?.medicineUnit[index] || 0}
                onChange={(e) =>
                  handleChange(e.target.value, "medicineUnit", index)
                }
              >
                {medicineUnitData?.map((option: string, index: number) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextInputFS>
            </div>
            <UploadImage
              onChange={(e) => handleFileChange(e, "pictureofUnwellPig", index)}
              imageUrl={imageUrl[index]}
              handleRemoveImage={(event) => handleRemoveimage(event, index)}
              index={index}
              t={t}
            />
            <span>{formErrorMessage(formik, "pictureofunwellPig")}</span>

            <div className="w-[200px] h-[1px] bg-[#C9C9C9] my-5 mx-auto"></div>
          </div>
        ))}
        <div className="mb-5 flex justify-center">
          <div className="inline-block relative">
            <AddCircleIcon
              className="absolute left-[14px] top-[8px] z-[1]"
              style={{ color: "#68C184" }}
            />
            <CustomButton
              onClick={handleAddForm}
              className="!w-[200px] !h-[39px] !text-[15px] !pr-0 !pl-[30px] !pt-5"
              variant="secondary"
              label={t("treatment.addMedicineButtonLabel")}
            />
          </div>
        </div>
        {isFormError && (
          <div className="w-full flex justify-center items-center text-[#FF0000]">
            <span> {isFormError} </span>
          </div>
        )}
        <div className="flex flex-col gap-4 mb-4">
          <CustomButton
            disabled={!formik?.isValid || formik?.isSubmitting}
            variant="tertiary"
            label={t("treatment.confirmButtonLabel")}
            onClick={submit}
          />
        </div>
      </div>
    </form>
  );
};

export default Treatment;

import { Dialog, DialogContent, IconButton } from "@mui/material";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import { useEffect, useState } from "react";
import { getPartnerById } from "../../../../services/admin/user-management/partner.service";
import { IGetPartnerByID } from "../../../../types/admin/user-management/partner";

type DetailPartnerModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  selectedRow: string;
};

const DetailPartnerModal = ({
  isOpen,
  handleClose,
  selectedRow,
}: DetailPartnerModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [partnerDetail, setPartnerDetail] = useState<IGetPartnerByID | null>();

  const handleFetch = async () => {
    if (selectedRow) {
      setIsLoading(true);
      try {
        const partner = await getPartnerById(selectedRow);
        setPartnerDetail(partner);
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleFetch();
    }
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isLoading || isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent sx={{ width: "750px" }}>
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold pb-3 mb-1">รายละเอียดพาร์ทเนอร์</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">ชื่อ</p>
              <p className="text-sm pb-3 mb-1">{partnerDetail?.result.name}</p>
            </div>
            <div className="items-start w-[50%]">
              <p className=" text-sm font-bold pb-3 mb-1">นามสกุล</p>
              <p className=" text-sm pb-3 mb-1">
                {partnerDetail?.result.surname}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">ประเภทพาร์ทเนอร์</p>
              <p className="text-sm pb-3 mb-1">
                {partnerDetail?.result.partnerType}
              </p>
            </div>
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">รหัสพาร์ทเนอร์</p>
              <p className="text-sm pb-3 mb-1">
                {partnerDetail?.result.partnerCode}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">อีเมล</p>
              <p className="text-sm pb-3 mb-1">{partnerDetail?.result.email}</p>
            </div>
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">เบอร์โทรศัพท์</p>
              <p className="text-sm pb-3 mb-1">
                {partnerDetail?.result.mobileNumber}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[100%]">
              <p className="text-sm font-bold pb-3 mb-1">ชื่อกลุ่ม</p>
              <p className="text-sm pb-3 mb-1">
                {(partnerDetail?.result.userGroups || []).length > 0
                  ? partnerDetail?.result.userGroups
                      .map((role) => role.name)
                      .join(", ")
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">ชื่อผู้ใช้</p>
              <p className="text-sm pb-3 mb-1">
                {partnerDetail?.result.username}
              </p>
            </div>
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">Initial PIN</p>
              <p className="text-sm pb-3 mb-1">
                {partnerDetail?.result.initialPin}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[100%]">
              <p className="text-sm font-bold pb-3 mb-1">ที่อยู่</p>
              <p className="text-sm pb-3 mb-1">
                {`${partnerDetail?.result.address} ${partnerDetail?.result.subDistrict.nameTh} ${partnerDetail?.result.district.nameTh} ${partnerDetail?.result.province.nameTh} ${partnerDetail?.result.subDistrict.zipCode}`}
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailPartnerModal;

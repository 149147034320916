import { useFormik } from "formik";
import { number, object, string } from "yup";
import TextInputFS from "../../../components/common/Input/TextInput";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  formError,
  formErrorMessage,
  twoDecimalPlacesValidator,
} from "../../../utils/form-validator";
import CustomButton from "../../../components/common/Button";
import { useEffect, useState, useContext } from "react";
import DailyLogContext from "../../../provider/DailyLogProvider/DailyLogContext";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
interface DailyLogVaccineProps {
  vaccines?: any[];
  form?: any;
  totalPigs: number;
  onDailyLogVaccineSubmit?: (formValue: any) => void;
  finalDailyLogData?: any;
}

export default function DailyLogVaccine({
  totalPigs,
  vaccines,
  form,
  finalDailyLogData,
  onDailyLogVaccineSubmit,
}: DailyLogVaccineProps) {
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng");
  const [selectedMethod, setSelectedMethod] = useState<
    "filter" | "search" | null
  >(null);
  const [selectedVaccines, setSelectedVaccines] = useState<any>();
  const { setDailyLogData } = useContext(DailyLogContext);
  useEffect(() => {
    if (form) {
      populateForm(form);
    }
  }, [form]);
  useEffect(() => {
    if (finalDailyLogData?.vaccineData && selectedVaccines !== null) {
      formik.setValues({ ...finalDailyLogData?.vaccineData });
      if (vaccines)
        setSelectedVaccines(
          vaccines.find(
            (vaccine) => (vaccine.Id = finalDailyLogData?.vaccineData.vaccineId)
          )
        );
    }
  }, [finalDailyLogData?.vaccineData]);

  async function populateForm(formValue: any) {
    await formik.setValues(formValue, true);
    await formik.validateForm();
  }

  const formik = useFormik({
    initialValues: {
      vaccineId: "",
      numberOfPigsVaccine: "",
      vaccinePerPig: "",
      totalBottleOfVaccine: "",
    },
    validationSchema: object({
      vaccineId: string().optional(),
      numberOfPigsVaccine: number()
        .positive(`${t("dailyLog.shouldBePossitiveNumberErrorMessage")}`)
        .integer(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .typeError(`${t("dailyLog.shouldBeNumberErrorMessage")}`)
        .when("vaccineId", {
          is: (vaccineId: string | undefined) => vaccineId, // Add type annotation here
          then: (schema) =>
            schema
              .test(
                "max-total-pigs",
                `${t("dailyLog.shouldNotExceedTotalPigsErrorMessage")}`,
                (value) => {
                  return value !== undefined && value <= totalPigs; // Check for undefined here
                }
              )
              .required(
                `${t("dailyLog.numberOfPigForVaccineRequiredErrorMessage")}`
              ),
          otherwise: (schema) => schema.optional(),
        }),
      vaccinePerPig: number()
        .positive(`${t("dailyLog.shouldBePossitiveNumberErrorMessage")}`)
        .typeError(`${t("dailyLog.notAnNumberErrorMessage")}`)
        .test(
          "two-decimal",
          `${t("dailyLog.onlyTwoDecimialErrorMessage")}`,
          twoDecimalPlacesValidator
        )
        .when("vaccineId", {
          is: (vaccineId: any) => vaccineId,
          then: (schema) =>
            schema.required(
              `${t("dailyLog.vaccinePerPigRequiredErrorMessage")}`
            ),
          otherwise: (schema) => schema.optional(),
        }),
      totalBottleOfVaccine: number()
        .positive(`${t("dailyLog.shouldBePossitiveNumberErrorMessage")}`)
        .integer(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .typeError(`${t("dailyLog.shouldBeNumberErrorMessage")}`)
        .when("vaccineId", {
          is: (vaccineId: any) => vaccineId,
          then: (schema) =>
            schema.required(
              `${t("dailyLog.totalBottleOfVaccineRequiredErrorMessage")}`
            ),
          otherwise: (schema) => schema.optional(),
        }),
    }),
    onSubmit: async (values) => {
      try {
        onDailyLogVaccineSubmit?.(values);
      } catch (err) {
        console.error(err);
      }
    },
  });

  const handleSearchChanges = (e: any) => {
    setSelectedMethod(null);
  };
  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 ">
      <div>
        <Autocomplete
          fullWidth
          disableClearable={true}
          disabled={selectedMethod === "search"}
          componentsProps={{
            popper: {
              modifiers: [
                {
                  name: "flip",
                  enabled: false,
                },
                {
                  name: "preventOverflow",
                  enabled: true,
                },
              ],
            },
          }}
          options={vaccines ? vaccines : []}
          getOptionLabel={(option) =>
            language === "en" ? option.nameEn : option.nameTh
          }
          onChange={(event, newValue) => {
            if (newValue === null) {
              formik.setFieldValue("vaccineId", "");
              formik.setFieldValue("numberOfPigsVaccine", "");
              formik.setFieldValue("vaccinePerPig", "");
              formik.setFieldValue("totalBottleOfVaccine", "");
              formik.setTouched({
                vaccineId: false,
                numberOfPigsVaccine: false,
                vaccinePerPig: false,
                totalBottleOfVaccine: false,
              });
              setSelectedVaccines(null);
            } else {
              formik.setFieldValue("vaccineId", newValue?.id);
              setSelectedMethod("filter");
              setSelectedVaccines(newValue);
            }
          }}
          value={selectedVaccines || null}
          onInput={handleSearchChanges}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props} key={`${option.id}`}>
                {language === "en" ? option.nameEn : option.nameTh}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: selectedVaccines ? (
                  <React.Fragment>
                    <IconButton
                      size="small"
                      onClick={() => {
                        formik.setFieldValue("vaccineId", "");
                        formik.setFieldValue("numberOfPigsVaccine", "");
                        formik.setFieldValue("vaccinePerPig", "");
                        formik.setFieldValue("totalBottleOfVaccine", "");
                        formik.setTouched({
                          vaccineId: false,
                          numberOfPigsVaccine: false,
                          vaccinePerPig: false,
                          totalBottleOfVaccine: false,
                        });
                        setSelectedVaccines(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
              label={t("dailyLog.nameOfVaccineLabel")}
              defaultValue={
                selectedVaccines
                  ? language === "en"
                    ? selectedVaccines.nameEn
                    : selectedVaccines.nameTh
                  : ""
              }
            />
          )}
        />
      </div>

      <TextInputFS
        fullWidth
        type="tel"
        helperText={formErrorMessage(formik, "numberOfPigsVaccine")}
        error={formError(formik, "numberOfPigsVaccine")}
        label={t("dailyLog.numberOfPigForVaccineLabel")}
        {...formik.getFieldProps("numberOfPigsVaccine")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium text-base">
                | {t("common.pronoun")}
              </h6>
            </InputAdornment>
          ),
        }}
        disabled={formik.values.vaccineId === ""}
      />

      <TextInputFS
        fullWidth
        type="number"
        helperText={formErrorMessage(formik, "vaccinePerPig")}
        error={formError(formik, "vaccinePerPig")}
        label={t("dailyLog.vaccinePerPigLabel")}
        {...formik.getFieldProps("vaccinePerPig")}
        InputProps={{
          inputProps: {
            inputMode: "decimal",
          },
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium">| {t("common.cc")}</h6>
            </InputAdornment>
          ),
        }}
        disabled={formik.values.vaccineId === ""}
      />

      <TextInputFS
        fullWidth
        type="tel"
        helperText={formErrorMessage(formik, "totalBottleOfVaccine")}
        error={formError(formik, "totalBottleOfVaccine")}
        label={t("dailyLog.totalBottleOfVaccineLabel")}
        {...formik.getFieldProps("totalBottleOfVaccine")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium">
                | {t("common.bottle")}
              </h6>
            </InputAdornment>
          ),
        }}
        disabled={formik.values.vaccineId === ""}
      />

      <div className="flex flex-col gap-4 mb-4">
        <CustomButton
          disabled={
            (formik?.values?.vaccineId !== "" && !formik?.isValid) ||
            formik?.isSubmitting
          }
          variant="tertiary"
          label={t("addHouseDetail.NextButtonLabel")}
        />
      </div>
    </form>
  );
}

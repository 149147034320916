import { InputAdornment, TextFieldProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { ClipboardEvent, KeyboardEvent } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

export interface ITextInputCustomProps {
  borderColor?: string;
  borderRadius?: string;
  textAlign?: string;
  suffix?: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

function TextInputCustom({
  borderColor,
  borderRadius,
  textAlign,
  suffix,
  type,
  ...rest
}: TextFieldProps & ITextInputCustomProps) {
  //* Positive number only
  const handleSanitizedKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    //* Get the current value and the key being pressed
    const { key, target } = event;
    const value = (target as HTMLInputElement).value;

    //* Prevent if the key is '-' or if '--' would be formed
    if (key === "-" || (key === "-" && value.includes("-"))) {
      event.preventDefault();
    }
  };

  //* Positive number only
  const handleSanitizedPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.Clipboard).getData("text");
    const sanitizedPaste = paste.replace(/-/g, "");

    const target = event.target as HTMLInputElement;
    const start = target.selectionStart || 0;
    const end = target.selectionEnd || 0;

    //* Insert the sanitized text at the cursor position
    const newValue =
      target.value.substring(0, start) +
      sanitizedPaste +
      target.value.substring(end);

    //* Update the input value
    target.value = newValue;
    //* Trigger the change event
    const eventInput = new Event("input", { bubbles: true });
    target.dispatchEvent(eventInput);
  };

  return (
    <TextField
      {...rest}
      fullWidth
      variant="outlined"
      autoComplete="off"
      onPaste={type === "number" ? handleSanitizedPaste : undefined}
      onKeyDown={type === "number" ? handleSanitizedKeyDown : undefined}
      InputProps={{
        inputComponent:
          type === "number" ? (NumericFormatCustom as any) : undefined,
        sx: {
          borderRadius: borderRadius || "6px !important",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#191919",
          background: "#FFFFFF",
          borderColor: borderColor || "#E2E2E2",
          textAlign: textAlign || "",
          height: "32px",
          width: "100%",
          paddingRight: "6px",
        },
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "14px",
                color: "#191919",
                paddingRight: "10px",
              },
            }}
          >
            {suffix}
          </InputAdornment>
        ),
        ...rest?.InputProps,
      }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E2E2E2",
        },
        "& .Mui-focused": {
          "& > svg": {
            color: "#000000 !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
          },
        },
        "& .Mui-disabled": {
          color: "black !important",
          WebkitTextFillColor: "black !important",
          bgcolor: "#FBFBFB !important",
        },
        "& input::placeholder": {
          opacity: 1,
          color: "#979797",
        },
        "& .MuiInputBase-input": {
          height: "1.475em",
          padding: "0px 15px",
          ":-webkit-autofill": {
            bgcolor: "transparent",
          },
        },
      }}
      SelectProps={{
        sx: {
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: borderColor || "#E2E2E2",
          textAlign: textAlign || "",

          "& .Mui-disabled": {
            color: "#979797 !important",
            WebkitTextFillColor: "#979797 !important",
            bgcolor: "#FFFFFF !important",
          },
        },
      }}
      InputLabelProps={{
        sx: {
          "&.Mui-disabled": {
            color: "#979797 !important",
          },
        },
      }}
      FormHelperTextProps={{
        sx: {
          "&.Mui-disabled": {
            color: "#d32f2f !important",
            WebkitTextFillColor: "#d32f2f !important",
            bgcolor: "transparent !important",
          },
        },
      }}
    />
  );
}

export default TextInputCustom;

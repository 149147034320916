export enum UserRole {
  Admin = "admin",
  SuperUser = "super_user",
  Sale = "sale",
  CoSale = "co_sale",
  Lending = "lending",
  CoLending = "co_lending",
  Management = "management",
  Guest = "guest",
}

import { useEffect, useMemo, useState } from "react";

import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useShowButtonByRoles } from "../../../../hooks/useActionButtonByRoles";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import {
  deleteFarmer,
  getCustomer,
} from "../../../../services/admin/user-management/customer.service";
import { UserRole } from "../../../../utils/enum/user-role.enum";
import DeleteFillGreen from "../../../assets/svg/DeleteFillGreen.svg";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import EditOrderCustomModal from "../../../components/Modal/EditOrderCustomModal";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../components/Table/TableCustom";
import { usePaginationFilter } from "../../../hooks/usePaginationFilter.hook";
import UserManagementSearch from "../components/UserManagementSearch";
import DetailCustomerModal from "./DetailCustomerModal";

type Props = {};

type TEmployeeStatus = "true" | "false";

export interface IEmployee {
  id: string;
  registrationId: string;
  fullName: string;
  username: string;
  mobileNumber: string;
  email: string;
  registerDate: string;
  lastActiveAt: string;
  status: TEmployeeStatus;
}

function createData(
  id: string,
  registrationId: string,
  fullName: string,
  username: string,
  mobileNumber: string,
  email: string,
  registerDate: string,
  lastActiveAt: string,
  status: TEmployeeStatus
): IEmployee {
  return {
    id,
    registrationId,
    fullName,
    username,
    mobileNumber,
    email: email ? email : "-",
    registerDate: dayjs(registerDate).format("DD/MM/YYYY"),
    lastActiveAt: dayjs(registerDate).format("DD/MM/YYYY HH:mm"),
    status: status as TEmployeeStatus,
  };
}
const headCells: ITableCustomHeadCell<keyof IEmployee>[] = [
  {
    id: "registrationId",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "120px",
    isSorting: true,
  },
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "180px",
    isSorting: false,
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "ชื่อผู้ใช้",
    width: "180px",
    isSorting: false,
  },
  {
    id: "mobileNumber",
    numeric: false,
    disablePadding: false,
    label: "เบอร์โทรศัพท์",
    width: "120px",
    isSorting: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "อีเมล",
    width: "150px",
    isSorting: false,
  },
  {
    id: "registerDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่ลงทะเบียน",
    width: "100px",
    isSorting: false,
  },
  {
    id: "lastActiveAt",
    numeric: false,
    disablePadding: false,
    label: "ใช้งานล่าสุด",
    width: "120px",
    isSorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "สถานะ",
    width: "50px",
    isSorting: false,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "80px",
    isSorting: false,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20px",
    isSorting: false,
  },
];

const EmployeeStatusMap: Record<
  TEmployeeStatus,
  { color: string; bgColor: string; label: string }
> = {
  true: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "เปิดใช้งาน",
  },
  false: {
    color: "#3F3748",
    bgColor: "#E4E4E4",
    label: "ปิดใช้งาน",
  },
};
const EmployeePanel = (props: Props) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const { page, limit, setPage } = usePaginationFilter();
  const [countRows, setCountRows] = useState<number>(0);
  const [list, setList] = useState<IEmployee[]>([]);
  const [direction, setDirection] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IEmployee>("registrationId");
  const [isOpenDetailCustomerModal, setIsOpenDetailCustomerModal] =
    useState(false);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const { showSnackBar } = useSnackBar();
  const [toggleFetch, setToggleFetch] = useState(false);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const MapOrderByWithFilter: { [K in keyof IEmployee]?: string } = {
    registrationId: "farmer_code",
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchText);
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("direction", direction);
    searchParams.append("orderBy", MapOrderByWithFilter[orderBy] ?? "");
    setList([]);
    const data = await getCustomer(searchParams);
    if (data?.farmers) {
      const newList = data.farmers.map((row) => {
        return createData(
          row.id,
          row.registrationId,
          `${row.name} ${row.surname}`,
          row.userName,
          row.mobileNumber,
          row.email,
          row.registerDate,
          row.lastActiveAt,
          row.isActive as TEmployeeStatus
        );
      });
      setCountRows(data.count);
      setList(newList);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [orderBy, direction, page, toggleFetch]);

  const { showButton } = useShowButtonByRoles([
    UserRole.Admin,
    UserRole.Management,
  ]);

  const filterHeaderCell = useMemo(
    () =>
      showButton
        ? headCells
        : headCells.filter((_, idx) => idx <= headCells.length - 2),
    [showButton]
  );

  const onSearchText = () => {
    setPage(1);
    setToggleFetch((prev) => !prev);
  };

  return (
    <div className="flex flex-col w-full pt-6">
      <UserManagementSearch
        searchText={searchText}
        onSearch={onSearchText}
        setSearchText={setSearchText}
        itemSelected={itemSelected}
        placeholder={"ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"}
        feature="customer"
        onClickChangeStatus={() => setItemSelected([])}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={direction}
          orderBy={orderBy}
          rowsPerPage={limit}
          itemsSelected={itemSelected}
          page={page}
          headerItems={filterHeaderCell}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          rows={list}
          currentPageItems={list.map((row) => row.id)}
          onChangePage={(val) => setPage(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={countRows}
          onChangeOrder={(val) => setDirection(val)}
          onChangeOrderBy={(val) => setOrderBy(val as keyof IEmployee)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.registrationId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.fullName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.mobileNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.registerDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.lastActiveAt}
                    </TableCell>

                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={EmployeeStatusMap[row.status]["bgColor"]}
                          fontColor={EmployeeStatusMap[row.status].color}
                          label={EmployeeStatusMap[row.status].label}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          width: "80px",
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                        onClick={() => {
                          setIsOpenDetailCustomerModal(true);
                          setSelectedId(row.id);
                        }}
                      >
                        ดูรายละเอียด
                      </Link>
                    </TableCell>
                    {showButton && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                        onClick={() => {
                          setIsOpenDeleteModal(true);
                          setSelectedId(row.id);
                        }}
                      >
                        <DeleteFillGreen />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <EditOrderCustomModal
        title="ยืนยันการลบ"
        subtitleFirstRow="คุณต้องการลบรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้?"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="ยกเลิก"
        open={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        onSubmit={async () => {
          const payload = {
            id: selectedId,
          };
          await deleteFarmer(payload);
          showSnackBar("ลบลูกค้าสำเร็จ");
          setIsOpenDeleteModal(false);
          onSearchText();
        }}
      />
      <DetailCustomerModal
        showButton={showButton}
        isOpen={isOpenDetailCustomerModal}
        handleClose={() => setIsOpenDetailCustomerModal(false)}
        selectedRow={selectedId}
      />
    </div>
  );
};

export default EmployeePanel;

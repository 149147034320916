import { Modal } from "@mui/material";
import { memo, useCallback } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import AppBarFS from "../../../../../components/app-bar/AppBar";
import { tw } from "../../../../../utils/tw";

import "swiper/css";
import "swiper/css/pagination";
import { TBaseGetListPartnerStockList } from "../../../../../types/partner/stock.type";
import { useActionController } from "../../../../order-history/actionController";
import { useNavigate } from "react-router-dom";

interface ISummaryModalPageProps {
  isOpen: boolean;
  onClose: () => void;
  images: File[];
  stockDetail?: TBaseGetListPartnerStockList;
}

const classes = {
  wrapper: tw(``),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-[#2C702F] rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-[#2C702F] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full h-auto object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const SummaryModalPageStock = (props: ISummaryModalPageProps) => {
  const { isOpen, onClose, images, stockDetail } = props;
  const navigate = useNavigate();

  const { actionFunctions } = useActionController({
    stockDetail,
    onCloseModal: onClose,
  });
  //TODO : check here!!!!
  const onSave = useCallback(() => {
    actionFunctions.proveShipped(images, true);
  }, [actionFunctions.proveShipped, images]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.appbarContainer}>
          <AppBarFS title="หลักฐานการจัดส่ง" onIconPress={() => navigate(-1)} />
        </div>
        <div className={classes.headerContainer}>
          หมายเลขสั่งซื้อ {stockDetail?.stockRefill?.stockRefillNumber}
          <div className={classes.label}>จัดส่งสำเร็จ</div>
        </div>
        <Swiper
          pagination
          modules={[Pagination]}
          loop
          className={classes.swiperWrapper}
        >
          {images.map((image, index) => {
            const src = URL.createObjectURL(image);
            return (
              <SwiperSlide key={`${index}`} className={classes.swiperContainer}>
                <img src={src} className={classes.swiperImage} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="flex flex-1" />
        <div className={classes.danger}>
          {`หากไม่ได้กดบันทึกเอกสารการขาย\nคุณสามารถกลับมาดูย้อนหลังได้ที่เมนูประวัติคำสั่งซื้อ`}
        </div>
        <div className={classes.actionContainer}>
          <button onClick={onSave} className={tw(classes.button)}>
            บันทึกรูปภาพ
          </button>
          <button
            onClick={onClose}
            className={tw(classes.button, "bg-[white] text-secondary")}
          >
            ปิด
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(SummaryModalPageStock);

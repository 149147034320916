import { useCallback, useEffect, useState } from "react";
import { cloneDeep, get, isEmpty, result, set } from "lodash";
import { IFarmers } from "../../../../types/CreditFeed.type";
import {
  getFarmAndHouse,
  getFarmers,
  getId,
  getTabList3,
  getTabList4,
} from "../../../../services/CreditFeed.service";

export const useCreditFeedController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [customerTable, setCustomerTable] = useState<IFarmers[]>([]);
  const [list3Count, setList3Count] = useState(0);
  const [list4Count, setList4Count] = useState(0);
  const [tableCurrentPageTap1, setTableCurrentPageTap1] = useState(1);
  const [tableCurrentPageTap3, setTableCurrentPage3] = useState(1);
  const [tableCurrentPageTap4, setTableCurrentPage4] = useState(1);
  const [customerTableCount, setCustomerTableCount] = useState(0);

  const onSearch = useCallback(() => {}, []);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const initData = useCallback(() => {
    Promise.all([
      getFarmers({ page: 1 }),
      getTabList3({ page: 1, limit: 10 }),
      getTabList4({ page: 1, limit: 10 }),
    ])
      .then(([_farmers, list3, list4]) => {
        setCustomerTable(_farmers!.farmers);
        setList3Count(get(list3, "count"));
        setList4Count(get(list4, "count"));
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, []);

  const pageTap1 = useCallback(() => {
    getFarmers({
      page: tableCurrentPageTap1,
    })
      .then((result) => {
        setCustomerTable(result!.farmers);
        setCustomerTableCount(result!.count);
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, [customerTable, tableCurrentPageTap1]);

  const pageTap3 = useCallback(() => {
    getTabList3({
      page: tableCurrentPageTap3,
      limit: 10,
    })
      .then((result) => {
        setList3Count(get(result, "count"));
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, [tableCurrentPageTap3]);

  const pageTap4 = useCallback(() => {
    getTabList4({
      page: tableCurrentPageTap4,
      limit: 10,
    })
      .then((result) => {
        setList4Count(get(result, "count"));
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, [tableCurrentPageTap4]);

  const refetch = useCallback(() => {
    pageTap1();
    pageTap3();
    pageTap4();
  }, [tableCurrentPageTap1, tableCurrentPageTap3, tableCurrentPageTap4]);

  useEffect(() => {
    initData();
  }, []);

  return {
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
    customerTable,
    refetch,
    tableCurrentPageTap1,
    setTableCurrentPageTap1,
    tableCurrentPageTap3,
    setTableCurrentPage3,
    tableCurrentPageTap4,
    setTableCurrentPage4,
    customerTableCount,
    list3Count,
    list4Count,
    setCustomerTable,
  };
};

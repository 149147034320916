import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, InputAdornment, MenuItem, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FieldArray, FormikProps, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/Farms/delete-icon.svg";
import CustomButton from "../../../components/common/Button";
import DatePickerFS from "../../../components/common/DatePicker";
import { InputNumberString } from "../../../components/common/Input/InputNumberString";
import SelectCustom from "../../../components/common/Input/SelectCustom";
import TextInputCustom from "../../../components/common/Input/TextInputCustom";
import { Contract } from "../../../types/ContactList.type";
import { FeedBrands } from "../../../types/FeedBrand.type";
import { IOrderFeedForm } from "../../../types/OrderFeed.type";
import { formError, formErrorMessage } from "../../../utils/form-validator";
import OrderFeedSingleOrder from "./OrderFeedSingleOrder";

export const DeliveryType = [
  { label: "orderFeed.input.option.pickup", value: "pickup" },
  { label: "orderFeed.input.option.shipping", value: "shipping" },
];

interface OrderFeedDetailsProps {
  formik: FormikProps<IOrderFeedForm>;
  feedBrands: FeedBrands[];
  contracts: Contract[];
}

export default function OrderFeedDetailsForm({
  feedBrands,
  formik,
  contracts,
}: Readonly<OrderFeedDetailsProps>) {
  const minDate = dayjs().startOf("day");
  const { t } = useTranslation();
  const formikContext = useFormikContext<IOrderFeedForm>();

  const calculateSumPrice = () => {
    return formik.values.orders.reduce((prev, curr) => {
      return prev + Number(curr.amount) * Number(curr.pricePerUnit);
    }, 0);
  };
  const handleDateChange = (
    formik: FormikProps<IOrderFeedForm>,
    value: Dayjs | null
  ) => {
    formik.setFieldValue("deliveryDate", value);
    formik.validateField("deliveryDate");
  };

  const handleDateClose = (formik: FormikProps<IOrderFeedForm>) => {
    formik.setFieldTouched("deliveryDate");
    formik.validateField("deliveryDate");
  };

  const handleChangeContract = (contractId: any) => {
    const house = contracts.find((row) => row.id === contractId);
    formik.setFieldValue("houseId", house?.houseId);
    formik.setFieldValue("houseName", house?.house.name);
    formik.setFieldValue("contractName", house?.contractNumber);
    formik.setFieldValue(
      "remainingCredit",
      Math.max((house?.credit || 0) - (house?.creditUsed || 0), 0)
    );
  };

  const handleChangeHouse = (houseId: any) => {
    const contract = contracts.find((row) => row.houseId === houseId);
    formik.setFieldValue("contractId", contract?.id);
    formik.setFieldValue("contractName", contract?.contractNumber);
    formik.setFieldValue("houseName", contract?.house.name);
    formik.setFieldValue(
      "remainingCredit",
      Math.max((contract?.credit || 0) - (contract?.creditUsed || 0), 0)
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col grow gap-4 justify-between"
    >
      <div className="flex flex-col gap-4">
        {/* Select house */}
        <SelectCustom
          displayEmpty
          label={t("orderFeed.input.label.house") as any}
          fullWidth
          variant="outlined"
          shrink={true}
          error={formError(formik, "houseId")}
          helperText={formErrorMessage(formik, "houseId")}
          {...formik.getFieldProps("houseId")}
          renderValue={
            formik.values?.houseId !== ""
              ? undefined
              : () => (
                  <div className="text-[1rem] text-primary-grey-100">
                    {t("orderFeed.input.placeholder.house")}
                  </div>
                )
          }
          onChange={(e) => {
            const { onChange } = formik.getFieldProps("houseId");
            onChange(e);
            handleChangeHouse(e.target.value);
          }}
          menuItems={contracts.map((option, idx) => (
            <MenuItem key={idx} value={option.houseId}>
              {option.house.name}
            </MenuItem>
          ))}
        />
        {/* select contract */}
        <SelectCustom
          displayEmpty
          label={t("orderFeed.input.label.contract") as any}
          fullWidth
          variant="outlined"
          shrink={true}
          error={formError(formik, "contractId")}
          helperText={formErrorMessage(formik, "contractId")}
          {...formik.getFieldProps("contractId")}
          renderValue={
            formik.values?.contractId !== ""
              ? undefined
              : () => (
                  <div className="text-[1rem] text-primary-grey-100">
                    {t("orderFeed.input.placeholder.contract")}
                  </div>
                )
          }
          onChange={(e) => {
            const { onChange } = formik.getFieldProps("contractId");
            onChange(e);
            handleChangeContract(e.target.value);
          }}
          menuItems={contracts.map((option, idx) => (
            <MenuItem key={idx} value={option.id}>
              {option.contractNumber}
            </MenuItem>
          ))}
        />
        <TextInputCustom
          focused={true}
          disabled
          label={t("orderFeed.input.label.remainingCredit")}
          value={formik.values.remainingCredit}
          InputProps={{
            inputComponent: InputNumberString as any,
            endAdornment: (
              <InputAdornment position="end">
                <Typography className="font-medium" color="disabled">
                  | {t("orderFeed.input.unit.baht")}
                </Typography>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon color="disabled" fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <FieldArray name="orders">
        {({ remove, push }) => (
          <div className="flex flex-col gap-4" key="orders-array">
            <>
              {formikContext.values.orders.length <= 1 ? (
                <OrderFeedSingleOrder
                  formik={formik}
                  feedBrands={feedBrands}
                  orderIndex={0}
                />
              ) : (
                formik.values.orders.map((_, idx) => (
                  <div className="flex flex-col gap-4" key={`order-${idx}`}>
                    <div className="flex justify-between">
                      <div>
                        <span className="text-tertiary font-medium text-[20px]">
                          {`${t("orderFeed.listNumber")} ${idx + 1}`}
                        </span>
                      </div>
                      <div
                        className="flex cursor-pointer gap-2"
                        onClick={(e) => {
                          e.preventDefault();
                          remove(idx);
                        }}
                      >
                        <Typography variant="h6" color="error">
                          {`${t("orderFeed.button.delete")}`}
                        </Typography>
                        <img src={DeleteIcon} alt="delete-icon" />
                      </div>
                    </div>
                    <OrderFeedSingleOrder
                      key={`order-details-${idx}`}
                      formik={formik}
                      feedBrands={feedBrands}
                      orderIndex={idx}
                    />
                  </div>
                ))
              )}
              <div className="flex justify-center">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    push({
                      brandId: "",
                      brandSKU: "",
                      amount: "",
                      unit: "",
                      unitLabel: "",
                      pricePerUnit: "",
                      nameTh: "",
                      nameEn: "",
                      skuTh: "",
                      skuEn: "",
                    });
                  }}
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  sx={{
                    color: "#68C184",
                    borderColor: "#68C184",
                    fontWeight: "600",
                    width: "50%",
                  }}
                >
                  {t("orderFeed.button.add")}
                </Button>
              </div>
            </>
          </div>
        )}
      </FieldArray>
      <div className="flex flex-col">
        <div>
          <TextInputCustom
            focused={true}
            disabled
            label={t("orderFeed.input.label.totalPrice")}
            value={calculateSumPrice()}
            InputProps={{
              inputComponent: InputNumberString as any,
              endAdornment: (
                <InputAdornment position="end">
                  <Typography className="font-medium" color="disabled">
                    | {t("orderFeed.input.unit.baht")}
                  </Typography>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon color="disabled" fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <span className="pt-2 text-primary-grey text-[0.75rem]">
            {t("orderFeed.input.notice.price")}
          </span>
        </div>
        <div className="py-4">
          <SelectCustom
            displayEmpty
            label={t("orderFeed.input.label.deliveryType") as any}
            fullWidth
            variant="outlined"
            shrink={true}
            error={formError(formik, "deliveryType")}
            helperText={formErrorMessage(formik, "deliveryType")}
            {...formik.getFieldProps("deliveryType")}
            renderValue={
              formik.values?.deliveryType !== ""
                ? undefined
                : () => (
                    <div className="text-[1rem] text-primary-grey-100">
                      {t("orderFeed.input.placeholder.deliveryType")}
                    </div>
                  )
            }
            menuItems={DeliveryType.map((option, idx) => (
              <MenuItem key={idx} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          />
        </div>
        <DatePickerFS
          minDate={minDate}
          value={formik?.values?.deliveryDate}
          placeholder={`${t("orderFeed.input.placeholder.deliveryDate")}`}
          slotProps={{
            textField: {
              helperText: formErrorMessage(formik, "deliveryDate"),
              error: formError(formik, "deliveryDate"),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <EventIcon color="info" />
                  </InputAdornment>
                ),
              },
            },
          }}
          label={t("orderFeed.input.placeholder.deliveryDate")}
          onChange={(value) => handleDateChange(formik, value)}
          onClose={() => handleDateClose(formik)}
        />
        <span className="pt-2 text-tertiary text-[0.75rem]">
          {t("orderFeed.input.notice.delivery")}
        </span>
      </div>
      <div className="flex flex-col gap-4 mb-4">
        <CustomButton
          disabled={!formik?.isValid}
          variant="tertiary"
          label={t("addHouseDetail.NextButtonLabel")}
        />
      </div>
    </form>
  );
}

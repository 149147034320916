export default function FooterHomeSVG() {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.59 5.22038L12.5002 1.57028C10.6598 -0.0758372 7.8788 -0.0758372 6.03841 1.57028L1.94867 5.22038C0.916011 6.14057 0.322998 7.45952 0.322998 8.85003V15.3527C0.322998 17.4998 2.04069 19.2482 4.15713 19.2482H14.3815C16.4979 19.2482 18.2156 17.4998 18.2156 15.3527V8.85003C18.2156 7.46974 17.6226 6.14057 16.59 5.22038ZM13.1034 16.1707C13.1034 16.5899 12.7558 16.9375 12.3366 16.9375C11.9174 16.9375 11.5698 16.5899 11.5698 16.1707V13.1034C11.5698 11.8355 10.5371 10.8029 9.26931 10.8029C8.00149 10.8029 6.96883 11.8355 6.96883 13.1034V16.1707C6.96883 16.5899 6.6212 16.9375 6.202 16.9375C5.7828 16.9375 5.43518 16.5899 5.43518 16.1707V13.1034C5.43518 10.9869 7.15287 9.26923 9.26931 9.26923C11.3858 9.26923 13.1034 10.9869 13.1034 13.1034V16.1707Z"
        fill="white"
      />
    </svg>
  );
}

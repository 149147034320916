import { useCallback, useEffect, useState } from "react"
import { get } from "lodash"
import { Schema } from "yup"

interface TUseFormValidatorProps {
  errorSchema: Schema<any>
  onValidateSuccess: (props?: any) => void
  onValidateError?: (firstErrorMessage: string) => void
  form: any
  disableClearIsSubmit?: boolean
}

const useFormValidator = (props: TUseFormValidatorProps) => {
  const {
    errorSchema,
    onValidateSuccess,
    onValidateError,
    form,
    disableClearIsSubmit,
  } = props
  const [isSubmit, setIsSubmit] = useState(false)
  const [errors, setErrors] = useState<any>({})

  const validator = useCallback(() => {
    try {
      errorSchema.validateSync(form, { abortEarly: false })
      setErrors({})
      return {
        isValid: true,
        errorTexts: {},
        firstError: '',
      }
    } catch (errors: any) {
      let errorTexts = {}
      for (let i = 0; i < errors.inner.length; i++) {
        const e = errors.inner[i]
        if (e.path) {
          errorTexts = { ...errorTexts, [e.path]: e.message }
        }
      }
      setErrors(errorTexts)
      return {
        isValid: false,
        errorTexts,
        firstError: get(errorTexts, [Object.keys(errorTexts)[0]], 'something went wrong.'),
      }
    }
  }, [errorSchema, form])

  const onSubmit = useCallback((props?: any) => {
    const result = validator()
    if (result.isValid) {
      onValidateSuccess(props)
      if (!disableClearIsSubmit) {
        setIsSubmit(false)
      } else {
        setIsSubmit(true)
      }
    } else if (!isSubmit) {
      setIsSubmit(true)
      onValidateError && onValidateError(
        get(result.errorTexts, [Object.keys(result.errorTexts)[0]], 'something went wrong.')
      )
    } else {
      onValidateError && onValidateError(
        get(result.errorTexts, [Object.keys(result.errorTexts)[0]], 'something went wrong.')
      )
    }
    return result.isValid
  }, [errorSchema, form, isSubmit])

  useEffect(() => {
    if (isSubmit) {
      validator()
    }
  }, [isSubmit, JSON.stringify(form)])

  const resetError = () => {
    setErrors({})
    setIsSubmit(false)
  }

  return {
    errors,
    isSubmit,
    setIsSubmit,
    onSubmit,
    resetError,
    validator,
  }
}

export default useFormValidator

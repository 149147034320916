export const mockDataUpcomingStock = {
  success: true,
  filter: {
    limit: 10,
    direction: "desc",
  },
  result: {
    orderFeeds: [
      {
        contractId: "37487186-bfa2-4e60-ad24-cbd92993398d",
        createdAt: "2024-03-19T03:46:57.143Z",
        houseId: "6ee47b02-46ab-4eec-b204-e8ac1c5f1266",
        id: "cebd0131-20e2-4b7e-9aff-6e91bda96c94",
        orderNumber: "100000015",
        rejectReason: "",
        receiveDate: "2024-03-19T03:46:37.468Z",
        saleReason: "",
        shippingDate: "2024-03-19T03:46:37.468Z",
        shippingType: "pickup",
        status: "waiting_for_acceptance",
        totalFeedPrice: 146,
        updatedAt: "2024-03-19T03:46:57.143Z",
        orderFeedItems: [
          {
            id: "35269e49-271f-4302-a894-48b97526ebed",
            feedAmount: 2,
            feedPricePerUnit: 73,
            feedUnit: "bag",
            totalItemPrice: 146,
            feedBrand: {
              id: "1",
              nameTh: "เบทาโกร",
              nameEn: "Betagro",
            },
            feedBrandItem: {
              id: "142",
              skuTh: "301 บี",
              skuEn: "301B",
            },
          },
        ],
      },
      {
        contractId: "37487186-bfa2-4e60-ad24-cbd92993398d",
        createdAt: "2024-03-19T03:27:28.309Z",
        houseId: "6ee47b02-46ab-4eec-b204-e8ac1c5f1266",
        id: "03db3bc2-3d01-452f-8abb-de8feda0649e",
        orderNumber: "100000014",
        rejectReason: "",
        saleReason: "",
        shippingDate: "2024-03-19T03:27:06.114Z",
        shippingType: "pickup",
        status: "delivery",
        totalFeedPrice: 1573,
        updatedAt: "2024-03-19T03:27:28.309Z",
        receiveDate: "2024-03-19T03:46:37.468Z",
        orderFeedItems: [
          {
            id: "44014f43-3fb9-44ce-9854-78d060dba0f5",
            feedAmount: 11,
            feedPricePerUnit: 143,
            feedUnit: "bag",
            totalItemPrice: 1573,
            feedBrand: {
              id: "1",
              nameTh: "เบทาโกร",
              nameEn: "Betagro",
            },
            feedBrandItem: {
              id: "6",
              skuTh: "324",
              skuEn: "324",
            },
          },
        ],
      },
    ],
    nextPageCursor:
      "WyIyMDI0LTAzLTE0VDA3OjE3OjIyLjQwNFoiLCI4YzM0ZTY3Yy1hYjBkLTRhZmEtOTllYy04Y2ZlYjRjNzFiNmYiXQ==",
  },
};
export const mockDataStockHistory = {
  success: true,
  filter: {
    limit: 10,
    direction: "desc",
  },
  result: {
    orderFeeds: [
      {
        contractId: "37487186-bfa2-4e60-ad24-cbd92993398d",
        createdAt: "2024-03-19T03:46:57.143Z",
        houseId: "6ee47b02-46ab-4eec-b204-e8ac1c5f1266",
        id: "cebd0131-20e2-4b7e-9aff-6e91bda96c94",
        orderNumber: "100000016",
        rejectReason: "",
        receiveDate: "2024-03-19T03:46:37.468Z",
        saleReason: "",
        shippingDate: "2024-03-19T03:46:37.468Z",
        shippingType: "pickup",
        status: "accepted",
        totalFeedPrice: 146,
        updatedAt: "2024-03-19T03:46:57.143Z",
        orderFeedItems: [
          {
            id: "35269e49-271f-4302-a894-48b97526ebed",
            feedAmount: 2,
            feedPricePerUnit: 73,
            feedUnit: "bag",
            totalItemPrice: 146,
            feedBrand: {
              id: "1",
              nameTh: "เบทาโกร",
              nameEn: "Betagro",
            },
            feedBrandItem: {
              id: "142",
              skuTh: "301 บี",
              skuEn: "301B",
            },
          },
        ],
      },
    ],
    nextPageCursor:
      "WyIyMDI0LTAzLTE0VDA3OjE3OjIyLjQwNFoiLCI4YzM0ZTY3Yy1hYjBkLTRhZmEtOTllYy04Y2ZlYjRjNzFiNmYiXQ==",
  },
};

export const mockDataUpcomingStockDetail = {
  success: true,
  result: {
    receiveDate: "2024-03-19T03:27:28.309Z",
    id: "67c21875-2f34-404d-b2ac-38d517895179",
    farmId: "",
    orderNumber: "100000007",
    contractId: "37487186-bfa2-4e60-ad24-cbd92993398d",
    contractNumber: "BU-00004",
    contract: "",
    credit: 32899,
    remainingCredit: 32899,
    totalFeedPrice: 8722,
    status: "waiting_for_acceptance",
    createdAt: "Thu Mar 14 2024 17:24:03 GMT+0700 (Indochina Time)",
    shippingDate: "Sat Mar 30 2024 17:20:12 GMT+0700 (Indochina Time)",
    shippingType: "shipping",
    rejectedStatus: "",
    canceledStatus: "waiting_for_acceptance",
    house: {
      id: "6ee47b02-46ab-4eec-b204-e8ac1c5f1266",
      name: "โรงเรือนคุณภีม",
    },
    orderFeedItems: [
      {
        id: "f3d68f9e-7507-410c-8abc-ca7828ab8452",
        feedAmount: 98,
        feedUnit: "bag",
        totalItemPrice: 8722,
        feedPricePerUnit: 89,
        feedBrand: {
          id: "1",
          nameTh: "เบทาโกร",
          nameEn: "Betagro",
        },
        feedBrandItem: {
          id: "4",
          skuTh: "322",
          skuEn: "322",
          unit: "BAG",
          pricePerUnit: 89,
        },
      },
    ],
  },
};

export const mockDataStockHistoryDetail = {
  success: true,
  result: {
    receiveDate: "2024-03-19T03:27:28.309Z",
    id: "67c21875-2f34-404d-b2ac-38d517895179",
    farmId: "",
    orderNumber: "100000007",
    contractId: "37487186-bfa2-4e60-ad24-cbd92993398d",
    contractNumber: "BU-00004",
    contract: "",
    credit: 32899,
    remainingCredit: 32899,
    totalFeedPrice: 8722,
    status: "accepted",
    createdAt: "Thu Mar 14 2024 17:24:03 GMT+0700 (Indochina Time)",
    shippingDate: "Sat Mar 30 2024 17:20:12 GMT+0700 (Indochina Time)",
    shippingType: "shipping",
    rejectedStatus: "",
    canceledStatus: "waiting_for_acceptance",
    house: {
      id: "6ee47b02-46ab-4eec-b204-e8ac1c5f1266",
      name: "โรงเรือนคุณภีม",
    },
    orderFeedItems: [
      {
        id: "f3d68f9e-7507-410c-8abc-ca7828ab8452",
        feedAmount: 98,
        feedUnit: "bag",
        totalItemPrice: 8722,
        feedPricePerUnit: 89,
        feedBrand: {
          id: "1",
          nameTh: "เบทาโกร",
          nameEn: "Betagro",
        },
        feedBrandItem: {
          id: "4",
          skuTh: "322",
          skuEn: "322",
          unit: "BAG",
          pricePerUnit: 89,
        },
      },
    ],
  },
};

import { Link, SvgIcon, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import DeleteIcon from "../../../assets/svg/DeleteIcon";
import PaperClip from "../../../assets/svg/PaperClip";

interface DownloadLinkWithDeleteProps {
  onDownload: () => Promise<void>;
  onDelete?: () => Promise<void> | undefined;
  name: string;
  hideDeleteBtn?: boolean;
  sx?: SxProps<Theme> | undefined;
  hideClip?: boolean;
}
function DownloadLinkWithDelete({
  hideDeleteBtn,
  name,
  onDelete,
  onDownload,
  sx,
  hideClip,
}: DownloadLinkWithDeleteProps) {
  const [showBtnDelete, setShowBtnDelete] = useState(false);

  return (
    <Link
      underline="hover"
      component="button"
      color="#3777BC"
      className="!text-[14px] flex gap-1 justify-between items-center w-full !rounded-[20px] !p-1 !my-1"
      onClick={() => onDownload()}
      onMouseOver={() => setShowBtnDelete(true)}
      onMouseOut={() => setShowBtnDelete(false)}
      sx={
        sx
          ? { ...sx }
          : {
              ":hover": {
                background: "#F0FDF4",
              },
            }
      }
    >
      <div className="flex items-center">
        {!hideClip && (
          <SvgIcon
            inheritViewBox={true}
            sx={{
              color: "currentColor",
              width: "24px",
              height: "auto",
            }}
            component={"div"}
          >
            <PaperClip />
          </SvgIcon>
        )}
        <span>{name}</span>
      </div>
      {!hideDeleteBtn && showBtnDelete && (
        <SvgIcon
          inheritViewBox={true}
          onClick={(e) => {
            e.stopPropagation();
            if (onDelete) {
              onDelete();
            }
          }}
          sx={{
            color: "red",
            width: "24px",
            height: "auto",
          }}
          component={"div"}
        >
          <DeleteIcon />
        </SvgIcon>
      )}
    </Link>
  );
}
export default DownloadLinkWithDelete;

import { IUpdateStatusEmployeeForm } from "../../../types/admin/user-management/employee";
import {
  IDeletePartner,
  IGetPartner,
  IGetPartnerByID,
  IPartnerForm,
} from "../../../types/admin/user-management/partner";
import adminAPI from "../../AdminApiService";

export const getPartner = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetPartner>(
      `/user-management/partner?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createPartner = async (payload: IPartnerForm) => {
  return adminAPI.post("/user-management/partner", payload);
};

export const updateStatusPartner = async (
  payload: IUpdateStatusEmployeeForm
) => {
  return adminAPI.put("/user-management/partner/active", payload);
};

export const getPartnerById = async (partnerId: string) => {
  try {
    const { data } = await adminAPI.get<IGetPartnerByID>(
      `/user-management/partner/${partnerId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const deletePartner = async (payload: IDeletePartner) => {
  return adminAPI.delete("/user-management/partner", { data: payload });
};

export const editPartner = async (payload: IPartnerForm) => {
  return adminAPI.put("/user-management/partner", payload);
};

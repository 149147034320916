import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  AuthProvider,
  ProtectedRoute,
  UnprotectedRoute,
} from "./components/common/ProtectedRoute/ProtectedRoute";
import "./styles/App.scss";

import { Navigate, useOutlet } from "react-router-dom";
import { AdminAuthProvider } from "./admin/components/AdminAuth/AdminAuthProvider";
import { PartnerAuthProvider } from "./admin/components/PartnerAuth/PartnerAuthProvider";
import CreditFeed from "./admin/pages/credit/credit-feed";
import CreditStatus from "./admin/pages/credit/credit-status";
import GroupArea from "./admin/pages/group-area";

import Home from "./admin/pages/home";
import Login from "./admin/pages/login";
import AzureCallBackPage from "./admin/pages/login/azure";
import { loginLoader } from "./admin/pages/login/loader";
import ManageStock from "./admin/pages/manage-stock";
import ManageStockById from "./admin/pages/manage-stock/[id]";
import { manageStockByIdLoader } from "./admin/pages/manage-stock/[id]/loader";
import { manageStockLoader } from "./admin/pages/manage-stock/loader";
import MasterData from "./admin/pages/masterdata";
import Role from "./admin/pages/role";
import SaleOrder from "./admin/pages/sale-order";
import { SaleOrderProvider } from "./admin/pages/sale-order/Provider/SaleOrderProvider";
import { loadSaleOrderData } from "./admin/pages/sale-order/loader";
import UserManagement from "./admin/pages/user-management";
import { loadUserManagementData } from "./admin/pages/user-management/loader";
import DefaultError from "./components/common/ErrorPage/DefaultErrorPage";
import AddFarm from "./pages/add-farm";
import EditFarmSuccess from "./pages/add-farm/edit-farm-sucess";
import { farmLoader } from "./pages/add-farm/loader";
import AddHouse from "./pages/add-house";
import EditHouse from "./pages/add-house/edit-house";
import EditHouseSuccess from "./pages/add-house/edit-house-success";
import EditFeedingProgram from "./pages/add-house/feeding-program/EditFeedingProgram";
import AddHouseSuccess from "./pages/add-house/house-success";
import EditVaccineProgram from "./pages/add-house/vaccine-program/edit-vaccine-program";
import ProgramOverview from "./pages/add-house/vaccine-program/program-overview";
import Authentication from "./pages/authentication/Authentication";
import { ContactFarmer } from "./pages/contact-farmer";
import { loaderContactFarmer } from "./pages/contact-farmer/loader";
import DailyLog from "./pages/daily-log";
import DailyLogEdit from "./pages/daily-log/daily-log-edit";
import EditSelectMedicine from "./pages/daily-log/edit-select-medicine";
import { dailyLogLoader } from "./pages/daily-log/loader";
import SelectMedicine from "./pages/daily-log/select-medicine";
import FarmDashboard from "./pages/farm-dashboard/FarmDashboard";
import { farmDashboardLoader } from "./pages/farm-dashboard/farm-dashboard.loader";
import FarmPastPerformance from "./pages/farm-past-performance";
import { farmPastPerformanceLoader } from "./pages/farm-past-performance/farm-past-performance.loader";
import Farms from "./pages/farms/Farms";
import FeedOrderAndDelivery from "./pages/feed-order-and-delivery";
import MyOrder from "./pages/feed-order-and-delivery/my-order";
import { ContactSeller } from "./pages/feed-order-and-delivery/my-order/contact-seller";
import { myOrderLoader } from "./pages/feed-order-and-delivery/my-order/loader";
import { default as MyOrderDetail } from "./pages/feed-order-and-delivery/my-order/my-order-detail";
import { myOrderDetailLoader } from "./pages/feed-order-and-delivery/my-order/my-order-detail/loader";
import OrderFeed from "./pages/feed-order-and-delivery/order-feed";
import { orderFeedLoader } from "./pages/feed-order-and-delivery/order-feed/loader";
import { OrderFeedFormSuccess } from "./pages/feed-order-and-delivery/order-feed/success";
import { orderFeedSuccessLoader } from "./pages/feed-order-and-delivery/order-feed/success/loader";
import { default as OrderHistoryPage } from "./pages/feed-order-and-delivery/order-history";
import { orderHistoryLoader } from "./pages/feed-order-and-delivery/order-history/loader";
import OrderHistoryDetail from "./pages/feed-order-and-delivery/order-history/order-history-detail";
import OrderViewDocument from "./pages/feed-order-and-delivery/order-view-document";
import FinancialSupport from "./pages/financial-support";
import FinancialContract from "./pages/financial-support/financial-contract";
import FinancialHistory from "./pages/financial-support/financial-history";
import FinancialShowHistoryDetail from "./pages/financial-support/financial-history/show-history";
import NotificationPage from "./pages/homepage/notificaction-page";
import { HouseDashboard } from "./pages/house-dashboard/HouseDashboard";
import HouseCurrentProgram from "./pages/house-dashboard/current-program";
import {
  houseDashboardLoader,
  moveOutPigHouseLoader,
} from "./pages/house-dashboard/house-dashboard.loader";
import { HouseMoveOutPig } from "./pages/house-dashboard/move-out-pig";
import { HouseMoveOutPigSelling } from "./pages/house-dashboard/move-out-pig-selling";
import MoveOutPigSuccess from "./pages/house-dashboard/move-out-pig-success";
import HousePerformanceReport from "./pages/house-performance-report";
import Manualfile from "./pages/manual-file/Manualfile";
import OrderHistory from "./pages/order-history";
import NewOrder from "./pages/order-history/new-order";
import OrderDetail from "./pages/order-history/new-order/detail";
import ProveShipping from "./pages/order-history/new-order/proveShipping";
import PartnerPinAuthentication from "./pages/partner/reset-pin/1-pin-authentication";
import PartnerUpdatePin from "./pages/partner/reset-pin/2-update-pin";
import PartnerResetPinSuccess from "./pages/partner/reset-pin/3-success";
import RegisterFarmSuccess from "./pages/register-farm/RegisterSuccess";
import PinAuthentication from "./pages/reset-pin/pin-authorization";
import ResetPinSuccess from "./pages/reset-pin/reset-pin-success";
import UpdatePin from "./pages/reset-pin/update-pin";
import { SellingHistory } from "./pages/selling-history";
import { moveOutPigSellingHistoryLoader } from "./pages/selling-history/selling-history.loader";
import { SellingReport } from "./pages/selling-report";
import { sellingReportLoader } from "./pages/selling-report/selling-report.loader";
import SetPin from "./pages/set-pin";
import SingupSuccess from "./pages/signup-success";
import Signup from "./pages/signup/signup";
import SignupPage from "./pages/signuppage";
import Stock from "./pages/stock";
import PartnerLanding from "./pages/stock/PartnerLanding";
import StockHistory from "./pages/stock/stock-history";
import { historyStockLoader } from "./pages/stock/stock-history/loader";
import StockHistoryDetail from "./pages/stock/stock-history/stock-history-detail";
import { historyStockDetailLoader } from "./pages/stock/stock-history/stock-history-detail/loader";
import StockUploadDocument from "./pages/stock/stock-upload-documents";
import UpcomingStock from "./pages/stock/upcoming-stock";
import { upComingStockLoader } from "./pages/stock/upcoming-stock/loader";
import StockProveShipping from "./pages/stock/upcoming-stock/proveShipping";
import UpcomingStockDetail from "./pages/stock/upcoming-stock/upcoming-stock-detail";
import { upComingStockDetailLoader } from "./pages/stock/upcoming-stock/upcoming-stock-detail/loader";
import OnboardingPage from "./pages/welcome/OnboardingPage";
import { WelcomLoginPage } from "./pages/welcome/WelcomeLoginPage";
import { SnackBarProvider } from "./provider/SnackBarProvider";

export const AuthLayout = () => {
  const outlet = useOutlet();

  return <AuthProvider>{outlet}</AuthProvider>;
};
export const PartnerLayout = () => {
  const outlet = useOutlet();
  return <PartnerAuthProvider>{outlet}</PartnerAuthProvider>;
};

export const AdminAuthLayout = () => {
  const outlet = useOutlet();
  return (
    <AdminAuthProvider>
      <SnackBarProvider>{outlet}</SnackBarProvider>
    </AdminAuthProvider>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthLayout />}>
        <Route
          path="/"
          element={
            <UnprotectedRoute>
              <OnboardingPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <UnprotectedRoute>
              <Signup />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/authentication"
          element={
            <UnprotectedRoute>
              <Authentication />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/welcome"
          element={
            <UnprotectedRoute>
              <WelcomLoginPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/onboarding"
          element={
            <UnprotectedRoute>
              <OnboardingPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/manualfile"
          element={
            <UnprotectedRoute>
              <Manualfile />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <UnprotectedRoute>
              {/* <LoginPage /> */}
              <WelcomLoginPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/signup-page"
          element={
            <UnprotectedRoute>
              <SignupPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/set-pin"
          element={
            <UnprotectedRoute>
              <SetPin />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/signup-success"
          element={
            <UnprotectedRoute>
              <SingupSuccess />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/pin-authentication"
          element={
            <UnprotectedRoute>
              <PinAuthentication />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/update-pin"
          element={
            <UnprotectedRoute>
              <UpdatePin />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/reset-pin-success"
          element={
            <UnprotectedRoute>
              <ResetPinSuccess />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/add-farm"
          element={
            <ProtectedRoute>
              <AddFarm />
            </ProtectedRoute>
          }
          loader={() => farmLoader()}
        />
        <Route
          path="/edit-farm"
          element={
            <ProtectedRoute>
              <AddFarm />
            </ProtectedRoute>
          }
          loader={() => farmLoader()}
        />
        <Route
          path="/edit-farm-success"
          element={
            <ProtectedRoute>
              <EditFarmSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farms"
          element={
            <ProtectedRoute>
              <Farms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/error"
          element={
            <ProtectedRoute>
              <DefaultError />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-farm-success"
          element={
            <ProtectedRoute>
              <RegisterFarmSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <NotificationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-house"
          element={
            <ProtectedRoute>
              <AddHouse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-house"
          element={
            <ProtectedRoute>
              <EditHouse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-vaccine"
          element={
            <ProtectedRoute>
              <EditVaccineProgram />
            </ProtectedRoute>
          }
        />
        <Route
          path="/program-overview"
          element={
            <ProtectedRoute>
              <ProgramOverview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/house-success/:farmId"
          element={
            <ProtectedRoute>
              <AddHouseSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quick-daily-log"
          element={
            <ProtectedRoute>
              <DailyLog />
            </ProtectedRoute>
          }
          loader={() => dailyLogLoader()}
        />
        <Route
          path="/daily-log"
          element={
            <ProtectedRoute>
              <DailyLog />
            </ProtectedRoute>
          }
          loader={() => dailyLogLoader()}
        />
        <Route
          path="/daily-log-edit"
          element={
            <ProtectedRoute>
              <DailyLogEdit />{" "}
            </ProtectedRoute>
          }
          loader={() => dailyLogLoader()}
        />
        <Route
          path="/select-medicine"
          element={
            <ProtectedRoute>
              <SelectMedicine />
            </ProtectedRoute>
          }
        />
        <Route path="/edit-feed-program" element={<EditFeedingProgram />} />
        <Route path="/edit-house-success" element={<EditHouseSuccess />} />
        <Route
          path="/houses/:houseId/program-overview"
          element={
            <ProtectedRoute>
              <HouseCurrentProgram />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farm/:farmId"
          element={
            <ProtectedRoute>
              <FarmDashboard />
            </ProtectedRoute>
          }
          loader={({ params }) => farmDashboardLoader({ params })}
          errorElement={<Navigate to="/error" />}
        />
        <Route
          path="/my-order/:farmId"
          element={
            <ProtectedRoute>
              <MyOrder />
            </ProtectedRoute>
          }
          loader={(loader) => myOrderLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/my-order-detail/:farmId/:orderId"
          element={
            <ProtectedRoute>
              <MyOrderDetail />
            </ProtectedRoute>
          }
          loader={(loader) => myOrderDetailLoader(loader)}
        />
        <Route
          path="/order-history-detail/:farmId/:orderId"
          element={
            <ProtectedRoute>
              <OrderHistoryDetail />
            </ProtectedRoute>
          }
          loader={(loader) => myOrderDetailLoader(loader)}
        />
        <Route
          path="/order-history"
          element={
            <ProtectedRoute>
              <OrderHistoryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner"
          element={
            <ProtectedRoute>
              <PartnerLanding />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order"
          element={<OrderHistory />}
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/new"
          element={
            <ProtectedRoute>
              <NewOrder />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/detail/:id"
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/prove-shipping/:orderId"
          element={
            <ProtectedRoute>
              <ProveShipping />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/history"
          element={
            <ProtectedRoute>
              <NewOrder isHistory />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/stock"
          element={
            <ProtectedRoute>
              <Stock />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/stock/upcoming-stock"
          element={
            <ProtectedRoute>
              <UpcomingStock />
            </ProtectedRoute>
          }
          loader={(loader) => upComingStockLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/stock/upcoming-stock/:stockId"
          element={
            <ProtectedRoute>
              <UpcomingStockDetail />
            </ProtectedRoute>
          }
          loader={(loader) => upComingStockDetailLoader(loader)}
        />
        <Route
          path="/stock/prove-shipping/:stockId"
          element={
            <ProtectedRoute>
              <StockProveShipping />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/stock/stock-history"
          element={
            <ProtectedRoute>
              <StockHistory />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
          loader={(loader) => historyStockLoader(loader)}
        />
        <Route
          path="/stock/stock-history/:stockId"
          element={
            <ProtectedRoute>
              <StockHistoryDetail />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
          loader={(loader) => historyStockDetailLoader(loader)}
        />
        <Route
          path="/stock/stock-upload-documents"
          element={
            <ProtectedRoute>
              <StockUploadDocument />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/partner/reset-pin/pin-authentication"
          element={
            <ProtectedRoute>
              <PartnerPinAuthentication />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/reset-pin/update-pin"
          element={
            <ProtectedRoute>
              <PartnerUpdatePin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/reset-pin/success"
          element={
            <ProtectedRoute>
              <PartnerResetPinSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/house-performance-report"
          element={<HousePerformanceReport />}
        />
        <Route
          path="/feed-order-and-delivery/order-feed/success"
          element={
            <ProtectedRoute>
              <OrderFeedFormSuccess />
            </ProtectedRoute>
          }
          loader={(loader) => orderFeedLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/order-history/:farmId"
          element={
            <ProtectedRoute>
              <OrderHistoryPage />
            </ProtectedRoute>
          }
          loader={(loader) => orderHistoryLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/contact-seller"
          element={
            <ProtectedRoute>
              <ContactSeller />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-select-medicine"
          element={
            <ProtectedRoute>
              <EditSelectMedicine />
            </ProtectedRoute>
          }
        />
        <Route
          path="/house/:houseId"
          element={
            <ProtectedRoute>
              <HouseDashboard />
            </ProtectedRoute>
          }
          loader={({ params }) => houseDashboardLoader({ params })}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/:houseId/move-out-pig"
          element={
            <ProtectedRoute>
              <HouseMoveOutPig />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            moveOutPigHouseLoader({ params: params as { houseId: string } })
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/:houseId/move-out-pig-selling"
          element={
            <ProtectedRoute>
              <HouseMoveOutPigSelling />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            moveOutPigHouseLoader({ params: params as { houseId: string } })
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/:moveOutPigId/move-out-pig-success"
          element={
            <ProtectedRoute>
              <MoveOutPigSuccess />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/selling-history/:houseId"
          element={
            <ProtectedRoute>
              <SellingHistory />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            moveOutPigSellingHistoryLoader({
              params: params as { houseId: string },
            })
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/selling-report/:houseId"
          element={
            <ProtectedRoute>
              <SellingReport />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            sellingReportLoader({ params: params as { houseId: string } })
          }
          // errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/farm-past-performance/:farmId"
          element={
            <ProtectedRoute>
              <FarmPastPerformance />
            </ProtectedRoute>
          }
          loader={({ params }) => farmPastPerformanceLoader({ params })}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/feed-order-and-delivery/order-feed/:farmId"
          element={
            <ProtectedRoute>
              <OrderFeed />
            </ProtectedRoute>
          }
          loader={(loader) => orderFeedLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/feed-order-and-delivery/order-feed/:farmId/success"
          element={
            <ProtectedRoute>
              <OrderFeedFormSuccess />
            </ProtectedRoute>
          }
          loader={(loader) => orderFeedSuccessLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/feed-order-and-delivery/:farmId/:orderId/view-document"
          element={
            <ProtectedRoute>
              <OrderViewDocument />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/farms" />}
          loader={(loader) => myOrderDetailLoader(loader)}
        />
        <Route
          path="/feed-order-and-delivery/:farmId"
          element={
            <ProtectedRoute>
              <FeedOrderAndDelivery />
            </ProtectedRoute>
          }
          loader={({ params }) => params}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Farms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support"
          element={
            <ProtectedRoute>
              <FinancialSupport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-contract"
          element={
            <ProtectedRoute>
              <FinancialContract />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-history"
          element={
            <ProtectedRoute>
              <FinancialHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-history/show-history"
          element={
            <ProtectedRoute>
              <FinancialShowHistoryDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/contact-buyer/:id"
          element={
            <ProtectedRoute>
              <ContactFarmer />
            </ProtectedRoute>
          }
          loader={({ params }) => loaderContactFarmer(params)}
        />
      </Route>
      {/* Admin */}
      <Route path="/admin/login/azure" element={<AzureCallBackPage />} />
      <Route element={<AdminAuthLayout />}>
        <Route path="/admin" element={<Login />} loader={loginLoader} />
        <Route path="/admin/login" element={<Login />} loader={loginLoader} />
        <Route path="/admin/home" element={<Home />} />
        <Route path="/admin/masterdata" element={<MasterData />} />
        <Route
          path="/admin/sale-order"
          element={
            <SaleOrderProvider>
              <SaleOrder />
            </SaleOrderProvider>
          }
          loader={loadSaleOrderData}
        />
        <Route
          path="/admin/manage-stock"
          element={<ManageStock />}
          loader={manageStockLoader}
        />
        <Route
          path="/admin/manage-stock/:id"
          loader={({ params }) => manageStockByIdLoader(params)}
          element={<ManageStockById />}
        />
        <Route
          path="/admin/user-management"
          element={<UserManagement />}
          loader={loadUserManagementData}
        />
        <Route path="/admin/credit/credit-feed" element={<CreditFeed />} />
        <Route path="/admin/credit/credit-status" element={<CreditStatus />} />
        <Route path="/admin/group-area" element={<GroupArea />} />
        <Route path="/admin/role" element={<Role />} />
      </Route>
    </>
  )
);
function App() {
  return (
    <>
      {/* <Helmet> */}
      {/* Default setup */}
      {/* <meta
            http-equiv="Content-Security-Policy"
            content="default-src 'self'  ; img-src https://*; child-src 'none';" 
        /> */}

      {/* More config */}
      {/* <meta http-equiv="Content-Security-Policy" content="
          default-src 'self';
          script-src 'self' https://cdn.example.com;
          style-src 'self' 'unsafe-inline';
          img-src 'self' data:;
          connect-src 'self' https://api.example.com;
          font-src 'self' https://fonts.gstatic.com;
          media-src 'self' https://media.example.com;
          object-src 'none';
          frame-src https://www.youtube.com;"
        /> */}
      {/* </Helmet> */}

      <div className="App">
        {/* <Routes>
          <Route path='/' element={<OnboardingPage />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/authentication' element={<Authentication />} />
          <Route path='/welcome' element={<WelcomLoginPage />} />
          <Route path='/onboarding' element={<OnboardingPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/signup-page' element={<SignupPage />} />
          <Route path='/set-pin' element={<SetPin />} />
          <Route path='/signup-success' element={<SingupSuccess />} />
          <Route path='/pin-authentication' element={<PinAuthentication />} />
          <Route path='/update-pin' element={<UpdatePin />} />
          <Route path='/reset-pin-success' element={<ResetPinSuccess />} />
          <Route path='/add-farm' element={<AddFarm />} loader={() => farmLoader()} />
          <Route path='/edit-farm' element={<AddFarm />} loader={() => farmLoader()} />
          <Route path='/farms' element={<Farms />} />
          <Route path='/add-farm-success' element={<RegisterFarmSuccess />} />
          <Route path="/notifications" element={<NotificationPage />} />
          <Route path='/add-house' element={<AddHouse />} />
          <Route path='/add-house/success' element={<AddHouseSuccess />} />
          <Route path='/edit-vaccine' element={<EditVaccineProgram />} />
          <Route path='/program-overview' element={<ProgramOverview />} />
        </Routes> */}
        {/* <FeedingProvider>
          <Routes>
            <Route path='/' element={<OnboardingPage />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/authentication' element={<Authentication />} />
            <Route path='/welcome' element={<WelcomLoginPage />} />
            <Route path='/onboarding' element={<OnboardingPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup-page' element={<SignupPage />} />
            <Route path='/set-pin' element={<SetPin />} />
            <Route path='/signup-success' element={<SingupSuccess />} />
            <Route path='/pin-authentication' element={<PinAuthentication />} />
            <Route path='/update-pin' element={<UpdatePin />} />
            <Route path='/reset-pin-success' element={<ResetPinSuccess />} />
            <Route path='/add-farm' element={<AddFarm />} />
            <Route path='/farms' element={<Farms />} />
            <Route path='/add-farm-success' element={<RegisterFarmSuccess />} />
            <Route path="/notifications" element={<NotificationPage />} />
            <Route path='/add-house' element={<AddHouse />} />
            <Route path='/edit-house' element={<EditHouse />} />
            <Route path='/edit-vaccine' element={<EditVaccineProgram />} />
            <Route path='/program-overview' element={<ProgramOverview />} />
            <Route path='/house-success' element={<AddHouseSuccess />} />
            <Route path='/daily-log' element={<DailyLog/>} />
          </Routes>
        </FeedingProvider> */}
      </div>
    </>
  );
}

export default App;

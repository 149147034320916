import { TDropdownValue } from "../../../group-area/views/Dropdown";

export type TForm = {
  farmSize: TDropdownValue[];
  farmName: TDropdownValue[];
  greenHouse: TDropdownValue[];
  pigType: string;
  countPig: string;
  financialAmount: string;
  grade: TDropdownValue[];
  contractType: TDropdownValue[];
  timeCredit: string;
};

export const defaultForm: TForm = {
  farmSize: [],
  farmName: [],
  greenHouse: [],
  pigType: "หมูขุน",
  countPig: "",
  financialAmount: "",
  grade: [],
  contractType: [],
  timeCredit: "",
};

import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton } from "@mui/material";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { OrderStockFood } from "../../admin/assets/svg/OrderStockFood.svg";
import BackgroundImage from "../../assets/images/Farms/FarmBackground.png";
import FarmIcon from "../../assets/images/OnboardingImages/FarmIcon.svg";
import topIcon from "../../assets/images/OnboardingImages/top_img-2.svg";
import topIcon3 from "../../assets/images/OnboardingImages/top_img-3.svg";
import ImgMngStock from "../../assets/images/manage-stock-bg.jpeg";
import ButtonBackgroundImageCard from "../../components/common/ButtonBackgroundImageCard/ButtonBackgroundImageCard";
import { mockDataUpcomingStock } from "../../types/UpcomingStock";
import { useNewOrderController } from "../order-history/new-order/controller";
import OrderItem from "../order-history/views/OrderItem";
import {
  TBaseGetListPartnerStockList,
  TUintStock,
} from "../../types/partner/stock.type";
import { useEffect, useState } from "react";
import { getPartnerUnitStock } from "../../services/partner/historyStock.service";

type Props = {};

const PartnerLanding = (props: Props) => {
  const {
    orderList,
    onBack,
    onMoreDetail,
    orderBy,
    setOrderBy,
    onAction,
    onCloseAction,
    actionModalDetail,
    isShowActionModal,
  } = useNewOrderController(false);

  const stock = mockDataUpcomingStock;
  const navigate = useNavigate();
  const [unitStock, setUnitStock] = useState<TUintStock>();

  const fetchUintStock = async () => {
    const unitStock = await getPartnerUnitStock();
    if (unitStock) {
      setUnitStock(unitStock);
    }
  };
  useEffect(() => {
    fetchUintStock();
  }, []);

  return (
    <div>
      <div className="flex">
        <div className="flex-grow">
          <div
            className={`top-img-container bg-gray-200 flex flex-col bg-cover relative`}
            style={{
              backgroundColor: "rgb(229 231 235 / 0)",
              backgroundImage: `url(${BackgroundImage})`,
              minHeight: "100vh",
            }}
          >
            <img
              src={topIcon}
              className="w-[65%] top-10 right-4 opacity-25 absolute"
              alt="Top Icon"
            />
            <img
              src={topIcon3}
              className="topIcon1 w-[20%] top-[19%] right-12 opacity-25 absolute"
              alt="Top Icon 3"
            />
            <div className="flex w-11/12 relative justify-between items-center top-6 z-1 mx-auto">
              <img src={FarmIcon} className="w-[60%]" alt="farm-icon" />
              <Link to={"/notifications"}>
                <IconButton className="relative">
                  <NotificationsIcon className="notification-icon" />
                  <span className="absolute w-[7px] h-[7px] bg-[#F57C00] rounded-full top-[12px] left-[12px]"></span>
                </IconButton>
              </Link>
            </div>
            <div className="farms-headTitle-container flex relative justify-left top-6 z-1 ml-4 mt-9">
              <h1 className="farms-headTitle text-[#ffff]">{"ยินดีต้อนรับ"}</h1>
            </div>

            <div
              className="w-11/12 mt-[35px] mx-auto my-10 flex flex-col bg-white-200 pt-[10px] pb-9 px-[25px] rounded-[30px] bg-[#ffffff] transform "
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              <div className="flex justify-between mt-[10px] gap-0 text-tertiary">
                <div className="flex ">
                  <PersonIcon />
                  <h2 className="text-base font-semibold ml-1">ลูกค้า</h2>
                </div>
                <div className="flex items-center">
                  <h2 className="text-[14px] font-normal">ดูรายละเอียด</h2>
                  <ChevronRightOutlinedIcon />
                </div>
              </div>
              <div className="flex justify-start gap-6 mt-5 text-primary-900">
                <div className="flex-col">
                  <p className="text-xl font-medium">0</p>
                  <span>Onboard Customer</span>
                </div>
                <div className="flex-col">
                  <p className="text-xl font-medium">0</p>
                  <span>ลูกค้าเครดิต</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col mx-4 mb-3">
              <div className="flex justify-between">
                <h2 className="text-primary text-xl font-semibold">ออเดอร์</h2>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => navigate("/order")}
                >
                  <h2 className={"text-primary text-[14px]"}>ออเดอร์ทั้งหมด</h2>
                  <ChevronRightOutlinedIcon sx={{ color: "#34407b" }} />
                </div>
              </div>
              <span className="text-sm font-normal text-[#747474] pt-2">
                จัดการออเดอร์ของคุณในที่เดียว
              </span>
              {orderList.length > 0 ? (
                <div className="pt-4 pb-4">
                  {(orderList || [])
                    .filter((order) => order.status === "new")
                    .slice(0, 3)
                    .map((order, index) => (
                      <OrderItem
                        key={order.id}
                        {...order}
                        index={index}
                        onMoreDetail={onMoreDetail(order.id)}
                        onAction={onAction(order)}
                        hideButtonAction
                      />
                    ))}
                </div>
              ) : (
                <h2 className="w-full flex justify-center text-sm my-4 text-[#C9C9C9]">
                  ไม่มีออเดอร์
                </h2>
              )}
            </div>
            <div className="flex flex-col mx-4 mb-3">
              <div className="flex justify-between">
                <h2 className="text-primary text-xl font-semibold">
                  สต๊อกสินค้า
                </h2>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    navigate("/stock");
                  }}
                >
                  <h2 className={"text-primary text-[14px]"}>ดูรายละเอียด</h2>
                  <ChevronRightOutlinedIcon sx={{ color: "#34407b" }} />
                </div>
              </div>
              <span className="text-sm font-normal text-[#747474] pt-2">
                จัดการสต็อกสินค้าของคุณ
              </span>
            </div>
            <div className="mx-4">
              <span className="font-semibold">อาหาร</span>
              <div
                className="w-full flex justify-between my-3 items-center bg-white-200 px-4 py-2 rounded-[20px] bg-[#ffffff] transform "
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 5px" }}
              >
                <div className="flex items-center">
                  <OrderStockFood />
                  <span className="text-tertiary font-semibold">
                    สต๊อกทั้งหมด
                  </span>
                </div>
                <span className="text-primary font-medium">
                  {unitStock?.result}
                </span>
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-4 mx-4 pb-6">
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <h2 className="text-primary text-xl font-semibold">
                    ออเดอร์สต๊อกสินค้า
                  </h2>
                </div>
                <span className="text-sm font-normal text-tertiary pt-2">
                  ออเดอร์สต็อกสินค้าของคุณ
                </span>
              </div>
              <ButtonBackgroundImageCard
                image={ImgMngStock}
                title="ให้เรามีส่วนช่วยธุรกิจของคุณ"
                btnTitle="Manage stock"
                onClick={() => {
                  navigate("/stock");
                }}
              />
            </div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerLanding;

import { useCallback, useEffect, useState } from "react";
import { getFarmAndHouse } from "../../../../../services/CreditFeed.service";
import { IFarmAndHouse } from "../../../../../types/CreditFeed.type";

export const useApproveCreditController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [isOpenApproveCredit, setIsOpenApproveCredit] = useState(false);
  const [isOpenRejectCredit, setIsOpenRejectCredit] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const onOpenApproveCredit = useCallback(
    () => setIsOpenApproveCredit(true),
    []
  );
  const onHideApproveCredit = useCallback(
    () => setIsOpenApproveCredit(false),
    []
  );

  const onOpenRejectCredit = useCallback(() => setIsOpenRejectCredit(true), []);
  const onHideRejectCredit = useCallback(
    () => setIsOpenRejectCredit(false),
    []
  );

  const onSearch = useCallback(() => {}, []);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  return {
    isOpenApproveCredit,
    onHideApproveCredit,
    onOpenApproveCredit,
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isOpenRejectCredit,
    onOpenRejectCredit,
    onHideRejectCredit,
  };
};

import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddHouseNoticeModal from "../../../components/common/AddHouseNoticeModal/AddHouseNoticeModal";
import RadioButton from "../../../components/common/RadioButton";
import CustomTable from "../../../components/common/Table";
import { vaccineProgramList } from "../../../services/AuthService";
import FeedingContext from "../feeding-program/Provider/FeedingContext";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useLocation } from "react-router-dom";

interface AddhouseVaccineProps {
  onSubmit?: (form: any) => void;
  basicDetailForm?: any;
  houseId?: String;
  veccEdited?: boolean;
}

const VaccineProgram = ({
  onSubmit,
  basicDetailForm,
  houseId,
  veccEdited,
}: AddhouseVaccineProps) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng");
  const [addHouseNoticeModal, setAddHouseNoticeModal] = useState(false);
  const [vaccineProgramData, setVaccineProgramData] = useState<any>([]);
  const [vaccineProgramDetailsData, setVaccineProgramDetailsData] =
    useState<any>([]);
  const { finalAddHouseData, setAddHouseData, isEditHouse } =
    useContext(FeedingContext);
  const location = useLocation();

  const handleModalClose = () => {
    setAddHouseNoticeModal(false);
  };

  const handleEditVaccineProgram = (vaccineProgramDetails: any) => {
    setVaccineProgramDetailsData(vaccineProgramDetails);
    setAddHouseNoticeModal(true);
  };

  const navigate = useNavigate();

  const tableHeadData = [
    { nameEn: "pig age", nameTh: "อายุหมู" },
    { nameEn: "Vaccine", nameTh: "วัคซีน" },
    { nameEn: "quantity", nameTh: "ปริมาณ" },
  ];

  const getvaccineProgramList = async () => {
    const resp = await vaccineProgramList(
      finalAddHouseData?.basicDetails?.ageOfPig
    );
    if (resp?.status === 200) {
      if (isEditHouse) {
        let data: any = [];
        data[0] = resp?.data?.vaccineProgram[0];
        const updatedList = basicDetailForm.vaccineDetails.sort(
          (a: any, b: any) => {
            return a.pigAge - b.pigAge;
          }
        );
        data[0].vaccineProgramDetails = updatedList;
        setVaccineProgramData(data);
        setAddHouseData &&
          setAddHouseData({ ...finalAddHouseData, vaccineDetails: data });
      } else {
        setVaccineProgramData(resp?.data?.vaccineProgram);
        setAddHouseData &&
          setAddHouseData({
            ...finalAddHouseData,
            vaccineDetails: resp?.data?.vaccineProgram,
          });
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem("skip");
    if (!finalAddHouseData?.vaccineDetails) getvaccineProgramList();
    else setVaccineProgramData(finalAddHouseData?.vaccineDetails);
  }, []);

  const handleNext = () => {
    localStorage.setItem("skip", "true");
    setAddHouseData &&
      setAddHouseData({ ...finalAddHouseData, vaccineDetails: [] });
    navigate("/program-overview", {
      state: {
        houseId: houseId,
      },
    });
  };

  const instructionsData = (instructionValue: any) => {
    const dataArray = instructionValue?.split("\n");
    const instructionsArray = dataArray?.reduce((acc: any, item: any) => {
      const trimmedItem = item.trim();
      if (trimmedItem !== "") {
        const instructionsItem = trimmedItem.replace(/^\d+\s/g, "");
        acc.push({ instructions: instructionsItem });
      }
      return acc;
    }, []);

    return (
      <>
        {instructionsArray &&
          instructionsArray.map((item: any, index: any) => {
            return (
              <div key={index} className="flex">
                <span
                  className="flex-none"
                  style={{
                    height: "22px",
                    width: "5px",
                    backgroundColor: "#DC5B5B",
                    borderRadius: "99px",
                    marginRight: "10px",
                  }}
                ></span>
                <span className="text-[#7A1B1B] text-[14px]">
                  {item?.instructions}
                </span>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <div className="w-full mt-[20px]">
      <div className="text-[#3777BC] text-[20px] font-semibold mb-[24px]">
        {t("addHouse.recommendVaccineTitle")}
      </div>
      {location.state?.veccEdited && !veccEdited && (
        <div
          style={{
            background: "#68C184",
            width: "9rem",
            borderRadius: "10px 10px 0px 0",
            color: "white",
            display: "flex",
            position: "relative",
            top: "1px",
            left: "20px",
          }}
        >
          <PushPinIcon
            style={{
              transform: "rotate(45deg)",
              marginTop: "4px",
              marginLeft: "20px",
              width: "20px",
              height: "35px",
            }}
          />
          <button
            style={{
              padding: "8px",
              fontSize: "17px",
              fontWeight: "700",
              lineHeight: "19.2px",
              fontStyle: "normal",
            }}
          >
            {t("feedingProgram.pinned")}
          </button>
        </div>
      )}

      {vaccineProgramData?.length &&
        vaccineProgramData?.map((item: any, index: number) => {
          return (
            <>
              <div key={index} className="mb-[20px]">
                <div className="bg-[#D9EBFE] flex items-center p-[16px] pb-[8px] rounded-t-lg justify-between">
                  <RadioButton />
                  <h5 className="text-[20px] font-medium">
                    {language === "th" ? (
                      <>{item?.titleTh}</>
                    ) : (
                      <>{item?.titleEn}</>
                    )}
                  </h5>
                  <button
                    className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]"
                    onClick={() => {
                      handleEditVaccineProgram(item?.vaccineProgramDetails);
                    }}
                  >
                    {t("addHouse.editButtonText")}
                  </button>
                </div>
                <CustomTable
                  tableData={item?.vaccineProgramDetails}
                  tableHeadData={tableHeadData}
                />
                <div className="bg-[#D9EBFE] rounded-b-lg">
                  <div className="flex items-center justify-between p-[16px]">
                    <h5 className="text-[16px] font-medium px-[3%] text-[#3777BC]">
                      {t("addHouse.tableFooterWeekText")}
                    </h5>
                    <h5 className="px-[6%] text-[16px] font-medium text-[#3777BC]">
                      {t("addHouse.tableFooterCCText")}
                    </h5>
                  </div>
                  <div className="flex flex-col pt-0 p-[16px] gap-[8px]">
                    {language === "th" ? (
                      <>{instructionsData(item?.instructionsTh)}</>
                    ) : (
                      <>{instructionsData(item?.instructionsEn)}</>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      <div className="text-center underline mt-[25px] text-[15px]">
        <span
          className="cursor-pointer"
          onClick={() => {
            handleNext();
          }}
        >
          {t("addHouse.skipVaccinateText")}
        </span>
      </div>
      <Button
        onClick={() => {
          navigate("/program-overview", { state: { houseId: houseId } });
        }}
        sx={{ mb: 2, mt: 2 }}
        variant="contained"
        fullWidth
        color="secondary"
        size="large"
      >
        {t("add_farm_details.next_button_label")}
      </Button>
      <AddHouseNoticeModal
        addHouseNoticeModal={addHouseNoticeModal}
        handleModalClose={handleModalClose}
        vaccineProgramDetailsData={vaccineProgramDetailsData}
      />
    </div>
  );
};
export default VaccineProgram;

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { boolean, date, object, string } from "yup";
import AppBarFS from "../../components/app-bar/AppBar";
import CustomButton from "../../components/common/Button";
import CheckboxFS from "../../components/common/CheckBox/checkbox";
import DatePickerFS from "../../components/common/DatePicker";
import TextInputFS from "../../components/common/Input/TextInput";
import { checkUserEmail, verifyMobileNumber } from "../../services/AuthService";
import { formError, formErrorMessage } from "../../utils/form-validator";
import { nameRegExp, phoneRegExp } from "../../const/regex-pattern.constant";

export default function Signup() {
  const maxDate = dayjs();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const formik = useFormik({
    initialValues: {
      name: "",
      surName: "",
      dob: null,
      email: "",
      mobileNumber: "",
      acceptPDPA: false,
      acceptPromotion: false,
    },
    validationSchema: object({
      name: string()
        .trim()
        .max(15, `${t("signup.must_be_15_characters_or_less")}`)
        .matches(nameRegExp, `${t("signup.invalid_name_error")}`)
        .required(`${t("signup.name_required_error")}`),
      surName: string()
        .trim()
        .max(15, `${t("signup.must_be_15_characters_or_less")}`)
        .matches(nameRegExp, `${t("signup.invalid_surname_error")}`)
        .required(`${t("signup.surname_required_error")}`),
      email: string()
        .trim()
        .email(`${t("signup.invalid_email_error")}`)
        .optional(),
      mobileNumber: string()
        .required(`${t("signup.mobile_number_required_error")}`)
        .trim()
        .matches(phoneRegExp, `${t("signup.invalid_mobile_number_error")}`)
        .min(10, `${t("signup.mobile_number_length_error")}`)
        .max(10, `${t("signup.mobile_number_length_error")}`),
      acceptPDPA: boolean(),
      acceptPromotion: boolean(),
      dob: date().nullable(),
    }),
    onSubmit: async (values) => {
      try {
        handleFormSubmit();
      } catch (err) {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (state) {
      formik.setValues({
        ...state,
        dob: state.dob ? dayjs(state?.dob, "YYYY-MM-DD") : null,
      });
    }
  }, []);

  const handleFormSubmit = async () => {
    try {
      const isEmailMobileValid = await validateEmailMobileNumber(
        String(formik.values.mobileNumber),
        formik?.values?.email?.trim()
      );
      if (!isEmailMobileValid) {
        return;
      }
      navigate("/authentication", {
        state: {
          ...formik.values,
          dob: formik?.values?.dob
            ? dayjs(formik?.values?.dob).format("YYYY-MM-DD")
            : "",
        },
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const validateEmailMobileNumber = async (
    mobileNumber: string,
    email?: string
  ) => {
    try {
      const mobileVerifyResp = await verifyMobileNumber(
        String(formik.values.mobileNumber)
      );
      if (mobileVerifyResp.data.success) {
        formik.setFieldError("mobileNumber", mobileVerifyResp.data.message);
        return false;
      }
      if (email) {
        const emailVerifyResp = await checkUserEmail({ email });
        if (emailVerifyResp.data.success) {
          formik.setFieldError("email", emailVerifyResp.data.message);
          return false;
        }
      }
      return true;
    } catch (err) {
      console.error(err);
      // TODO : Application Wide error handler using toast
      return true;
    }
  };

  const handleDateChange = (val: Dayjs | null) => {
    if (val) {
      formik.setFieldValue("dob", val);
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        paddingY: "1rem",
        flexDirection: "column",
        rowGap: "2rem",
        height: "100vh",
      }}
    >
      <Box sx={{}}>
        <AppBarFS
          title={t("signup.personal_information")}
          onIconPress={() => navigate("/login")}
        />
      </Box>
      <form onSubmit={formik.handleSubmit} className="container">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "2rem",
            flex: 1,
            justifyContent: "space-around",
          }}
        >
          <Box
            className="form-fields"
            sx={{ display: "flex", flexDirection: "column", rowGap: "1.5rem" }}
          >
            <TextInputFS
              helperText={formErrorMessage(formik, "name")}
              error={formError(formik, "name")}
              label={t("signup.name_label")}
              {...formik.getFieldProps("name")}
            />
            <TextInputFS
              helperText={formErrorMessage(formik, "surName")}
              error={formError(formik, "surName")}
              label={t("signup.surname_label")}
              variant="outlined"
              {...formik.getFieldProps("surName")}
            />
            <DatePickerFS
              value={formik?.values?.dob}
              maxDate={maxDate}
              label={t("signup.dob_label")}
              onChange={(value) => handleDateChange(value)}
            />
            <TextInputFS
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              type="text"
              helperText={
                formErrorMessage(formik, "mobileNumber") ||
                t("signup.otp_helper_text")
              }
              error={formError(formik, "mobileNumber")}
              label={t("signup.mobile_number_label")}
              {...formik.getFieldProps("mobileNumber")}
            />
            <TextInputFS
              helperText={formErrorMessage(formik, "email")}
              error={formError(formik, "email")}
              label={t("signup.email_label")}
              {...formik.getFieldProps("email")}
            />
          </Box>

          <Box
            className="consent-fields"
            sx={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
          >
            <div className="radio-confirmation !items-center">
              <CheckboxFS
                checked={formik?.values?.acceptPDPA}
                {...formik.getFieldProps("acceptPDPA")}
              />
              <Typography variant="body2">
                {t("signup.consent_text1")}{" "}
                <Link onClick={() => setOpen(true)}>
                  {t("signup.consent_link_text")}
                </Link>{" "}
                {t("signup.consent_text2")}
              </Typography>
            </div>

            <div className="radio-confirmation !items-center">
              <CheckboxFS
                checked={formik?.values?.acceptPromotion}
                {...formik.getFieldProps("acceptPromotion")}
              />
              <Typography variant="body2">
                {t("signup.opt_out_of_promotions")}
              </Typography>
            </div>
          </Box>
          <CustomButton
            variant={"tertiary"}
            label={t("signup.continue_button") || ""}
            size={"large"}
            disabled={!formik?.values?.acceptPDPA}
          />
        </Box>
      </form>

      <Dialog fullScreen open={open}>
        <DialogTitle
          id="scroll-dialog-title"
          align="center"
          sx={{ fontSize: "30px", color: "#34407B" }}
        >
          {t("pdpa.title")}
        </DialogTitle>
        <Typography
          align="center"
          variant="subtitle2"
          sx={{ paddingX: "2rem", color: "#3777BC" }}
        >
          {t("pdpa.sub_title")}
        </Typography>
        <DialogContent dividers={scroll === "paper"}>
          <Typography
            variant="body2"
            sx={{ fontSize: "12px", color: "#747474", fontWeight: 500 }}
          >
            เรียน ท่านลูกค้าผู้มีอุปการะคุณ เพื่อให้บริษัท ฟาร์มเทค เวนเจอร์ส
            จำกัด และบริษัทในเครือปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
            พ.ศ. 2562 บริษัทฯ ใคร่ขอความยินยอมจากท่านในการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลของท่าน สำหรับวัตถุประสงค์ดังต่อไปนี้ 1.
            เก็บและใช้ข้อมูลส่วนบุคคลของท่าน
            เพื่อสำรวจความพึงพอใจของท่านเกี่ยวกับผลิตภัณฑ์และบริการของบริษัทฯและวิเคราะห์ความสนใจของท่าน
            จัดทำแบบจำลองด้านเครดิต ประโยชน์ในการวิเคราะห์สินเชื่อ/
            วงเงินการซื้อขายทบทวนสินเชื่อ/วงเงินการซื้อขาย
            ต่ออายุสัญญาสินเชื่อ/วงเงินการซื้อขาย
            รวมทั้งประวัติการซื้อผลิตภัณฑ์หรือการใช้บริการ เพื่อประเมิน จัดการ
            ปรับปรุง ดำเนินการวิจัย วางแผน และพัฒนาผลิตภัณฑ์ บริการ
            และรายการส่งเสริมการขายต่าง ๆ ของบริษัทฯ
            ให้ตรงตามความต้องการของท่านได้ดียิ่งขึ้น 2.
            เก็บและใช้ข้อมูลส่วนบุคคลของท่าน เพื่อแจ้งข้อมูลหรือแจ้งเตือนใดๆ
            เกี่ยวกับผลิตภัณฑ์หรือบริการที่ท่านมีอยู่กับบริษัทฯ
            รวมทั้งผลิตภัณฑ์หรือบริการอื่นๆ ของบริษัทฯ การแจ้งสิทธิประโยชน์
            รายการส่งเสริมการขายต่าง ๆ การดำเนินกิจกรรมทางการตลาด
            การเชิญชวนเข้าร่วมงานกิจกรรมและโครงการต่างๆ ของบริษัทฯ
            และสื่อสารเกี่ยวกับกิจกรรมและโครงการดังกล่าว
            รวมทั้งนำเสนอผลิตภัณฑ์หรือบริการต่างๆ ของ บริษัทฯ ผ่านทางโทรศัพท์ /
            ข้อความ SMS / E-mail / LINE / FACEBOOK / จดหมาย และอื่นๆ 3.
            เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บริษัทในเครือบริษัท ฟาร์มเทค
            เวนเจอร์ส จำกัด
            เพื่อแจ้งข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการของบริษัทในเครือบริษัท
            ฟาร์มเทค เวนเจอร์ส จำกัด รายการส่งเสริมการขายต่าง ๆ
            การดำเนินกิจกรรมทางการตลาด
            การเชิญชวนเข้าร่วมงานกิจกรรมและสื่อสารเกี่ยวกับกิจกรรมดังกล่าว
            และนำเสนอผลิตภัณฑ์หรือบริการต่าง ๆ ของบริษัทในเครือเบทาโกร
            ผ่านทางโทรศัพท์ / ข้อความ SMS / E-mail / LINE / FACEBOOK / จดหมาย
            และอื่นๆ
            (ท่านสามารถศึกษารายละเอียดเพิ่มเติมเกี่ยวกับบริษัทในเครือบริษัท
            ฟาร์มเทค เวนเจอร์ส จำกัด ได้ที่ xxx)
            รายละเอียดเพิ่มเติมเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ
            เป็นไปตามนโยบายความเป็นส่วนตัว (Privacy Policy) ที่แนบมาด้วยนี้
            และ/หรือที่ประกาศในเว็บไซต์ xxx ทั้งนี้
            หากท่านประสงค์จะเพิกถอนความยินยอมที่ท่านให้ไว้นี้
            ท่านสามารถแจ้งบริษัทฯ ได้ที่ email: DPOoffice@betagro.com หรือ โทร:
            0-2833-8333
            ข้าพเจ้าได้อ่านและเข้าใจวัตถุประสงค์เกี่ยวกับข้อมูลส่วนบุคคล
            ดังกล่าวข้างต้น รวมทั้งนโยบายความเป็นส่วนตัว (Privacy Policy)
            ตามเอกสารแนบอย่างชัดเจนแล้ว จึงแสดงความประสงค์ดังนี้
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant={"tertiary"}
            label={t("pdpa.button_label") || ""}
            size={"large"}
            onClick={() => setOpen(false)}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import { memo, useCallback } from "react";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import { tw } from "../../../../utils/tw";

interface IInputProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  containerClassName?: string;
  errorMessage?: string;
  value: string;
  onChangeText: (text: string) => void;
  suffix?: string;
  inputType?: "number" | "text";
  disabled?: boolean;
}

export const Input = memo((props: IInputProps) => {
  const {
    label,
    required,
    value,
    onChangeText,
    placeholder = "กรอก",
    containerClassName,
    errorMessage = "",
    suffix,
    inputType = "text",
    disabled,
  } = props;

  const onChangeFn = useCallback((e: any) => {
    const text = e.target.value;
    onChangeText(text);
  }, []);

  return (
    <div className={tw("flex flex-col flex-1", containerClassName)}>
      {label && (
        <p className="text-[14px] mb-1">
          {label}
          {required && <span className="text-error-300">*</span>}
        </p>
      )}
      <div className="w-full pb-3">
        <TextInputCustom
          type={inputType}
          error={!!errorMessage}
          placeholder={placeholder}
          helperText={errorMessage}
          value={value}
          onChange={onChangeFn}
          suffix={suffix}
          disabled={disabled}
        />
      </div>
    </div>
  );
});

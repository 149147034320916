import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Link, Paper } from "@mui/material";
import axios from "axios";
import get from "lodash/get";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import CustomButton from "../../../components/common/Button";
import ViewImageAndPreviewCarousel from "../../../components/common/ViewImageAndPreviewCarousel/ViewImageAndPreviewCarousel";
import { getDownloadUrlOrderFarmer } from "../../../services/MyOrder.service";

function OrderViewDocument() {
  const navigate = useNavigate();
  const [images, setImages] = useState<string[]>([]);
  const { farmId, orderId, myOrderList } = useLoaderData() as any;

  const initImage = useCallback(async () => {
    const imgs = get(myOrderList, ["result", "orderFeedFiles"], []);
    const orderNo = get(myOrderList, ["result", "orderNumber"], "");
    const imageList = [];
    if (imgs.length > 0) {
      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i];
        const preSignURL = await getDownloadUrlOrderFarmer(
          orderNo,
          img.documentType,
          img.id
        );
        if (preSignURL) {
          const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
            responseType: "blob",
          });
          const objectURL = window.URL.createObjectURL(response.data);
          imageList.push(objectURL);
        }
      }
    }
    setImages(imageList);
  }, [myOrderList]);

  useEffect(() => {
    initImage();
  }, [myOrderList]);

  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#FFFFF]">
      <div className="rounded-b-[40px] bg-white">
        <AppBarFS onIconPress={() => navigate(-1)} title={"เอกสารการซื้อขาย"} />
      </div>
      <div className="flex flex-col p-4 w-full h-full">
        <div className="flex flex-row w-full justify-between pt-4 align-middle items-center">
          <span className="font-semibold text-[18px]">
            หมายเลขสั่งซื้อ {get(myOrderList, ["result", "orderNumber"])}
          </span>
          <Chip
            label="รับสินค้าสำเร็จ"
            variant="outlined"
            sx={{
              color: "#2C702F",
              borderColor: "#2C702F",
              bgcolor: "#F5F9F5",
              height: "24px",
              width: "fit-content",
              fontWeight: "normal",
              fontSize: "14px",
            }}
          />
        </div>
        <div className="h-full w-full max-h-[415px] min-h-[415px] pb-4 mt-6 pt-4">
          <ViewImageAndPreviewCarousel images={images} />
        </div>
        <div className="w-full h-full">
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              bgcolor: "#F5F9F5",
              borderRadius: "10px",
              height: "70px",
              padding: "1rem",
              alignItems: "center",
            }}
          >
            <div className="flex gap-3 h-full items-center self-center content-center">
              <FontAwesomeIcon
                style={{
                  background: "transparent",
                }}
                className="bg-white rounded-full text-[22px]"
                color="#68C184"
                size="lg"
                icon={faCircleCheck}
              />
              <span className="text-[1rem] font-semibold leading-5">
                เราได้เพิ่มสินค้าในสต๊อก <br /> ของคุณเรียบร้อยแล้ว
              </span>
            </div>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                console.info("I'm a button.");
              }}
              sx={{
                color: "#646464",
              }}
            >
              ดูสต๊อก
            </Link>
          </Paper>
        </div>
        <span className="text-[14px] font-semibold leading-5 text-[#D32F2F] text-center">
          หากไม่ได้กดบันทึกเอกสารการขาย <br />
          คุณสามารถกลับมาดูย้อนหลังได้ที่เมนูประวัติคำสั่งซื้อ
        </span>
        <div className="flex flex-col gap-4 my-4 pb-4">
          <CustomButton
            variant="tertiary"
            disabled={false}
            removeBoxShadow={true}
            label={"บันทึกรูปภาพ"}
            onClick={() => {}}
          />
          <CustomButton
            variant="secondary"
            disabled={false}
            label={"ปิด"}
            onClick={() => {
              navigate("/order-history/" + farmId);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default OrderViewDocument;

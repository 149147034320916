import {
  FormControl,
  FormHelperText,
  TextareaAutosize,
  TextareaAutosizeProps,
} from "@mui/material";
import React from "react";

interface ITextAreaCustomProps extends TextareaAutosizeProps {
  errormessage?: string;
}

function TextAreaCustom({ ...rest }: ITextAreaCustomProps) {
  const { errormessage } = rest;

  return (
    <FormControl error={!!errormessage}>
      <TextareaAutosize
        {...rest}
        autoComplete="off"
        style={{
          border: "1px solid #C9C9C9",
          borderRadius: "6px",
          height: "120px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 1px",
          resize: "none",
          padding: "8px",
          fontWeight: 400,
          fontSize: "14px",
          fontFamily: "IBM Plex Sans Thai",
          ...rest?.style,
        }}
      />
      {!!errormessage && <FormHelperText>{errormessage}</FormHelperText>}
    </FormControl>
  );
}

export default TextAreaCustom;

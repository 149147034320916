import { memo, useCallback } from "react"
import { Switch as SwitchMui } from '@mui/material'

interface ISwitchProps {
  checked: boolean
  onChange: (checked: boolean) => void
}

const Switch = (props: ISwitchProps) => {
  const {
    checked,
    onChange: onChangeFn,
  } = props

  const onChange = useCallback((e: any) => {
    onChangeFn(e.target.checked)
  }, [])

  return (
    <SwitchMui
      focusVisibleClassName=".Mui-focusVisible" disableRipple
      checked={checked}
      onChange={onChange}
      color="primary"
      sx={{
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 0.25,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#68C184',
              opacity: 1,
              border: 0,
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: '#fff',
              width: 22,
              height: 22,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#68C184',
            border: '6px solid #fff',
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#E9E9EA',
          opacity: 1,
          transition: 'background-color 300ms',
        },
      }}
    />
  )
}

export default memo(Switch)

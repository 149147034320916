import { useParams } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import get from "lodash/get";
import { tw } from "../../../../utils/tw";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { useOrderDetailController } from "./controller";
import Steps from "../../views/Steps";
import ReceiptLongSvg from "../../../../admin/assets/svg/ReceiptLong.svg";
import { getShortDate } from "../../../../utils/date";
import Dialog from "../../views/Dialog";
import { actionWord, dialogWord, statusWord } from "../../constants";
import { useActionController } from "../../actionController";
import { unitMap } from "../../../../admin/pages/manage-stock/[id]";
import SummaryModalPdf from "../proveShipping/views/SummaryModalPdf";

const classes = {
  container: tw(`flex flex-col min-h-screen w-full`),
  appbarContainer: tw(`py-4`),
  orderNoHeaderContainer: tw(
    `flex flex-row items-center justify-between py-[16px] px-[16.5px]`
  ),
  orderNo: tw(`text-[18px] font-semibold leading-[21.6px]`),
  label: tw(
    `text-warning bg-[#FEF9F2] text-[14px] leading-[19.6px] py-[2px] px-[8px] rounded-full border-[1px] border-warning text-center whitespace-pre`
  ),
  stepContainer: tw(`p-[16px]`),
  addressWrapper: tw(`px-[16px] py-[8px]`),
  addressContainer: tw(`py-[8px] px-[16px] rounded-[20px] bg-[#F6FBFF]`),
  addressHeader: tw(
    `text-[16px] leading-[19.2px] font-semibold text-tertiary mb-[6px]`
  ),
  addressText: tw(
    `text-[14px] leading-[16.8px] font-medium text-[#646464] mb-[4px]`
  ),
  items: {
    wrapper: tw(`px-[8px] w-full border-b-[1px] border-b-[#D9EBFE]`),
    contentContainer: tw(`flex flex-col pt-[16px] rounded-b-[20px]`),
    dotContainer: tw(
      `flex flex-row items-center text-[14px] font-semibold leading-[18.2px] mb-[12px]`
    ),
    dot: tw(
      `w-[7.76px] h-[7.76px] rounded-full bg-[#014F79] ml-[15.12px] mr-[17.12px]`
    ),
    contentColumn: tw(
      `flex flex-row items-center justify-between mb-[12px] px-[24px]`
    ),
    columnTitle: tw(`text-[#3777BC] text-[14px] leading-[18.2px]`),
    columnValue: tw(`text-[#34407B] text-[16px] leading-[19.2px]`),
  },
  itemsCount: tw(
    `text-[#979797] text-[15px] leading-[18px] pt-[16px] pb-[24px] px-[16px]`
  ),
  space: tw("h-[12px] bg-[#E2E2E2]"),
  proveShippingContainer: tw(
    `pl-[19px] pr-[16px] py-[16px] flex flex-col w-full`
  ),
  proveShippingHeaderContainer: tw(
    "flex flex-row items-center gap-x-2 mb-[8px]"
  ),
  proveShippingHeader: tw(`text-[15px] leading-[18px] text-tertiary`),
  proveShippingTextContainer: tw(`flex flex-row items-center justify-between`),
  proveShippingText: tw(`text-[15px] leading-[18px] text-[#979797] pl-[26px]`),
  proveShippingFile: tw(
    `text-[15px] leading-[18px] text-[#979797] underline cursor-pointer`
  ),
  saleOrderContainer: tw(`p-[16px] flex flex-col w-full`),
  saleOrderRow: tw(`flex flex-row items-center justify-between mb-[8px]`),
  saleOrderText: tw(`text-[15px] leading-[18px] text-[#979797]`),
  footerContainer: tw(
    `pt-[16px] flex flex-col justify-center items-center pb-[34px]`
  ),
  contactButtonContainer: tw(
    `text-[16px] leading-[25.6px] font-bold border-[1px] border-secondary text-secondary flex flex-row gap-x-2 justify-center items-center cursor-pointer hover:bg-[#F0FDF4] duration-150 transition-colors py-[8.5px] px-[51px] max-w-[200px] rounded-full whitespace-pre`
  ),
  warningText: tw(
    `text-status-bad text-[14px] leading-[21px] font-semibold mt-[24px] mb-[16px] whitespace-pre-line text-center`
  ),
  actionButton: tw(
    `self-center max-w-[450px] bg-secondary transition-all hover:brightness-105 duration-150 rounded-full py-[13.5px] flex justify-center items-center cursor-pointer text-center text-[16px] leading-[20.8px] text-[white]`,
    "w-[calc(100%-32px)]"
  ),
  proveImageContainer: tw(`flex flex-col pl-[19px] pr-[16px] py-[16px] w-full`),
  proveImage: tw(`w-[80px] h-[80px] rounded-[10px]`),
  proveTextContainer: tw(`flex flex-row justify-between`),
  proveImage1Container: tw(`flex flex-row gap-4 py-4`),
  proveImage2Container: tw(`flex flex-row gap-4 pt-4`),
};

const OrderDetail = () => {
  const { id } = useParams();
  const {
    onBack,
    orderDetail,
    isShowSummaryPdf,
    onShowSummaryPdf,
    onCloseSummaryPdf,
    onEditImage,
    onContact,
    stepsFromStatus,
    onAction,
    onCloseAction,
    isShowActionModal,
    actionModalDetail,
    images1,
    images2,
  } = useOrderDetailController(id as string);

  const { actionFunctions } = useActionController({
    orderDetail: actionModalDetail,
    onCloseModal: onCloseAction,
  });

  if (!orderDetail) return null;

  return (
    <div className={classes.container}>
      <div className={classes.appbarContainer}>
        <AppBarFS title="รายละเอียดออเดอร์" onIconPress={onBack} />
      </div>
      <div className={classes.orderNoHeaderContainer}>
        <div className={classes.orderNo}>
          หมายเลขคำสั่งซื้อ {orderDetail?.orderNo}
        </div>
        <div
          className={tw(
            classes.label,
            orderDetail!.status === "canceled" &&
              "text-status-bad border-status-bad bg-[#F9E0E0] ",
            orderDetail!.status === "received" &&
              "text-[#409261] border-[#409261] bg-[#E9FFEF]"
          )}
        >
          {get(statusWord, orderDetail!.status, "-")}
        </div>
      </div>
      <div className={classes.stepContainer}>
        <Steps
          steps={stepsFromStatus}
          isCanceled={orderDetail?.status === "canceled"}
        />
      </div>
      <div className={classes.addressWrapper}>
        <div className={classes.addressContainer}>
          {orderDetail?.shippingType === "shipping" ? (
            <>
              <div className={classes.addressHeader}>ที่อยู่ในการจัดส่ง</div>
              <div className={classes.addressText}>
                {get(orderDetail, ["address"], "-")}
              </div>
            </>
          ) : (
            <>
              <div className={classes.addressHeader}>ที่อยู่ในการจัดส่ง</div>
              <div className={classes.addressText}>รับที่พาร์ทเนอร์</div>
            </>
          )}
        </div>
      </div>
      {get(orderDetail, "items", []).map((item, index) => {
        return (
          <div key={item.id} className={classes.items.wrapper}>
            <div className={classes.items.contentContainer}>
              <div className={classes.items.dotContainer}>
                <div className={classes.items.dot} />
                รายการที่ {index + 1}
              </div>
              <div className={classes.items.contentColumn}>
                <div className={classes.items.columnTitle}>
                  แบรนด์อาหารสัตว์
                </div>
                <div className={classes.items.columnValue}>
                  {item.brandName}
                </div>
              </div>
              <div className={classes.items.contentColumn}>
                <div className={classes.items.columnTitle}>เบอร์อาหารสัตว์</div>
                <div className={classes.items.columnValue}>{item.brandNo}</div>
              </div>
              <div className={classes.items.contentColumn}>
                <div className={classes.items.columnTitle}>จำนวน</div>
                <div className={classes.items.columnValue}>
                  {item.quantity} {unitMap[item.quantityUnit as "kg" | "bag"]}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className={classes.itemsCount}>
        {orderDetail?.items.length} รายการ
      </div>
      <div className={classes.space} />
      <div className={classes.proveShippingContainer}>
        <div className={classes.proveShippingHeaderContainer}>
          <ReceiptLongSvg />
          <div className={classes.proveShippingHeader}>หลักฐานการจัดส่ง</div>
        </div>
        <div className={classes.proveShippingTextContainer}>
          <div className={classes.proveShippingText}>หลักฐานการจัดส่ง</div>
          <button
            onClick={onShowSummaryPdf}
            className={classes.proveShippingFile}
          >
            ดูหลักฐาน
          </button>
        </div>
      </div>
      {orderDetail.images!.filter(
        (d) =>
          d.documentType === "partnerPickingSlip" ||
          d.documentType === "partnerProofOfDelivery"
      ).length > 0 && (
        <>
          <div className={classes.space} />
          <div className={classes.proveImageContainer}>
            <div className={classes.proveTextContainer}>
              <div className={classes.proveShippingHeader}>
                หลักฐานการจัดส่ง
              </div>
              <button
                onClick={onEditImage}
                className={tw(classes.proveShippingFile, "text-tertiary")}
              >
                อัปโหลด
              </button>
            </div>
            <div className={classes.proveImage1Container}>
              {images1.map((img1Src, index) => (
                <img
                  key={`${index}`}
                  className={classes.proveImage}
                  alt="image1"
                  src={img1Src}
                />
              ))}
            </div>
            <div className={classes.proveShippingHeader}>ใบ Picking Slip</div>
            <div className={classes.proveImage2Container}>
              {images2.map((img2Src, index) => (
                <img
                  key={`${index}`}
                  className={classes.proveImage}
                  alt="image2"
                  src={img2Src}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <div className={classes.space} />
      <div className={classes.saleOrderContainer}>
        <div className={classes.saleOrderRow}>
          <div className={tw(classes.saleOrderText, "text-tertiary")}>
            Sale Order
          </div>
          <div className={tw(classes.saleOrderText, "text-tertiary")}>
            {orderDetail?.orderNo}
          </div>
        </div>
        <div className={classes.saleOrderRow}>
          <div className={classes.saleOrderText}>วันที่สั่งสินค้า</div>
          <div className={classes.saleOrderText}>
            {getShortDate(orderDetail?.orderDate)}
          </div>
        </div>
        <div className={tw(classes.saleOrderRow, "mb-0")}>
          <div className={classes.saleOrderText}>วันที่ต้องการรับสินค้า</div>
          <div className={classes.saleOrderText}>
            {getShortDate(orderDetail?.expectReceivedDate)}
          </div>
        </div>
      </div>
      <div className={classes.space} />
      <div className={classes.footerContainer}>
        <button onClick={onContact} className={classes.contactButtonContainer}>
          <PhoneIcon className="!text-[18px] !text-secondary" />
          <span className="mt-0.5">ติดต่อผู้ซื้อ</span>
        </button>
        <div className={classes.warningText}>
          {`กรุณากดยืนยันหลังจากได้รับ\nและตรวจสอบสินค้าแล้ว`}
        </div>
        {get(actionWord, orderDetail!.status, "-") !== "-" && (
          <button
            onClick={onAction(orderDetail!)}
            className={classes.actionButton}
          >
            {get(actionWord, orderDetail!.status, "-")}
          </button>
        )}
      </div>
      <Dialog
        isOpen={isShowActionModal}
        title={get(dialogWord, [orderDetail?.status || "-", "title"], "-")}
        text={get(dialogWord, [orderDetail?.status || "-", "text"], "-")}
        confirmText="ยืนยัน"
        onClose={onCloseAction}
        onConfirm={actionFunctions[orderDetail?.status || "new"]}
      />
      <SummaryModalPdf
        isOpen={isShowSummaryPdf}
        onClose={onCloseSummaryPdf}
        orderDetail={orderDetail}
      />
    </div>
  );
};

export default OrderDetail;

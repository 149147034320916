import { useEffect, useMemo, useState } from "react";

import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import { useShowButtonByRoles } from "../../../../hooks/useActionButtonByRoles";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import {
  deletePartner,
  getPartner,
} from "../../../../services/admin/user-management/partner.service";
import {
  IPartner,
  TPartnerStatus,
  UserGroups,
} from "../../../../types/admin/user-management/partner";
import { UserRole } from "../../../../utils/enum/user-role.enum";
import DeleteFillGreen from "../../../assets/svg/DeleteFillGreen.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import EditOrderCustomModal from "../../../components/Modal/EditOrderCustomModal";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../components/Table/TableCustom";
import { usePaginationFilter } from "../../../hooks/usePaginationFilter.hook";
import UserManagementSearch from "../components/UserManagementSearch";
import CreatePartnerModal from "./CreatePartnerModal";
import DetailPartnerModal from "./DetailPartnerModal";

type Props = {};

function createData(
  id: string,
  partnerId: string,
  username: string,
  partnerName: string,
  address: string,
  phone: string,
  email: string,
  role: string,
  roleName: UserGroups[],
  status: TPartnerStatus
): IPartner {
  return {
    id,
    partnerId,
    username,
    partnerName,
    address,
    phone,
    email,
    role,
    roleName: roleName.length
      ? roleName.map((item) => item.name).join(", ")
      : "-",
    status: status as TPartnerStatus,
  };
}
const headCells: ITableCustomHeadCell<keyof IPartner>[] = [
  {
    id: "partnerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสพาร์ทเนอร์",
    width: "150px",
    isSorting: true,
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "ชื่อผู้ใช้",
    width: "100px",
    isSorting: false,
  },
  {
    id: "partnerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อพาร์ทเนอร์",
    width: "180px",
    isSorting: false,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "ที่อยู่",
    width: "400px",
    isSorting: false,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "เบอร์โทรศัพท์",
    width: "150px",
    isSorting: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "อีเมล",
    width: "180px",
    isSorting: false,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
    width: "80px",
    isSorting: true,
  },
  {
    id: "roleName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อกลุ่ม",
    width: "200px",
    isSorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "สถานะ",
    width: "80px",
    isSorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "80px",
    isSorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
    isSorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20px",
    isSorting: false,
  },
];

const PartnerStatusMap: Record<
  TPartnerStatus,
  { color: string; bgColor: string; label: string }
> = {
  true: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "เปิดใช้งาน",
  },
  false: {
    color: "#3F3748",
    bgColor: "#E4E4E4",
    label: "ปิดใช้งาน",
  },
};
const PartnerPanel = (props: Props) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const { page, limit, setPage } = usePaginationFilter();
  const [countRows, setCountRows] = useState<number>(0);
  const [list, setList] = useState<IPartner[]>([]);
  const [direction, setDirection] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IPartner>("partnerId");

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { showSnackBar } = useSnackBar();

  const [isOpenEditPartnerModal, setIsOpenEditPartnerModal] = useState(false);
  const [isOpenDetailPartnerModal, setIsOpenDetailPartnerModal] =
    useState(false);

  const [selectedId, setSelectedId] = useState("");
  const [toggleFetch, setToggleFetch] = useState(false);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const MapOrderByWithFilter: { [K in keyof IPartner]?: string } = {
    partnerId: "partner_code",
    role: "type",
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchText);
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("direction", direction);
    searchParams.append("orderBy", MapOrderByWithFilter[orderBy] ?? "");
    setList([]);
    const data = await getPartner(searchParams);
    if (data?.result.rows) {
      const newList = data.result.rows.map((row) => {
        return createData(
          row.id,
          row.partnerCode,
          row.username,
          `${row.name} ${row.surname}`,
          `${row.address} ${row.subDistrict.nameTh} ${row.district.nameTh} ${row.province.nameTh} ${row.zipCode}`,
          row.mobileNumber,
          row.email,
          row.type,
          row.userGroups,
          row.isActive as TPartnerStatus
        );
      });
      setCountRows(data.result.count);
      setList(newList);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [orderBy, direction, page, toggleFetch]);

  const { showButton } = useShowButtonByRoles([
    UserRole.Admin,
    UserRole.Management,
  ]);

  const filterHeaderCell = useMemo(
    () =>
      showButton
        ? headCells
        : headCells.filter((_, idx) => idx <= headCells.length - 3),
    [showButton]
  );

  const onSearchText = () => {
    setPage(1);
    setToggleFetch((prev) => !prev);
  };

  return (
    <div className="flex flex-col w-full pt-6">
      <UserManagementSearch
        searchText={searchText}
        onSearch={onSearchText}
        setSearchText={setSearchText}
        itemSelected={itemSelected}
        placeholder="ค้นหาโดยรหัสพาร์ทเนอร์, ชื่อพาร์ทเนอร์"
        feature="partner"
        onClickChangeStatus={() => setItemSelected([])}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={direction}
          orderBy={orderBy}
          rowsPerPage={limit}
          itemsSelected={itemSelected}
          page={page}
          headerItems={filterHeaderCell}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          rows={list}
          currentPageItems={list.map((row) => row.id)}
          onChangePage={(val) => setPage(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={countRows}
          onChangeOrder={(val) => setDirection(val)}
          onChangeOrderBy={(val) => setOrderBy(val as keyof IPartner)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.partnerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.partnerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.address}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.phone}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.role}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.roleName}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={PartnerStatusMap[row.status]["bgColor"]}
                          fontColor={PartnerStatusMap[row.status].color}
                          label={PartnerStatusMap[row.status].label}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          width: "80px",
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                        onClick={() => {
                          setIsOpenDetailPartnerModal(true);
                          setSelectedId(row.id);
                        }}
                      >
                        ดูรายละเอียด
                      </Link>
                    </TableCell>
                    {showButton && (
                      <>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <ButtonFillCustom
                            title={"แก้ไข"}
                            onClick={() => {
                              setSelectedId(row.id);
                              setIsOpenEditPartnerModal(true);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                          onClick={() => {
                            setIsOpenDeleteModal(true);
                            setSelectedId(row.id);
                          }}
                        >
                          <DeleteFillGreen />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <EditOrderCustomModal
        title="ยืนยันการลบ"
        subtitleFirstRow="คุณต้องการลบรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้?"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="ยกเลิก"
        open={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        onSubmit={async () => {
          const payload = {
            id: selectedId,
          };
          await deletePartner(payload);
          showSnackBar("ลบพาร์ทเนอร์สำเร็จ");
          setIsOpenDeleteModal(false);
          onSearchText();
        }}
      />
      <CreatePartnerModal
        isOpen={isOpenEditPartnerModal}
        handleClose={() => setIsOpenEditPartnerModal(false)}
        selectedRow={selectedId}
        handleFetch={() => onSearchText()}
      />
      <DetailPartnerModal
        isOpen={isOpenDetailPartnerModal}
        handleClose={() => setIsOpenDetailPartnerModal(false)}
        selectedRow={selectedId}
      />
    </div>
  );
};

export default PartnerPanel;

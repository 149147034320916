import { Divider } from '@mui/material'
import MenuBar from '../../components/MenuBar/MenuBar'
import ButtonFillCustom from '../../components/Button/ButtonFillCustom'
import { tw } from '../../../utils/tw';
import PlusCircleIcon from '../../assets/svg/PlusCircleIcon.svg';
import { useMasterdataController } from './controller';
import FoodModal from './views/FoodModal';
import SwitchSellingModal from './views/SwitchSellingModal';

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  rowContainer: tw(`flex flex-row w-full items-center justify-between`),
  title: tw(`text-black font-bold text-3xl`),
  subTitle: tw(`text-tertiary font-bold text-[22px] leading-[28.64px]`),
  titleHeader: tw(`py-[16px] px-[20px] bg-[#FBFBFB] font-sans rounded-t-[6px] text-[#534D59] font-bold text-[14px] leading-[18.23px] border-[#E4E4E4] border-[0.5px]`),
  subTitleHeader: tw(`py-[16px] px-[20px] bg-[#FBFBFB] font-sans text-[#534D59] text-[14px] leading-[18.23px] border-[#E4E4E4] border-[0.5px] border-y-0`),
  detail: tw(`text-secondary text-[14px] leading-[14px] tracking-[-5%] font-medium underline hover:brightness-110 cursor-pointer`),
}

const MasterData = () => {
  const {
    isShowFoodDetail,
    onAddCreditFeed,
    onCreditDetail,
    onFoodDetail,
    onHideFoodDetail,
    isShowSwitchSelling,
    onShowSwitchSelling,
    onHideSwitchSelling,
  } = useMasterdataController()

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>Master Data</span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className={classes.mainContainer}>
              <div className={tw(classes.rowContainer, 'mb-[12px]')}>
                <div className={classes.subTitle}>Credit Feed</div>
                <ButtonFillCustom
                  title="Add"
                  startIcon={<PlusCircleIcon />}
                  onClick={onAddCreditFeed}
                />
              </div>
              <div className={tw(classes.rowContainer, classes.titleHeader)}>
                ชื่อหัวข้อ
              </div>
              <div className={tw(classes.rowContainer, classes.subTitleHeader, 'bg-[#fff]')}>
                การให้เครดิต
                <button onClick={onCreditDetail} className={classes.detail}>ดูรายละเอียด</button>
              </div>
              <div className={tw(classes.rowContainer, classes.subTitleHeader, 'bg-[#fff] border-b-[0.5px] rounded-b-[6px]')}>
                อาหารสัตว์
                <div className="flex flex-row items-center gap-x-3">
                  <button onClick={onShowSwitchSelling} className={classes.detail}>เปิด-ปิดการขาย</button>
                  <button onClick={onFoodDetail} className={classes.detail}>ดูรายละเอียด</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
      <FoodModal
        isOpen={isShowFoodDetail}
        onCloseFn={onHideFoodDetail}
      />
      <SwitchSellingModal
        isOpen={isShowSwitchSelling}
        onCloseFn={onHideSwitchSelling}
      />
    </>
  )
}

export default MasterData

import { memo, useCallback, useMemo } from "react";
import { FormControl, FormHelperText, MenuItem } from "@mui/material";
import { get } from "lodash";
import { tw } from "../../../../utils/tw";
import SelectAdminCustom from "../../../components/Select/Select";

export type TDropdownValue = {
  label: string;
  value: string;
};

interface IDropdownProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  containerClassName?: string;
  options: TDropdownValue[];
  multiple?: boolean;
  onChange: (value: TDropdownValue[]) => void;
  renderValueArray: TDropdownValue[];
  disabled?: boolean;
  errorMessage?: string;
  choseStyle2?: boolean;
  containerDropdown?: string;
}

export const Dropdown = memo((props: IDropdownProps) => {
  const {
    label,
    required,
    options,
    multiple,
    placeholder = "กรอก",
    containerClassName,
    renderValueArray,
    onChange,
    disabled,
    errorMessage = "",
    choseStyle2,
    containerDropdown,
  } = props;

  const onChangeFn = useCallback(
    (e: any) => {
      if (options.length > 0) {
        if (!multiple) {
          const op = options.find(({ value }) => value === e.target.value);
          op && onChange([op]);
        } else {
          const valueArray = e.target.value;
          const op = valueArray.map((v: string) =>
            options.find(({ value }) => value === v)
          );
          op && onChange(op);
        }
      }
    },
    [options, multiple]
  );

  const value = useMemo(() => {
    if (multiple) return (renderValueArray || []).map(({ value: v }) => v);
    return get(
      renderValueArray.map(({ value: v }) => v),
      0
    );
  }, [renderValueArray, multiple]);

  return (
    <div className={tw("flex flex-col flex-1", containerClassName)}>
      {label && (
        <p className="text-[14px] mb-1 font-sans">
          {label}
          {required && <span className="text-error-300">*</span>}
        </p>
      )}
      <div className={tw("w-full pb-3", containerDropdown)}>
        <FormControl className="w-full">
          <SelectAdminCustom
            disabled={disabled}
            onChange={onChangeFn}
            multiple={multiple}
            isShowCountAtMultiple
            menuItems={options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            placeholder={placeholder}
            renderValueArray={renderValueArray}
            value={value ?? ""}
            error={!!errorMessage}
            helperText={errorMessage}
            choseStyle2={choseStyle2}
          />
        </FormControl>
      </div>
    </div>
  );
});

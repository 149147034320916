import { useCallback, useEffect, useState } from "react";
import { getFarmAndHouse } from "../../../../../services/CreditFeed.service";
import { IFarmAndHouse } from "../../../../../types/CreditFeed.type";

export const useApplyCreditController = (idFramers: string) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isOpenApplyCredit, setIsOpenApplyCredit] = useState(false);

  const onOpenApplyCredit = useCallback(() => setIsOpenApplyCredit(true), []);
  const onHideApplyCredit = useCallback(() => setIsOpenApplyCredit(false), []);

  const onSearch = useCallback(() => {}, []);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  return {
    isOpenApplyCredit,
    onHideApplyCredit,
    onOpenApplyCredit,
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
  };
};

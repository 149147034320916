import { useCallback, useEffect, useState } from "react";
import { IContracts } from "../../types/Financial.type";
import { getAllContract } from "../../services/Financial.service";

export const useFinancialController = () => {
  const [data, setData] = useState<IContracts[]>([]);

  const initData = useCallback(() => {
    Promise.all([getAllContract()])
      .then(([result]) => {
        setData(result!.contracts);
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, []);

  useEffect(() => {
    initData();
  }, []);

  return {
    data,
  };
};

import React, { memo } from "react";
import { Tabs as TabsComponent } from "@mui/material";

interface TTabsProps {
  tabIndex: number;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  children: React.ReactNode;
}

const Tabs = (props: TTabsProps) => {
  const { tabIndex, onChange, children } = props;

  return (
    <TabsComponent
      value={tabIndex}
      onChange={onChange}
      aria-label="sale-order-tab-control"
      TabIndicatorProps={{
        sx: {
          "&.MuiTabs-indicator": {
            transform: "unset",
            width: "unset",
            bgcolor: "#68C184",
            height: "3px",
          },
        },
      }}
      sx={{
        height: "36px",
        minHeight: "unset",
        "& .MuiTabs-scroller": {
          "& .MuiTabs-flexContainer": {
            alignItems: "end",
            height: "100%",
            display: "flex",
            gap: "12px",
            "& .MuiTab-root": {
              padding: "6px 28px",
              textTransform: "unset",
              fontFamily: "IBM Plex Sans Thai",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: "20px",
              height: "32px",
              minHeight: "unset",
              color: "#000000",
              borderBottom: "3px solid #DDF3E4",
              "& .Mui-selected": {
                color: "#000000",
              },
            },
          },
        },
      }}
    >
      {children}
    </TabsComponent>
  );
};

export default memo(Tabs);

import dayjs from "dayjs";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { IGetMyOrderByID } from "../../../../types/MyOrderFeed.type";
import OrderFeedFarmerDetail from "../../my-order/my-order-component/OrderFeedFarmerDetail";

export default function OrderHistoryDetail() {
  const { orderId, myOrderList, farmId } = useLoaderData() as {
    orderId: string;
    myOrderList: IGetMyOrderByID;
    farmId: string;
  };
  const navigate = useNavigate();
  //date
  const receiveDate = dayjs(myOrderList.result.receiveDate);
  const today = new Date();

  //status
  const canceled = myOrderList.result.status === "canceled";
  return (
    <div className="pb-4 h-screen flex flex-col w-full bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title="รายละเอียดคำสั่งซื้อ"
        />
      </div>
      {canceled ? (
        <OrderFeedFarmerDetail
          primaryButtonText="สั่งซื้ออีกครั้ง"
          isPrimaryDisabled={false}
          onPrimaryClick={() => {
            navigate(
              "/feed-order-and-delivery/order-feed/" +
                farmId +
                "?orderId=" +
                orderId
            );
          }}
          isDisplaySecondaryButton={false}
          isTotalItemPrice={true}
          isTradingDocument={true}
          orderDetail={myOrderList}
        />
      ) : (
        <OrderFeedFarmerDetail
          primaryButtonText="สั่งซื้ออีกครั้ง"
          isPrimaryDisabled={false}
          onPrimaryClick={() => {
            navigate(
              "/feed-order-and-delivery/order-feed/" +
                farmId +
                "?orderId=" +
                orderId
            );
          }}
          secondaryButtonText="รายงานปัญหา"
          isSecondaryDisabled={Math.abs(receiveDate.diff(today, "day")) <= 6}
          isDisplaySecondaryButton={true}
          isTotalItemPrice={true}
          isTradingDocument={true}
          orderDetail={myOrderList}
        />
      )}
    </div>
  );
}

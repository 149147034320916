import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { DateRange } from "react-date-range";
import { IDateRangePickerPopoverProps } from "./type";
import { MenuItem, Popover, Select, IconButton } from "@mui/material";
import "react-date-range/dist/styles.css";
import "./DateRangePickerPopover.styles.scss";
import { range } from "lodash";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as locales from "date-fns/locale";
import { useDayjsLocale } from "../../../../hooks/useDayjsLocale.hook";

type DateRangePickerPopoverHandle = {
  onOpen: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
};

const DateRangePickerPopover: ForwardRefRenderFunction<
  DateRangePickerPopoverHandle,
  IDateRangePickerPopoverProps
> = (
  {
    id,
    handleSelect,
    openCalender,
    onClose,
    handleCalenderSave,
    startDate,
    endDate,
  }: IDateRangePickerPopoverProps,
  ref
) => {
  const { locale } = useDayjsLocale();
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  useImperativeHandle(ref, () => ({
    onOpen(event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) {
      handleClick(event);
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? id : undefined;

  const [selectedDateRange, setSelectedDateRange] = useState<any[]>([
    {
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : new Date(),
      key: "selection",
    },
  ]);
  // Calculate the end date as 6 days from the start date
  // const xEndDate = dayjs(selectedDateRange[0].startDate).toDate();

  const handleDateRangeChange = (item: any) => {
    setSelectedDateRange([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate, // Keep the end date as calculated
        key: "selection",
      },
    ]);
  };
  return (
    <Popover
      id={idPopover}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        "& .MuiPopover-paper": {
          borderRadius: "14px !important",
        },
      }}
    >
      <div className="relative rounded-[14px]">
        <DateRange
          showMonthAndYearPickers={true}
          editableDateInputs={false}
          moveRangeOnFirstSelection={false}
          ranges={selectedDateRange}
          onChange={handleDateRangeChange}
          monthDisplayFormat="MMM/YYYY"
          locale={locale === "en" ? locales.enGB : locales.th}
          // dayContentRenderer={(date) => <div>{dayjs(date).format("D")}</div>}
          classNames={{
            dayPassive: "custom-day-passive",
            day: "custom-day",
            startEdge: "custom-start-edge",
            endEdge: "custom-end-edge",
            dayNumber: "custom-day-number",
            prevButton: "custom-prev-btn",
            nextButton: "custom-next-btn",
          }}
          navigatorRenderer={(focusedDate, changeShownDate, props) => {
            const { locale, minDate, maxDate } = props;
            const getMonthNames = () => {
              return range(12).map((i) => locale?.localize?.month(i));
            };
            const upperYearLimit =
              maxDate?.getFullYear() || new Date().getFullYear();
            const lowerYearLimit =
              minDate?.getFullYear() || new Date().getFullYear();
            return (
              <div
                onMouseUp={(e) => e.stopPropagation()}
                className="w-full flex justify-between py-6"
              >
                <span className="flex flex-row h-full px-2">
                  <span className="flex h-full items-center self-center">
                    <Select
                      value={focusedDate.getMonth()}
                      onChange={(e) =>
                        changeShownDate(e.target.value, "setMonth")
                      }
                      sx={{
                        bgcolor: "transparent",
                        height: "30px",
                        border: "0px !important",
                        borderRadius: "10px !important",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                      }}
                      MenuProps={{
                        sx: {
                          maxHeight: "400px",
                        },
                      }}
                    >
                      {getMonthNames().map((month, i) => (
                        <MenuItem key={i} value={i}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                    {locale?.localize?.month()}
                  </span>
                  <span />
                  <span>
                    <Select
                      value={focusedDate.getFullYear()}
                      onChange={(e) =>
                        changeShownDate(e.target.value, "setYear")
                      }
                      sx={{
                        bgcolor: "transparent",
                        height: "30px",
                        border: "0px !important",
                        borderRadius: "10px !important",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                      }}
                      MenuProps={{
                        sx: {
                          maxHeight: "400px",
                        },
                      }}
                    >
                      {new Array(upperYearLimit - lowerYearLimit + 1)
                        .fill(upperYearLimit)
                        .map((val, i) => {
                          const year = val - i;
                          return (
                            <MenuItem key={year} value={year}>
                              {locale?.code === "th" ? year + 543 : year}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </span>
                </span>
                <div className="flex gap-4 h-full w-full justify-end pr-4">
                  <IconButton
                    aria-label="btn-prev"
                    size="small"
                    onClick={() => changeShownDate(-1, "monthOffset")}
                  >
                    <ChevronLeftIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    aria-label="btn-next"
                    size="small"
                    onClick={() => changeShownDate(1, "monthOffset")}
                  >
                    <ChevronRightIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
            );
          }}
        />
        <div className="text-right pb-8">
          <button
            onClick={handleClose}
            className="btn btn-transparent rounded-[50px] px-4 mr-2 border-[#68C184] text-[#68C184] w-[96px] h-[35px] font-[700] text-[16px] border-[1px]"
          >
            ยกเลิก
          </button>
          <button
            onClick={(e) => {
              handleClose();
              handleCalenderSave(e, selectedDateRange);
            }}
            className="btn btn-transparent rounded-[50px] px-4 mr-2 bg-[#68C184] text-[white] w-[96px] h-[35px] font-[700] text-[16px]"
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </Popover>
  );
};

DateRangePickerPopover.displayName = "DateRangePickerPopover";

export default forwardRef(DateRangePickerPopover);

import { Box, Modal, Paper } from "@mui/material";
import { Pagination, Zoom } from "swiper/modules";
import { SwiperClass, Swiper as SwiperSlid, SwiperSlide } from "swiper/react";
import DocumentCard from "./ItemsCard/DocumentCard";

import "./ViewImageAndPreviewCarousel.scss";
import "./swiper-styles/pagination-preview-image.scss";
import "./swiper-styles/swiper.scss";
import "./swiper-styles/zoom.scss";

type TImage = { id: string; url: string };

export interface IPreviewAndZoomImageCarouselModalProps {
  isOpen: boolean;
  initIndex: number;
  onClose: () => void;
  imageItems?: string[];
  images?: (TImage | File)[];
  docs?: { url: string; fileName: string }[];
}

const style = {
  position: "absolute" as "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "transparent",
  width: "100%",
  height: "500px",
  p: 2,
};

function PreviewAndZoomImageCarouselModal({
  isOpen,
  initIndex,
  imageItems,
  images,
  docs,
  onClose,
}: IPreviewAndZoomImageCarouselModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-preview-image"
      aria-describedby="parent-modal-preview-image-description"
      sx={{
        bgcolor: "unset",
      }}
      disableAutoFocus={false}
      disableEnforceFocus={false}
    >
      <Box sx={style}>
        <div className="h-full w-full max-h-[524px] min-h-[524px]">
          <SwiperSlid
            pagination={true}
            modules={[Pagination, Zoom]}
            className="mySwiper"
            initialSlide={initIndex}
            loop={true}
            centeredSlides={true}
            zoom={true}
            onZoomChange={(
              swiper: SwiperClass,
              scale: number,
              imageEl: HTMLElement,
              slideEl: HTMLElement
            ) => {}}
          >
            {docs
              ? (docs || []).map((val, index) => {
                  const src =
                    val instanceof File ? URL.createObjectURL(val) : val.url;

                  return (
                    <SwiperSlide key={`carousel-item-${index}`}>
                      <div className="flex h-full w-full justify-center relative swiper-zoom-container">
                        <Box
                          sx={{
                            width: "100%",
                            height: "450px",
                          }}
                        >
                          <div className="flex h-full w-full justify-center relative">
                            <Paper
                              elevation={0}
                              sx={{
                                position: "relative",
                              }}
                            >
                              <img
                                src={src}
                                alt={`invoice-item`}
                                width="100%"
                                height="auto"
                                className="object-contain w-[271px]"
                              />
                            </Paper>
                          </div>
                        </Box>
                      </div>
                    </SwiperSlide>
                  );
                })
              : images
              ? (images || []).map((val, index) => {
                  const src =
                    val instanceof File ? URL.createObjectURL(val) : val.url;

                  return (
                    <SwiperSlide key={`carousel-item-${index}`}>
                      <div className="flex h-full w-full justify-center relative swiper-zoom-container">
                        <Box
                          sx={{
                            width: "100%",
                            height: "450px",
                          }}
                        >
                          <div className="flex h-full w-full justify-center relative">
                            <Paper
                              elevation={0}
                              sx={{
                                position: "relative",
                              }}
                            >
                              <img
                                src={src}
                                alt={`invoice-item`}
                                width="100%"
                                height="auto"
                                className="object-contain w-[271px]"
                              />
                            </Paper>
                          </div>
                        </Box>
                      </div>
                    </SwiperSlide>
                  );
                })
              : (imageItems || []).map((val, index) => (
                  <SwiperSlide key={`carousel-item-${index}`}>
                    <div className="flex h-full w-full justify-center relative swiper-zoom-container">
                      <Box
                        sx={{
                          width: "100%",
                          height: "450px",
                        }}
                      >
                        <DocumentCard image={val} hiddenZoomIcon={true} />
                      </Box>
                    </div>
                  </SwiperSlide>
                ))}
          </SwiperSlid>
        </div>
      </Box>
    </Modal>
  );
}

export default PreviewAndZoomImageCarouselModal;

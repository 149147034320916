import { useCallback, useEffect, useState } from "react";
import { cloneDeep, get, isEmpty, result, set } from "lodash";
import { getListPartner } from "../../../services/ManageStock.service";
import { IGetListPartnerDetails } from "../../../types/ManageStock.type";

export const useManageStockController = () => {
  const [searchText, setSearchText] = useState("");

  const [listPartnerData, setListPartnerData] = useState<
    IGetListPartnerDetails[]
  >([]);

  const onSearch = useCallback(() => {}, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const initData = useCallback(() => {
    Promise.all([getListPartner()])
      .then(([data]) => {
        setListPartnerData(data?.result.rows!);
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, []);

  useEffect(() => {
    initData();
  }, []);

  return {
    onSearch,
    searchText,
    onSearchText,
    onClearSearchText,
    listPartnerData,
  };
};

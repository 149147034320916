export default function FarmSVG() {
  return (
    <svg
      width="50"
      height="49"
      viewBox="0 0 50 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5932_22239)" filter="url(#filter0_dd_5932_22239)">
        <path
          d="M35.3035 17.0509L32.8171 12.5774C32.794 12.5312 32.7571 12.4989 32.7109 12.4758L24.97 8.64926C24.9007 8.61691 24.8175 8.61691 24.7482 8.64926L17.0074 12.3833C16.9658 12.4018 16.9288 12.4342 16.9057 12.4758L14.0404 16.9585C13.9341 17.1203 14.0497 17.3375 14.2438 17.3421L14.974 17.3652C15.108 17.3652 15.2143 17.4807 15.2143 17.6148V26.9222C15.2143 27.6894 15.8335 28.3087 16.6007 28.3087H32.8495C33.6166 28.3087 34.2359 27.6894 34.2359 26.9222V17.6748C34.2359 17.5362 34.3468 17.4253 34.4855 17.4253H35.0816C35.2711 17.4253 35.3913 17.2219 35.2988 17.0509H35.3035ZM24.6558 13.1459C25.4091 13.1459 26.0191 13.7559 26.0191 14.5092C26.0191 15.2625 25.4091 15.8725 24.6558 15.8725C23.9025 15.8725 23.2925 15.2625 23.2925 14.5092C23.2925 13.7559 23.9025 13.1459 24.6558 13.1459ZM30.2384 24.2233C30.2384 24.9628 29.6376 25.5635 28.8982 25.5635H20.4133C19.6739 25.5635 19.0731 24.9628 19.0731 24.2233V21.2333C19.0731 20.4939 19.6739 19.8931 20.4133 19.8931H28.8936C29.633 19.8931 30.2338 20.4939 30.2338 21.2333V24.2233H30.2384Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_5932_22239"
          x="-0.461426"
          y="-0.632812"
          width="50"
          height="50.4219"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5932_22239"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="dilate"
            in="SourceAlpha"
            result="effect2_dropShadow_5932_22239"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_5932_22239"
            result="effect2_dropShadow_5932_22239"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_5932_22239"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_5932_22239">
          <rect
            width="22"
            height="22.4211"
            fill="white"
            transform="translate(13.5386 7.36719)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import { IDownloadUrlCreateFeed } from "../types/CreditFeed.type";
import { IGetAllContract } from "../types/Financial.type";
import api from "./ApiService";

export const getAllContract = async () => {
  try {
    const { data } = await api.get<IGetAllContract>("/farmers/contract");
    return data;
  } catch (error) {
    return null;
  }
};

export const getDownloadUrl = async (id: string, fileId: string) => {
  const { data } = await api.get<IDownloadUrlCreateFeed>(
    `/farmers/contract/download/${id}/${fileId}`
  );
  return data;
};

import { configureStore } from "@reduxjs/toolkit";
import clickSlice from "./slices/clickSlice";
import personalSlice from "./slices/exampleSlice";
import alertSlice from "./slices/alertSlice";
import snakeSlice from "./slices/snakeSlice";
import loadingSlice from "./slices/loadingSlice";

export const store = configureStore({
  reducer: {
    click: clickSlice.reducer,
    personal: personalSlice.reducer,
    alert: alertSlice.reducer,
    snake: snakeSlice.reducer,
    loading: loadingSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Box, Button, IconButton, MenuItem, Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import { IFilterModalProps, IFilterOrderYear } from "./types";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextInputFS from "../../components/common/Input/TextInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dayjs from "dayjs";
import DateRangePickerPopover from "../../components/common/DateRangePickerPopover/DateRangePickerPopover";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { Order } from "./types";

const FILTER_DATE_FORMAT = "YYYY-MM-DD";

function FilterModal({
  title,
  labelSelectFilter,
  labelDateRangeFilter,
  isOpen,
  onClose,
  t,
  onReset,
  onSubmit,
}: IFilterModalProps) {
  const refCalendarRange =
    useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const [orderCloseDate, setOrderCloseDate] = useState<Order>("desc");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  // const [startDate, setStartDate] = useState<any>(
  //   dayjs().format(FILTER_DATE_FORMAT)
  // );
  // const [endDate, setEndDate] = useState<any>(
  //   dayjs().format(FILTER_DATE_FORMAT)
  // );
  const orderCloseDateItems: IFilterOrderYear[] = [
    {
      id: "new-old",
      name: t("farmPastPerformance.filter.orderYear.newToOld"),
      key: "desc",
    },
    {
      id: "old-new",
      name: t("farmPastPerformance.filter.orderYear.oldToNew"),
      key: "asc",
    },
  ];

  const [isOpenCalender, setIsOpenCalender] = useState<boolean>(false);
  const handleSelect = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  // const handleOpenCalender = (event: any) => {
  //   event?.stopPropagation();
  //   setIsOpenCalender(!isOpenCalender);
  // };

  const handleCloseCalender = () => {
    setIsOpenCalender(false);
  };
  const handleCalenderSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  const handleResetFilter = () => {
    setStartDate(dayjs().format(FILTER_DATE_FORMAT));
    setEndDate(dayjs().format(FILTER_DATE_FORMAT));
    setOrderCloseDate("desc");
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableEnforceFocus={false}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "313px",
          minWidth: "313px",
          bgcolor: "#000",
          borderRadius: "40px",
          boxShadow: 24,
        }}
      >
        <div className="flex flex-col relative px-6 pt-4 pb-6 bg-white w-full h-full rounded-[40px]">
          <div className="flex flex-row gap-2">
            <FilterListIcon
              sx={{
                color: "#68C184",
                fontWeight: 500,
                alignSelf: "center",
                height: "100%",
              }}
            />
            <span className="text-secondary font-medium text-2xl h-full self-center pt-1">
              {title || t("farmPastPerformance.titleFilter")}
            </span>
          </div>
          <div className="absolute right-6 top-4">
            <IconButton aria-label="close" onClick={() => onClose()}>
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
          <div className="flex flex-col h-full justify-start pt-6 gap-6">
            <div className="flex flex-col">
              <span className="text-tertiary font-semibold text-xl pb-3">
                {labelSelectFilter || t("farmPastPerformance.filter.closeDate")}
              </span>
              <TextInputFS
                select
                name="closeDate"
                id="closeDate"
                onChange={(e) => {
                  setOrderCloseDate(e.target.value as Order);
                }}
                value={orderCloseDate}
                InputProps={{
                  startAdornment: null,
                  sx: {
                    height: "2rem",
                    width: "fit-content",
                    borderRadius: "10px",
                    border: "1px solid #68C184",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px !important",
                    border: "1px solid #68C184",
                    bgcolor: "#F0FDF4",
                    color: "#68C184",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0px",
                  },
                }}
                SelectProps={{
                  sx: {
                    "& .MuiSelect-icon": {
                      color: "#68C184",
                      transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                    "& .MuiSelect-select": {
                      padding: "4px",
                      width: "100%",
                      textAlign: "center",
                      justifyContent: "center",
                    },
                    height: "32px",
                    width: "130px",
                  },
                  IconComponent: KeyboardArrowDownIcon,
                }}
              >
                {orderCloseDateItems.map((item, index) => (
                  <MenuItem key={`farm-item-${index}`} value={item.key}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextInputFS>
            </div>

            <div className="flex flex-col w-full">
              <span className="text-tertiary font-semibold text-xl pb-3">
                {labelDateRangeFilter ||
                  t("farmPastPerformance.filter.closeDateRange")}
              </span>
              <Button
                variant="outlined"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  refCalendarRange.current?.onOpen(e);
                }}
                endIcon={<EventOutlinedIcon />}
                sx={{
                  height: "2rem",
                  borderRadius: "10px !important",
                  minWidth: "132px",
                  padding: "10px",
                  border: "1px solid #68C184",
                  color: "#68C184",
                  bgcolor: "#F0FDF4",
                  justifyContent: "space-between",
                  width: "fit-content",
                  textAlign: "center",
                  fontWeight: "normal",
                  fontSize: "1rem",
                }}
                fullWidth={false}
              >
                {t("farmPastPerformance.filter.button.titleCalendar")}
              </Button>

              {startDate || endDate ? (
                <span className="text-sm font-normal text-secondary pt-2">
                  {dayjs(startDate).format("DD/MM/BBBB")} -{" "}
                  {dayjs(endDate).format("DD/MM/BBBB")}
                </span>
              ) : null}
            </div>
          </div>
          <div className="flex w-full gap-2 pt-6">
            <Button
              variant="outlined"
              sx={{
                border: "1px solid #68C184",
                color: "#68C184",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                height: "37px",
              }}
              fullWidth
              onClick={() => {
                handleResetFilter();
                onReset();
                onClose();
              }}
            >
              {t("farmPastPerformance.filter.button.reset")}
            </Button>
            <Button
              sx={{
                bgcolor: "#68C184",
                color: "#FFFFFF",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                height: "37px",
              }}
              fullWidth
              onClick={() => {
                onSubmit(startDate, endDate, orderCloseDate);
                onClose();
              }}
            >
              {t("farmPastPerformance.filter.button.apply")}
            </Button>
          </div>
        </div>

        <DateRangePickerPopover
          ref={refCalendarRange}
          id={"calendar-range-popover"}
          handleSelect={handleSelect}
          openCalender={isOpenCalender}
          onClose={handleCloseCalender}
          handleCalenderSave={handleCalenderSave}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>
    </Modal>
  );
}

export default FilterModal;

import dayjs from "dayjs"

export const getShortDate = (date?: string | number) => {
  const d = dayjs(date)
  if (d.isValid()) {
    const year = +d.year() + 543
    return d.format(`DD/MM/${year}`)
  }
  return '-'
}

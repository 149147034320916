import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, InputAdornment, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { object, ref, string } from "yup";
import AppBarFS from "../../components/app-bar/AppBar";
import AuthBox from "../../components/common/AuthBox";
import TextInputFS from "../../components/common/Input/TextInput";
import Loader from "../../components/common/Loader";
import { checkUserName, regiterUserService } from "../../services/AuthService";
import { formError, formErrorMessage } from "../../utils/form-validator";

export default function SetPin() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const preventThaiInput = (event: any) => {
    const usernameRegex = /^[a-zA-Z0-9_]{0,20}$/;
    if (!usernameRegex.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9_]/g, "");
    }
  };

  useEffect(() => {
    setLoading(false);
    if (!state && !state?.mobileNumber) {
      navigate("/signup");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      userName: "",
      pin: "",
      confirmPin: "",
    },
    validationSchema: object({
      userName: string()
        .trim()
        .required(`${t("set_pin.user_name_required_error_message")}`)
        .max(15, `${t("set_pin.user_name_length_error_message")}`)
        .matches(
          /^[ก-๙a-zA-Z0-9]+$/,
          `${t("set_pin.special_characters_not_allowed_error_message")}`
        ),
      pin: string()
        .trim()
        .min(4, `${t("set_pin.pin_minimum_4_digit_error_message")}`)
        .max(4, `${t("set_pin.pin_maximum_4_digit_error_message")}`)
        .matches(/^\d+$/, `${t("set_pin.pin_maximum_4_digit_error_message")}`)
        .required(`${t("set_pin.pin_required_error_message")}`),
      confirmPin: string()
        .trim()
        .min(4, `${t("set_pin.pin_minimum_4_digit_error_message")}`)
        .max(4, `${t("set_pin.pin_maximum_4_digit_error_message")}`)
        .required(`${t("set_pin.pin_required_error_message")}`)
        .matches(/^\d+$/, `${t("set_pin.pin_maximum_4_digit_error_message")}`)
        .oneOf(
          [ref("pin")],
          `${t("set_pin.pin_and_confirm_pin_must_match_error_message")}`
        ),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const validateUserName = async () => {
    try {
      const resp = await checkUserName(formik?.values?.userName?.trim());
      if (!resp?.data?.success) {
        return true;
      } else {
        formik.setFieldError("userName", resp?.data?.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const isUserNameValid = await validateUserName();
      if (!isUserNameValid) {
        setLoading(false);
        return;
      }
      const data: any = {
        name: state.name,
        surname: state.surName,
        mobileNumber: String(state.mobileNumber),
        pdpaAccepted: state?.acceptPDPA,
        pdpaLink:
          "https://www.trade.gov/market-intelligence/thailand-personal-data-protection-act",
        subscribeNewsLetter: state?.acceptPromotion,
        userName: formik.values.userName,
        pin: formik.values.pin,
      };

      if (state.email) {
        data["email"] = state?.email;
      }
      if (state.dob) {
        data["dob"] = state?.dob;
      }

      const resp: any = await regiterUserService(data);
      if (resp?.data?.success) {
        setLoading(false);
        navigate("/signup-success", { state: state });
      } else {
        if (resp.data.message && resp.data.message.length) {
          resp.data.message.map((field: any) => {
            formik.setFieldError(field.path, field.message);
          });
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };
  return (
    <div className="p-4 h-screen flex flex-col  w-full">
      <AppBarFS
        onIconPress={() => navigate("/signup", { state })}
        title={t("set_pin.app_header")}
      />
      {loading && <Loader />}
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col grow mt-8 gap-4 justify-between"
      >
        <div className="flex flex-col gap-8">
          {/* <LabeledInput
                        labelName={t('set_pin.input_user_name_label')}
                        inputValue={formik.values.userName}
                        errorMessage={formik?.errors?.userName}
                        onChange={(e) => formik.setFieldValue('userName', e.target.value)}
                        pattern={usernameRegex} 
                        type="checked"
                    /> */}

          <TextInputFS
            helperText={formErrorMessage(formik, "userName")}
            error={formError(formik, "userName")}
            placeholder={t("set_pin.input_user_name_placeholder") || ""}
            label={t("set_pin.input_user_name_label")}
            {...formik.getFieldProps("userName")}
            onChange={(e) => {
              preventThaiInput(e);
              formik.handleChange(e);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik?.values?.userName && !formik?.errors?.userName && (
                    <CheckCircleOutlineIcon sx={{ color: "#68C184" }} />
                  )}
                </InputAdornment>
              ),
            }}
          />

          <div className="flex flex-col gap-2">
            <Typography variant="h6" align="left">
              {t("set_pin.set_pin_label")}{" "}
            </Typography>
            <AuthBox
              inputType="password"
              placeholder="*"
              boxLength={4}
              onChange={(val) => formik.setFieldValue("pin", val)}
              errorMessage={formErrorMessage(formik, "pin")}
              error={formError(formik, "pin")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Typography variant="h6" align="left">
              {t("set_pin.confirm_pin_label")}
            </Typography>
            <AuthBox
              labelStyle={{
                whiteSpace: "nowrap",
                position: "absolute",
                bottom: "10%",
                transform: "translateX(-50%)",
                left: "50%",
              }}
              inputType="password"
              placeholder="*"
              boxLength={4}
              onChange={(val) => formik.setFieldValue("confirmPin", val)}
              errorMessage={formErrorMessage(formik, "confirmPin")}
              error={formError(formik, "confirmPin")}
            />
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          size="large"
        >
          {t("authentication.confirm_button")}
        </Button>
      </form>
    </div>
  );
}

import { TDropdownValue } from "../../group-area/views/Dropdown";

export type TDetail = {
  brandName: TDropdownValue[];
  brandNumber: TDropdownValue[];
  amount: string;
  unit: string;
};

export type TForm = {
  numberOrder?: string | null;
  detail: TDetail[];
};

export const defaultForm: TForm = {
  numberOrder: null,
  detail: [
    {
      brandName: [],
      brandNumber: [],
      amount: "",
      unit: "",
    },
  ],
};

import { TableCell } from "@mui/material";
import dayjs from "dayjs";
import { get } from "lodash";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../redux/store";
import { rejectFeed } from "../../../../../services/CreditFeed.service";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import { tw } from "../../../../../utils/tw";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import { FILTER_DATE_FORMAT } from "../../../sale-order/components/SaleOrderSearchFilter";
import { AccordionInformation } from "../customer/views/AccordionInformation";
import { useApproveCreditController } from "./controller";
import ApproveCredit from "./views/ApproveCredit";
import { getCommaNumber } from "../../../../../utils/number";
import RejectCredit from "./views/RejectCredit";

type Props = {
  tabIndex?: number | undefined;
  showButton?: boolean;
  data: any;
  refetchTab3: () => void;
  refetchCount?: () => void;
};

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] !max-w-[1311px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  text: tw(`font-["IBM Plex Sans Thai"] font-bold text-[16px] leading-5`),
};

const FillSaleContract = (props: Props) => {
  const { tabIndex: _tabIndex, data, refetchTab3, refetchCount } = props;
  const dispatch = useDispatch<AppDispatch>();

  const {
    isOpenApproveCredit,
    onHideApproveCredit,
    onOpenApproveCredit,
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
    isOpenRejectCredit,
    onOpenRejectCredit,
    onHideRejectCredit,
  } = useApproveCreditController();

  return (
    <>
      <TableCell colSpan={8} sx={{ background: "#F6FBFF", padding: "24px" }}>
        <div className="w-full h-full bg-[#FFFFFF] p-6">
          <div className="flex flex-col gap-4 pt-4">
            <div className="flex justify-between items-center px-4">
              <span className="text-xl font-bold text-secondary">
                รายละเอียดการให้เครดิต
              </span>
              {_tabIndex === 3 && props.showButton && (
                <div className="flex gap-4">
                  <ButtonFillCustom
                    title="ไม่อนุมัติ"
                    onClick={onOpenRejectCredit}
                    btnBgColor="#D32F2F"
                  />
                  <ButtonFillCustom
                    title="อัพเดตข้อมูล"
                    onClick={onOpenApproveCredit}
                  />
                </div>
              )}
            </div>
            <div className={classes.text}>Credit Model Version 1.0.1</div>
            {data.fullData.isActive === true && (
              <div className="flex flex-row w-full gap-6">
                <div className="w-[50%] flex flex-row gap-6">
                  <div className="text-[14px] text-[#1B2128] font-bold w-[143px]">
                    วันเริ่มสัญญา
                  </div>
                  <div className="text-[14px] text-[#1B2128] font-normal">
                    {dayjs(get(data, ["fullData", "startDate"])).format(
                      FILTER_DATE_FORMAT
                    )}
                  </div>
                </div>
                <div className="w-[50%] flex flex-row gap-6">
                  <div className="text-[14px] text-[#1B2128] font-bold w-[143px]">
                    วันสิ้นสุดสัญญา
                  </div>
                  <div className="text-[14px] text-[#1B2128] font-normal">
                    {dayjs(get(data, ["fullData", "expireDate"])).format(
                      FILTER_DATE_FORMAT
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="w-full">
              <AccordionInformation
                idData={get(data, ["fullData", "id"])}
                openFirstTime
                accordionTitle="Credit Information"
                isPDF={
                  get(data, ["fullData", "creditInformation", "files"]) &&
                  get(data, ["fullData", "creditInformation", "files"]).length >
                    0
                }
                fileBeforeUpload={get(
                  data,
                  ["fullData", "creditInformation", "files"],
                  []
                )}
                contract={get(
                  data,
                  ["fullData", "creditInformation", "contractDocument"],
                  []
                )}
                data={[
                  {
                    label: "ขนาดฟาร์ม",
                    value: get(
                      data,
                      ["fullData", "creditInformation", "farmSize"],
                      "-"
                    ).toUpperCase(),
                  },
                  {
                    label: "ฟาร์ม",
                    value: get(data, ["fullData", "farmName"], "-"),
                  },
                  {
                    label: "โรงเรือน",
                    value: get(data, ["fullData", "houseName"], "-"),
                  },
                  {
                    label: "ประเภทหมู",
                    value: "หมูขุน",
                  },
                  {
                    label: "จำนวนหมู",
                    value: `${getCommaNumber(
                      +get(data, ["fullData", "fattenerPigCount"], "-")
                    )}`,
                  },
                  {
                    label: "วงเงินลูกค้า",
                    value: `${getCommaNumber(
                      +get(
                        data,
                        ["fullData", "creditInformation", "creditAmount"],
                        "-"
                      )
                    )}`,
                  },
                  {
                    label: "เกรดลูกค้า",
                    value: get(
                      data,
                      ["fullData", "customerGrade"],
                      "-"
                    ).toUpperCase(),
                  },
                  {
                    label: "ประเภทสัญญา",
                    value:
                      get(
                        data,
                        ["fullData", "creditInformation", "contractType"],
                        "-"
                      ) === "pay_after_expire"
                        ? "จ่ายหลังหมดสัญญา"
                        : get(
                            data,
                            ["fullData", "creditInformation", "contractType"],
                            "-"
                          ) === "fill_credit"
                        ? "จ่ายตาม invoice"
                        : "-",
                  },
                  {
                    label: "ระยะเครดิต",
                    value: `${getCommaNumber(
                      +get(
                        data,
                        ["fullData", "creditInformation", "creditPeriod"],
                        "-"
                      )
                    )}`,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </TableCell>
      <ApproveCredit
        isOpen={isOpenApproveCredit}
        onClose={onHideApproveCredit}
        data={data}
        refetchTab3={refetchTab3}
        refetchCount={refetchCount}
      />
      <RejectCredit
        isOpen={isOpenRejectCredit}
        data={data}
        refetchTab3={refetchTab3}
        onClose={onHideRejectCredit}
        refetchCount={refetchCount}
      />
    </>
  );
};

export default memo(FillSaleContract);

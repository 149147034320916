import axios from "axios";
import adminAPI from "../services/AdminApiService";
import { getDownloadPreSignURL } from "../services/admin/sale-order/approvedOrder.service";

export const handleDownload = async (url: string, name: string) => {
  const response = await adminAPI.get(url, { responseType: "blob" });
  const objectURL = window.URL.createObjectURL(new Blob([response.data]));
  handleDownloadBlob(objectURL, name);
};

export const handleDownloadStatic = async (url: string, name: string) => {
  const response = await axios.get(url, { responseType: "blob" });
  const objectURL = window.URL.createObjectURL(new Blob([response.data]));
  handleDownloadBlob(objectURL, name);
};

export const handleDownloadBlobAzure = async (
  orderFeedId: string,
  url: string
) => {
  const preSignURL = await getDownloadPreSignURL(orderFeedId, url);
  if (preSignURL) {
    const response = await axios.get<Blob>(preSignURL.result.blobUrl, {
      responseType: "blob",
    });
    return response.data;
  }
  return null;
};

export const handleDownloadAzure = async (
  orderFeedId: string,
  url: string,
  name: string
) => {
  const preSignURL = await getDownloadPreSignURL(orderFeedId, url);
  if (preSignURL) {
    const response = await axios.get<Blob>(preSignURL.result.blobUrl, {
      responseType: "blob",
    });
    const objectURL = window.URL.createObjectURL(response.data);
    handleDownloadBlob(objectURL, name);
  }
};

export function handleDownloadBlob(objectURL: string, name: string) {
  const link = document.createElement("a");
  link.href = objectURL;
  link.setAttribute("download", `${name}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

import { Dialog, DialogContent, IconButton, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import { getAdminLocation } from "../../../../services/admin/location/location.service";
import {
  createPartner,
  editPartner,
  getPartnerById,
} from "../../../../services/admin/user-management/partner.service";
import { IAdminProvince } from "../../../../types/admin/location/location";
import { InitialPartnerForm } from "../../../../types/admin/user-management/partner";
import { formError, formErrorMessage } from "../../../../utils/form-validator";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import EditOrderCustomModal from "../../../components/Modal/EditOrderCustomModal";
import SelectAdminCustom from "../../../components/Select/Select";

type CreatePartnerModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  selectedRow?: string;
  handleFetch: () => void;
};

const CreatePartnerModal = ({
  isOpen,
  handleClose,
  selectedRow,
  handleFetch,
}: CreatePartnerModalProps) => {
  const RolePartnerSelect = ["basic", "core"];
  const [province, setProvince] = useState<IAdminProvince[]>([]);
  const { showSnackBar, showErrorSnackBar } = useSnackBar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenConfirmCreateModal, setIsOpenConfirmCreateModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLocations = async () => {
    const getLocation = await getAdminLocation();
    const province = getLocation?.provinces || [];
    setProvince(province);
  };

  let initialValues: InitialPartnerForm = {
    type: "",
    name: "",
    surname: "",
    mobileNumber: "",
    email: "",
    address: "",
    zipCode: "",
    provinceCode: "",
    districtCode: "",
    subDistrictCode: "",
    initialPin: "",
    username: "",
    partnerCode: "",
  };

  const handleInitForm = async () => {
    if (selectedRow) {
      setIsLoading(true);
      try {
        const partner = await getPartnerById(selectedRow);
        if (partner) {
          const result = partner.result;
          formik.setValues({
            type: result.partnerType || "",
            name: result.name || "",
            surname: result.surname || "",
            mobileNumber: result.mobileNumber || "",
            email: result.email || "",
            address: result.address || "",
            zipCode: result.subDistrict.zipCode,
            provinceCode: result.province.code.toString() || "",
            districtCode: result.district.code.toString() || "",
            subDistrictCode: result.subDistrict.code.toString() || "",
            initialPin: result.initialPin || "",
            username: result.username || "",
            partnerCode: result.partnerCode || "",
          });
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleInitForm();
    }
  }, [isOpen]);

  useEffect(() => {
    formik.resetForm();
  }, [handleClose]);

  const handleSubmit = async (value: InitialPartnerForm) => {
    setIsSubmitting(true);
    try {
      const payload = {
        id: selectedRow,
        type: value.type,
        name: value.name,
        surname: value.surname,
        mobileNumber: value.mobileNumber,
        email: value.email,
        address: value.address,
        zipCode: Number(value.zipCode),
        provinceCode: Number(value.provinceCode),
        districtCode: Number(value.districtCode),
        subDistrictCode: Number(value.subDistrictCode),
      };
      await (selectedRow ? editPartner(payload) : createPartner(payload));
      setIsOpenConfirmCreateModal(false);
      showSnackBar(selectedRow ? "บันทึกสำเร็จ" : "เพิ่มพาร์ทเนอร์สำเร็จ");
      handleFetch();
    } catch (error) {
      const axiosError = error as any;
      const status = axiosError?.status || 500;
      const errorMessage = axiosError.data?.message || "Request error";
      showErrorSnackBar(`${status}: ${errorMessage}`);
      throw error;
    } finally {
      setIsOpenConfirmCreateModal(false);
      setIsSubmitting(false);
    }
  };

  const validationSchema = object().shape({
    name: string().ensure().required("กรุณากรอกชื่อ"),
    surname: string().ensure().required("กรุณากรอกนามสกุล"),
    type: string().ensure().required("กรุณาเลือกพาร์ทเนอร์"),
    mobileNumber: string().ensure().required("กรุณากรอกเบอร์โทรศัพท์"),
    email: string().email("กรุณากรอกอีเมลให้ถูกต้อง").optional(),
    address: string().ensure().required("กรุณากรอกที่อยู่"),
    provinceCode: string().required("กรุณาเลือกจังหวัด"),
    districtCode: string().ensure().required("กรุณาเลือกเขต/อำเภอ"),
    subDistrictCode: string().ensure().required("กรุณาเลือกแขวง/ตำบล"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });
  const getValueDistrict = formik.getFieldProps("districtCode").value;
  const getValueProvince = formik.getFieldProps("provinceCode").value;

  const districts = (idProvince: number) =>
    province.find((row) => row.code === idProvince)?.districts || [];

  const subDistricts = (idProvince: number, idDistrict: number) =>
    districts(idProvince).find((row) => row.code === idDistrict)
      ?.subDistricts || [];

  const zipCode = (
    idProvince: number,
    idDistrict: number,
    idSubDistrict: number
  ) =>
    subDistricts(idProvince, idDistrict).find(
      (row) => row.code === idSubDistrict
    )?.zipCode ?? "";

  useEffect(() => {
    formik.resetForm();
  }, [onclose]);

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <>
      <Dialog
        open={!isLoading && isOpen}
        maxWidth="lg"
        onClose={() => handleClose(true)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent>
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold pb-3 mb-1">รายละเอียดพาร์ทเนอร์</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <>
            <div className="flex gap-x-3">
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">
                  ชื่อ<span className="text-error-300">*</span>
                </p>
                <div className="w-[340px] pb-3">
                  <TextInputCustom
                    placeholder="กรอก"
                    error={formError(formik, "name")}
                    helperText={formErrorMessage(formik, "name")}
                    {...formik.getFieldProps("name")}
                  />
                </div>
              </div>
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">
                  นามสกุล<span className="text-error-300">*</span>
                </p>
                <div className="w-[340px] pb-3">
                  <TextInputCustom
                    placeholder="กรอก"
                    error={formError(formik, "surname")}
                    helperText={formErrorMessage(formik, "surname")}
                    {...formik.getFieldProps("surname")}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">
                  ประเภทพาร์ทเนอร์<span className="text-error-300">*</span>
                </p>
                <div className="w-[340px] pb-3">
                  <SelectAdminCustom
                    placeholder="เลือก"
                    error={formError(formik, "type")}
                    helperText={formErrorMessage(formik, "type")}
                    {...formik.getFieldProps("type")}
                    menuItems={RolePartnerSelect.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                    renderValueArray={RolePartnerSelect.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                  />
                </div>
              </div>
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">รหัสพาร์ทเนอร์</p>
                <div className="w-[340px] pb-3">
                  <TextInputCustom
                    disabled
                    {...formik.getFieldProps("partnerCode")}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">
                  เบอร์โทรศัพท์<span className="text-error-300">*</span>
                </p>
                <div className="w-[340px] pb-3">
                  <TextInputCustom
                    placeholder="กรอก"
                    error={formError(formik, "mobileNumber")}
                    helperText={formErrorMessage(formik, "mobileNumber")}
                    {...formik.getFieldProps("mobileNumber")}
                  />
                </div>
              </div>
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">อีเมล</p>
                <div className="w-full pb-3">
                  <TextInputCustom
                    placeholder="กรอก"
                    error={formError(formik, "email")}
                    helperText={formErrorMessage(formik, "email")}
                    {...formik.getFieldProps("email")}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-x-3">
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">ชื่อผู้ใช้</p>
                <div className="w-[340px] pb-3">
                  <TextInputCustom
                    disabled
                    {...formik.getFieldProps("username")}
                  />
                </div>
              </div>
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">Initial PIN</p>
                <div className="w-[340px] pb-3">
                  <TextInputCustom
                    disabled
                    {...formik.getFieldProps("initialPin")}
                  />
                </div>
              </div>
            </div>

            <p className="text-lg font-bold py-4 text-tertiary">ที่อยู่</p>
            <div>
              <p className="text-[14px] mb-1">
                ที่อยู่<span className="text-error-300">*</span>
              </p>
              <div className="w-full pb-3">
                <TextInputCustom
                  placeholder="กรอก"
                  error={formError(formik, "address")}
                  helperText={formErrorMessage(formik, "address")}
                  {...formik.getFieldProps("address")}
                />
              </div>
            </div>
            <div className="flex gap-x-3">
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">
                  จังหวัด<span className="text-error-300">*</span>
                </p>
                <div className="w-[340px] pb-3">
                  <SelectAdminCustom
                    placeholder="เลือก"
                    renderValueArray={province.map((row) => ({
                      value: row.code.toString(),
                      label: row.nameTh,
                    }))}
                    error={formError(formik, "provinceCode")}
                    helperText={formErrorMessage(formik, "provinceCode")}
                    {...formik.getFieldProps("provinceCode")}
                    menuItems={province.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.nameTh}
                      </MenuItem>
                    ))}
                  />
                </div>
              </div>
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">
                  เขต/อำเภอ<span className="text-error-300">*</span>
                </p>
                <div className="w-[340px] pb-3">
                  <SelectAdminCustom
                    placeholder="เลือก"
                    renderValueArray={districts(+getValueProvince).map(
                      (row) => ({
                        value: row.code.toString(),
                        label: row.nameTh,
                      })
                    )}
                    error={formError(formik, "districtCode")}
                    helperText={formErrorMessage(formik, "districtCode")}
                    {...formik.getFieldProps("districtCode")}
                    menuItems={districts(+getValueProvince).map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.nameTh}
                      </MenuItem>
                    ))}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-x-3">
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">
                  แขวง/ตำบล<span className="text-error-300">*</span>
                </p>
                <div className="w-[340px] pb-3">
                  <SelectAdminCustom
                    placeholder="เลือก"
                    error={formError(formik, "subDistrictCode")}
                    helperText={formErrorMessage(formik, "subDistrictCode")}
                    {...formik.getFieldProps("subDistrictCode")}
                    menuItems={subDistricts(
                      +getValueProvince,
                      +getValueDistrict
                    ).map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.nameTh}
                      </MenuItem>
                    ))}
                    renderValueArray={subDistricts(
                      +getValueProvince,
                      +getValueDistrict
                    ).map((row) => ({
                      value: row.code.toString(),
                      label: row.nameTh,
                    }))}
                    onChange={(e) => {
                      const { onChange } =
                        formik.getFieldProps("subDistrictCode");
                      onChange(e);
                      const selectSubDistrictCode = +(e.target.value as string);
                      formik.setFieldValue(
                        "zipCode",
                        zipCode(
                          +getValueProvince,
                          +getValueDistrict,
                          selectSubDistrictCode
                        )
                      );
                    }}
                  />
                </div>
              </div>
              <div className="w-[50%]">
                <p className="text-[14px] mb-1">รหัสไปรษณีย์</p>
                <div className="w-[340px] pb-3">
                  <TextInputCustom
                    disabled
                    {...formik.getFieldProps("zipCode")}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <ButtonFillCustom
                title={"ยืนยัน"}
                onClick={async () => {
                  await formik.validateForm();
                  if (formik.isValid) {
                    setIsOpenConfirmCreateModal(true);
                  } else {
                    formik.setTouched({
                      name: true,
                      surname: true,
                      type: true,
                      mobileNumber: true,
                      email: true,
                      address: true,
                      provinceCode: true,
                      districtCode: true,
                      subDistrictCode: true,
                    });
                  }
                }}
              />
            </div>
          </>
        </DialogContent>
      </Dialog>
      <EditOrderCustomModal
        title={selectedRow ? "ยืนยันการบันทึก" : "ยืนยันการเพิ่มพาร์ทเนอร์"}
        subtitleFirstRow={
          selectedRow
            ? "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
            : "คุณต้องการเพิ่มพาร์ทเนอร์ใช่หรือไม่?"
        }
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="กลับไปแก้ไข"
        disablePrimaryBtn={isSubmitting}
        disableSecondaryBtn={isSubmitting}
        open={isOpenConfirmCreateModal}
        onClose={() => setIsOpenConfirmCreateModal(false)}
        onSubmit={() => formik.handleSubmit()}
      />
    </>
  );
};

export default CreatePartnerModal;

import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
} from "@mui/material";
import { tw } from "../../../../../../utils/tw";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import { Dropdown } from "../../../../group-area/views/Dropdown";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { Input } from "../../../../group-area/views/Input";
import { get } from "lodash";
import * as Y from "yup";
import useFormValidator from "../../../../../../hooks/useFormValidator.hook";
import { AppDispatch } from "../../../../../../redux/store";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import { TForm } from "../defaultForm";
import { defaultForm } from "../defaultForm";
import TextInputCustom from "../../../../../components/Input/TextInputCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import DateRangePickerPopover from "../../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import { FILTER_DATE_FORMAT } from "../../../../sale-order/components/SaleOrderSearchFilter";
import dayjs from "dayjs";
import { CalendarSvg } from "../../../../../assets/svg/Calendar.svg";
import { useApproveCreditController } from "../controller";
import { IGetFarmAndHouse } from "../../../../../../types/CreditFeed.type";
import {
  approveFeed,
  getDownloadUrl,
  getFarmAndHouse,
  getUploadUrlCreateFeed,
} from "../../../../../../services/CreditFeed.service";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import DownloadLinkWithDelete from "../../../../../../components/common/File/DownloadLinkWithDelete";
import { handleDownloadBlob } from "../../../../../../utils/downloadFile";
import axios from "axios";
import DatePickerPopover from "../../../../../components/Input/DatePickerPopover/DatePickerPopover";

interface FileBeforeUpload {
  fileName: string;
  fileSize: number;
  id: string;
}
interface ICreateGroupAreaModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  refetchTab3?: () => void;
  refetchCount?: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] !max-w-[1311px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  title2: tw(`text-[16px] font-bold text-[#3777BC]`),
  text: tw(`font-["IBM Plex Sans Thai"] font-bold text-[16px] leading-5`),
};

const errorSchema = Y.object({
  farmSize: Y.array().min(1, "กรุณาเลือกขนาดฟาร์ม"),
  farmName: Y.array().min(1, "กรุณาเลือกฟาร์ม"),
  greenHouse: Y.array().min(1, "กรุณาเลือกโรงเรือน"),
  financialAmount: Y.string().required("กรุณากรอกวงเงินลูกค้า"),
  grade: Y.array().min(1, "กรุณาเลือกเกรดลูกค้า"),
  contractType: Y.array().min(1, "กรุณาเลือกประเภทสัญญา"),
  timeCredit: Y.string().required("กรุณากรอกระยะเครดิต"),
});

const ApproveCredit = (props: ICreateGroupAreaModalProps) => {
  const { isOpen, onClose, data, refetchTab3, refetchCount } = props;
  const { startDate, endDate, setStartDate, setEndDate } =
    useApproveCreditController();

  const dispatch = useDispatch<AppDispatch>();
  const [form, setForm] = useState<TForm>(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof TForm) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [customerPickingSlip, setCustomerPickingSlip] = useState<
    FileBeforeUpload[]
  >([]);
  const [customerUpload, setCustomerUpload] = useState<File[]>([]);
  const [customerContractSlip, setCustomerContractSlip] = useState<File[]>([]);

  const handleDownload = useCallback(
    async (file: File) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [customerPickingSlip]
  );

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [customerPickingSlip]
  );

  const handleDownload2 = useCallback(
    async (file: File) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [customerContractSlip]
  );

  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectStartDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setStartDate(formattedDate);
  };

  const refCalendarEndDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectEndDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setEndDate(formattedDate);
  };

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema,
    onValidateSuccess: (_tabIndex) => {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการอัปเดตข้อมูล",
          text: "คุณต้องการอัปเดตข้อมูลรายการนี้ใช่หรือไม่",
          cancelText: "กลับไปแก้ไข",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: async () => {
            const fileArray = [];
            const fileArrayContract = [];
            dispatch(
              loadingActions.show({ message: "กำลังยืนยันการอัปเดตข้อมูล..." })
            );
            if (customerUpload.length > 0) {
              // upload image
              const presign = await getUploadUrlCreateFeed(data.fullData.id);
              await axios.put(presign.blob.blobUrl, customerUpload[0], {
                responseType: "blob",
                headers: {
                  "Content-Type": customerUpload[0].type,
                  "x-ms-blob-type": "BlockBlob",
                },
              });
              fileArray.push({
                id: presign.blob.fileId,
                fileName: customerUpload[0].name,
                fileSize: customerUpload[0].size,
              });
            }
            if (customerContractSlip.length > 0) {
              // upload image
              const presign = await getUploadUrlCreateFeed(data.fullData.id);
              await axios.put(presign.blob.blobUrl, customerContractSlip[0], {
                responseType: "blob",
                headers: {
                  "Content-Type": customerContractSlip[0].type,
                  "x-ms-blob-type": "BlockBlob",
                },
              });
              fileArrayContract.push({
                id: presign.blob.fileId,
                fileName: customerContractSlip[0].name,
                fileSize: customerContractSlip[0].size,
              });
            }
            approveFeed({
              id: data.fullData.id,
              creditInformation: {
                farmSize: get(form, ["farmSize", 0, "value"], "-"),
                houseId: get(form, ["greenHouse", 0, "value"], "-"),
                creditAmount: +form.financialAmount,
                customerGrade: get(form, ["grade", 0, "value"], "-"),
                contractType: get(form, ["contractType", 0, "value"], "-"),
                creditPeriod: +form.timeCredit,
                files:
                  customerUpload.length === 0
                    ? get(data, ["fullData", "creditInformation", "files"], [])
                    : fileArray,
              },
              expireDate: dayjs(endDate, FILTER_DATE_FORMAT).toDate(),
              startDate: dayjs(startDate, FILTER_DATE_FORMAT).toDate(),
              contractDocument:
                customerContractSlip.length === 0 ? [] : fileArrayContract,
            })
              .then(() => {
                refetchTab3 && refetchTab3();
                setTimeout(() => {
                  dispatch(alertAction.hide());
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: "อัปเดตข้อมูลสำเร็จ",
                      type: "success",
                    })
                  );
                  onCloseFn();
                }, 1500);
              })
              .catch((e) => {
                setTimeout(() => {
                  dispatch(alertAction.hide());
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: getErrorMessage(e),
                      type: "error",
                    })
                  );
                }, 1500);
              });
          },
        })
      );
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    setTimeout(() => {
      resetError();
      setForm(defaultForm);
      setCustomerPickingSlip([]);
      refetchCount && refetchCount();
    }, 500);
  }, []);

  const [farmAndHouseData, setFarmAndHouseData] = useState<IGetFarmAndHouse>();

  const farmOptions = useMemo(() => {
    if (data) {
      const _farmOptions = get(farmAndHouseData, "farms", []).map(
        ({ id, name, houses }) => ({
          label: name,
          value: id,
          houses: houses.map(({ id, name, fattenerPigCount }) => ({
            label: name,
            value: id,
            fattenerPigCount,
          })),
        })
      );

      return [
        {
          label: data.fullData.farmName,
          value: data.fullData.farmId,
          house: [
            {
              label: data.fullData.houseName,
              value: data.fullData.houseId,
              fattenerPigCount: `${data.fullData.fattenerPigCount}`,
            },
          ],
        },
        ..._farmOptions,
      ];
    }
    return [];
  }, [farmAndHouseData, data]);

  const contractMap = {
  pay_after_expire: "จ่ายหลังหมดสัญญา",
  fill_credit: "จ่ายตาม invoice",
};

  useEffect(() => {
    if (isOpen && data) {
      getFarmAndHouse(data.fullData.id)
        .then((result) => {
          setFarmAndHouseData(result);
          setForm((prev) => ({
            ...prev,
            farmSize: [
              {
                label: data.fullData.creditInformation.farmSize.toUpperCase(),
                value: data.fullData.creditInformation.farmSize,
              },
            ],
            farmName: [
              { label: data.fullData.farmName, value: data.fullData.farmId },
            ],
            greenHouse: [
              {
                label: data.fullData.houseName,
                value: data.fullData.houseId,
                fattenerPigCount: `${data.fullData.fattenerPigCount}`,
              },
            ],
            pigType: "หมูขุน",
            countPig: `${data.fullData.fattenerPigCount}`,
            financialAmount: `${data.fullData.creditInformation.creditAmount}`,
            grade: [
              {
                label: data.fullData.customerGrade.toUpperCase(),
                value: data.fullData.customerGrade,
              },
            ],
            contractType: [
              
              { label: contractMap[data.fullData.creditInformation.contractType as "pay_after_expire" | 'fill_credit'], value: data.fullData.creditInformation.contractType },
            ], // TODO: will fix later
            timeCredit: `${data.fullData.creditInformation.creditPeriod}`,
          }));
          setCustomerPickingSlip(
            get(data, ["fullData", "creditInformation", "files"], [])
          );
        })
        .catch((e) => {
          console.log("fail to getFarmAndHouse2 error:", e);
        });
    }
  }, [isOpen, data]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>อัปเดตข้อมูล</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={classes.title2}>ข้อมูลการให้เครดิต</div>
        <div className={classes.row}>
          <Dropdown
            label="ขนาดฟาร์ม"
            options={[
              { label: "S", value: "s" },
              { label: "M", value: "m" },
              { label: "L", value: "l" },
              { label: "XL", value: "xl" },
            ]}
            onChange={onChangeForm("farmSize")}
            renderValueArray={form.farmSize}
            errorMessage={errors.farmSize}
          />
          <Dropdown
            label="ฟาร์ม"
            options={farmOptions}
            onChange={onChangeForm("farmName")}
            renderValueArray={form.farmName}
            errorMessage={errors.farmName}
          />
          <Dropdown
            disabled={!get(form.farmName, 0)}
            label="โรงเรือน"
            options={get(form.farmName, ["0", "houses"], [])}
            onChange={(value) => {
              setForm((prev) => ({
                ...prev,
                greenHouse: value,
                countPig: `${get(value, [0, "fattenerPigCount"], "-")}`,
              }));
            }}
            renderValueArray={form.greenHouse}
            errorMessage={errors.greenHouse}
          />
        </div>
        <div className={classes.row2}>
          <Input
            label="ประเภทหมู"
            value={form.pigType}
            onChangeText={onChangeForm("pigType")}
            disabled
          />
          <Input
            label="จำนวนหมู"
            value={form.countPig}
            onChangeText={onChangeForm("countPig")}
            placeholder=""
            disabled
          />
          <Input
            label="วงเงินลูกค้า"
            value={form.financialAmount}
            onChangeText={onChangeForm("financialAmount")}
            inputType="number"
            suffix="บาท"
            errorMessage={errors.financialAmount}
          />
        </div>
        <div className={classes.row2}>
          <Dropdown
            label="เกรดลูกค้า"
            options={[
              { label: "A", value: "a" },
              { label: "B", value: "b" },
              { label: "C", value: "c" },
              { label: "D", value: "d" },
              { label: "E", value: "e" },
              { label: "F", value: "f" },
            ]}
            onChange={onChangeForm("grade")}
            renderValueArray={form.grade}
            errorMessage={errors.grade}
          />
          <Dropdown
            label="ประเภทสัญญา"
            options={[
              { label: "จ่ายหลังหมดสัญญา", value: "pay_after_expire" },
              { label: "จ่ายตาม invoice", value: "fill_credit" },
            ]}
            onChange={onChangeForm("contractType")}
            renderValueArray={form.contractType}
            errorMessage={errors.contractType}
          />
          <Input
            label="ระยะเครดิต"
            value={form.timeCredit}
            onChangeText={onChangeForm("timeCredit")}
            inputType="number"
            suffix="วัน"
            errorMessage={errors.timeCredit}
          />
        </div>
        <div className="h-[8px]"></div>
        <p className="font-normal text-[14px] mb-2">เอกสารการคำนวณเครดิต</p>
        {customerUpload.length > 0 ? (
          <ListOfFile
            files={customerUpload}
            onDownload={handleDownload}
            onDelete={async (idx) => {
              setCustomerUpload([]);
              setCustomerPickingSlip([]);
            }}
          />
        ) : (
          customerPickingSlip.map((row, idx) => {
            return (
              <DownloadLinkWithDelete
                onDownload={handleDownloadByUrl(
                  get(data, ["fullData", "id"]),
                  row.id,
                  row.fileName
                )}
                onDelete={async () => {
                  setCustomerPickingSlip([]);
                }}
                name={row.fileName}
              />
            );
          })
        )}
        <DragAndDropUploadImage
          onUpload={(files) => {
            setCustomerUpload(files);
          }}
          type={{ "application/pdf": [".pdf"] }}
        />
        <div className={tw(classes.title2, "pt-10")}>ข้อมูลสัญญา</div>
        <div className="flex flex-row gap-[12px] pt-6">
          <div className="w-[50%]">
            <span className="font-normal text-[14px] mb-2">วันเริ่มสัญญา</span>
            <div className="pt-2">
              <TextInputCustom
                placeholder="เริ่มต้น"
                value={startDate}
                InputProps={{
                  endAdornment:
                    startDate !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {}}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          fontSize: "24px !important",
                          color: "#646464",
                        }}
                        fontSize="small"
                      >
                        <CalendarSvg />
                      </SvgIcon>
                    ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarStartDate.current?.onOpen(e);
                }}
              />
            </div>
          </div>
          <div className="w-[50%]">
            <span className="font-normal text-[14px] mb-2">
              วันสิ้นสุดสัญญา
            </span>
            <div className="pt-2">
              <TextInputCustom
                placeholder="สิ้นสุด"
                value={endDate}
                InputProps={{
                  endAdornment:
                    startDate !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {}}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          fontSize: "24px !important",
                          color: "#646464",
                        }}
                        fontSize="small"
                      >
                        <CalendarSvg />
                      </SvgIcon>
                    ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarEndDate.current?.onOpen(e);
                }}
              />
            </div>
          </div>
        </div>
        <p className="font-normal text-[14px] mb-2 pt-6">เอกสารสัญญา</p>
        <ListOfFile
          files={customerContractSlip}
          onDownload={handleDownload2}
          onDelete={async (idx) => {
            setCustomerContractSlip([]);
          }}
        />
        <DragAndDropUploadImage
          onUpload={(files) => {
            setCustomerContractSlip(files);
          }}
          type={{ "application/pdf": [".pdf"] }}
        />
        <div className={tw(classes.row, "justify-end")}>
          <ButtonFillCustom title={"ยืนยัน"} onClick={onSubmit} />
        </div>
      </DialogContent>
      <DatePickerPopover
        ref={refCalendarStartDate}
        id={"date-picker-start-date"}
        date={startDate}
        handleCalenderSave={handleSelectStartDate}
      />
      <DatePickerPopover
        ref={refCalendarEndDate}
        id={"date-picker-end-date"}
        date={endDate}
        handleCalenderSave={handleSelectEndDate}
      />
    </Dialog>
  );
};

export default memo(ApproveCredit);

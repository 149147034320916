import { useLoaderData, useNavigate } from "react-router-dom";
import MyOrderSVG from "../../assets/svg/MyOrder";
import OrderFeedSVG from "../../assets/svg/OrderFeed";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";

export default function FeedOrderAndDelivery() {
  const navigate = useNavigate();
  const { farmId } = useLoaderData() as { farmId: string };

  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#ffff]">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/farm/" + farmId)}
          title={"สั่งซื้ออาหารสัตว์"}
        />
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"สั่งซื้ออาหารสัตว์"}
            subTitle={"สร้างรายการใหม่"}
            icon={<OrderFeedSVG />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={() =>
              navigate("/feed-order-and-delivery/order-feed/" + farmId)
            }
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"ออเดอร์ของฉัน"}
            subTitle={"รายการที่ต้องได้รับทั้งหมด"}
            icon={<MyOrderSVG />}
            removeBgIcon={true}
            bgColor="#EEF2FF"
            onPress={() => navigate("/my-order/" + farmId)}
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"ประวัติคำสั่งซื้อ"}
            subTitle={"รายการที่เคยสั่งซื้อทั้งหมด"}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={() => navigate("/order-history/" + farmId)}
          />
        </div>
        {/* <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"การชำระเงิน"}
            subTitle={"รายการที่ต้องชำระ"}
            icon={<PaymentSVG />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={() => navigate("")}
          />
        </div> */}
      </div>
    </div>
  );
}

import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_AUTH_REFRESH_TOKEN_KEY,
  ADMIN_AUTH_TOKEN_KEY,
} from "../../../const/app.constant";
import { login } from "../../../services/AdminAuthService";
import { setItem } from "../../../services/StorageService";
import ButtonWithLoader from "../../components/Button/ButtonWithLoader";
import TextInputCustom from "../../components/Input/TextInputCustom";

function Login() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await login({ email: email });
      setItem(ADMIN_AUTH_TOKEN_KEY, response.result.token);
      setItem(ADMIN_AUTH_REFRESH_TOKEN_KEY, response.result.refreshToken);
      navigate("/admin/home");
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message ?? "Login error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setErrorMessage("");
  }, [email]);

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <h1 className="font-bold text-2xl my-4">Farmtech Login</h1>
      <form onSubmit={handleLogin}>
        <div className="flex flex-col gap-4 w-[320px]">
          <TextInputCustom
            autoComplete="email"
            id="email"
            name="email"
            placeholder="Username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ButtonWithLoader
            isLoading={isLoading}
            isValid={!!email}
            title={"Submit"}
          />
          <div className="min-h-[24px]">
            {errorMessage && (
              <p className="text-error font-bold">{errorMessage}</p>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;

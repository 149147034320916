import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
} from "@mui/material";
import { tw } from "../../../../../../utils/tw";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../group-area/views/Dropdown";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { Input } from "../../../../group-area/views/Input";
import { get } from "lodash";
import * as Y from "yup";
import useFormValidator from "../../../../../../hooks/useFormValidator.hook";
import { AppDispatch } from "../../../../../../redux/store";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import TextInputCustom from "../../../../../components/Input/TextInputCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import DateRangePickerPopover from "../../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import { FILTER_DATE_FORMAT } from "../../../../sale-order/components/SaleOrderSearchFilter";
import dayjs from "dayjs";
import { CalendarSvg } from "../../../../../assets/svg/Calendar.svg";
import { useApproveCreditController } from "../controller";
import { IGetFarmAndHouse } from "../../../../../../types/CreditFeed.type";
import {
  approveFeed,
  getDownloadUrl,
  getFarmAndHouse,
  getUploadUrlCreateFeed,
  rejectFeed,
} from "../../../../../../services/CreditFeed.service";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import DownloadLinkWithDelete from "../../../../../../components/common/File/DownloadLinkWithDelete";
import { handleDownloadBlob } from "../../../../../../utils/downloadFile";
import axios from "axios";
import DatePickerPopover from "../../../../../components/Input/DatePickerPopover/DatePickerPopover";
import TextAreaCustom from "../../../../../components/Input/TextAreaCustom";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";

interface IRejectCreditModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  refetchTab3?: () => void;
  refetchCount?: () => void;
}

type TForm = {
  timeDate: TDropdownValue[];
  reason: TDropdownValue[];
  detailsReason: string;
};

const defaultForm: TForm = {
  timeDate: [],
  reason: [],
  detailsReason: "",
};

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] !max-w-[548px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  title2: tw(`text-[16px] font-bold text-[#3777BC]`),
  text: tw(`font-["IBM Plex Sans Thai"] font-bold text-[16px] leading-5`),
};

const errorSchema = Y.object({
  timeDate: Y.array().min(1, "กรุณาเลือกระยะเวลา"),
  reason: Y.array().min(1, "กรุณาเลือกเหตุผล"),
  detailsReason: Y.string().max(500, "กรอกรายละเอียดได้ไม่เกิน 500 ตัวอักษร"),
});

const RejectCredit = (props: IRejectCreditModalProps) => {
  const { isOpen, onClose, data, refetchTab3, refetchCount } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [form, setForm] = useState<TForm>(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof TForm) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema,
    onValidateSuccess: (_tabIndex) => {
      dispatch(loadingActions.show({}));
      rejectFeed({
        id: data.fullData.id,
        duration: form.timeDate[0].value,
        reason: form.reason[0].value,
        detail: form.detailsReason,
      })
        .then(() => {
          refetchTab3 && refetchTab3();
          setTimeout(() => {
            dispatch(loadingActions.hide());
            dispatch(
              snakeActions.showMessage({
                message: "ไม่อนุมัติสำเร็จ",
                type: "success",
              })
            );
            refetchCount && refetchCount();
          }, 1000);
        })
        .catch((e) => {
          setTimeout(() => {
            dispatch(loadingActions.hide());
            dispatch(
              snakeActions.showMessage({
                message: getErrorMessage(e),
                type: "error",
              })
            );
          }, 1000);
        });
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    setTimeout(() => {
      resetError();
      setForm(defaultForm);
      refetchCount && refetchCount();
    }, 500);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={tw(classes.titleContainer, "items-center")}>
          <p className={tw(classes.title, "pb-0 mb-0")}>ไม่อนุมัติเครดิต</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={tw(classes.row, "flex md:flex-col gap-6")}>
          <Dropdown
            label="ระยะเวลา"
            options={[
              { label: "30 วัน", value: "30" },
              { label: "60 วัน", value: "60" },
              { label: "90 วัน", value: "90" },
            ]}
            onChange={onChangeForm("timeDate")}
            renderValueArray={form.timeDate}
            errorMessage={errors.timeDate}
            placeholder="เลือก"
          />
          <Dropdown
            label="เหตุผล"
            options={[{ label: "ค้างชำระ", value: "in_debt" }]}
            onChange={onChangeForm("reason")}
            renderValueArray={form.reason}
            errorMessage={errors.reason}
            placeholder="เลือก"
          />
          <div className={tw(classes.row, "flex md:flex-col gap-[6px] pt-0")}>
            <div className="text-[#191919] font-normal text-[14px]">
              รายละเอียด
            </div>
            <div className="flex flex-col">
              <TextAreaCustom
                placeholder="กรอก"
                className="w-full"
                value={form.detailsReason}
                onChange={(e) => onChangeForm("detailsReason")(e.target.value)}
                errormessage={errors.detailsReason}
              />
              <div className="w-full flex justify-end pt-[2px]">
                <span className="font-normal text-[12px] text-[#979797]">
                  (maximum 500 characters)
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={tw(classes.row, "justify-end")}>
          <ButtonOutlinedCustom
            btnBgColor="#FFFFFF"
            btnBorderColor="#68C184"
            btnTextColor="#68C184"
            title={"ยกเลิก"}
            onClick={onCloseFn}
          />
          <ButtonFillCustom title={"ยืนยัน"} onClick={onSubmit} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(RejectCredit);

import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BackgrounImage from "../../../assets/images/Login/BackGroungImage.png";

type item = {
  title: string;
  description: string;
};

const NotificationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const data = [
    {
      title: `${t("notification.title1")}`,
      description: `${t("notification.description1")}`,
    },
    {
      title: `${t("notification.title2")}`,
      description: `${t("notification.description2")}`,
    },
  ];

  const getCurrentDate = () => {
    let str = new Date().toString().split(" ");
    return str[2] + ", " + str[1] + " " + str[3];
  };

  const createNotificationsCard = (item: item) => {
    return (
      <div className="max-w-sm rounded-[20px] overflow-hidden shadow-lg bg-[#ffffff] w-[90%] h-[128px] mt-3">
        <div className="flex flex-row justify-between m-3">
          <p className="text-[#68C184] font-[600] text-[20px]">{item.title}</p>
          <DeleteIcon className="!text-[28px] text-[#68C184]" />
        </div>
        <div className="m-3">
          <p className="text-[#646464] text-[15px] text-left">
            {item.description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${BackgrounImage})`,
        height: "100vh",
        backgroundSize: "cover",
        backgroundColor: "#4882c8",
      }}
      className="main-container"
    >
      <div className="flex justify-center flex-col rounded-b-[45px] bg-[#ffffff] h-[120px]">
        <div className="flex items-center text-left w-[100%] px-[20px]">
          <IconButton onClick={() => navigate(-1)}>
            <NavigateBeforeIcon className="!text-[35px] text-[#68C184]" />
          </IconButton>
          <h2 className="text-[#68C184] text-[24px] font-[500] ">
            {t("notification.headerTitleText") || ""}
          </h2>
        </div>
      </div>
      <div className="h-[75%]">
        <div className="flex justify-end flex-row mt-4">
          <div>
            <DoneOutlinedIcon className="text-[20px] text-[#FFFFFF]" />
            <DoneOutlinedIcon className="text-[20px] text-[#FFFFFF] ml-[-16px]" />
          </div>
          <p className="text-[#ffffff] ml-[10px]">
            {" "}
            {t("notification.markAsRead")}{" "}
          </p>
          <NotificationsIcon className="text-[20px] text-[#FFFFFF] ml-[20px] mt-[-3px]" />
          <Switch
            defaultChecked
            color="secondary"
            size="medium"
            className="top-[-8px]"
          />
        </div>
        <div className="flex justify-start flex-row ml-4 text-[#ffffff]">
          <p> {getCurrentDate()} </p>
        </div>
        <div className="flex flex-col items-center mt-2">
          {data.map((item) => createNotificationsCard(item))}
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Toolbar,
} from "@mui/material";
import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN_AUTH_REFRESH_TOKEN_KEY,
  ADMIN_AUTH_TOKEN_KEY,
} from "../../../const/app.constant";
import { deleteItem } from "../../../services/StorageService";
import { UserRole } from "../../../utils/enum/user-role.enum";
import FarmSookLogo from "../../assets/fs-admin-logo.png";
import { ClaimOrderSvg } from "../../assets/svg/ClaimOrder.svg";
import { CreditSvg } from "../../assets/svg/Credit.svg";
import { FarmSvg } from "../../assets/svg/Farm.svg";
import { FarmTechWarehouseSvg } from "../../assets/svg/FarmTechWarehouse.svg";
import { HomeSvg } from "../../assets/svg/Home.svg";
import { InvoiceListSvg } from "../../assets/svg/InvoiceList.svg";
import { ManageStockSvg } from "../../assets/svg/ManageStock.svg";
import { NotificationSvg } from "../../assets/svg/Notification.svg";
import { PartnerSvg } from "../../assets/svg/Partner.svg";
import { SaleOrderSvg } from "../../assets/svg/SaleOrder.svg";
import { SettingSvg } from "../../assets/svg/Setting.svg";
import { useAdminAuth } from "../AdminAuth/AdminAuthProvider";

const drawerWidth = 280;

const linkMenu = [
  {
    group: "g-01",
    name: "Home",
    link: "/admin/home",
    icon: <HomeSvg />,
    subGroup: [],
  },
  {
    group: "g-02",
    name: "Farm",
    link: "",
    icon: <FarmSvg />,
    subGroup: [
      {
        name: "Dashboard",
        link: "",
      },
      {
        name: "Details",
        link: "",
      },
    ],
  },
  {
    group: "g-03",
    name: "Partner",
    link: "",
    icon: <PartnerSvg />,
    subGroup: [
      {
        name: "Dashboard",
        link: "",
      },
      // {
      //   name: "Stock Management",
      //   link: "/admin/partner-stock-management",
      // },
    ],
  },
  {
    group: "g-04",
    name: "Manage Stock",
    link: "/admin/manage-stock",
    icon: <ManageStockSvg />,
    subGroup: [],
  },
  {
    group: "g-05",
    name: "Sale Order",
    link: "/admin/sale-order",
    icon: <SaleOrderSvg />,
    subGroup: [],
  },
  {
    group: "g-06",
    name: "Claim Order",
    link: "",
    icon: <ClaimOrderSvg />,
    subGroup: [],
  },
  {
    group: "g-07",
    name: "Credit",
    link: "",
    icon: <CreditSvg />,
    subGroup: [
      {
        name: "Credit Feed",
        link: "/admin/credit/credit-feed",
      },
      {
        name: "Credit Status",
        link: "/admin/credit/credit-status",
      },
    ],
  },
  {
    group: "g-08",
    name: "Money Dept and Collection",
    link: "",
    icon: <CreditSvg />,
    subGroup: [
      {
        name: "Dept and Collection",
        link: "",
      },
      {
        name: "History",
        link: "",
      },
    ],
  },
  {
    group: "g-09",
    name: "Invoice List",
    link: "",
    icon: <InvoiceListSvg />,
    subGroup: [],
  },
  {
    group: "g-10",
    name: "Farmtech Warehouse",
    link: "",
    icon: <FarmTechWarehouseSvg />,
    subGroup: [],
  },
  {
    group: "g-11",
    name: "Setting",
    link: "",
    icon: <SettingSvg />,
    subGroup: [
      {
        name: "Master Data",
        link: "/admin/masterdata",
      },
      {
        name: "Role",
        link: "/admin/role",
      },
      {
        name: "Group Area",
        link: "/admin/group-area",
      },
      {
        name: "User Management",
        link: "/admin/user-management",
      },
    ],
  },
];

export const canViewMenus: Record<UserRole, string[]> = {
  sale: ["g-01", "g-04", "g-05", "g-07", "g-11"],
  lending: ["g-01", "g-05", "g-07", "g-11"],
  admin: ["g-01", "g-11"],
  management: ["g-01", "g-04", "g-07", "g-11"],
  co_lending: ["g-01"],
  co_sale: ["g-01"],
  super_user: ["g-01"],
  guest: ["g-01"],
};

export interface IMenuBarProps {
  children: JSX.Element;
  window?: () => Window;
}

function MenuBar({ children, window }: IMenuBarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isOpenSubmenu, setIsOpenSubMenu] = React.useState(0);
  const { user } = useAdminAuth();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleOpenSubMenu = (index: number) => {
    if (isOpenSubmenu === index) {
      setIsOpenSubMenu(0);
    } else {
      setIsOpenSubMenu(index);
    }
  };

  const handleLogout = () => {
    deleteItem(ADMIN_AUTH_TOKEN_KEY);
    deleteItem(ADMIN_AUTH_REFRESH_TOKEN_KEY);
    navigate("/admin");
  };

  const filteredMenu = useMemo(
    () =>
      linkMenu.filter((row) =>
        (canViewMenus[user?.userRole || UserRole.Admin] || []).includes(
          row.group
        )
      ),
    [user]
  );

  const drawer = (
    <div className="px-4">
      <div className="flex w-full py-5 pl-5">
        <img
          alt="logo"
          src={FarmSookLogo}
          height="28px"
          style={{ height: "28px" }}
        />
      </div>
      <List key={`app-bar-menu-list`}>
        {filteredMenu.map((item, index) => (
          <div key={index}>
            <ListItem
              key={item.group}
              disablePadding
              sx={{
                color: "#4B4B4B",
                "&:hover": {
                  bgcolor: "#68C184",
                  color: "#ffffff",
                  borderRadius: "4px",
                },
              }}
              onClick={() => {
                handleOpenSubMenu(index);
                if (item.link && item.link !== "") {
                  navigate(item.link);
                }
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: "44px",
                  color: "currentcolor",
                  "&.Mui-selected": {
                    bgcolor: "#68C184",
                    color: "#ffffff",
                    borderRadius: "4px",
                  },
                }}
                selected={Boolean(
                  item.link && item.link.includes(location.pathname)
                )}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "unset",
                    paddingRight: "1rem",
                    color: "currentcolor",
                  }}
                >
                  <SvgIcon
                    inheritViewBox={true}
                    sx={{
                      color: "currentColor",
                      width: "24px",
                      height: "auto",
                    }}
                    component={"div"}
                  >
                    {item.icon}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{}}
                  primaryTypographyProps={{
                    sx: {
                      fontFamily: "DM Sans !important",
                      fontWeight: 500,
                      fontSize: "1rem",
                      lineHeight: "20px",
                    },
                  }}
                />
                {item.subGroup.length > 0 &&
                  (isOpenSubmenu !== index ? (
                    <KeyboardArrowDownRoundedIcon sx={{ stroke: "none" }} />
                  ) : (
                    <KeyboardArrowUpIcon sx={{ stroke: "none" }} />
                  ))}
              </ListItemButton>
            </ListItem>
            {isOpenSubmenu === index && item.subGroup.length > 0 ? (
              <List key={`head-list-${index}`}>
                {item.subGroup.map((val, idx) => (
                  <ListItem
                    key={`sub-item-${snakeCase(val.name)}-${idx}`}
                    disablePadding
                    sx={{
                      color: "#4B4B4B",
                      "&:hover": {
                        bgcolor: "#68C184",
                        color: "#ffffff",
                        borderRadius: "4px",
                      },
                    }}
                    onClick={() => {
                      navigate(val.link);
                    }}
                  >
                    <ListItemButton
                      sx={{
                        "&.Mui-selected": {
                          bgcolor: "#68C184",
                          color: "#ffffff",
                          borderRadius: "4px",
                        },
                      }}
                      selected={Boolean(
                        item.link && item.link.includes(location.pathname)
                      )}
                    >
                      <ListItemText
                        primary={val.name}
                        sx={{}}
                        primaryTypographyProps={{
                          sx: {
                            fontFamily: "DM Sans !important",
                            fontWeight: 500,
                            fontSize: "1rem",
                            lineHeight: "20px",
                            paddingLeft: "40px",
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            ) : null}
          </div>
        ))}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", fontFamily: "DM Sans !important" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#F5F5F5FB",
          border: "1px solid #EEEEEE",
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="div"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div className="flex flex-row h-full align-middle items-center gap-6">
              <Badge
                color="secondary"
                badgeContent={0}
                showZero
                sx={{
                  paddingTop: "4px",
                  "& .MuiBadge-badge": {
                    transform: "scale(1) translate(30%, -40%)",
                  },
                }}
              >
                <SvgIcon sx={{ fontSize: "24px", stroke: "#68C184" }}>
                  <NotificationSvg />
                </SvgIcon>
              </Badge>
              <Avatar
                alt="user-avatar"
                src="https://i.pravatar.cc/300"
                sx={{
                  width: "25px",
                  height: "25px",
                  border: "1px solid #D9D9D9",
                  cursor: "pointer",
                }}
                onClick={handleLogout}
              />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#F5F5F5",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#F5F5F5",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default MenuBar;

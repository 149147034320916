import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import dragHandle from "../../assets/images/Login/Draghandle.png";
import AuthBox from "../../components/common/AuthBox";
import CheckboxFS from "../../components/common/CheckBox/checkbox";
import LabeledInput from "../../components/common/Input/LabeledInput";
import { useAuth } from "../../components/common/ProtectedRoute/ProtectedRoute";
import {
  AUTH_TOKEN_KEY,
  AUTH_USERNAME,
  IS_SAVED,
  LOCK_OUT,
  NUMBER_OF_MISTAKE,
} from "../../const/app.constant";
import {
  getOTPByUsernameSevice,
  userLoginService,
} from "../../services/AuthService";

type LoginData = {
  userName: string;
  pin: string;
};

const LoginAuthenticationPage = ({ isOpen }: any) => {
  const [username, setUsername] = useState("");
  const [pin, setPin] = useState([]);
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [userNameError, setUserError] = useState("");
  const [lockoutError, setlockoutError] = useState<boolean>(false);
  const [waitTime, setWaitTime] = useState<string>("");
  const usernameRegex = /^[ก-๙a-zA-Z0-9_]{1,20}$/;
  const [checked, setChecked] = useState<boolean>(false);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const checkOTP = (arr: any) => {
    if (arr.every((item: any) => item.value)) {
      setPin(arr.map((item: any) => item.value));
    }
  };
  const checkLockout = () => {
    const lockout = localStorage.getItem(LOCK_OUT);
    if (lockout)
      if (dayjs(lockout) > dayjs()) {
        setWaitTime(dayjs(lockout).diff(dayjs(), "minutes").toString());
        setError(true);
        setlockoutError(true);
        return false;
      } else {
        setlockoutError(false);
        return true;
      }
    return true;
  };
  const getUserLogin = async () => {
    const data: LoginData = {
      userName: username,
      pin: pin.join(""),
    };
    if (checkLockout()) {
      const resp = await userLoginService(data);
      if (resp?.status === 200) {
        setError(false);
        setlockoutError(false);
        localStorage.setItem("firstTimeLogin", resp?.data?.firstTimeLogin);
        localStorage.setItem(AUTH_USERNAME, username);
        if (checked) localStorage.setItem(IS_SAVED, checked.toString());
        else localStorage.removeItem(IS_SAVED);
        localStorage.removeItem(LOCK_OUT);
        localStorage.removeItem(NUMBER_OF_MISTAKE);
        login(resp.data.token, resp.data.userType, resp.data.firstTimeLogin);
      } else {
        let mistake = 0;

        setError(true);

        if (localStorage.getItem(NUMBER_OF_MISTAKE))
          mistake = parseInt(localStorage.getItem(NUMBER_OF_MISTAKE)!);
        localStorage.setItem(NUMBER_OF_MISTAKE, (mistake + 1).toString());
        if ((mistake + 1) % 5 === 0)
          localStorage.setItem(
            LOCK_OUT,
            dayjs()
              .add(15 * ((mistake + 1) / 5), "minute")
              .toISOString()
          );
      }
    } else setlockoutError(true);
  };

  const handleResetButtonPIN = () => {
    userSendOTP();
  };

  const userSendOTP = async () => {
    const resp: any = await getOTPByUsernameSevice(username);
    if (resp.status === 200) {
      navigate("/pin-authentication", {
        state: { username, mobileNumber: resp.data.mobileNumber },
      });
      localStorage.setItem(AUTH_TOKEN_KEY, resp.data.token);
    } else {
      setUserError(resp.data.message || `Username doesn't exist.`);
    }
  };

  useEffect(() => {
    setUserError("");
    if (usernameRegex.test(username) && pin.length === 4) getUserLogin();
  }, [username, pin]);

  useEffect(() => {
    const user = localStorage.getItem(AUTH_USERNAME);
    const check = Boolean(localStorage.getItem(IS_SAVED));
    if (user && check) setUsername(user);
    setChecked(check);
  }, []);
  return (
    <div className={`bottom-sheet ${isOpen && "open"}`}>
      <div className="flex justify-center pt-[15px]">
        <img src={dragHandle} />
      </div>
      <div className="py-[60px]">
        <LabeledInput
          labelName={t("set_pin.input_user_name_label")}
          inputValue={username}
          errorMessage={userNameError}
          onChange={(e) => setUsername(e.target.value)}
          pattern={usernameRegex}
          type="checked"
          datatestid="loginUsername"
        />
        <div className="flex items-center px-[8%]">
          <CheckboxFS
            checked={checked}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              check: boolean
            ) => setChecked(check)}
          />
          <label>{t("set_pin.remember_me")}</label>
        </div>
        <div className="flex flex-col items-center my-[30px] fixed top-[35%] w-[100%]">
          <label className="text-[#34407B] font-[500] text-[16px] mb-[25px]">
            {t("set_pin.enter_pin")}
          </label>
          <AuthBox
            boxLength={4}
            lockOutError={lockoutError}
            lockOutMessage={t("authentication.lockout_error").replace(
              "-",
              waitTime
            )}
            inputType="password"
            error={error}
            sucess={false}
            errorMessage={t("authentication.invalid_username_error")}
            checkOTP={checkOTP}
            datatestid="loginPIN"
            labelStyle={{ textAlign: "center" }}
            alignError="center"
          />
        </div>
        <div className="fixed bottom-[15%] w-[100%] my-[30px] text-center">
          {userNameError && (
            <label className="error font-[400] text-[14px] font-['IBM Plex Sans Thai'] font-normal text-[#707070] flex justify-center px-[15px] w-[100%]">
              {userNameError}
            </label>
          )}
          {usernameRegex.test(username) && (
            <Button
              data-testid="resetPINButton"
              sx={{ textTransform: "none", textDecoration: "underline" }}
              onClick={handleResetButtonPIN}
            >
              {t("login.resetPin")}
            </Button>
          )}
        </div>
        <div className="fixed bottom-[12%] w-[100%] text-center">
          <p className="text-[14px] text-[#012055] font-[400] text-center my-[10px]">
            {t("set_pin.new_user")}{" "}
            <Link to="/signup" className="font-[500] underline">
              {t("login.link_register_now")}
            </Link>
          </p>
          <p className="text-[14px] text-[#012055] font-[400] text-center">
            {t("login.cantAccess")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginAuthenticationPage;

import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { approveComingOrder } from "../../../../services/SaleOrderService";
import {
  postConfirmDelivery,
  postUpdateDelivery,
} from "../../../../services/admin/sale-order/approvedOrder.service";
import { ITableRowDetail } from "../../../../types/EditOrderFeed";
import { FeedBrands, UnitEnum } from "../../../../types/FeedBrand.type";
import { thousandComma } from "../../../../utils/common/string";
import delay from "../../../../utils/delay";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonLinkCustom from "../../../components/Button/ButtonLinkCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import ApproveOrderUpcomingModal from "../../../components/Modal/ApproveOrderUpcomingModal";
import ConfirmOrderDeliveryModal from "../../../components/Modal/ConfirmOrderDeliveryModal";
import EditOrderCustomModal from "../../../components/Modal/EditOrderCustomModal";
import RejectOrderUpcomingModal from "../../../components/Modal/RejectOrderUpcomingModal";
import SuccessSnackBar from "../../../components/SnackBar/SuccessSnackBar";
import TableBasicCustom, {
  ITableBasicCustomHeadCell,
  StyledTableBasicCustomCell,
  StyledTableBasicCustomRow,
} from "../../../components/Table/TableBasicCustom";
import { useCountSaleOrder } from "../Provider/SaleOrderProvider";
import EditOrderModal from "./EditOrderModal/EditOrderModal";

const tableHeaders: ITableBasicCustomHeadCell[] = [
  {
    disablePadding: false,
    label: "",
    width: "60px",
  },
  {
    disablePadding: false,
    label: "เเบรนด์อาหารสัตว์",
    width: "120px",
  },
  {
    disablePadding: false,
    label: "เบอร์อาหารสัตว์",
    width: "100px",
  },
  {
    disablePadding: false,
    label: "ราคาต่อหน่วย",
    width: "100px",
  },
  {
    disablePadding: false,
    label: "จำนวน",
    width: "80px",
  },
  {
    disablePadding: false,
    label: "ราคารวม",
    width: "110px",
  },
  // {
  //   disablePadding: false,
  //   label: "สถานะ(รอ stock)",
  //   width: "120px",
  // },
];

type OrderStockStatus = "delivery_ready" | "delivery_not_ready";

interface IData {
  animalFoodBrands: string;
  animalFoodNumber: string;
  pricePerUnit: string;
  quantity: string;
  amount: string;
  status: OrderStockStatus;
}

const dataMock: IData[] = [
  {
    animalFoodBrands: "Betagro",
    animalFoodNumber: "SKU-0001",
    pricePerUnit: "50 บาท",
    quantity: "50 ถุง",
    amount: "10,000.00 บาท",
    status: "delivery_ready",
  },
  {
    animalFoodBrands: "Betagro",
    animalFoodNumber: "SKU-0001",
    pricePerUnit: "50 บาท",
    quantity: "50 ถุง",
    amount: "10,000.00 บาท",
    status: "delivery_not_ready",
  },
];

const OrderStockStatusMap: Record<
  OrderStockStatus,
  { color: string; bgColor: string; label: string }
> = {
  delivery_ready: {
    color: "#29A329",
    bgColor: "#F0FDF4",
    label: "พร้อมจัดส่ง",
  },
  delivery_not_ready: {
    color: "#F57C00",
    bgColor: "#FBCFA166",
    label: "สินค้าไม่พร้อม",
  },
};

interface IOrderDetailPanelProps {
  id: string | number;
  order?: ITableRowDetail;
  showRejectBtn?: boolean;
  showApproveBtn?: boolean;
  showDeliveryBtn?: boolean;
  showManageStockBtn?: boolean;
  ShowEditOrderBtn?: boolean;
  showReadyToDeliveryChip?: boolean;
  disableDeliveryBtn?: boolean;
  handleFetch: () => void;
}

function OrderDetailPanel({
  id,
  order,
  showRejectBtn,
  showApproveBtn,
  showDeliveryBtn,
  showManageStockBtn,
  ShowEditOrderBtn,
  showReadyToDeliveryChip,
  disableDeliveryBtn,
  handleFetch,
}: IOrderDetailPanelProps) {
  const [openRejectModal, setOpenRejectModal] = React.useState(false);
  const [openApproveModal, setOpenApproveModal] = React.useState(false);
  const [openDeliveryModal, setOpenDeliveryModal] = React.useState(false);
  const [openDeliverySuccessModal, setOpenDeliverySuccessModal] =
    React.useState(false);
  const [openRejectSnackBar, setOpenRejectSnackBar] = React.useState(false);
  const [openApproveSnackBar, setOpenApproveSnackBar] = React.useState(false);
  const [openDeliverySnackBar, setOpenDeliverySnackBar] = React.useState(false);
  const [openDeliverySuccessSnackBar, setOpenDeliverySuccessSnackBar] =
    React.useState(false);
  const loaderData = useLoaderData() as { feedBrands: FeedBrands[] };
  const [isOpenEditOrderModal, setIsOpenEditOrderModal] =
    useState<boolean>(false);
  const handleSaleApprove = async (
    isApproved: boolean,
    rejectOption?: string
  ) => {
    await approveComingOrder(id.toString(), {
      isApproved,
      rejectOption,
    });
  };
  const navigate = useNavigate();
  const { resetCount } = useCountSaleOrder();
  return (
    <>
      <div className="relative h-full w-full pt-6">
        <div className="flex flex-row justify-between align-middle items-center">
          <span className="font-sans font-bold text-[20px] text-[#68C184]">
            รายละเอียดออเดอร์
          </span>
          <div className="flex flex-row gap-2">
            <div className="pr-6 self-center">
              {showManageStockBtn && (
                <ButtonLinkCustom
                  title="จัดการสต๊อก"
                  onClick={() =>
                    navigate("/admin/manage-stock/" + order?.partnerId)
                  }
                />
              )}
            </div>
            {ShowEditOrderBtn && (
              <ButtonOutlinedCustom
                title="แก้ไขออเดอร์"
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                disabled={order?.status === "delivery"}
                onClick={() => setIsOpenEditOrderModal(true)}
              />
            )}
            {showRejectBtn && (
              <ButtonFillCustom
                onClick={() => setOpenRejectModal(true)}
                title="ไม่อนุมัติ"
                btnTextColor="#ffffff"
                btnBgColor="#D32F2F"
              />
            )}
            {showApproveBtn && (
              <ButtonFillCustom
                onClick={() => setOpenApproveModal(true)}
                title="อนุมัติ"
                btnTextColor="#ffffff"
                btnBgColor="#68C184"
              />
            )}
            {showDeliveryBtn &&
              (order?.status === "delivery" ? (
                <ButtonFillCustom
                  onClick={() => setOpenDeliverySuccessModal(true)}
                  disabled={disableDeliveryBtn}
                  title="จัดส่งสำเร็จ"
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                />
              ) : (
                <ButtonFillCustom
                  onClick={() => setOpenDeliveryModal(true)}
                  disabled={disableDeliveryBtn}
                  title="จัดส่งสินค้า"
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                />
              ))}
          </div>
        </div>
        <div className="pt-6 w-[264px] min-w-[264px] flex justify-between">
          <span className="font-sans font-bold text-[14px] text-[#1B2128]">
            ราคารวมทั้งหมด
          </span>
          <span className="font-sans font-normal text-[14px] text-[#1B2128]">
            {thousandComma(Number(order?.totalFeedPrice), 2)} บาท
          </span>
        </div>
        <div className="pt-6 w-full">
          <TableBasicCustom
            headerItems={tableHeaders}
            tableBodyChildren={order?.orderFeedItems.map((item, index) => {
              const farmSize = order.farmSize;
              const deliverStatus = order.stocks.find(
                (row) =>
                  row.fbi.id === item.feedBrandItem.id &&
                  row.amount >= item.feedAmount
              )
                ? "delivery_ready"
                : "delivery_not_ready";
              return (
                <StyledTableBasicCustomRow key={`row-${index}`}>
                  <StyledTableBasicCustomCell
                    align="left"
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "19px",
                    }}
                  >
                    {index + 1}.
                  </StyledTableBasicCustomCell>
                  <StyledTableBasicCustomCell
                    align="left"
                    sx={{
                      fontFamily: "IBM Plex Sans Thai",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "19px",
                    }}
                  >
                    {item.feedBrand.nameTh}
                  </StyledTableBasicCustomCell>

                  <StyledTableBasicCustomCell
                    align="left"
                    sx={{
                      fontFamily: "IBM Plex Sans Thai",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "19px",
                    }}
                  >
                    {item.feedBrandItem.skuTh}
                  </StyledTableBasicCustomCell>

                  <StyledTableBasicCustomCell
                    align="left"
                    sx={{
                      fontFamily: "IBM Plex Sans Thai",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "19px",
                    }}
                  >
                    {`${item.feedBrandItem.pricePerUnit} บาท`}
                  </StyledTableBasicCustomCell>

                  <StyledTableBasicCustomCell
                    align="left"
                    sx={{
                      fontFamily: "IBM Plex Sans Thai",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "19px",
                    }}
                  >
                    {`${thousandComma(item.feedAmount, 2)} ${
                      UnitEnum[item.feedBrandItem.unit as keyof typeof UnitEnum]
                    }`}
                  </StyledTableBasicCustomCell>

                  <StyledTableBasicCustomCell
                    align="left"
                    sx={{
                      fontFamily: "IBM Plex Sans Thai",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "19px",
                    }}
                  >
                    {`${thousandComma(item.totalItemPrice, 2)} บาท`}
                  </StyledTableBasicCustomCell>
                  {showReadyToDeliveryChip && farmSize === "s" && (
                    <StyledTableBasicCustomCell align="left">
                      <ChipOrderStatus
                        label={OrderStockStatusMap[deliverStatus].label}
                        fontColor={OrderStockStatusMap[deliverStatus].color}
                        bgColor={OrderStockStatusMap[deliverStatus].bgColor}
                      />
                    </StyledTableBasicCustomCell>
                  )}
                </StyledTableBasicCustomRow>
              );
            })}
          />
        </div>
      </div>
      <EditOrderModal
        order={order}
        isOpen={isOpenEditOrderModal}
        handleClose={() => setIsOpenEditOrderModal(false)}
        feedBrands={loaderData.feedBrands}
        handleFetch={() => handleFetch()}
      />
      <RejectOrderUpcomingModal
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        onSubmit={async (reason) => {
          handleSaleApprove(false, reason);
          setOpenRejectModal(false);
          setOpenRejectSnackBar(true);
          await delay(2000);
          handleFetch();
          resetCount();
        }}
      />
      <ApproveOrderUpcomingModal
        open={openApproveModal}
        onClose={() => setOpenApproveModal(false)}
        onSubmit={async () => {
          await handleSaleApprove(true);
          setOpenApproveModal(false);
          setOpenApproveSnackBar(true);
          await delay(2000);
          handleFetch();
          resetCount();
        }}
      />
      <ConfirmOrderDeliveryModal
        open={openDeliverySuccessModal}
        onClose={() => setOpenDeliverySuccessModal(false)}
        onSubmit={async () => {
          await postConfirmDelivery(order?.id);
          setOpenDeliverySuccessModal(false);
          setOpenDeliverySuccessSnackBar(true);
          await delay(2000);
          handleFetch();
          resetCount();
        }}
      />
      <EditOrderCustomModal
        title={"ยืนยันการจัดส่งสินค้า"}
        subtitleFirstRow={"คุณต้องการจัดส่งสินค้าใช่หรือไม่?"}
        subtitleSecondRow={"การดำเนินการนี้ไม่สามารถยกเลิกได้"}
        buttonPrimaryText={"ยืนยัน"}
        buttonSecondaryText={"ยกเลิก"}
        open={openDeliveryModal}
        onClose={() => setOpenDeliveryModal(false)}
        onSubmit={async () => {
          await postUpdateDelivery(order?.id);
          setOpenDeliveryModal(false);
          setOpenDeliverySnackBar(true);
          await delay(2000);
          handleFetch();
          resetCount();
        }}
      />
      <SuccessSnackBar
        openSnackbar={openRejectSnackBar}
        handleCloseSnackbar={() => {
          setOpenRejectSnackBar(false);
        }}
        snackbarText={"ไม่อนุมัติสำเร็จ"}
      />
      <SuccessSnackBar
        openSnackbar={openApproveSnackBar}
        handleCloseSnackbar={() => {
          setOpenApproveSnackBar(false);
        }}
        snackbarText={"อนุมัติสำเร็จ"}
      />
      <SuccessSnackBar
        openSnackbar={openDeliverySnackBar}
        handleCloseSnackbar={() => {
          setOpenDeliverySnackBar(false);
        }}
        snackbarText={"จัดส่งสินค้าสำเร็จ"}
      />
      <SuccessSnackBar
        openSnackbar={openDeliverySuccessSnackBar}
        handleCloseSnackbar={() => {
          setOpenDeliverySuccessModal(false);
        }}
        snackbarText={"จัดส่งสำเร็จ"}
      />
    </>
  );
}

export default OrderDetailPanel;

import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface IcustomStylesProps {
  borderRadius?: string;
  textAlign?: string;
  borderColor?: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

export default function TextInputFS(
  props: TextFieldProps & IcustomStylesProps
) {
  const { borderRadius, textAlign, borderColor, type, ...restProps } = props;
  return (
    <TextField
      {...restProps}
      color="tertiary"
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent:
          type === "number" ? (NumericFormatCustom as any) : undefined,
        sx: {
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: borderColor || "#3777BC",
          textAlign: textAlign || "",
        },
        ...restProps?.InputProps,
      }}
      InputLabelProps={{ shrink: restProps.value ? true : false }}
    />
  );
}

import { useCallback, useEffect, useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Checkbox, Link, SvgIcon, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { cloneDeep, get } from "lodash";
import { useShowButtonByRoles } from "../../../../../hooks/useActionButtonByRoles";
import {
  filterCustomerTable,
  getExportCreditFeed,
} from "../../../../../services/CreditFeed.service";
import { UserRole } from "../../../../../utils/enum/user-role.enum";
import { DownloadSvg } from "../../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import TextInputSearchCustom from "../../../../components/Input/TextInputSearchCustom";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import { Dropdown, TDropdownValue } from "../../../group-area/views/Dropdown";
import FillSaleContract from "./FillSaleContract";
import { useApproveCreditController } from "./controller";
import { makeXlsx } from "../../../../../utils/make-xlsx";

type Props = {
  tabIndex?: number;
  currentPage: number;
  count: number;
  onChangePage: (page: number) => void;
  refetchCount: () => void;
};

type TPartnerStatus = "success" | "rejected";

interface Data {
  customerId: string;
  customerName: string;
  garde: string;
  saleName: string;
  date: string;
  contractNumber?: string;
  statues?: string;
}

interface DataExport {
  customerId: string;
  customerName: string;
  garde: string;
  saleName: string;
  date: string;
}

interface DataExport2 {
  customerId: string;
  customerName: string;
  garde: string;
  saleName: string;
  date: string;
  contractNumber: string;
  statues: string;
}

function createData(
  customerId: string,
  customerName: string,
  garde: string,
  saleName: string,
  date: string
): DataExport {
  return {
    customerId,
    customerName,
    garde,
    saleName,
    date,
  };
}

function createData2(
  customerId: string,
  contractNumber: string,
  customerName: string,
  garde: string,
  saleName: string,
  date: string,
  statues: string
): DataExport2 {
  return {
    customerId,
    contractNumber,
    customerName,
    garde,
    saleName,
    date,
    statues,
  };
}

const headCells: ITableCustomHeadCell<keyof Data>[] = [
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "auto",
    isSorting: true,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "auto",
    isSorting: false,
  },
  {
    id: "garde",
    numeric: false,
    disablePadding: false,
    label: "เกรดลูกค้า",
    width: "auto",
    isSorting: true,
  },
  {
    id: "saleName",
    numeric: false,
    disablePadding: false,
    label: "พนักงานขาย",
    width: "auto",
    isSorting: false,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัพเดตล่าสุด",
    width: "auto",
    isSorting: true,
  },
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
    isSorting: false,
  },
];

const headCells2: ITableCustomHeadCell<keyof Data>[] = [
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "auto",
    isSorting: true,
  },
  {
    id: "contractNumber",
    numeric: false,
    disablePadding: false,
    label: "เลขที่สัญญา",
    width: "auto",
    isSorting: false,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "auto",
    isSorting: false,
  },
  {
    id: "garde",
    numeric: false,
    disablePadding: false,
    label: "เกรดลูกค้า",
    width: "auto",
    isSorting: true,
  },
  {
    id: "saleName",
    numeric: false,
    disablePadding: false,
    label: "พนักงานขาย",
    width: "auto",
    isSorting: false,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัพเดตล่าสุด",
    width: "auto",
    isSorting: true,
  },
  {
    id: "statues",
    numeric: false,
    disablePadding: false,
    label: "สถานะเครดิต",
    width: "auto",
    isSorting: false,
  },
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
    isSorting: false,
  },
];

const OrderStatusMap: Record<
  TPartnerStatus,
  { color: string; bgColor: string; label: string }
> = {
  success: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "สำเร็จ",
  },
  rejected: {
    color: "#D32F2F",
    bgColor: "#F9E0E0",
    label: "ไม่อนุมัติ",
  },
};

export const grade = [
  { label: "A", value: "a" },
  { label: "B", value: "b" },
  { label: "C", value: "c" },
  { label: "D", value: "d" },
  { label: "E", value: "e" },
  { label: "F", value: "f" },
];

const status = [
  { label: "สำเร็จ", value: "approved" },
  { label: "ไม่อนุมัติ", value: "denied" },
];

interface TForm {
  grade: TDropdownValue[];
  status: TDropdownValue[];
}

const defaultForm: TForm = {
  grade: [],
  status: [],
};

const SalesContract = (props: Props) => {
  const { tabIndex, currentPage, onChangePage, refetchCount, count } = props;

  const [form, setForm] = useState<TForm>(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof TForm) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { searchText, onSearchText, onClearSearchText } =
    useApproveCreditController();

  const [itemSelected, setItemSelected] = useState<string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = rowPartner.map((n) => n?.fullData.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const [rowPartner, setRowPartner] = useState<any[]>([]);

  const refetchTab3 = useCallback(() => {
    filterCustomerTable({
      page: currentPage,
      limit: 10,
      farmerGrade: form.grade.map(({ value }) => value.toLowerCase()).join(","),
      searchText: searchText || undefined,
      tabName: "processing",
    })
      .then((data) => {
        setRowPartner([]);
        setTimeout(() => {
          setRowPartner(
            cloneDeep(
              data.creditFeeds.map((d) => ({
                partnerId: d.registrationId,
                username: `${d.name} ${d.surname}`,
                sellerName: `${d.sellerName} ${d.sellerSurname}`,
                grade: get(d, "customerGrade", "-").toUpperCase(),
                date: dayjs(get(d, "updatedAt")).format("DD/MM/YYYY"),
                fullData: d,
              }))
            )
          );
        }, 100);
      })
      .catch((error) => {
        console.log("fail to filter customer table error:", error);
      });
  }, [tabIndex, currentPage, form.grade, searchText]);

  const refetchTab4 = useCallback(() => {
    filterCustomerTable({
      page: currentPage,
      limit: 10,
      farmerGrade: form.grade.map(({ value }) => value.toLowerCase()).join(","),
      searchText: searchText || undefined,
      status: form.status.map(({ value }) => value).join(",") || undefined,
      tabName: "history",
    })
      .then((data) => {
        setRowPartner([]);
        setTimeout(() => {
          setRowPartner(
            cloneDeep(
              data.creditFeeds.map((d) => ({
                partnerId: d.registrationId,
                username: `${d.name} ${d.surname}`,
                sellerName: `${d.sellerName} ${d.sellerSurname}`,
                grade: get(d, "customerGrade", "-").toUpperCase(),
                date: dayjs(get(d, "updatedAt")).format("DD/MM/YYYY"),
                status:
                  d.reject === null || !d.reject
                    ? ("success" as TPartnerStatus)
                    : "rejected",
                fullData: d,
                contractNumber: d.contractNumber,
              }))
            )
          );
        }, 100);
      })
      .catch((error) => {
        console.log("fail to filter customer table error:", error);
      });
  }, [tabIndex, currentPage, form.grade, form.status, searchText]);

  useEffect(() => {
    if (tabIndex === 3) {
      refetchTab3();
    } else if (tabIndex === 4) {
      refetchTab4();
    }
  }, [tabIndex, currentPage, form.grade, form.status, searchText]);

  useEffect(() => {
    if (tabIndex === 3) {
      refetchTab3();
    } else if (tabIndex === 4) {
      refetchTab4();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 3) {
      setRowPartner([]);
      refetchTab3();
    } else if (tabIndex === 4) {
      setRowPartner([]);
      refetchTab4();
    }
  }, [currentPage]);

  const { showButton } = useShowButtonByRoles([UserRole.Lending]);

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `credit_feed_prepare_sale_contract_tab_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เกรดลูกค้า"
      | "พนักงานขาย"
      | "วันที่อัพเดตล่าสุด";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportCreditFeed(itemSelected);
    if (allSelectItemData) {
      const data: TExcelData[] = allSelectItemData?.map<TExcelData>((row) => {
        const mappedData = createData(
          row.registrationId,
          row.name + " " + row.surname,
          row.customerGrade.toLocaleUpperCase(),
          row.sellerName + " " + `${row.sellerSurname || ""}`,
          dayjs(row.updatedAt).format("DD/MM/YYYY")
        );
        return {
          รหัสลูกค้า: mappedData.customerId,
          ชื่อลูกค้า: mappedData.customerName,
          เกรดลูกค้า: mappedData.garde,
          พนักงานขาย: mappedData.saleName,
          วันที่อัพเดตล่าสุด: mappedData.date,
        };
      });
      return data;
    }
    return [];
  };

  const handleExportExcel2 = async () => {
    const data = await getExportExcelData2();
    const fileName = `credit_feed_history_sale_contract_tab_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData2 = async () => {
    type TColumnName =
      | "รหัสลูกค้า"
      | "เลขที่สัญญา"
      | "ชื่อลูกค้า"
      | "เกรดลูกค้า"
      | "พนักงานขาย"
      | "วันที่อัพเดตล่าสุด"
      | "สถานะเครดิต";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportCreditFeed(itemSelected);
    if (allSelectItemData) {
      const data: TExcelData[] = allSelectItemData?.map<TExcelData>((row) => {
        const mappedData = createData2(
          row.registrationId,
          row.contractNumber,
          row.name + " " + row.surname,
          row.customerGrade.toLocaleUpperCase(),
          row.sellerName + " " + `${row.sellerSurname || ""}`,
          dayjs(row.updatedAt).format("DD/MM/YYYY"),
          row.reject === null || !row.reject ? "สำเร็จ" : "ไม่อนุมัติ"
        );
        return {
          รหัสลูกค้า: mappedData.customerId,
          เลขที่สัญญา: mappedData.contractNumber,
          ชื่อลูกค้า: mappedData.customerName,
          เกรดลูกค้า: mappedData.garde,
          พนักงานขาย: mappedData.saleName,
          วันที่อัพเดตล่าสุด: mappedData.date,
          สถานะเครดิต: mappedData.statues,
        };
      });
      return data;
    }
    return [];
  };

  return (
    <div className="flex flex-col w-full pt-6">
      {(tabIndex === 3 || tabIndex === 4) && (
        <div className="w-full mr-4 pb-6 mb-[-8px] flex flex-row justify-between">
          <div className="w-full flex flex-row gap-[12px]">
            <div className="max-w-[261px]">
              <Dropdown
                label="เกรดลูกค้า"
                placeholder="เลือก"
                options={grade}
                onChange={onChangeForm("grade")}
                renderValueArray={form.grade}
                containerClassName="w-[261px]"
              />
            </div>
            {tabIndex === 4 && (
              <div className="max-w-[261px]">
                <Dropdown
                  label="สถานะเครดิต"
                  placeholder="เลือก"
                  options={status}
                  onChange={onChangeForm("status")}
                  renderValueArray={form.status}
                  containerClassName="w-[261px]"
                />
              </div>
            )}
          </div>
          <div className="pt-[28px]">
            <ButtonFillCustom title={"ค้นหา"} onClick={() => {}} />
          </div>
        </div>
      )}
      <div className="flex flex-row w-full justify-between pb-6">
        <div className="flex w-full max-w-[656px]">
          <div className="w-full max-w-[470px] mr-4">
            <TextInputSearchCustom
              value={searchText}
              placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
              onChange={onSearchText}
              InputProps={{
                endAdornment:
                  searchText.length > 0 ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px !important",
                      }}
                      onClick={onClearSearchText}
                    />
                  ) : null,
              }}
            />
          </div>
        </div>
        <ButtonOutlinedCustom
          title="นำออก"
          btnTextColor="#68C184"
          btnBorderColor="#68C184"
          disabled={itemSelected.length === 0}
          onClick={tabIndex === 4 ? handleExportExcel2 : handleExportExcel}
          startIcon={
            <SvgIcon
              sx={{
                fontSize: "14px !important",
                color: "currentColor",
                marginBottom: "3px",
              }}
              fontSize="small"
            >
              <DownloadSvg />
            </SvgIcon>
          }
        />
      </div>
      <div className="flex w-full">
        <TableCustom
          order={"asc"}
          orderBy={""}
          rowsPerPage={10}
          itemsSelected={itemSelected}
          page={currentPage}
          headerItems={tabIndex === 4 ? headCells2 : headCells}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          rows={rowPartner}
          currentPageItems={rowPartner.map((row) => row.fullData.id)}
          onChangePage={onChangePage}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          EmptyComponent={
            rowPartner.length === 0 ? (
              <div className="font-sans text-center w-full text-[#979797] text-[14px] py-3">
                ไม่มีข้อมูล
              </div>
            ) : null
          }
          onChangeOrder={function (value: {}): void {}}
          onChangeOrderBy={function (value: string): void {}}
          rowChildren={
            <>
              {rowPartner.map((row, index) => {
                const isItemSelected = isSelected(row.fullData.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <ExpandableTableRow
                    key={`${row.fullData.id + index}`}
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    expandComponent={
                      <FillSaleContract
                        showButton={showButton}
                        refetchTab3={refetchTab3}
                        data={row}
                        tabIndex={tabIndex}
                        refetchCount={refetchCount}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.fullData.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.partnerId}
                      </Link>
                    </TableCell>
                    {tabIndex === 4 && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <div className="flex flex-row gap-[6px]">
                          <div>{row.contractNumber || "-"}</div>
                        </div>
                      </TableCell>
                    )}
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <div className="flex flex-row gap-[6px]">
                        <div>{row.username}</div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.grade}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.sellerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.date}
                    </TableCell>
                    {tabIndex === 4 && (
                      <TableCell align="left">
                        <div className="flex flex-row w-full">
                          <ChipOrderStatus
                            bgColor={
                              (OrderStatusMap as any)[row.status].bgColor
                            }
                            fontColor={
                              (OrderStatusMap as any)[row.status].color
                            }
                            label={(OrderStatusMap as any)[row.status].label}
                          />
                        </div>
                      </TableCell>
                    )}
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
    </div>
  );
};

export default SalesContract;

import axios from "axios";
import adminAPI from "./AdminApiService";
import { IGetRoleRes, ILoginReq, ILoginRes } from "./AdminAuth.type";

// Define the base URL for your API
const BASE_URL = `${
  process.env.REACT_APP_BASE_API_URL || window.location.origin
}/api/admin/`;

export const login = async (body: ILoginReq) => {
  try {
    const { data } = await axios.post<ILoginRes>("/login", body, {
      baseURL: BASE_URL,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRole = async () => {
  try {
    const { data } = await adminAPI.get<IGetRoleRes>("/role");
    return data;
  } catch (error) {
    return null;
  }
};

import { Box, Button, Container } from "@mui/material";
import dayjs from "dayjs";
import { ListOrder } from "../../../../types/MyOrderFeed.type";
import { thousandComma } from "../../../../utils/common/string";
import OrderFeedFarmerItemList from "./OrderFeedFarmerItemList";
import StatusFarmerOrderFeed from "./StatusFarmerOrderFeed";
import { StatusEnum } from "./type";

type CardFarmerOrderProps = {
  // props primary button
  primaryButtonText: string;
  isPrimaryDisabled?: boolean;
  onPrimaryClick: () => void;
  //props secondary button
  isSecondaryDisabled?: boolean;
  onSecondaryClick?: () => void;
  //props display condition
  isDisplaySecondaryButton?: boolean;
  textDescription?: string;
  isTotalItemPrice?: boolean;
  onMenuClick?: () => void;
  //props order data
  order: ListOrder;
};
const CardFarmerOrder = ({
  primaryButtonText,
  isPrimaryDisabled,
  onPrimaryClick,
  isSecondaryDisabled,
  onSecondaryClick,
  isDisplaySecondaryButton,
  textDescription,
  isTotalItemPrice,
  onMenuClick,
  order,
}: CardFarmerOrderProps) => {
  return (
    <Container sx={{ marginBottom: "16px" }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#D9EBFE",
          height: "62px",
          borderRadius: "20px 20px 0 0",
          display: "flex",
        }}
      >
        <div className="flex  justify-between items-center w-full px-5">
          <div>
            <p className="font-medium text-sm">
              หมายเลขคำสั่งซื้อ{" "}
              <span className="font-bold">{order?.orderNumber}</span>
            </p>
            {order?.receiveDate ? (
              <p className="font-medium text-sm">
                วันที่ได้รับสินค้า{" "}
                <span className="font-bold">
                  {dayjs(order?.receiveDate).format("DD/MM/BBBB")}
                </span>
              </p>
            ) : null}
          </div>
          <StatusFarmerOrderFeed status={order?.status as StatusEnum} />
        </div>
      </Box>
      <Box
        sx={{
          bgcolor: "#F6FBFF",
          height: "auto",
          borderRadius: "0 0 20px 20px",
          paddingBottom: "16px",
        }}
      >
        <OrderFeedFarmerItemList
          isTotalItemPrice={isTotalItemPrice}
          listOrder={order?.orderFeedItems[0]}
        />
        <div className="border-y border-tertiary-200 text-center my-4">
          <Button
            className="w-full"
            color="tertiary"
            size="small"
            sx={{
              color: "#97BFEA",
            }}
            onClick={onMenuClick}
          >
            ดูรายการทั้งหมด
          </Button>
        </div>
        <div className="px-4">
          <div className="flex justify-between text-sm mb-4">
            <p className="text-primary-grey-200">
              {order?.orderFeedItems.length} รายการ
            </p>
            <p>{thousandComma(order.totalFeedPrice)} บาท</p>
          </div>
          <div className="text-sx text-end text-primary-grey-200">
            {textDescription ? (
              <p className="mb-2 text-[12px]">{textDescription}</p>
            ) : null}
            {isDisplaySecondaryButton ? (
              <Button
                color="secondary"
                variant="outlined"
                sx={{
                  border: "1px solid #68C184",
                  backgroundColor: "#ffff",
                  padding: "5px 14px 5px 14px",
                  marginRight: "8px",
                }}
                onClick={onSecondaryClick}
                disabled={isSecondaryDisabled}
              >
                ยกเลิกคำสั่งซื้อ
              </Button>
            ) : null}
            <Button
              color="secondary"
              variant="contained"
              sx={{
                padding: "5px 14px 5px 14px",
              }}
              onClick={onPrimaryClick}
              disabled={isPrimaryDisabled}
            >
              {primaryButtonText}
            </Button>
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default CardFarmerOrder;

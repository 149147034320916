import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import { tw } from "../../../../../../utils/tw";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import { Dropdown } from "../../../../group-area/views/Dropdown";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import { TabPanelCustom } from "../../../../../components/TabPanelCustom/TabPanelCustom";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import { Input } from "../../../../group-area/views/Input";
import { AccordionInformation } from "./AccordionInformation";
import { get } from "lodash";
import * as Y from "yup";
import useFormValidator from "../../../../../../hooks/useFormValidator.hook";
import { AppDispatch } from "../../../../../../redux/store";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import { TForm, defaultForm } from "../defaultForm";
import {
  createFeed,
  getFarmAndHouse,
  getId,
  getUploadUrlCreateFeed,
} from "../../../../../../services/CreditFeed.service";
import { IGetFarmAndHouse } from "../../../../../../types/CreditFeed.type";
import axios from "axios";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { getCommaNumber } from "../../../../../../utils/number";
interface ICreateGroupAreaModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  refetch: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const errorSchema = Y.object({
  farmSize: Y.array().min(1, "กรุณาเลือกขนาดฟาร์ม"),
  farmName: Y.array().min(1, "กรุณาเลือกฟาร์ม"),
  greenHouse: Y.array().min(1, "กรุณาเลือกโรงเรือน"),
  financialAmount: Y.string().required("กรุณากรอกวงเงินลูกค้า"),
  grade: Y.array().min(1, "กรุณาเลือกเกรดลูกค้า"),
  contractType: Y.array().min(1, "กรุณาเลือกประเภทสัญญา"),
  timeCredit: Y.string().required("กรุณากรอกระยะเครดิต"),
});

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] !max-w-[1311px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  text: tw(`font-["IBM Plex Sans Thai"] font-bold text-[16px] leading-5`),
};

const ApplyCredit = (props: ICreateGroupAreaModalProps) => {
  const { id, isOpen, onClose, refetch } = props;
  const maxIndex = 1;
  const minIndex = 0;

  const dispatch = useDispatch<AppDispatch>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [form, setForm] = useState<TForm>(defaultForm);
  const [ids, setIds] = useState("");

  const onChangeForm = useCallback(
    (key: keyof TForm) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const onChangeTabIndex = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: number) => {
      let success = onSubmit(value);
      success && setTabIndex(value);
    },
    [tabIndex, form]
  );

  const onClickNext = useCallback(() => {
    let success = onSubmit();
    getId().then((result) => {
      setIds(result.generatedUUID);
    });
    if (success && tabIndex < maxIndex) {
      setTabIndex(tabIndex + 1);
    }
  }, [form, tabIndex]);

  const onClickPrev = useCallback(() => {
    if (tabIndex > minIndex) {
      setTabIndex(tabIndex - 1);
    }
  }, [form, tabIndex]);

  const [customerPickingSlip, setCustomerPickingSlip] = useState<File[]>([]);

  const handleDownload = useCallback(
    async (file: File) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [customerPickingSlip]
  );

  const accordionData = useMemo(() => {
    return [
      {
        label: "ขนาดฟาร์ม",
        value: get(form, ["farmSize", 0, "label"], ""),
      },
      {
        label: "ฟาร์ม",
        value: get(form, ["farmName", 0, "label"], ""),
      },
      {
        label: "โรงเรือน",
        value: get(form, ["greenHouse", 0, "label"], ""),
      },
      {
        label: "ประเภทหมู",
        value: get(form, ["pigType"], ""),
      },
      {
        label: "จำนวนหมู",
        value: getCommaNumber(+get(form, ["countPig"], "")),
      },
      {
        label: "วงเงินลูกค้า",
        value: getCommaNumber(+get(form, ["financialAmount"], "")),
      },
      {
        label: "เกรดลูกค้า",
        value: get(form, ["grade", 0, "label"], ""),
      },
      {
        label: "ประเภทสัญญา",
        value: get(form, ["contractType", 0, "label"], ""),
      },
      {
        label: "ระยะเครดิต",
        value: getCommaNumber(+get(form, ["timeCredit"], "")),
      },
    ];
  }, [form]);

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
      tabIndex,
    },
    errorSchema,
    onValidateSuccess: (_tabIndex) => {
      const index =
        !!`${_tabIndex}` && typeof _tabIndex === "number"
          ? _tabIndex
          : tabIndex;
      const isSwitchTab =
        _tabIndex !== tabIndex && typeof _tabIndex === "number";
      if (index === 1 && !isSwitchTab) {
        dispatch(
          alertAction.showDialog({
            title: "ยืนยันการให้เครดิต",
            text: "คุณต้องการให้เครดิตรายการนี้ใช่หรือไม่?",
            cancelText: "กลับไปแก้ไข",
            confirmText: "ยืนยัน",
            onCancel: () => {
              dispatch(alertAction.hide());
            },
            onSubmit: async () => {
              const fileArray = [];
              try {
                dispatch(
                  loadingActions.show({ message: "กำลังยืนยันการให้เครดิต..." })
                );
                if (customerPickingSlip.length > 0) {
                  // upload image
                  const presign = await getUploadUrlCreateFeed(ids);
                  await axios.put(
                    presign.blob.blobUrl,
                    customerPickingSlip[0],
                    {
                      responseType: "blob",
                      headers: {
                        "Content-Type": customerPickingSlip[0].type,
                        "x-ms-blob-type": "BlockBlob",
                      },
                    }
                  );
                  fileArray.push({
                    id: presign.blob.fileId,
                    fileName: customerPickingSlip[0].name,
                    fileSize: customerPickingSlip[0].size,
                  });
                }
                await createFeed({
                  id: ids,
                  creditInformation: {
                    farmSize: get(form, ["farmSize", 0, "value"]),
                    houseId: get(form, ["greenHouse", 0, "value"]),
                    creditAmount: +form.financialAmount,
                    customerGrade: get(form, ["grade", 0, "value"]),
                    contractType: get(form, ["contractType", 0, "value"]),
                    creditPeriod: +form.timeCredit,
                    files:
                      customerPickingSlip.length === 0 ? undefined : fileArray,
                  },
                });
                setTimeout(() => {
                  dispatch(alertAction.hide());
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: "ให้เครดิตสำเร็จ",
                      type: "success",
                    })
                  );
                  onCloseFn();
                }, 500);
              } catch (error) {
                setTimeout(() => {
                  dispatch(alertAction.hide());
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: getErrorMessage(error),
                      type: "error",
                    })
                  );
                }, 500);
              }
            },
          })
        );
      }
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    setTimeout(() => {
      resetError();
      setForm(defaultForm);
      setCustomerPickingSlip([]);
      setTabIndex(0);
      refetch();
    }, 500);
  }, []);

  const [farmAndHouseData, setFarmAndHouseData] = useState<IGetFarmAndHouse>();

  const farmOptions = useMemo(() => {
    const _farmOptions = get(farmAndHouseData, "farms", []).map(
      ({ id, name, houses }) => ({
        label: name,
        value: id,
        houses: houses.map(({ id, name, fattenerPigCount }) => ({
          label: name,
          value: id,
          fattenerPigCount,
        })),
      })
    );

    return _farmOptions;
  }, [farmAndHouseData]);

  useEffect(() => {
    if (isOpen && id) {
      getFarmAndHouse(id)
        .then((result) => {
          setFarmAndHouseData(result);
        })
        .catch((e) => {
          console.log("fail to getFarmAndHouse error:", e);
        });
    }
  }, [isOpen, id]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>การให้เครดิต</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <Tabs
          value={tabIndex}
          onChange={onChangeTabIndex}
          aria-label="apply-credit-tab-control"
          TabIndicatorProps={{
            sx: {
              "&.MuiTabs-indicator": {
                transform: "unset",
                width: "unset",
                bgcolor: "#68C184",
                height: "3px",
              },
            },
          }}
          sx={{
            height: "36px",
            minHeight: "unset",
            "& .MuiTabs-scroller": {
              "& .MuiTabs-flexContainer": {
                alignItems: "end",
                height: "100%",
                display: "flex",
                gap: "12px",
                "& .MuiTab-root": {
                  padding: "6px 28px",
                  textTransform: "unset",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 400,
                  fontSize: "1rem",
                  lineHeight: "20px",
                  height: "32px",
                  minHeight: "unset",
                  borderBottom: "3px solid #DDF3E4",
                  color: "#000000 !important",
                  backgroundColor: "white !important",
                  "& .Mui-selected": {
                    color: "#000000",
                  },
                },
              },
            },
          }}
        >
          <Tab label={`1.ข้อมูลการให้เครดิต`} />
          <Tab label={`2.สรุป`} />
        </Tabs>
        <TabPanelCustom value={tabIndex} index={0} prefix="apply-credit">
          <div className={classes.row}>
            <Dropdown
              label="ขนาดฟาร์ม"
              options={[
                { label: "S", value: "s" },
                { label: "M", value: "m" },
                { label: "L", value: "l" },
                { label: "XL", value: "xl" },
              ]}
              onChange={onChangeForm("farmSize")}
              renderValueArray={form.farmSize}
              errorMessage={errors.farmSize}
            />
            <Dropdown
              label="ฟาร์ม"
              options={farmOptions}
              onChange={onChangeForm("farmName")}
              renderValueArray={form.farmName}
              errorMessage={errors.farmName}
            />
            <Dropdown
              disabled={!get(form.farmName, 0)}
              label="โรงเรือน"
              options={get(form.farmName, ["0", "houses"], [])}
              onChange={(value) => {
                setForm((prev) => ({
                  ...prev,
                  greenHouse: value,
                  countPig: `${get(value, [0, "fattenerPigCount"], "-")}`,
                }));
              }}
              renderValueArray={form.greenHouse}
              errorMessage={errors.greenHouse}
            />
          </div>
          <div className={classes.row2}>
            <Input
              label="ประเภทหมู"
              value={form.pigType}
              onChangeText={onChangeForm("pigType")}
              disabled
            />
            <Input
              label="จำนวนหมู"
              value={(form.countPig)}
              onChangeText={(onChangeForm("countPig"))}
              placeholder=""
              disabled
            />
            <Input
              label="วงเงินลูกค้า"
              value={form.financialAmount}
              onChangeText={onChangeForm("financialAmount")}
              inputType="number"
              suffix="บาท"
              errorMessage={errors.financialAmount}
            />
          </div>
          <div className={classes.row2}>
            <Dropdown
              label="เกรดลูกค้า"
              options={[
                { label: "A", value: "a" },
                { label: "B", value: "b" },
                { label: "C", value: "c" },
                { label: "D", value: "d" },
                { label: "E", value: "e" },
                { label: "F", value: "f" },
              ]}
              onChange={onChangeForm("grade")}
              renderValueArray={form.grade}
              errorMessage={errors.grade}
            />
            <Dropdown
              label="ประเภทสัญญา"
              options={[
                { label: "จ่ายหลังหมดสัญญา", value: "pay_after_expire" },
                { label: "จ่ายตาม invoice", value: "fill_credit" },
              ]}
              onChange={onChangeForm("contractType")}
              renderValueArray={form.contractType}
              errorMessage={errors.contractType}
            />
            <Input
              label="ระยะเครดิต"
              value={form.timeCredit}
              onChangeText={onChangeForm("timeCredit")}
              inputType="number"
              suffix="วัน"
              errorMessage={errors.timeCredit}
            />
          </div>
          <div className="h-[8px]"></div>
          <p className="font-normal text-[14px] mb-2">เอกสารการคำนวณเครดิต</p>
          <ListOfFile
            files={customerPickingSlip}
            onDownload={handleDownload}
            onDelete={async (idx) => {
              setCustomerPickingSlip([]);
            }}
          />
          <DragAndDropUploadImage
            onUpload={(files) => {
              setCustomerPickingSlip(files);
            }}
            type={{ "application/pdf": [".pdf"] }}
          />
        </TabPanelCustom>
        <TabPanelCustom value={tabIndex} index={1} prefix="apply-credit">
          <div className={classes.space2}></div>
          <div className={classes.text}>Credit Model Version 1.0.1</div>
          <div className={classes.space2}></div>
          <AccordionInformation
            idData={ids}
            openFirstTime
            accordionTitle="Credit Information"
            data={accordionData}
            isPDF={customerPickingSlip.length > 0}
            pdfFile={customerPickingSlip}
          />
        </TabPanelCustom>
        <div className={tw(classes.row, "justify-end")}>
          {tabIndex !== 0 && (
            <ButtonOutlinedCustom
              title={"ย้อนกลับ"}
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              onClick={onClickPrev}
            />
          )}
          {tabIndex === maxIndex ? (
            <ButtonFillCustom title={"ยืนยัน"} onClick={onSubmit} />
          ) : (
            <ButtonFillCustom title={"ถัดไป"} onClick={onClickNext} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ApplyCredit);

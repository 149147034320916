import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AppBarFS from "../../../components/app-bar/AppBar";
import { IconButton, Tab } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FenceSVG from "../../../assets/svg/Fence";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, FreeMode, Pagination } from "swiper/modules";
import "./styles.css";
import Tabs from "../../../admin/pages/group-area/views/Tabs";
import InfoContract from "./pages/InfoContract";
import { IContracts } from "../../../types/Financial.type";
import { useFinancialController } from "../controller";
import dayjs from "dayjs";
import FilterModal from "./views/FilterModal";
import i18next from "i18next";

const FinancialContract = () => {
  let navigate = useNavigate();
  const { data } = useFinancialController();
  const [startDate, setStartDate] = useState("");

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleBack = useCallback(() => {
    navigate("/financial-support");
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onSlideChange = ({ realIndex }: { realIndex: number }) => {
    setActiveIndex(realIndex);
  };

  const dataActive = useMemo(() => {
    const result = data.filter(
      ({ isActive, startDate: _startDate, expireDate }) => {
        const a = isActive;
        return (
          a === true ||
          (!isActive &&
            dayjs().valueOf() > dayjs(_startDate).valueOf() &&
            dayjs().valueOf() < dayjs(expireDate).valueOf())
        );
      }
    );
    if (!startDate) return result;
    const startDayTimeStamp = dayjs(startDate).startOf("days").valueOf();
    return result.filter(({ startDate: _startDate }) => {
      return dayjs(_startDate).startOf("days").valueOf() >= startDayTimeStamp;
    });
  }, [data, startDate]);

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  return (
    <div className="bg-[#d9ebfe] w-full min-h-full">
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
          <AppBarFS title={"สัญญาที่ใช้งาน"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-row py-[16px] px-4 justify-between">
          <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
            สัญญาทั้งหมด
          </span>
          <div className="h-full flex gap-2 items-center">
            <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
              กรองโดย
            </span>
            <IconButton
              aria-label="filter"
              sx={{
                color: "#68C184",
                border: "1px solid #68C184",
                ":hover": {
                  color: "#FFFFFF",
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                },
                ":active": {
                  color: "#FFFFFF",
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                },
              }}
              onClick={onOpenFilterModal}
            >
              <FilterListIcon />
            </IconButton>
          </div>
        </div>
        {dataActive.length > 0 ? (
          <>
            <Swiper
              pagination={{
                clickable: true,
              }}
              freeMode={true}
              slidesPerView={1}
              modules={[FreeMode, Pagination, Controller]}
              loop={true}
              tabIndex={activeIndex}
              onSlideChange={onSlideChange}
            >
              {dataActive.map((data, index) => {
                let statusContractType: any = {
                  pay_after_expire: "จ่ายหลังหมดสัญญา",
                  fill_credit: "จ่ายตาม invoice",
                };
                return (
                  <SwiperSlide key={`dataActive-${index}`}>
                    <div className="px-[16px]">
                      <div className="bg-[#FFFF] rounded-[15px] px-4 pt-4 pb-6 flex flex-col gap-3">
                        <div className="text-[#34407B] text-[16px] font-semibold flex flex-row gap-[11px]">
                          <FenceSVG />
                          {data.contractNumber}
                        </div>
                        <div className="flex flex-col gap-[6px] text-[#4B4B4B] text-[14px] font-medium leading-5">
                          <div className="flex flex-row justify-between">
                            <div className="">เลขที่สัญญา</div>
                            <div className="text-end">
                              {data.contractNumber}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="">ประเภทสัญญา</div>
                            <div className="text-end">
                              {statusContractType[
                                data.creditInformation.contractType
                              ] || "-"}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="">วันเริ่มสัญญา</div>
                            <div className="text-end">
                              {dayjs(data.startDate)
                                .locale(i18next.language)
                                .format(
                                  i18next.language === "th"
                                    ? "DD/MM/BBBB"
                                    : "DD/MM/YYYY"
                                )}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="">วันสิ้นสุดสัญญา</div>
                            <div className="text-end">
                              {dayjs(data.expireDate)
                                .locale(i18next.language)
                                .format(
                                  i18next.language === "th"
                                    ? "DD/MM/BBBB"
                                    : "DD/MM/YYYY"
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="px-4">
              <div className="w-full h-auto bg-[#FFFFFF] rounded-[20px] flex flex-col justify-center">
                <Tabs tabIndex={tabIndex} onChange={onChangeTab}>
                  <Tab label="ข้อมูลสัญญา" />
                  {/* <Tab label="Invoice" /> */}
                  {/* <Tab label="ออเดอร์สินค้า" /> */}
                </Tabs>
                {tabIndex === 0 && (
                  <InfoContract data={dataActive[activeIndex]} />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex flex-row justify-center">
              ไม่มีข้อมูลสัญญาที่ใช้งาน
            </div>
          </>
        )}
      </div>
      <FilterModal
        title="กรองโดย"
        labelDateRangeFilter="วันเริ่มสัญญา"
        isOpen={isOpenFilterModal}
        onClose={onHideFilterModal}
        onSubmit={() => {}}
        startDate={startDate}
        setStartDate={setStartDate}
      />
    </div>
  );
};

export default FinancialContract;

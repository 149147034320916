import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import CommonConfirmModal from '../../admin/components/Modal/CommonConfirmModal'

const GlobalAlert = () => {
  const alertState = useSelector((state: RootState) => state.alert)

  return (
    <CommonConfirmModal
      open={alertState.isVisible}
      header={alertState.title}
      body={alertState.text}
      cancelBtnText={alertState.cancelText}
      confirmBtnText={alertState.confirmText}
      onClose={alertState.onCancel}
      onSubmit={alertState.onSubmit}
    />
  )
}

export default memo(GlobalAlert)

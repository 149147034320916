import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEnglish from "./Translation/English/translation.json";
import translationThai from "./Translation/Thai/translation.json";
import { locizePlugin } from "locize";

const resources = {
  en: {
    translation: translationEnglish,
  },
  th: {
    translation: translationThai,
  },
};

i18n
  .use(locizePlugin)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Comment/uncomment the resources line to switch between local and locize
    resources,
    // backend: {
    //     loadPath: 'https://api.locize.app/333d0ea8-0f3d-4d95-af25-ec82708fdcc1/latest/{{lng}}/farmtech',
    //     projectId: '333d0ea8-0f3d-4d95-af25-ec82708fdcc1',
    //     apiKey: '8d1dd1e0-acaa-43b2-bfaa-d9419f57f3b9',
    //     referenceLng: 'th',
    // },
    lng: "th",
    fallbackLng: "en",
    debug: true,
    saveMissing: true,
  });

export default i18n;

import { InputAdornment, MenuItem, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { number, object, string } from "yup";
import CustomButton from "../../../components/common/Button";
import TextInputFS from "../../../components/common/Input/TextInput";
import { FeedBrands } from "../../../types/FeedBrand.type";
import {
  formError,
  formErrorMessage,
  twoDecimalPlacesValidator,
} from "../../../utils/form-validator";
type LogFeedDetailsType = {
  onSubmit?: (form: any) => void;
  feedBrands: FeedBrands[];
  finalDailyLogData: any;
};

const LogFeedDetails = ({
  feedBrands,
  onSubmit: onFormSubmit,
  finalDailyLogData,
}: LogFeedDetailsType) => {
  const { t, i18n } = useTranslation();
  const [serverError, setServerError] = useState("");
  const [allSkus, setAllSkus] = useState<any>();

  const formik = useFormik({
    initialValues: {
      feedBrand: "",
      SKUOfFeed: "",
      feedIntake: "",
    },
    validationSchema: object({
      feedBrand: string().required(
        `${t("dailyLog.feedBrandRequiredErrorMessage")}`
      ),
      SKUOfFeed: string().required(
        `${t("dailyLog.skuOfFeedRequiredErrorMessage")}`
      ),
      feedIntake: number()
        .required(`${t("dailyLog.feedIntakeRequiredErrorMessage")}`)
        .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .typeError(`${t("dailyLog.notAnIntegerErrorMessage")}`)
        .test(
          "two-decimal",
          `${t("dailyLog.onlyTwoDecimialErrorMessage")}`,
          twoDecimalPlacesValidator
        ),
    }),
    onSubmit: async (values) => {
      try {
        onFormSubmit?.({ ...values });
      } catch (err) {
        console.error(err);
      }
    },
  });
  // useEffect(() => {
  //     if (!formik?.values?.SKUOfFeed && finalDailyLogData?.feedData?.SKUOfFeed){
  //         formik.setFieldValue('SKUOfFeed', finalDailyLogData?.feedData?.SKUOfFeed);
  //         setSKUs(finalDailyLogData?.feedData?.SKUOfFeed);
  //     }
  // },[finalDailyLogData,feedBrands]);

  // useEffect(() => {
  //     if (formik.values?.feedBrand) setSKUs(formik.values?.feedBrand);
  //     if (finalDailyLogData?.feedData?.SKUOfFeed) formik.setFieldValue('feedBrand', finalDailyLogData?.feedData?.feedBrand);
  //     if (!formik.values?.feedIntake) formik.setFieldValue('feedIntake', finalDailyLogData?.feedData?.feedIntake);
  // }, [formik.values?.feedBrand, finalDailyLogData?.feedData?.SKUOfFeed,]);

  useEffect(() => {
    setSKUs(formik.values?.feedBrand);
  }, [feedBrands, formik?.values?.feedBrand]);

  // useEffect(() => {
  //     if (finalDailyLogData?.feedData?.feedBrand) formik.setFieldValue('feedBrand', finalDailyLogData?.feedData?.feedBrand);
  //     if (finalDailyLogData?.feedData?.SKUOfFeed) formik.setFieldValue('SKUOfFeed', finalDailyLogData?.feedData?.SKUOfFeed);
  //     if (!formik.values?.feedIntake) formik.setFieldValue('feedIntake', finalDailyLogData?.feedData?.feedIntake);
  // }, [finalDailyLogData])

  const setSKUs = (value: string) => {
    if (value) {
      let brandNm = feedBrands.filter((ele: { id: string }) => ele.id == value);
      setAllSkus(brandNm[0]?.feed_brand_items);
    }
  };

  function handleFormChanges() {
    if (serverError) {
      setServerError("");
    }
  }

  // const onSelectedBrand = (event: any) => {
  //     const { value } = event.target;
  //     formik.setFieldValue('feedBrand', value);

  // }
  useEffect(() => {
    if (finalDailyLogData) {
      // Manually set touched for pre-populated fields
      if (finalDailyLogData?.feedData?.feedBrand) {
        formik.setFieldTouched("feedBrand", true);
      }
      if (finalDailyLogData?.feedData?.SKUOfFeed) {
        formik.setFieldTouched("SKUOfFeed", true);
      }
      if (finalDailyLogData?.feedData?.feedIntake) {
        formik.setFieldTouched("feedIntake", true);
      }
      formik.setValues({ ...finalDailyLogData?.feedData });
    }
  }, [finalDailyLogData]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      onChange={handleFormChanges}
      className="flex flex-col grow gap-4 justify-between"
    >
      <div className="flex flex-col gap-4">
        <TextInputFS
          select
          fullWidth
          helperText={formErrorMessage(formik, "feedBrand")}
          error={formError(formik, "feedBrand")}
          label={t("dailyLog.feedBrandlabel")}
          {...formik.getFieldProps("feedBrand")}
        >
          {feedBrands?.length > 0 ? (
            feedBrands?.map((option: FeedBrands) => (
              <MenuItem key={option.id} value={option.id}>
                {i18n.language === "en" ? option.nameEn : option.nameTh}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={""} className="text-right">
              No Feed Brands available
            </MenuItem>
          )}
        </TextInputFS>

        <TextInputFS
          select
          fullWidth
          helperText={formErrorMessage(formik, "SKUOfFeed")}
          error={formError(formik, "SKUOfFeed")}
          label={t("dailyLog.SKUlabel")}
          {...formik.getFieldProps("SKUOfFeed")}
        >
          {allSkus?.length > 0 ? (
            allSkus.map((option: any, index: number) => (
              <MenuItem key={index} value={option.id} className="text-right">
                {i18n.language == "en" ? option?.skuEn : option?.skuTh}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={""} className="text-right">
              No SKUs available
            </MenuItem>
          )}
        </TextInputFS>

        <TextInputFS
          fullWidth
          helperText={formErrorMessage(formik, "feedIntake")}
          error={formError(formik, "feedIntake")}
          label={t("dailyLog.feedIntakelabel")}
          {...formik.getFieldProps("feedIntake")}
          type="Number"
          InputProps={{
            inputProps: {
              inputMode: "decimal",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="h6" className="text-tertiary font-medium">
                  | {t("dailyLog.feedIntakeSuffix")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className="flex flex-col gap-4 my-4">
        {serverError && (
          <Typography
            variant="body2"
            className="text-[#D32F2F] px-4"
            align="center"
          >
            {serverError}
          </Typography>
        )}
        <CustomButton
          disabled={
            !(
              formik?.isValid &&
              formik.isValid &&
              Object.keys(formik.touched).length > 0
            ) || formik?.isSubmitting
          }
          variant="tertiary"
          label={t("dailyLog.nextButtonLabel")}
        />
      </div>
    </form>
  );
};

export default LogFeedDetails;

/* eslint-disable no-shadow */
import React from "react";
import { PieChart, Pie } from "recharts";
import { GaugeStatusMap, IPigStatusGaugeChartProps } from "./types";
import { SellingReportLevelStatusEnum } from "../../../pages/farm-past-performance/types";
import ReportPoorPigSVG from "../../../assets/svg/ReportPoorPig";
import ReportMediumPigSVG from "../../../assets/svg/ReportMediumPig";
import ReportGoodPigSVG from "../../../assets/svg/ReportGoodPig";

const RADIAN = Math.PI / 180;
const data = [{ name: "TEMP", value: 100, color: "#ff0000" }];
const cx = 100;
const cy = 90;
const iR = 32;
const oR = 80;

const needle = (
  value: number,
  data: any[],
  cx: number,
  cy: number,
  iR: number,
  oR: number,
  color: string
) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 5;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy - 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      d={`
        M${xba} ${yba}
        ${xbb} ${ybb}
        L${xp} ${yp}
        Z
      `}
      stroke="none"
      fill={color}
    />,
  ];
};

export default function PigStatusGaugeChart({
  value,
  minRate,
  maxRate,
  status,
  unit,
  t,
}: IPigStatusGaugeChartProps) {
  // const [valueCalc, setValueCalc] = useState<number>(0);
  // const [status, setStatus] = useState<SellingReportLevelStatusEnum>(
  //   SellingReportLevelStatusEnum.None
  // );
  // useEffect(() => {
  //   const findPercent = (value / maxRate) * 100;
  //   const findValue = (findPercent / 100) * 100;
  //   if (findPercent < 50) {
  //     setStatus(SellingReportLevelStatusEnum.Poor);
  //   } else if (findPercent === 50) {
  //     setStatus(SellingReportLevelStatusEnum.Medium);
  //   } else {
  //     setStatus(SellingReportLevelStatusEnum.Good);
  //   }
  //   setValueCalc(findValue);
  // }, [value, maxRate, status]);
  return (
    <div className="flex flex-row relative">
      <div className="flex flex-col">
        <PieChart width={210} height={100}>
          <defs>
            <linearGradient id="colorUv" x1="23%" y1="2%" x2="72%" y2="2%">
              <stop offset="0%" fill="red" stopColor="red" stopOpacity={1} />
              <stop offset="55%" stopColor="#F6C94B" stopOpacity={1} />
              <stop offset="100%" stopColor="#68C184" stopOpacity={1} />
            </linearGradient>
            <filter id="neonGlow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.7" />
              </feComponentTransfer>
              <feBlend mode="screen" in2="SourceGraphic" />
            </filter>
          </defs>

          <Pie
            activeIndex={0}
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            stroke="none"
            fill="url(#colorUv)"
            filter="url(#neonGlow)"
          ></Pie>
          {needle(
            GaugeStatusMap.get(status)?.value ?? 0,
            data,
            cx,
            cy,
            iR,
            oR,
            "#34407B"
          )}
        </PieChart>
        <div className="flex flex-col justify-center w-full items-center">
          <span
            className={`text-3xl font-medium`}
            style={{
              color: GaugeStatusMap.get(status)?.color,
            }}
          >
            {value}
            {unit}
          </span>
          <span className="text-lg">
            {t("sellingReport.summaryReportTable.gaugeChart.yourScore")}{" "}
            <span
              className={`font-medium capitalize`}
              style={{
                color: GaugeStatusMap.get(status)?.color,
              }}
            >
              {t(
                `sellingReport.summaryReportTable.status.${
                  GaugeStatusMap.get(status)?.key
                }`
              )}
            </span>
          </span>
          <span className="text-sm text-primary-grey-100">
            {t("sellingReport.summaryReportTable.gaugeChart.standard")}{" "}
            {maxRate}
            {unit}
          </span>
        </div>
      </div>

      <div className="flex grow h-full w-full items-center self-center justify-center">
        {status === SellingReportLevelStatusEnum.Poor ? (
          <ReportPoorPigSVG />
        ) : status === SellingReportLevelStatusEnum.Medium ? (
          <ReportMediumPigSVG />
        ) : status === SellingReportLevelStatusEnum.Good ? (
          <ReportGoodPigSVG />
        ) : null}
      </div>
    </div>
  );
}

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider, SvgIcon, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useShowButtonByRoles } from "../../../../hooks/useActionButtonByRoles";
import {
  getFillStockListById,
  getListPartnerById,
  getListStockRefill,
} from "../../../../services/ManageStock.service";
import {
  IGetPartnerStock,
  IStockRefillDetail,
} from "../../../../types/ManageStock.type";
import { UserRole } from "../../../../utils/enum/user-role.enum";
import { makeXlsx } from "../../../../utils/make-xlsx";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import {
  TabPanelCustom,
  a11yProps,
} from "../../../components/TabPanelCustom/TabPanelCustom";
import FillProductModal from "./components/FillProductModal";
import FillProductPanel from "./components/FillProductPanel";
import PartnerInfoCard from "./components/PartnerInfoCard";
import ProductPanel from "./components/ProductPanel";
import { manageStockByIdLoader } from "./loader";
import { FillProductData, TFillProductStatus } from "./type";

export const ChipStyle: {
  [k in TFillProductStatus]: {
    label: string;
    fontColor: string;
    bgColor: string;
  };
} = {
  create: {
    label: "สร้างรายการเติมสินค้า",
    fontColor: "#4B4E5F",
    bgColor: "#E2E2E2",
  },
  currently_shipping: {
    label: "อยู่ระหว่างจัดส่ง",
    fontColor: "#F57C00",
    bgColor: "#F7E6D3",
  },
  success_shipping: {
    label: "จัดส่งสำเร็จ",
    fontColor: "#409261",
    bgColor: "#E9FFEF",
  },
  success_receive: {
    label: "รับสินค้าสำเร็จ",
    fontColor: "#409261",
    bgColor: "#E9FFEF",
  },
};

export const unitMap = {
  kg: "กิโลกรัม",
  bag: "ถุง",
  "": "",
};

function ManageStockById() {
  const { id } = useLoaderData() as Awaited<
    ReturnType<typeof manageStockByIdLoader>
  >;
  const [selected, setSelected] = useState<string[]>([]);
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };
  const [openFillProduct, setOpenFillProduct] = useState(false);
  const [fillProductData, setFillProductData] = useState<FillProductData[]>([]);
  const handleFetch = async () => {
    const data = await getFillStockListById(id);
    setFillProductData(data);
  };
  const [listStockById, setListStockById] = useState<IGetPartnerStock>();
  const [listStockRefillById, setListStockRefillById] = useState<
    IStockRefillDetail[]
  >([]);

  const initData = useCallback(() => {
    Promise.all([getListPartnerById(id), getListStockRefill(id)])
      .then(([dataPartner, dataRefill]) => {
        setListStockById(dataPartner!);
        setListStockRefillById(dataRefill!.result);
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, []);

  useEffect(() => {
    initData();
  }, []);

  const transformExcelData = (data: FillProductData[]) => {
    type TExcelHeader =
      | "หมายเลขสั่งซื้อ"
      | "จำนวนรวม"
      | "วันที่จัดส่งสินค้า"
      | "สถานะ"
      | "แบรนด์อาหารสัตว์"
      | "เบอร์อาหารสัตว์"
      | "จำนวน";
    type TExcelData = { [k in TExcelHeader]: string };
    return data
      .map((row): TExcelData[] =>
        row.fillProductItems.map(
          (each): TExcelData => ({
            หมายเลขสั่งซื้อ: row.orderId,
            จำนวนรวม: row.overallAmount,
            วันที่จัดส่งสินค้า: row.deliveryDate,
            สถานะ: ChipStyle[row.status].label,
            แบรนด์อาหารสัตว์: each.nameTh,
            เบอร์อาหารสัตว์: each.skuTh,
            จำนวน: each.amount + " " + unitMap[each.unit],
          })
        )
      )
      .flat();
  };

  const handleExport = () => {
    const filterData = fillProductData.filter((row) =>
      selected.includes(row.id)
    );
    const excelData = transformExcelData(filterData);
    const fileName = `fill_up_stock_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(excelData, fileName, sheetName);
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const { showButton } = useShowButtonByRoles([UserRole.Sale]);

  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative font-sans">
        <div className="p-10">
          {/* Header */}
          <div className="text-3xl flex items-center justify-between">
            <div className="flex items-center">
              <ChevronLeftIcon
                fontSize="inherit"
                className="cursor-pointer mr-3"
                onClick={(_) => navigate(-1)}
              />
              <span className="text-[#000] font-bold">
                จัดการสต็อกของพาร์ทเนอร์
              </span>
            </div>
            {showButton && (
              <ButtonFillCustom
                title="เติมสินค้า"
                onClick={() => setOpenFillProduct(true)}
              />
            )}
          </div>
          <Divider sx={{ paddingTop: "12px", marginBottom: "24px" }} />
          <PartnerInfoCard data={listStockById?.result} />
          <div className="pt-6 mb-6 flex justify-between items-center">
            <div>
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="manage-stock-by-id-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab
                  label={`รายการสินค้า`}
                  {...a11yProps("manage-stock-by-id", 0)}
                />
                <Tab
                  label={`รายการเติมสินค้า`}
                  {...a11yProps("manage-stock-by-id", 1)}
                />
              </Tabs>
            </div>
            {tabIndex === 1 && (
              <ButtonOutlinedCustom
                onClick={handleExport}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                disabled={selected.length === 0}
                startIcon={
                  <SvgIcon
                    sx={{
                      fontSize: "14px !important",
                      color: "currentColor",
                    }}
                    fontSize="small"
                  >
                    <DownloadSvg />
                  </SvgIcon>
                }
                title="นำออก"
              />
            )}
          </div>
          <TabPanelCustom
            value={tabIndex}
            index={0}
            prefix="manage-stock-by-id"
          >
            <ProductPanel stock={listStockById?.result.stocks} />
          </TabPanelCustom>
          <TabPanelCustom
            value={tabIndex}
            index={1}
            prefix="manage-stock-by-id"
          >
            <FillProductPanel
              showButton={showButton}
              itemSelected={selected}
              setItemSelected={setSelected}
              data={listStockRefillById}
              id={id}
              refetch={initData}
            />
          </TabPanelCustom>
        </div>
        <FillProductModal
          open={openFillProduct}
          onClose={() => setOpenFillProduct(false)}
          data={listStockById}
          refetch={initData}
        />
      </div>
    </MenuBar>
  );
}

export default ManageStockById;

import { memo, useMemo } from "react";
import { tw } from "../../../../utils/tw";
import Assignment2 from "../../../../assets/svg/Assignment2";
import Stat from "../../../../assets/svg/Stat";
import Clock from "../../../../assets/svg/Clock";
import Calendar from "../../../../assets/svg/Calendar";
import { IContracts } from "../../../../types/Financial.type";
import dayjs from "dayjs";
import { get } from "lodash";
import { getCommaNumber } from "../../../../utils/number";
import i18next from "i18next";

interface IDetailAndIcon {
  data: IContracts;
}

const DetailAndIcon = (props: IDetailAndIcon) => {
  const { data } = props;

  const statusText = useMemo(() => {
    if (
      get(data, ["isActive"]) === false &&
      dayjs(get(data, ["startDate"])).valueOf() > dayjs().valueOf()
    ) {
      return "รอวันเริ่มสัญญา";
    } else if (
      get(data, ["isActive"]) === false &&
      dayjs(get(data, ["expireDate"])).valueOf() < dayjs().valueOf()
    ) {
      return "สิ้นสุดสัญญา";
    } else if (get(data, ["isActive"]) === false) {
      return "ปิดใช้งาน";
    } else if (get(data, ["isActive"]) === true) {
      return "เปิดใช้งาน";
    }
  }, [data]);

  return (
    <div className="grid grid-cols-2 px-3 gap-3">
      <div className={tw("bg-[#F6FBFF] text-start rounded-[20px] w-full")}>
        <div className="p-3 flex flex-col gap-[6px]">
          <Stat />
          <div className="text-[#3777BC] text-[14px] font-normal">
            วงเงินลูกค้า
          </div>
          <div className="text-[#34407B] text-[18px] font-medium">
            {getCommaNumber(+get(data, ["credit"]))} บาท
          </div>
        </div>
      </div>
      <div className={tw("bg-[#F6FBFF] text-start rounded-[20px] w-full")}>
        <div className="p-3 flex flex-col gap-[6px]">
          <Clock />
          <div className="text-[#3777BC] text-[14px] font-normal">
            ระยะเครดิต
          </div>
          <div className="text-[#34407B] text-[18px] font-medium">
            {getCommaNumber(+get(data, ["creditInformation", "creditPeriod"]))}{" "}
            วัน
          </div>
        </div>
      </div>
      <div className={tw("bg-[#F6FBFF] text-start rounded-[20px] w-full")}>
        <div className="p-3 flex flex-col gap-[6px]">
          <Calendar />
          <div className="text-[#3777BC] text-[14px] font-normal">
            วันเริ่มสัญญา
          </div>
          <div className="text-[#34407B] text-[18px] font-medium">
            {dayjs(get(data, ["startDate"]))
              .locale(i18next.language)
              .format(i18next.language === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
          </div>
        </div>
      </div>
      <div className={tw("bg-[#F6FBFF] text-start rounded-[20px] w-full")}>
        <div className="p-3 flex flex-col gap-[6px]">
          <Calendar />
          <div className="text-[#3777BC] text-[14px] font-normal">
            วันสิ้นสุดสัญญา
          </div>
          <div className="text-[#34407B] text-[18px] font-medium">
            {dayjs(get(data, ["expireDate"]))
              .locale(i18next.language)
              .format(i18next.language === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
          </div>
        </div>
      </div>
      <div
        className={tw(
          "bg-[#F6FBFF] text-start rounded-[20px] w-full col-span-2"
        )}
      >
        <div className="p-3 flex flex-col gap-[6px]">
          <Assignment2 />
          <div className="text-[#3777BC] text-[14px] font-normal">สถานะ</div>
          <div className="text-[#34407B] text-[18px] font-medium">
            {statusText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DetailAndIcon);

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "./ViewImageAndPreviewCarousel.scss";
import "./swiper-styles/swiper.scss";
import "./swiper-styles/pagination.scss";
import DocumentCard from "./ItemsCard/DocumentCard";
import PreviewAndZoomImageCarouselModal from "./PreviewAndZoomImageCarouselModal";
import { Box } from "@mui/material";

export interface IViewImageAndPreviewCarouselProps {
  images: string[];
}

function ViewImageAndPreviewCarousel({
  images,
}: IViewImageAndPreviewCarouselProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  return (
    <>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className="mySwiper"
        // onSlideChange={(event) => {
        //   console.log("[onSlideChange] => ", event);
        // }}
        onActiveIndexChange={(event) => {
          setSlideIndex(event.realIndex);
        }}
        initialSlide={slideIndex}
        loop={true}
        centeredSlides={true}
      >
        {images.map((val, index) => (
          <SwiperSlide
            key={`carousel-item-${index}`}
            className="w-full"
            onClick={() => setIsOpenModal(true)}
          >
            <div className="flex h-full w-full justify-center relative">
              <Box
                sx={{
                  width: "271px",
                  height: "343px",
                }}
              >
                <DocumentCard image={val} />
              </Box>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <PreviewAndZoomImageCarouselModal
        isOpen={isOpenModal}
        initIndex={slideIndex}
        onClose={() => {
          setIsOpenModal(false);
        }}
        imageItems={images}
      />
    </>
  );
}

export default ViewImageAndPreviewCarousel;

import { Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useShowButtonByRoles } from "../../../hooks/useActionButtonByRoles";
import { UserRole } from "../../../utils/enum/user-role.enum";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../components/TabPanelCustom/TabPanelCustom";
import CustomerPanel from "./CustomerPanel";
import EmployeePanel from "./EmployeePanel";
import CreateEmployeeModal from "./EmployeePanel/CreateEmployeeModal";
import PartnerPanel from "./PartnerPanel";
import CreatePartnerModal from "./PartnerPanel/CreatePartnerModal";

type Props = {};

const UserManagement = (props: Props) => {
  const [tabIndex, setTabIndex] = useState<number>(1);
  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };
  const [isOpenCreatePartnerModal, setIsOpenCreatePartnerModal] =
    useState(false);
  const [isOpenCreateEmployeeModal, setIsOpenCreateEmployeeModal] =
    useState(false);

  const buttonTitle = ["เพิ่มผู้ใช้", "เพิ่มพาร์ทเนอร์"];
  const buttonOnClink = [
    setIsOpenCreateEmployeeModal,
    setIsOpenCreatePartnerModal,
  ];

  const { showButton } = useShowButtonByRoles([
    UserRole.Admin,
    UserRole.Management,
  ]);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex justify-between">
              <span className="text-[#000] font-bold text-3xl">
                User Management
              </span>
              {tabIndex !== 2 && showButton && (
                <ButtonFillCustom
                  title={buttonTitle[tabIndex]}
                  onClick={() => buttonOnClink[tabIndex](true)}
                />
              )}
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6">
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="sale-order-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab label={`พนักงาน`} />
                <Tab label={`พาร์ทเนอร์`} />
                <Tab label={`ลูกค้า`} />
              </Tabs>
            </div>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix="sale-order-order"
            >
              <EmployeePanel />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix="sale-order-order"
            >
              <PartnerPanel />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={2}
              prefix="sale-order-order"
            >
              <CustomerPanel />
            </TabPanelCustom>
          </div>
        </div>
      </MenuBar>
      <CreatePartnerModal
        isOpen={isOpenCreatePartnerModal}
        handleClose={() => setIsOpenCreatePartnerModal(false)}
        handleFetch={() => {}}
      />
      <CreateEmployeeModal
        isOpen={isOpenCreateEmployeeModal}
        handleClose={() => setIsOpenCreateEmployeeModal(false)}
        handleFetch={() => {}}
      />
    </>
  );
};

export default UserManagement;

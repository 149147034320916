import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { array, date, number, object, string } from "yup";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import DatePickerFS from "../../../components/common/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { InputAdornment, MenuItem, Typography } from "@mui/material";
import MoveOutPigSellingInfoCard from "../../../components/common/MoveOutPigSellingInfoCard/MoveOutPigSellingInfoCard";
import CustomButton from "../../../components/common/Button";
import { useEffect, useState } from "react";
import DropdownAndTextInput from "../../../components/common/Input/DropdownAndTextInput";
import Resizer from "react-image-file-resizer";
import CustomUploadImage from "../../../components/common/uploadImage/CustomUploadImage";
import { IMoveOutPigPayloadData } from "./MoveOutPigSelling";
import { formError, formErrorMessage } from "../../../utils/form-validator";
import SellingHistoryInfoModal from "../../../components/common/SellingHistoryInfoModal/SellingHistoryInfoModal";
import { InputNumberString } from "../../../components/common/Input/InputNumberString";
import {
  IMoveOutPigHouseDetail,
  ICreateMoveOutPigRequest,
} from "../../../types/MoveOutPig.type";
import {
  checkAndReplaceZeroPrefix,
  thousandComma,
} from "../../../utils/common/string";
import TextInputCustom from "../../../components/common/Input/TextInputCustom";
import SelectCustom from "../../../components/common/Input/SelectCustom";
import {
  getMoveOutPigGenerateId,
  getPresignedUploadReceipt,
  postMoveOutPig,
  putUploadPresigned,
} from "../../../services/MoveOutPig.service";
import {
  MoveOutPigTypesEnum,
  SaleTypesEnum,
} from "../../../utils/enum/move-out-pig.enum";
import Loader from "../../../components/common/Loader";
import { useDayjsLocale } from "../../../hooks/useDayjsLocale.hook";
import { InputNumberStringNoDouble } from "../../../components/common/Input/InputNumberStringNoDouble";

const SaleTypesMap = [
  {
    id: SaleTypesEnum.CASH,
    name: "Cash",
  },
  {
    id: SaleTypesEnum.CREDIT,
    name: "Credit",
  },
];

const MoveOutPigMap = [
  {
    id: MoveOutPigTypesEnum.SellAll,
    name: "Sell All",
  },
  {
    id: MoveOutPigTypesEnum.SellPartially,
    name: "Sell Partially",
  },
];

const Image_Resize_limit = 2097152;
const MAX_FILE_SIZE = 1024 * 1024 * 100; //100mb
const ALLOW_IMAGE_FILE_TYPE = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
];

export function HouseMoveOutPigSelling() {
  useDayjsLocale();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { house } = useLoaderData() as { house: IMoveOutPigHouseDetail };
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>("");
  const [files, setFiles] = useState<File[]>([]);
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  type MyFormValues = {
    picturesOfReceipt: any[];
  };

  const formik = useFormik<IMoveOutPigPayloadData>({
    initialValues: {
      date: dayjs(),
      farmId: house?.farmId,
      houseId: house?.houseId,
      saleType: "",
      pigMoveOutType: "",
      picturesOfReceipt: [],
    },
    validationSchema: object({
      date: date().required(
        t("moveOutPigSelling.validateField.selectDate") as any
      ),
      farmId: string().required(
        t("moveOutPigSelling.validateField.selectFarm") as any
      ),
      houseId: string().required(
        t("moveOutPigSelling.validateField.selectHouse") as any
      ),
      pigsMoveOut: number()
        .positive(t("moveOutPigSelling.validateField.numberPositive") as any)
        .required(t("moveOutPigSelling.validateField.fillPigsMoveOut") as any),
      pigMoveOutType: string().optional(),
      saleType: string().required(
        t("moveOutPigSelling.validateField.selectSalesType") as any
      ),
      credit: number()
        .positive(t("moveOutPigSelling.validateField.numberPositive") as any)
        .integer(t("moveOutPigSelling.validateField.numberPositive") as any)
        .when("saleType", {
          is: (saleType: string | undefined) =>
            saleType === SaleTypesEnum.CREDIT,
          then: (schema) =>
            schema.required(
              t("moveOutPigSelling.validateField.fillCredit") as any
            ),
          otherwise: (schema) => {
            return schema.optional();
          },
        }),
      totalWeightOfPig: number()
        .positive(t("moveOutPigSelling.validateField.numberPositive") as any)
        .required(
          t("moveOutPigSelling.validateField.fillTotalWeightOfPig") as any
        ),
      pricePerKg: number()
        .positive(t("moveOutPigSelling.validateField.numberPositive") as any)
        .required(
          t("moveOutPigSelling.validateField.fillTotalPricePerUnit") as any
        ),
      companyOrPurchaserName: string()
        .max(
          50,
          t("moveOutPigSelling.validateField.maximumFiftyCharacter") as any
        )
        .nullable()
        .optional(),
      picturesOfReceipt: array<File[]>()
        .test(
          "file-validation",
          `${t("dailyLog.imageQuantityOrSizeErrorMessage")}`,
          (value) => {
            if (!value) return true;
            if (value.length > 2) {
              return false;
            }
            return true;
          }
        )
        .test(
          "FILE_SIZE",
          `${t("dailyLog.uploadFileSizeErrorMessage")}`,
          (value) => {
            if (!value) return true;
            if (Array.isArray(value) && value.length > 0) {
              const totalSize = value.reduce((prev, curr) => {
                return prev + curr?.size;
              }, 0);
              return totalSize < MAX_FILE_SIZE;
            }
            return true;
          }
        ),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const genMoveOutPigId = await getMoveOutPigGenerateId();
        // console.log("genMoveOutPigId", genMoveOutPigId);

        if (
          Array.isArray(values.picturesOfReceipt) &&
          values.picturesOfReceipt.length > 0
        ) {
          for (const file of values.picturesOfReceipt) {
            const presigned = await getPresignedUploadReceipt(
              genMoveOutPigId.moveOutPigId,
              file.type,
              {
                headers: {
                  "x-ms-date": dayjs().toISOString(),
                  "x-ms-version": dayjs().format("YYYY-MM-DD"),
                  "Content-Length": file.size ?? 0,
                  "x-ms-blob-type": "BlockBlob",
                },
              }
            );
            if (presigned.blobUrl) {
              // const myHeaders = new Headers();
              // myHeaders.append("x-ms-date", dayjs().toISOString());
              // myHeaders.append("x-ms-version", presigned.blobVersion);
              // myHeaders.append("x-ms-blob-type", "BlockBlob");
              // myHeaders.append("Content-Type", file.type);

              const fileBuf = await file.arrayBuffer();

              await putUploadPresigned(presigned.blobUrl, fileBuf, {
                headers: {
                  "x-ms-date": dayjs().toISOString(),
                  // "x-ms-version": presigned.blobVersion,
                  "x-ms-blob-type": "BlockBlob",
                  "Content-Type": file.type,
                },
              });
            }
          }
        }

        const payload: ICreateMoveOutPigRequest = {
          moveOutPigId: genMoveOutPigId.moveOutPigId,
          moveOutDate: dayjs(values.date).format("YYYY-MM-DD"),
          farmId: values?.farmId ?? "",
          houseId: values.houseId ?? "",
          amount: Number(values.pigsMoveOut) ?? 0,
          saleType: values.saleType as SaleTypesEnum,
          totalWeightOfPig: Number(values.totalWeightOfPig) ?? 0,
          pricePerUnit: Number(values.pricePerKg) ?? 0,
          purchaserName: values?.companyOrPurchaserName,
          creditDay:
            (values.saleType as SaleTypesEnum) === SaleTypesEnum.CASH
              ? undefined
              : Number(values?.credit),
        };
        const createResult = await postMoveOutPig(payload);
        if (createResult?.success) {
          navigate(`/house/${house?.houseId}/move-out-pig-success`, {
            state: {
              houseId: house?.houseId,
              farmId: house?.farmId,
              isSellAll:
                (values?.pigMoveOutType as MoveOutPigTypesEnum) ===
                MoveOutPigTypesEnum.SellAll,
            },
          });
        }
      } catch (err: any) {
        console.log(err);
        setServerError(err?.message ?? err);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleFileChange = (
    e: any,
    formikValue: keyof MyFormValues,
    index: number
  ) => {
    const inputElement = e.target as HTMLInputElement;

    setFiles([...files, ...e.target.files]);
    if (inputElement?.files) {
      const file: File = inputElement.files[0];
      if (!ALLOW_IMAGE_FILE_TYPE.includes(file.type)) {
        formik.setFieldError(
          "picturesOfReceipt",
          t("moveOutPigSelling.validateField.uploadFileTypeIncorrect") as any
        );
        return;
      }
      if (file.size > Image_Resize_limit) {
        Resizer.imageFileResizer(
          file,
          300, // maxWidth
          300, // maxHeight
          "JPEG", // compressFormat
          80, // quality
          0, // rotation
          async (uri: any) => {
            // Convert data URL to Blob
            const response = await fetch(uri);
            const blob = await response.blob();
            // Create a File object from the Blob
            const compressedFile = new File(
              [blob],
              `receipt_compressed_${file.name.split(".")[0]}.jpg`,
              {
                type: "image/jpeg",
                lastModified: Date.now(),
              }
            );
            setImageUrl((prevImageUrl) => [...prevImageUrl, uri]);
            const updatedArray: any = [
              ...(formik.values.picturesOfReceipt as File[]),
            ];
            formik.setFieldValue("picturesOfReceipt", [
              ...updatedArray,
              compressedFile,
            ]);
          },
          "base64" // outputType
        );
      } else {
        const url = URL.createObjectURL(file);
        if (file?.size < MAX_FILE_SIZE) {
          setImageUrl((prevImageUrl) => [...prevImageUrl, url]);

          if (e && e.target && e.target.files !== null) {
            const updatedArray: any[] = [
              ...(formik.values.picturesOfReceipt as File[]),
            ];
            updatedArray.push(e.target.files[0]);
            formik.setFieldValue("picturesOfReceipt", updatedArray);
          }
        } else {
          formik.setFieldError(
            "picturesOfReceipt",
            t("dailyLog.uploadFileSizeErrorMessage") as any
          );
        }
      }
    }
  };

  const handleRemoveImage = (ele: any) => {
    const item = imageUrl.filter((item) => ele !== item);
    let index = imageUrl.indexOf(ele);
    setImageUrl(item);
    if (item.length === 0) {
      formik.setValues({
        ...formik.values,
        picturesOfReceipt: [],
      });
    } else {
      formik.setValues({
        ...formik.values,
        picturesOfReceipt: (formik.values.picturesOfReceipt as File[]).splice(
          index,
          1
        ),
      });
    }
  };

  const handleDateChange = (val: Dayjs | null) => {
    if (val) {
      formik.setFieldValue("date", val);
    }
  };

  const handleFarmChange = (value: string) => {
    formik.setFieldValue("farmId", value);
  };

  const handleHouseChange = (value: string) => {
    formik.setFieldValue("houseId", value);
  };

  useEffect(() => {
    if (formik.values.totalWeightOfPig && formik.values.pricePerKg) {
      const totalPrice =
        formik.values.totalWeightOfPig * formik.values.pricePerKg;
      formik.setFieldValue("totalPrice", totalPrice);
    } else {
      formik.setFieldValue("totalPrice", "");
    }
  }, [formik.values.totalWeightOfPig, formik.values.pricePerKg]);

  const handleChangeMoveOutType = (val: MoveOutPigTypesEnum) => {
    if (val === MoveOutPigTypesEnum.SellAll) {
      formik.setFieldValue("pigsMoveOut", house?.remaining);
      setIsOpenModal(true);
    } else if (val === MoveOutPigTypesEnum.SellPartially) {
      formik.setFieldValue("pigsMoveOut", "");
    }
    formik.setFieldValue("pigMoveOutType", val);
  };

  const handleChangeMoveOutPigAmount = (val: string) => {
    if (Number(val) >= house?.remaining) {
      formik.setFieldValue("pigMoveOutType", MoveOutPigTypesEnum.SellAll);
      formik.setFieldValue("pigsMoveOut", Number(house?.remaining));
    } else {
      formik.setFieldValue("pigMoveOutType", MoveOutPigTypesEnum.SellPartially);
      formik.setFieldValue(
        "pigsMoveOut",
        checkAndReplaceZeroPrefix(Number(val).toString())
      );
    }
  };

  const handleChangeSaleType = (val: SaleTypesEnum) => {
    if (val === SaleTypesEnum.CASH) {
      formik.setFieldValue("credit", "");
    }
    formik.setFieldValue("saleType", val);
  };

  const handleChangeCompanyOrPurchaserName = (value: string) => {
    if (value.length > 50) return;
    formik.setFieldValue("companyOrPurchaserName", value);
  };

  const handleChangeInputValue = (
    fieldName: keyof IMoveOutPigPayloadData,
    value: string
  ) => {
    formik.setFieldValue(fieldName, checkAndReplaceZeroPrefix(value));
  };

  return (
    <div className="relative w-full bg-[#FFFFF]">
      {isLoading && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <div className="h-screen overflow-auto flex flex-col relative pb-24">
          <div className="p-4 rounded-b-[40px] bg-white">
            <AppBarFS
              onIconPress={() =>
                navigate(`/house/${house?.houseId}/move-out-pig`)
              }
              title={t("moveOutPigSelling.titleBar")}
            />
          </div>
          <div className="px-4 p-4 text-tertiary font-semibold ml-1 capitalize text-xl">
            {t("moveOutPigSelling.titleAddDetail")}
          </div>
          <div className="flex px-4 pb-2">
            <MoveOutPigSellingInfoCard
              title={t("moveOutPigSelling.infoCard.title")}
              items={[
                {
                  name: t("moveOutPigSelling.infoCard.alreadyMovedOut"),
                  value: thousandComma(house.movedOutCount),
                  unit: t("moveOutPigSelling.infoCard.unitPig"),
                },
                {
                  name: t("moveOutPigSelling.infoCard.remaining"),
                  value: thousandComma(house.remaining),
                  unit: t("moveOutPigSelling.infoCard.unitPig"),
                },
              ]}
            />
          </div>
          <div className="flex p-4">
            <div className="flex flex-col grow mt-1 gap-4">
              <DatePickerFS
                format={"DD/MM/BBBB"}
                value={formik.values?.date}
                maxDate={dayjs()}
                minDate={dayjs(house?.startHouseDate ?? new Date())}
                disabled={false}
                slotProps={{
                  textField: {
                    helperText: formErrorMessage(formik, "date"),
                    error: formError(formik, "date"),
                    focused: true,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="start">
                          <EventOutlinedIcon color={"info"} />
                        </InputAdornment>
                      ),
                      startAdornment: null,
                    },
                  },
                }}
                sx={{
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px !important",
                  },
                }}
                label={t("moveOutPigSelling.input.label.date")}
                onChange={(val) => handleDateChange(val)}
              />

              <TextInputCustom
                select
                fullWidth
                helperText={formErrorMessage(formik, "farmId")}
                error={formError(formik, "farmId")}
                disabled={true}
                label={t("moveOutPigSelling.input.label.farmName")}
                name="farmName"
                id="farmName"
                focused={true}
                onChange={(e) => handleFarmChange(e.target.value)}
                value={formik?.values?.farmId || null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        color="disabled"
                        fontSize="small"
                        sx={{
                          color: "#979797",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                {house.farmId &&
                  Array.from([{ id: house.farmId, name: house.farmName }]).map(
                    (value: { id: string; name: string }, index: number) => (
                      <MenuItem key={`farm-item-${index}`} value={value.id}>
                        {value.name}
                      </MenuItem>
                    )
                  )}
              </TextInputCustom>

              <TextInputCustom
                select
                fullWidth
                helperText={formErrorMessage(formik, "houseId")}
                error={formError(formik, "houseId")}
                disabled={true}
                label={t("moveOutPigSelling.input.label.houseName")}
                name="houseName"
                id="houseName"
                focused={true}
                onChange={(e) => handleHouseChange(e.target.value)}
                value={formik?.values?.houseId || null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        color="disabled"
                        fontSize="small"
                        sx={{
                          color: "#979797",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                {house &&
                  Array.from([
                    { id: house.houseId, name: house.houseName },
                  ]).map(
                    (value: { id: string; name: string }, index: number) => (
                      <MenuItem key={`house-item-${index}`} value={value.id}>
                        {value.name}
                      </MenuItem>
                    )
                  )}
              </TextInputCustom>

              <DropdownAndTextInput
                dropdownItems={MoveOutPigMap}
                isError={
                  formError(formik, "pigMoveOutType") ||
                  formError(formik, "pigsMoveOut")
                }
                helperText={
                  formErrorMessage(formik, "pigMoveOutType") ||
                  formErrorMessage(formik, "pigsMoveOut")
                }
                formik={formik}
                getFieldDropDownProps={formik.getFieldProps("pigMoveOutType")}
                getFieldInputProps={formik.getFieldProps("pigsMoveOut")}
                onChangeDropdown={(value) =>
                  handleChangeMoveOutType(value as MoveOutPigTypesEnum)
                }
                onChangeInput={(value) => handleChangeMoveOutPigAmount(value)}
                dropdownFieldName="pigMoveOutType"
                inputFieldName="pigsMoveOut"
                dropdownInputProps={{
                  variant: "outlined",
                  error: formError(formik, "pigMoveOutType"),
                }}
                max={house?.remaining ?? 0}
                inputProps={{
                  error: formError(formik, "pigsMoveOut"),
                  placeholder: t(
                    "moveOutPigSelling.input.placeholder.pigsMoveOut"
                  ) as any,
                }}
                t={t}
              />

              <SelectCustom
                displayEmpty
                label={t("moveOutPigSelling.input.label.saleType") as any}
                fullWidth
                variant="outlined"
                shrink={true}
                error={formError(formik, "saleType")}
                helperText={formErrorMessage(formik, "saleType")}
                {...formik.getFieldProps("saleType")}
                onChange={(e) =>
                  handleChangeSaleType(e.target.value as SaleTypesEnum)
                }
                renderValue={
                  formik.values?.saleType !== ""
                    ? undefined
                    : () => (
                        <div className="text-[1rem] text-primary-grey-100">
                          {t("moveOutPigSelling.input.placeholder.saleType")}
                        </div>
                      )
                }
                menuItems={SaleTypesMap.map((val, index) => (
                  <MenuItem key={val.id} value={val.id}>
                    {t(`moveOutPigSelling.saleType.${val.id}`)}
                  </MenuItem>
                ))}
              ></SelectCustom>

              {formik.values?.saleType === SaleTypesEnum.CREDIT && (
                <TextInputCustom
                  fullWidth
                  helperText={formErrorMessage(formik, "credit")}
                  error={formError(formik, "credit")}
                  label={t("moveOutPigSelling.input.label.creditDay")}
                  focused={true}
                  {...formik.getFieldProps("credit")}
                  onChange={(e) =>
                    handleChangeInputValue("credit", e.target.value)
                  }
                  placeholder={
                    t("moveOutPigSelling.input.placeholder.creditDay") as any
                  }
                  InputProps={{
                    inputComponent: InputNumberStringNoDouble as any,
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className="flex text-tertiary font-medium text-[1rem] gap-[6px]">
                          <span className="selling-line-vertical-input"></span>
                          {t("moveOutPigSelling.input.unit.creditDay")}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <TextInputCustom
                fullWidth
                helperText={formErrorMessage(formik, "totalWeightOfPig")}
                error={formError(formik, "totalWeightOfPig")}
                label={t("moveOutPigSelling.input.label.totalWeightOfPig")}
                focused={true}
                {...formik.getFieldProps("totalWeightOfPig")}
                onChange={(e) =>
                  handleChangeInputValue("totalWeightOfPig", e.target.value)
                }
                placeholder={
                  t(
                    "moveOutPigSelling.input.placeholder.totalWeightOfPig"
                  ) as any
                }
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: InputNumberString as any,
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className="flex text-tertiary font-medium text-[1rem] gap-[6px]">
                        <span className="selling-line-vertical-input"></span>
                        {t("moveOutPigSelling.input.unit.totalWeightOfPig")}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />

              <TextInputCustom
                fullWidth
                helperText={formErrorMessage(formik, "pricePerKg")}
                error={formError(formik, "pricePerKg")}
                label={t("moveOutPigSelling.input.label.pricePerKg")}
                focused={true}
                {...formik.getFieldProps("pricePerKg")}
                onChange={(e) =>
                  handleChangeInputValue("pricePerKg", e.target.value)
                }
                placeholder={
                  t("moveOutPigSelling.input.label.pricePerKg") as any
                }
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: InputNumberString as any,
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className="flex text-tertiary font-medium text-[1rem] gap-[6px]">
                        <span className="selling-line-vertical-input"></span>
                        {t("moveOutPigSelling.input.unit.pricePerKg")}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />

              <TextInputCustom
                fullWidth
                helperText={formErrorMessage(formik, "totalPrice")}
                error={formError(formik, "totalPrice")}
                disabled={true}
                focused={true}
                label={t("moveOutPigSelling.input.label.totalPrice")}
                placeholder={
                  t("moveOutPigSelling.input.placeholder.totalPrice") as any
                }
                {...formik.getFieldProps("totalPrice")}
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: InputNumberString as any,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        color="disabled"
                        fontSize="small"
                        sx={{
                          color: "#979797",
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className="text-primary-grey-100 font-medium text-[1rem]">
                        | {t("moveOutPigSelling.input.unit.totalPrice")}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />

              <div className="flex flex-col w-full h-full">
                <TextInputCustom
                  fullWidth
                  helperText={formErrorMessage(
                    formik,
                    "companyOrPurchaserName"
                  )}
                  error={formError(formik, "companyOrPurchaserName")}
                  disabled={false}
                  focused={true}
                  label={t(
                    "moveOutPigSelling.input.label.companyOrPurchaserName"
                  )}
                  placeholder={
                    t(
                      "moveOutPigSelling.input.placeholder.companyOrPurchaserName"
                    ) as any
                  }
                  type="text"
                  {...formik.getFieldProps("companyOrPurchaserName")}
                  onChange={(e) =>
                    handleChangeCompanyOrPurchaserName(e.target.value)
                  }
                  InputProps={{
                    inputProps: {
                      inputMode: "text",
                    },
                  }}
                />
                <span
                  className={`pb-4 text-tertiary text-[0.75rem] mx-[14px] font-normal pt-2 text-sm`}
                >
                  {t(
                    "moveOutPigSelling.input.helperText.companyOrPurchaserName"
                  )}
                </span>
              </div>

              <div className="flex flex-col w-full">
                <div className=" text-tertiary font-semibold ml-1 capitalize text-xl mt-2">
                  {t("moveOutPigSelling.uploadReceipt.titleUploadReceipt")}
                </div>
                <div className="h-full">
                  <CustomUploadImage
                    onChange={(e) =>
                      handleFileChange(e, "picturesOfReceipt", 1)
                    }
                    imageUrl={imageUrl}
                    handleRemoveImage={handleRemoveImage}
                    t={t}
                    errorMessage={formErrorMessage(formik, "picturesOfReceipt")}
                  />
                </div>
                <span className="text-[#D32F2F]">
                  {formErrorMessage(formik, "picturesOfReceipt")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 px-4 absolute z-10 bottom-0 w-full bg-white">
          <div className="w-full py-4">
            {serverError && (
              <Typography
                variant="body2"
                className="text-[#D32F2F] px-4"
                align="center"
              >
                {serverError}
              </Typography>
            )}
            {formik.values.pigMoveOutType ===
              MoveOutPigTypesEnum.SellPartially && (
              <span className="text-[#D32F2F] px-4 text-[0.75rem]">
                {t("moveOutPigSelling.input.helperText.partialNotice")}
              </span>
            )}
            <CustomButton
              variant="tertiary"
              type="submit"
              disabled={!formik?.isValid || formik?.isSubmitting}
              label={t("moveOutPigSelling.button.submit")}
            />
          </div>
        </div>
      </form>
      <SellingHistoryInfoModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        title="Notice!"
        descriptions={
          <div className="text-sm pb-12 pt-4 justify-center w-full">
            <p className="text-center">การขาย Wholesale จะเป็นการปิดรุ่นหมู</p>
            <p className="text-center">คุณจะไม่สามารถกลับมาขายหรือแก้ไข</p>
            <p className="text-center">ข้อมูลในโรงเรือนนี้ได้อีก</p>
          </div>
        }
        titleBtn={t("moveOutPigSelling.button.notice") as any}
        textColor="#F57C00"
        haveBackgroundImage={true}
        haveCloseIcon={true}
        height="358px"
      />
    </div>
  );
}

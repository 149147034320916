import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Divider,
  Link,
  SvgIcon,
  Tab,
  TableCell,
  Tabs,
} from "@mui/material";
import { cloneDeep, get, isEmpty } from "lodash";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { tw } from "../../../../utils/tw";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import { CalendarSvg } from "../../../assets/svg/Calendar.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import DateRangePickerPopover from "../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import dayjs from "dayjs";
import { FILTER_DATE_FORMAT } from "../../sale-order/components/SaleOrderSearchFilter";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import FillSaleContract from "../credit-feed/salesContract/FillSaleContract";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import { useCreditStatusController } from "./controller";
import {
  filterCustomerTable,
  getCreditStatus,
  getExportCreditFeed,
} from "../../../../services/CreditFeed.service";
import { grade } from "../credit-feed/salesContract";
import DatePickerPopover from "../../../components/Input/DatePickerPopover/DatePickerPopover";
import { makeXlsx } from "../../../../utils/make-xlsx";

type TPartnerStatus =
  | "success"
  | "rejected"
  | "waiting_start"
  | "contract_expire";

interface Data {
  customerId: string;
  contractNumber: string;
  customerName: string;
  garde: string;
  saleName: string;
  date: string;
  statues?: string;
}

interface DataExport {
  customerId: string;
  customerName: string;
  garde: string;
  saleName: string;
  date: string;
  contractNumber: string;
  statues: string;
}
function createData(
  customerId: string,
  contractNumber: string,
  customerName: string,
  garde: string,
  saleName: string,
  date: string,
  statues: string
): DataExport {
  return {
    customerId,
    contractNumber,
    customerName,
    garde,
    saleName,
    date,
    statues,
  };
}

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
  columnContainer: tw(`w-full flex flex-row overflow-x-auto mt-6 gap-x-3`),
};

const mockOptions = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
];

const contractOptions = [
  { label: "จ่ายหลังหมดสัญญา", value: "pay_after_expire" },
  { label: "จ่ายตาม invoice", value: "fill_credit" },
];

const OrderStatusMap: Record<
  TPartnerStatus,
  { color: string; bgColor: string; label: string }
> = {
  success: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "เปิดใช้งาน",
  },
  rejected: {
    color: "#3F3748",
    bgColor: "#E4E4E4",
    label: "ปิดใช้งาน",
  },
  waiting_start: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "รอวันเริ่มสัญญา",
  },
  contract_expire: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "สิ้นสุดสัญญา",
  },
};

const StatusMap = {
  success: "เปิดใช้งาน",
  rejected: "ปิดใช้งาน",
  waiting_start: "รอวันเริ่มสัญญา",
  contract_expire: "สิ้นสุดสัญญา",
  "": "",
};

const headCells: ITableCustomHeadCell<keyof Data>[] = [
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "auto",
    isSorting: true,
  },
  {
    id: "contractNumber",
    numeric: false,
    disablePadding: false,
    label: "เลขที่สัญญา",
    width: "auto",
    isSorting: false,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "auto",
    isSorting: false,
  },
  {
    id: "garde",
    numeric: false,
    disablePadding: false,
    label: "เกรดลูกค้า",
    width: "auto",
    isSorting: true,
  },
  {
    id: "saleName",
    numeric: false,
    disablePadding: false,
    label: "พนักงานขาย",
    width: "auto",
    isSorting: false,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัพเดตล่าสุด",
    width: "auto",
    isSorting: true,
  },
  {
    id: "statues",
    numeric: false,
    disablePadding: false,
    label: "สถานะเครดิต",
    width: "auto",
    isSorting: false,
  },
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
    isSorting: false,
  },
];

const statusOptions = [
  { label: "เปิดใช้งาน", value: "true" },
  { label: "ปิดใช้งาน", value: "false" },
];

interface TForm {
  grade: TDropdownValue[];
  status: TDropdownValue[];
  contract: TDropdownValue[];
}

const defaultForm: TForm = {
  grade: [],
  status: [],
  contract: [],
};

const CreditStatus = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rowPartner, setRowPartner] = useState<any[]>([]);

  const [form, setForm] = useState<TForm>(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof TForm) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = rowPartner.map((n) => n?.fullData.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectStartDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setStartDate(formattedDate);
  };

  const refCalendarEndDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectEndDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setEndDate(formattedDate);
  };

  const statusText = useCallback((d: any) => {
    const currentData = d;

    if (!currentData) {
      return "unknown";
    }

    const isActive = currentData.isActive;
    const _startDate = dayjs(currentData.startDate).valueOf();
    const expireDate = dayjs(currentData.expireDate).valueOf();
    const now = dayjs().valueOf();
    const re = currentData.reject;

    if (!re && !isActive && _startDate > now) {
      return "waiting_start";
    } else if (!re && !isActive && expireDate < now) {
      return "contract_expire";
    } else if (!re && !isActive) {
      return "rejected";
    } else if (!re && isActive) {
      return "success";
    } else {
      return "error";
    }
  }, []);

  const refetch = useCallback(async () => {
    setRowPartner([]);

    const data = await filterCustomerTable({
      page: currentPage,
      limit: 10,
      farmerGrade: form.grade.map(({ value }) => value.toLowerCase()).join(","),
      searchText: searchText || undefined,
      isActive: form.status.map(({ value }) => value).join(",") || undefined,
      tabName: "credit_status",
      startDate: startDate
        ? dayjs(startDate, FILTER_DATE_FORMAT).toISOString()
        : undefined,
      endDate: endDate
        ? dayjs(endDate, FILTER_DATE_FORMAT).toISOString()
        : undefined,
    });

    setRowPartner(
      data.creditFeeds.map((d, index) => ({
        partnerId: d.registrationId,
        username: `${d.name} ${d.surname}`,
        sellerName: `${d.sellerName} ${d.sellerSurname}`,
        grade: get(d, "customerGrade", "-").toUpperCase(),
        date: dayjs(get(d, "updatedAt")).format("DD/MM/YYYY"),
        status: statusText(d),
        fullData: d,
        contractNumber: d.contractNumber,
      }))
    );
  }, [
    currentPage,
    form.grade,
    form.status,
    searchText,
    startDate,
    endDate,
    statusText,
  ]);

  useEffect(() => {
    refetch();
  }, [currentPage, form.grade, form.status, searchText, startDate, endDate]);

  useEffect(() => {
    refetch();
  }, []);

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `credit_status_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "รหัสลูกค้า"
      | "เลขที่สัญญา"
      | "ชื่อลูกค้า"
      | "เกรดลูกค้า"
      | "พนักงานขาย"
      | "วันที่อัพเดตล่าสุด"
      | "สถานะเครดิต";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportCreditFeed(itemSelected);
    if (allSelectItemData) {
      const data: TExcelData[] = allSelectItemData?.map<TExcelData>((row) => {
        const mappedData = createData(
          row.registrationId,
          row.contractNumber,
          row.name + " " + row.surname,
          row.customerGrade.toLocaleUpperCase(),
          row.sellerName + " " + `${row.sellerSurname || ""}`,
          dayjs(row.updatedAt).format("DD/MM/YYYY"),
          StatusMap[statusText(row) as TPartnerStatus]
        );
        return {
          รหัสลูกค้า: mappedData.customerId,
          เลขที่สัญญา: mappedData.contractNumber,
          ชื่อลูกค้า: mappedData.customerName,
          เกรดลูกค้า: mappedData.garde,
          พนักงานขาย: mappedData.saleName,
          วันที่อัพเดตล่าสุด: mappedData.date,
          สถานะเครดิต: mappedData.statues,
        };
      });
      return data;
    }
    return [];
  };

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>Credit Status</span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6">
              <div className="w-full ">
                <div className="w-full mr-4 mb-[-8px] flex flex-col">
                  <div className="w-full flex flex-row gap-[12px]">
                    <Dropdown
                      label="เกรดลูกค้า"
                      placeholder="เลือก"
                      options={grade}
                      onChange={onChangeForm("grade")}
                      renderValueArray={form.grade}
                      containerClassName="w-[33%]"
                    />
                    <div className="w-[33%] mt-[-7px]">
                      <span className="font-normal text-[14px]">
                        วันเริ่มสัญญา
                      </span>
                      <div className="pt-2">
                        <TextInputCustom
                          placeholder="เริ่มต้น"
                          value={startDate}
                          InputProps={{
                            endAdornment:
                              startDate !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={() => {}}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarStartDate.current?.onOpen(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-[33%] mt-[-7px]">
                      <span className="font-normal text-[14px]">
                        วันสิ้นสุดสัญญา
                      </span>
                      <div className="pt-2">
                        <TextInputCustom
                          placeholder="สิ้นสุด"
                          value={endDate}
                          InputProps={{
                            endAdornment:
                              startDate !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={() => {}}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarEndDate.current?.onOpen(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-row gap-[12px]">
                    <Dropdown
                      label="สถานะเครดิต"
                      placeholder="เลือก"
                      options={statusOptions}
                      onChange={onChangeForm("status")}
                      renderValueArray={form.status}
                      containerClassName="w-[33%]"
                    />
                    <Dropdown
                      label="เงื่อนไข"
                      placeholder="เลือก"
                      options={contractOptions}
                      onChange={onChangeForm("contract")}
                      renderValueArray={form.contract}
                      containerClassName="w-[33%]"
                    />
                    <Dropdown
                      label="จำนวนวัน"
                      placeholder="เลือก"
                      options={mockOptions}
                      onChange={() => {}}
                      renderValueArray={[]}
                      containerClassName="w-[33%]"
                    />
                  </div>
                </div>
                <div className="pt-[18px] w-full flex justify-end">
                  <ButtonFillCustom title={"ค้นหา"} onClick={() => {}} />
                </div>
              </div>
              <div className="flex flex-row w-full justify-between pt-6">
                <div className="flex w-full max-w-[656px]">
                  <div className="w-full max-w-[470px] mr-4">
                    <TextInputSearchCustom
                      value={searchText}
                      placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
                      onChange={onSearchText}
                      InputProps={{
                        endAdornment:
                          searchText.length > 0 ? (
                            <CloseRoundedIcon
                              sx={{
                                cursor: "pointer",
                                fontSize: "18px !important",
                              }}
                              onClick={onClearSearchText}
                            />
                          ) : null,
                      }}
                    />
                  </div>
                </div>
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  disabled={itemSelected.length === 0}
                  onClick={handleExportExcel}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
              </div>
            </div>
            <div className="flex flex-col w-full pt-6">
              <div className="flex w-full">
                <TableCustom
                  order={"asc"}
                  orderBy={""}
                  rowsPerPage={10}
                  itemsSelected={itemSelected}
                  page={currentPage}
                  headerItems={headCells}
                  onSelectAllClick={(val) => handleSelectAllClick(val)}
                  rows={rowPartner}
                  currentPageItems={rowPartner.map((row) => row.fullData.id)}
                  onChangePage={setCurrentPage}
                  onSelectItem={(val) => setItemSelected(val as string[])}
                  totalItemSize={rowPartner.length}
                  EmptyComponent={
                    rowPartner.length === 0 ? (
                      <div className="font-sans text-center w-full text-[#979797] text-[14px] py-3">
                        ไม่มีข้อมูล
                      </div>
                    ) : null
                  }
                  onChangeOrder={function (value: {}): void {}}
                  onChangeOrderBy={function (value: string): void {}}
                  rowChildren={
                    <>
                      {rowPartner.map((row, index) => {
                        const isItemSelected = isSelected(row.fullData.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <ExpandableTableRow
                            key={`${row.fullData.id + index}`}
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                            expandComponent={
                              <FillSaleContract
                                refetchTab3={refetch}
                                data={row}
                                tabIndex={0}
                              />
                            }
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onClick={(event) =>
                                  handleClick(event, row.fullData.id)
                                }
                                sx={{
                                  borderWidth: "1px",
                                  color: "#C9C9C9",
                                  "&.Mui-checked": {
                                    color: "#68C184",
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "IBM Plex Sans Thai",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "19px",
                              }}
                            >
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                  console.info("I'm a button.");
                                }}
                                sx={{
                                  color: "#68C184",
                                  textDecorationColor: "#68C184",
                                  ":hover": {
                                    fontSize: "16px",
                                  },
                                }}
                              >
                                {row.partnerId}
                              </Link>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "IBM Plex Sans Thai",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "19px",
                              }}
                            >
                              <div className="flex flex-row gap-[6px]">
                                <div>{row.contractNumber || "-"}</div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "IBM Plex Sans Thai",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "19px",
                              }}
                            >
                              <div className="flex flex-row gap-[6px]">
                                <div>{row.username}</div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "IBM Plex Sans Thai",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "19px",
                              }}
                            >
                              {row.grade}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "IBM Plex Sans Thai",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "19px",
                              }}
                            >
                              <div className="flex flex-row gap-[6px]">
                                <div>{row.sellerName}</div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontFamily: "IBM Plex Sans Thai",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "19px",
                              }}
                            >
                              {row.date}
                            </TableCell>
                            <TableCell align="left">
                              <div className="flex flex-row w-full">
                                <ChipOrderStatus
                                  bgColor={get(OrderStatusMap, [
                                    row.status,
                                    "bgColor",
                                  ])}
                                  fontColor={get(OrderStatusMap, [
                                    row.status,
                                    "color",
                                  ])}
                                  label={get(OrderStatusMap, [
                                    row.status,
                                    "label",
                                  ])}
                                />
                              </div>
                            </TableCell>
                          </ExpandableTableRow>
                        );
                      })}
                    </>
                  }
                ></TableCustom>
              </div>
            </div>
          </div>
          <DatePickerPopover
            ref={refCalendarStartDate}
            id={"date-picker-start-date"}
            date={startDate}
            handleCalenderSave={handleSelectStartDate}
          />
          <DatePickerPopover
            ref={refCalendarEndDate}
            id={"date-picker-end-date"}
            date={endDate}
            handleCalenderSave={handleSelectEndDate}
          />
        </div>
      </MenuBar>
    </>
  );
};

export default CreditStatus;

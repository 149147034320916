import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Dialog, DialogContent, IconButton, TableCell, TableRow } from '@mui/material'
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { cloneDeep, get, isEmpty, set } from 'lodash'
import { tw } from '../../../../../../utils/tw'
import CrossIcon from '../../../../../assets/svg/CrossIcon.svg'
import { Dropdown, TDropdownValue } from '../../Dropdown'
import TextInputSearchCustom from '../../../../../components/Input/TextInputSearchCustom'
import TableCustom from '../../../../../components/Table/TableCustom'
import { addFarmerToGroup, getCustomerWithoutCurrentGroup } from '../../../../../../services/GroupArea.service'
import { IGroupAreaCustomerWithoutCurrentGroup, IGroupAreaTableDetail } from '../../../../../../types/GroupArea.type'
import ButtonFillCustom from '../../../../../components/Button/ButtonFillCustom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../../redux/store'
import { alertAction } from '../../../../../../redux/slices/alertSlice'
import { loadingActions } from '../../../../../../redux/slices/loadingSlice'
import { snakeActions } from '../../../../../../redux/slices/snakeSlice'
import getErrorMessage from '../../../../../../utils/getErrorMessage'

interface IAddModalProps {
  isOpen: boolean
  onClose: () => void
  editDetail?: IGroupAreaTableDetail
  refetch: () => void
  options: {
    areaOptions: {
      value: string
      label: string
    }[]
    provinceOptions: {
      value: string
      label: string
      areaCode: string
    }[]
    allProvinceOptions: {
      value: string
      label: string
      areaCode: string
    }[]
    districtOptions: {
      value: string
      label: string
      provinceCode: string
    }[]
    allDistrictOptions: {
      value: string
      label: string
      provinceCode: string
    }[]
    employeeOptions: {
      value: string
      label: string
    }[]
    partnerOptions: {
      value: string
      label: string
      provinceCode: string
      districtCode: string
    }[]
  }
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
}

const classes = {
  container: tw(`max-w-[900px] w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0`),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  submitContainer: tw(`flex w-full justify-end items-end`),
  row: tw(`flex flex-row items-center gap-x-3`),
  emptyTable: tw(`font-sans text-center w-full text-[#979797] text-[14px] py-3`),
}

const AddModal = (props: IAddModalProps) => {
  const {
    isOpen,
    onClose,
    options,
    editDetail,
    refetch,
  } = props

  const dispatch = useDispatch<AppDispatch>()
  const [currentPage, setCurrentPage] = useState(1)
  const [customerListInit, setCustomerList] = useState<IGroupAreaCustomerWithoutCurrentGroup['farmers']>([])
  const [provinces, setProvinces] = useState<TDropdownValue[]>([])
  const [districts, setDistricts] = useState<TDropdownValue[]>([])
  const [customerSelectedList, setCustomerSelectedList] = useState<{ [key: string]: boolean }>({})
  const [searchText, setSearchText] = useState('')

  const onChangeProvince = useCallback((provinceData: TDropdownValue[]) => {
    setProvinces(provinceData)
    setDistricts([])
  }, [])

  const onSelectCustomer = useCallback((customerId: string) => () => {
    setCustomerSelectedList(prev => {
      const prevState = cloneDeep(prev)
      const isSelected = get(prevState, customerId, false)
      return set(prevState, [customerId], !isSelected)
    })
  }, [])

  const itemSelected = useMemo(() => {
    return Object.entries(customerSelectedList).map(([key, value]) => ({ key, value })).filter(({ value }) => value === true).map(({ key }) => key)
  }, [customerSelectedList])

  const onSearch = useCallback((e: any) => {
    const text = e.target.value
    setSearchText(text)
  }, [])
  const onClearSearchText = useCallback(() => {
    setSearchText('')
  }, [])

  const onCloseFn = useCallback(() => {
    onClose()
    setTimeout(() => {
      setProvinces([])
      setDistricts([])
      setSearchText('')
    }, 500)
  }, [])

  const districtOptions = useMemo(() => {
    if (isEmpty(provinces)) return []
    const normalOptions = []
    for (let i = 0; i < options.allDistrictOptions.length; i++) {
      const { provinceCode } = options.allDistrictOptions[i]
      const isFound = provinces.findIndex(({ value }) => value === provinceCode) !== -1
      isFound && normalOptions.push(options.allDistrictOptions[i])
    }
    return normalOptions
  }, [options.allProvinceOptions, provinces])

  const initCustomerWithoutCurrentGroup = useCallback(() => {
    if (editDetail?.userGroup.id) {
      getCustomerWithoutCurrentGroup(editDetail.userGroup.id).then((result) => {
        setCustomerList(result.farmers)
      }).catch((e) => {
        console.log('fail to initCustomerWithoutCurrentGroup error:', e)
      })
    }
  }, [editDetail])

  const customerList = useMemo(() => {
    let result = cloneDeep(customerListInit)
    if (districts.length > 0) {
      result = result.filter(({ district }) => {
        const isFound = districts.findIndex(({ value }) => `${value}` === `${district.code}`) !== -1
        return isFound
      })
    } else if (provinces.length > 0) {
      result = result.filter(({ province }) => {
        const isFound = provinces.findIndex(({ value }) => `${value}` === `${province.code}`) !== -1
        return isFound
      })
    }
    if (searchText !== '') {
      result = result.filter(({ registrationId: code, name, surname }) => {
        const a = searchText.toLowerCase()
        const b = code.toLowerCase()
        const c = `${name} ${surname}`.toLowerCase()
        return b.indexOf(a) !== -1 || c.indexOf(a) !== -1
      })
    }
    return result
  }, [customerListInit, searchText, provinces, districts])

  const onSelectAllClick = useCallback((e: any) => {
    const isCheck = e.target.checked
    let selected = {}
    if (isCheck) {
      for (let i = 0; i < customerList.length; i++) {
        selected = { ...selected, [customerList[i].id]: true }
      }
      setCustomerSelectedList(selected)
    } else {
      setCustomerSelectedList({})
    }
  }, [editDetail, customerList])

  const onSubmit = useCallback(() => {
    dispatch(alertAction.showDialog({
      title: 'ยืนยันการเพิ่มลูกค้า',
      text: 'คุณต้องการเพิ่มลูกค้ารายการนี้ใช่หรือไม่?',
      cancelText: 'กลับไปแก้ไข',
      confirmText: 'ยืนยัน',
      onCancel: () => {
        dispatch(alertAction.hide())
      },
      onSubmit: () => {
        dispatch(loadingActions.show({ message: 'กำลังเพิ่มลูกค้า...' }))
        addFarmerToGroup({
          farmerIds: itemSelected,
          groupId: editDetail?.userGroup.id || '',
        }).then(() => {
          initCustomerWithoutCurrentGroup()
          refetch()
          dispatch(alertAction.hide())
          setTimeout(() => {
            dispatch(loadingActions.hide())
            dispatch(snakeActions.showMessage({
              message: 'เพิ่มลูกค้าสำเร็จ',
              type: 'success',
            }))
          }, 1000)
        }).catch((error) => {
          console.log('fail to add group error:', error)
          dispatch(alertAction.hide())
          setTimeout(() => {
            dispatch(loadingActions.hide())
            dispatch(snakeActions.showMessage({
              message: getErrorMessage(error),
              type: 'error',
            }))
          }, 1000)
        })
      },
    }))
  }, [editDetail, itemSelected])

  useEffect(() => {
    if (isOpen) {
      initCustomerWithoutCurrentGroup()
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: 'w-full' }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>เพิ่มลูกค้า</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={classes.row}>
          <Dropdown
            label="จังหวัด"
            options={options.allProvinceOptions}
            renderValueArray={provinces}
            onChange={onChangeProvince}
            multiple
          />
          <Dropdown
            label="เขต/อำเภอ"
            options={districtOptions}
            renderValueArray={districts}
            onChange={setDistricts}
            disabled={provinces.length === 0}
            multiple
          />
        </div>
        <div className="h-2" />
        <TextInputSearchCustom
          value={searchText}
          className="max-w-[470px]"
          onChange={onSearch}
          placeholder="ค้นหาโดยชื่อลูกค้า, พนักงานขาย"
          InputProps={{
            endAdornment: searchText ? <CloseRoundedIcon sx={{ cursor: "pointer" }} onClick={onClearSearchText} /> : null,
          }}
        />
        <div className="h-6" />
        <TableCustom
          order="desc"
          orderBy={""}
          totalItemSize={customerListInit.length}
          rowsPerPage={10}
          page={currentPage}
          headerItems={[
            {
              id: 'customerCode',
              numeric: false,
              disablePadding: false,
              label: 'รหัสลูกค้า',
              width: '50%',
              isSorting: false,
            },
            {
              id: 'customerName',
              numeric: false,
              disablePadding: false,
              label: 'ชื่อลูกค้า',
              width: '50%',
              isSorting: false,
            },
          ]}
          rows={customerList}
          onChangePage={setCurrentPage}
          currentPageItems={customerList.map(({ id }) => id)}
          itemsSelected={itemSelected}
          onSelectItem={() => {}}
          onSelectAllClick={onSelectAllClick}
          onChangeOrder={() => {}}
          onChangeOrderBy={() => { }}
          EmptyComponent={customerList.length === 0 ? <div className={classes.emptyTable}>ไม่มีข้อมูล</div> : null}
          rowChildren={customerList.map((row) => {
            const isSelected = get(customerSelectedList, row.id, false)
            return (
              <TableRow key={row.id} hover className="border-x-[1px] border-x-[#ddd]">
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isSelected}
                    onClick={onSelectCustomer(row.id)}
                    sx={{
                      borderWidth: "1px",
                      color: "#C9C9C9",
                      "&.Mui-checked": {
                        color: "#68C184",
                      },
                    }}
                  />
                </TableCell>
                <TableCell className="!text-secondary underline">{row.registrationId}</TableCell>
                <TableCell>{`${row.name} ${row.surname}`}</TableCell>
              </TableRow>
            )
          })}
        />
        <div className="w-full flex justify-end items-end mt-6">
          <ButtonFillCustom
            title="ยืนยัน"
            disabled={itemSelected.length === 0}
            onClick={onSubmit}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(AddModal)

import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import { MouseEventHandler } from "react";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  borderColor: "#C9C9C9",
  backgroundColor: "#FBFBFB",
  borderWidth: "2px",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#68C184",
  display: "block",
  width: 20,
  height: 20,
  backgroundImage: "radial-gradient(#fff,#fff 30%,transparent 0%)",
  content: '""',
  borderWidth: 0,
});
function BpRadio(props: any) {
  return (
    <Radio
      disableRipple
      color="success"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const RadioButton = ({
  checked = true,
  onClick,
}: {
  checked?: boolean;
  onClick?: MouseEventHandler<HTMLLabelElement>;
}) => {
  return (
    <RadioGroup
      defaultValue=""
      aria-labelledby="demo-customized-radios"
      name="customized-radios"
    >
      <FormControlLabel
        onClick={onClick ? onClick : undefined}
        value=""
        checked={checked}
        control={<BpRadio />}
        label=""
        sx={{ mr: 0 }}
      />
    </RadioGroup>
  );
};
export default RadioButton;

import { Dispatch, SetStateAction, useMemo, useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Checkbox, Link, SvgIcon, TableCell, TableRow } from "@mui/material";
import { get } from "lodash";
import { useShowButtonByRoles } from "../../../../../hooks/useActionButtonByRoles";
import { IFarmers } from "../../../../../types/CreditFeed.type";
import { UserRole } from "../../../../../utils/enum/user-role.enum";
import { DownloadSvg } from "../../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../../components/Input/TextInputSearchCustom";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import { usePaginationFilter } from "../../../../hooks/usePaginationFilter.hook";
import { useApplyCreditController } from "./controller";
import ApplyCredit from "./views/ApplyCredit";
import { getExportFarmer } from "../../../../../services/CreditFeed.service";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import dayjs from "dayjs";

type Props = {
  data: IFarmers[];
  totalData: number;
  onSort: () => void;
  currentPage: number;
  onChangePage: (page: number) => void;
  refetch: () => void;
  setSearch: Dispatch<SetStateAction<IFarmers[]>>;
};
interface Data {
  customerId: string;
  customerName: string;
  saleName: string;
  applyCredit: string;
}

interface DataExport {
  customerId: string;
  customerName: string;
  saleName: string;
}

function createData(
  customerId: string,
  customerName: string,
  saleName: string
): DataExport {
  return {
    customerId,
    customerName,
    saleName,
  };
}

const headCells: ITableCustomHeadCell<keyof Data>[] = [
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "129px",
    isSorting: true,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "372px",
    isSorting: false,
  },
  {
    id: "saleName",
    numeric: false,
    disablePadding: false,
    label: "พนักงานขาย",
    width: "372px",
    isSorting: false,
  },
  {
    id: "applyCredit",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "149px",
    isSorting: false,
  },
];

const Customer = (props: Props) => {
  const [idFramers, setIdFramers] = useState("");
  const {
    isOpenApplyCredit,
    onHideApplyCredit,
    onOpenApplyCredit,
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
  } = useApplyCreditController(idFramers);

  const {
    data,
    totalData,
    onSort,
    currentPage,
    onChangePage,
    refetch,
    setSearch,
  } = props;
  const [customerTableData, setCustomerTableData] = useState<IFarmers[]>([]);

  const paginatedRow = (page: number, limit: number) => {
    const offset = (page - 1) * limit;
    return customerList.slice(offset, offset + limit);
  };
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const { page, limit, setPage } = usePaginationFilter();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = data.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const customerList = useMemo(() => {
    return data.filter(({ registrationId, name, surname }) => {
      const _name = `${name} ${surname}`;
      const a = searchText.toLowerCase();
      const b = registrationId.toLowerCase();
      const c = _name.toLowerCase();
      return b.indexOf(a) !== -1 || c.indexOf(a) !== -1;
    });
  }, [data, searchText]);

  const { showButton } = useShowButtonByRoles([UserRole.Sale]);

  const filterHeaderCell = useMemo(
    () =>
      showButton
        ? headCells
        : headCells.filter((_, idx) => idx <= headCells.length - 2),
    [showButton]
  );

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `credit_feed_customers_pass_tab_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName = "รหัสลูกค้า" | "ชื่อลูกค้า" | "พนักงานขาย";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportFarmer(itemSelected);
    if (allSelectItemData) {
      const data: TExcelData[] = allSelectItemData?.map<TExcelData>((row) => {
        const mappedData = createData(
          row.registrationId,
          row.name + " " + row.surname,
          row.userGroup.seller.name +
            " " +
            `${row.userGroup.seller.surname || ""}`
        );
        return {
          รหัสลูกค้า: mappedData.customerId,
          ชื่อลูกค้า: mappedData.customerName,
          พนักงานขาย: mappedData.saleName,
        };
      });
      return data;
    }
    return [];
  };

  return (
    <div className="flex flex-col w-full pt-6">
      {/* {(tabIndex === 3 || tabIndex === 4) && (
        <div className="w-full mr-4 pt-6 mb-[-8px] flex flex-row justify-between">
          <div className="w-full flex flex-row gap-[12px]">
            <div className="max-w-[261px]">
              <Dropdown
                label="เกรดลูกค้า"
                placeholder="เลือก"
                options={grade}
                onChange={onChangeForm("grade")}
                renderValueArray={form.grade}
                containerClassName="w-[261px]"
              />
            </div>
            {tabIndex === 4 && (
              <div className="max-w-[261px]">
                <Dropdown
                  label="สถานะเครดิต"
                  placeholder="เลือก"
                  options={mockOptions}
                  onChange={() => {}}
                  renderValueArray={[]}
                  containerClassName="w-[261px]"
                />
              </div>
            )}
          </div>
          <div className="pt-[28px]">
            <ButtonFillCustom title={"ค้นหา"} onClick={() => {}} />
          </div>
        </div>
      )} */}
      <div className="flex flex-row w-full justify-between pb-6">
        <div className="flex w-full max-w-[656px]">
          <div className="w-full max-w-[470px] mr-4">
            <TextInputSearchCustom
              value={searchText}
              placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
              onChange={onSearchText}
              InputProps={{
                endAdornment:
                  searchText.length > 0 ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px !important",
                      }}
                      onClick={onClearSearchText}
                    />
                  ) : null,
              }}
            />
          </div>
        </div>
        <ButtonOutlinedCustom
          title="นำออก"
          btnTextColor="#68C184"
          btnBorderColor="#68C184"
          onClick={handleExportExcel}
          disabled={itemSelected.length === 0}
          startIcon={
            <SvgIcon
              sx={{
                fontSize: "14px !important",
                color: "currentColor",
                marginBottom: "3px",
              }}
              fontSize="small"
            >
              <DownloadSvg />
            </SvgIcon>
          }
        />
      </div>
      <div className="flex w-full">
        <TableCustom
          order={"asc"}
          orderBy={""}
          rowsPerPage={10}
          itemsSelected={itemSelected}
          page={currentPage}
          headerItems={filterHeaderCell}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          rows={data}
          currentPageItems={data.map((row) => row.id)}
          onChangePage={onChangePage}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={totalData}
          onChangeOrder={function (value: {}): void {}}
          onChangeOrderBy={function (value: string): void {}}
          rowChildren={
            <>
              {paginatedRow(page, limit).map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.registrationId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <div className="flex flex-row gap-[6px]">
                        <div>{row.name}</div>
                        <div>{row.surname}</div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.userGroup === null ? (
                        "-"
                      ) : (
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            {get(row, ["userGroup", "seller", "name"], [])}
                          </div>
                          <div>
                            {get(row, ["userGroup", "seller", "surname"], [])}
                          </div>
                        </div>
                      )}
                    </TableCell>
                    {showButton && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                          width: "149px",
                        }}
                      >
                        <ButtonFillCustom
                          title={"Apply Credit"}
                          onClick={() => {
                            setIdFramers(row.id);
                            onOpenApplyCredit();
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
        <ApplyCredit
          isOpen={isOpenApplyCredit}
          onClose={onHideApplyCredit}
          id={idFramers}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default Customer;

import { Box, Button, Container } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { TGetListPartnerStockRefillRep } from "../../../types/partner/stock.type";
import OrderFeedPartnerItemList from "./OrderFeedPartnerItemList";
import StatusPartnerOrderFeed from "./StatusPartnerOrderFeed";
import { StatusEnum } from "./type";

type CardFarmerOrderProps = {
  stock: TGetListPartnerStockRefillRep;
  displayButton?: boolean;
  onMenuClick: () => void;
};
const CardPartnerOrder = ({
  stock,
  displayButton,
  onMenuClick,
}: CardFarmerOrderProps) => {
  const navigate = useNavigate();
  return (
    <Container sx={{ marginBottom: "16px" }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#D9EBFE",
          height: "62px",
          borderRadius: "20px 20px 0 0",
          display: "flex",
        }}
      >
        <div className="flex  justify-between items-center w-full px-5">
          <div>
            <p className="font-medium text-sm">
              เลขที่ใบส่งของ{" "}
              <span className="font-bold">{stock?.stockRefillNumber}</span>
            </p>
            {stock?.receiveDate && (
              <p className="font-medium text-sm">
                วันที่ได้รับสินค้า{" "}
                <span className="font-bold">
                  {dayjs(stock?.receiveDate).format("DD/MM/BBBB")}
                </span>
              </p>
            )}
          </div>
          {/*TODO : status partner */}
          <StatusPartnerOrderFeed status={stock.status as StatusEnum} />
        </div>
      </Box>
      <Box
        sx={{
          bgcolor: "#F6FBFF",
          height: "auto",
          borderRadius: "0 0 20px 20px",
          paddingBottom: "16px",
        }}
      >
        <OrderFeedPartnerItemList stockList={stock?.stockRefillItems[0]} />
        <div className="border-y border-tertiary-200 text-center my-4">
          <Button
            className="w-full"
            color="tertiary"
            size="small"
            sx={{
              color: "#97BFEA",
            }}
            onClick={onMenuClick}
          >
            ดูรายการทั้งหมด
          </Button>
        </div>
        {displayButton && (
          <div className="text-end">
            <p className="mb-2 text-[12px] text-primary-grey-200 mr-4">
              กรุณากดยืนยันหลังจากได้รับและตรวจสอบสินค้าแล้ว
            </p>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                padding: "5px 14px 5px 14px",
                marginRight: "16px",
              }}
              disabled={stock.status === "delivery"}
              onClick={() => navigate("/stock/prove-shipping/" + stock.id)}
            >
              ยืนยันรับสินค้า
            </Button>
          </div>
        )}
      </Box>
    </Container>
  );
};

export default CardPartnerOrder;

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { Button, Paper, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import ImgFeedOrder from "../../assets/images/feed-order-bg.jpeg";
import ImgMngStock from "../../assets/images/manage-stock-bg.jpeg";
import DeadPig from "../../assets/svg/DeadPig";
import FenceSVG from "../../assets/svg/Fence";
import WaveSVG from "../../assets/svg/Wave";
import AppBarFS from "../../components/app-bar/AppBar";
import ButtonBackgroundImageCard from "../../components/common/ButtonBackgroundImageCard/ButtonBackgroundImageCard";
import DashboardPigStatus from "../../components/common/DashboardPigStatus/DashboardPigStatus";
import DashboardStatusLabel from "../../components/common/DashboardStatusLabel/DashboardStatusLabel";
import Footer from "../../components/common/Footer/Footer";
import PastPerformanceInfoCard from "../../components/common/PastPerformanceInfoCard/PastPerformanceInfoCard";
import PigCount from "../../components/common/PigCount/PigCount";
import PigCountPieChart from "../../components/common/PigCountChart/PigCountChart";
import { RestOfHousesModal } from "../../components/common/RestOfHousesModal/RestOfHousesModal";
import TotalPigsWithIcon from "../../components/common/TotalPigsWithIcon/TotalPigsWithIcon";
import WeatherDetail from "../../components/common/WeatherDetail/WeatherDetail";
import { farmList } from "../../services/AuthService";
import { getFarmOverviewService } from "../../services/FarmService";
import { getMoveOutPigFarmPastPerformance } from "../../services/MoveOutPig.service";
import { getWeatherData } from "../../services/WeatherApi";
import { Farm } from "../../types";
import { House } from "../../types/House.type";
import { IMoveOutPigFarmPastPerformanceResponse } from "../../types/MoveOutPig.type";
import { ProcessedPigCounts, processPigCounts } from "../../utils/app-helper";
import { thousandComma } from "../../utils/common/string";
import FeedingContext from "../add-house/feeding-program/Provider/FeedingContext";
import "./FarmDashboard.scss";
import {
  FarmDetail,
  FormStatusDetail,
  HouseDetail,
  PigTypes,
} from "./farm-dashboard.type";
import mapFarmStatusWithBusinesLogic from "./farm-dhashbord.service";

export default function FarmDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { houses, farm, farmStatus } = useLoaderData() as any;
  const language = localStorage.getItem("i18nextLng");
  const [feedIntake, setFeedIntake] = useState<any>([]);

  const [farmDetail, setFarmDetail] = useState<FarmDetail | null>(null);
  const [farmHouses, setFarmHouses] = useState<HouseDetail[]>([]);
  const [dashboardHouses, setDashboardHouses] = useState<HouseDetail[]>([]);
  const [farmStatusDetail, setFarmStatus] = useState<FormStatusDetail | null>(
    null
  );
  const [isAllHouseOpen, setIsAllHouseOpen] = useState(false);
  const [temperature, setTemperature] = useState<number>(0.0);
  const [humidity, setHumidity] = useState<number>(0.0);
  const { setEditHouse } = useContext(FeedingContext);
  const { setAddHouseData, setSelectedBrand, setFeedData } =
    useContext(FeedingContext);
  const [farmPastPerformance, setFarmPastPerformance] =
    useState<IMoveOutPigFarmPastPerformanceResponse | null>(null);

  useEffect(() => {
    setFarmDetail(buildFarmData(farm, houses));
    const allHouses = houses?.map((house: any) => buildHouseData(house)) || [];
    if (allHouses?.length > 3) {
      setDashboardHouses(allHouses?.slice(0, 3));
    } else {
      setDashboardHouses(allHouses);
    }
    setFarmHouses(allHouses);
    if (farmStatus) {
      const data = mapFarmStatusWithBusinesLogic(farmStatus);
      setFarmStatus(data);
    }

    getTemperatureDetails(farm?.province?.nameTh, farm?.district?.nameTh);
    getWeatherData(farm?.province?.nameTh, farm?.district?.nameTh);
    if (setAddHouseData) setAddHouseData({});
    if (setSelectedBrand) setSelectedBrand([]);
    if (setFeedData) setFeedData([{}]);
    // throw "test";
  }, []);

  const getFarmList = async () => {
    const resp = await farmList();

    if (resp?.status === 200) {
      const data1 = await getFarmOverviewService();
      setFeedIntake(data1.data?.feedintake);
    }
  };

  const getMoveOutPigFarmPast = async (farmId: string) => {
    const farmPastPerFormance = await getMoveOutPigFarmPastPerformance(farmId);
    if (farmPastPerFormance?.success) {
      setFarmPastPerformance(farmPastPerFormance);
    }
  };

  useEffect(() => {
    getFarmList();
    getMoveOutPigFarmPast(farm?.id);
  }, []);

  async function getTemperatureDetails(provinceName: string, district: string) {
    try {
      const resp = await getWeatherData(provinceName, district);
      setTemperature(resp.weatherForecast7Day.maxTemperature);
      setHumidity(resp.weatherForecast7Day.rainArea);
    } catch (err) {
      console.error(err);
    }
  }

  function buildChartData(
    breederPigs: number,
    fattenerPigs: number,
    nurseryPigs: number
  ) {
    const chartData = [];
    // TODO: Map color codes to theme values with pallete
    if (breederPigs > 0) {
      chartData.push({
        name: t("common.breeder_pig"),
        value: breederPigs,
        fill: "#68C184",
      });
    }
    if (fattenerPigs > 0) {
      chartData.push({
        name: t("common.fattener_pig"),
        value: fattenerPigs,
        fill: "#5572EB",
      });
    }

    if (nurseryPigs > 0) {
      chartData.push({
        name: t("common.nursery_pig"),
        value: nurseryPigs,
        fill: "#F57C00",
      });
    }

    return chartData;
  }

  function buildHouseData(house: House) {
    const totalPigs =
      (house.fattenerPigCount || 0) +
      (house.breederPigCount || 0) +
      (house.nurseryPigCount || 0);

    const chartData = buildChartData(
      house.breederPigCount,
      house.fattenerPigCount,
      house.nurseryPigCount
    );

    return {
      ...house,
      totalPigs: totalPigs,
      chartData: chartData,
    };
  }

  function buildFarmData(farm: Farm, houses: House[]) {
    let fattenerPigs = 0;
    let breederPigs = 0;
    let nurseryPigs = 0;

    houses.forEach((house: House) => {
      const pigCounts: ProcessedPigCounts = processPigCounts({ ...house });
      fattenerPigs += pigCounts.fattenerPigCount || 0;
      breederPigs += pigCounts.breederPigCount || 0;
      nurseryPigs += pigCounts.nurseryPigCount || 0;
    });

    let totalPigs = fattenerPigs + breederPigs + nurseryPigs;
    const houseChartData = buildChartData(
      breederPigs,
      fattenerPigs,
      nurseryPigs
    );

    return {
      ...farm,
      fattenerPigs,
      breederPigs,
      nurseryPigs,
      totalPigs,
      chartData: houseChartData,
    };
  }

  function handleAllHouseClicked() {
    if (farmHouses?.length > 3) {
      setIsAllHouseOpen(true);
    }
  }

  function handleAllHouseClosed(closePayload: any) {
    setIsAllHouseOpen(false);
  }

  function handleHouseClicked(closePayload: HouseDetail) {
    if (closePayload && closePayload.id) {
      setIsAllHouseOpen(false);
      navigate(`/house/${closePayload?.id}`);
    }
  }

  function getClassesForTotalAmountOfPig(
    blockType: any,
    farmDetail: FarmDetail
  ) {
    const total =
      getCountBasedOnPigNumber(farmDetail?.fattenerPigs) +
      getCountBasedOnPigNumber(farmDetail?.nurseryPigs) +
      getCountBasedOnPigNumber(farmDetail?.breederPigs);

    if (blockType === "container") {
      return ``;
    } else if (blockType === "block") {
    }
  }

  function getCountBasedOnPigNumber(pigCount: number = 0) {
    if (pigCount > 0) {
      return 1;
    }
    return 0;
  }

  const handleChange = () => {
    farmHouses.length &&
      navigate("/quick-daily-log", { state: { selectedFarm: farmDetail?.id } });
  };

  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#D9EBFE]">
      <RestOfHousesModal
        houses={houses}
        open={isAllHouseOpen}
        onHouseClick={handleHouseClicked}
        handleClose={handleAllHouseClosed}
      />
      <div className="p-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/farms")}
          title={t("farmDashboard.appHeader")}
        />
      </div>
      <div className="flex grow p-4 mb-28">
        <div className="flex flex-col grow">
          <Paper elevation={0} sx={{ borderRadius: "20px" }}>
            <div className="p-4 flex flex-col gap-4">
              <div className="flex justify-between gap-1">
                <div className="flex items-center">
                  <LocationOnOutlinedIcon color="info" />
                  <Tooltip
                    title={
                      language === "th"
                        ? farmDetail?.district?.nameTh +
                          ", " +
                          farmDetail?.province?.nameTh
                        : farmDetail?.district?.nameEn +
                          ", " +
                          farmDetail?.province?.nameEn
                    }
                    enterTouchDelay={0}
                    leaveTouchDelay={2000}
                  >
                    <h2 className="text-tertiary farm-address font-semibold ml-1 capitalize ">
                      {language === "th"
                        ? farmDetail?.district?.nameTh +
                          ", " +
                          farmDetail?.province?.nameTh
                        : farmDetail?.district?.nameEn +
                          ", " +
                          farmDetail?.province?.nameEn}
                    </h2>
                  </Tooltip>
                </div>
                <div
                  className="flex  items-center"
                  onClick={() =>
                    navigate("/edit-farm", { state: { farmDetail: farm } })
                  }
                >
                  <h2 className="text-tertiary whitespace-nowrap text-[14px]">
                    {t("farmDashboard.editFarm")}
                  </h2>
                  <ChevronRightOutlinedIcon color="info" />
                </div>
              </div>
              <div className="">
                <h2 className="text-primary text-2xl font-semibold capitalize">
                  {farmDetail?.name}
                </h2>
              </div>
              <div className="flex gap-4">
                <WeatherDetail
                  title={t("farmDashboard.temperature")}
                  weatherValue={
                    temperature ? temperature.toFixed(0) + "°C" : "-"
                  }
                  icon={
                    <WbSunnyOutlinedIcon
                      sx={{ fontSize: "18px", color: "#F79836" }}
                    />
                  }
                />
                <WeatherDetail
                  title={t("farmDashboard.humidity")}
                  weatherValue={humidity ? humidity.toFixed(0) + "%" : "-"}
                  icon={
                    <WaterDropOutlinedIcon
                      sx={{ fontSize: "18px" }}
                      color="info"
                    />
                  }
                />
              </div>
            </div>
          </Paper>

          <div className="mt-8 flex flex-col gap-4">
            <h2 className="text-primary text-[20px] font-semibold">
              {t("farmDashboard.totalAmountOfPigHeading")}
            </h2>
            <Paper elevation={0} sx={{ borderRadius: "20px" }}>
              <div className="p-4 flex-col gap-2 items-center">
                <div className="flex gap-2 ">
                  <TotalPigsWithIcon
                    title={t("farmDashboard.totalOfPigs")}
                    icon={<WaveSVG />}
                    count={farmDetail?.totalPigs || 0}
                    variant="col"
                  />
                  <PigCountPieChart dataset={farmDetail?.chartData} />
                </div>
                <div className="flex gap-1 grow justify-around">
                  {
                    <PigCount
                      pigCount={farmDetail?.breederPigs || 0}
                      pigLabel={t("common.breeder_pig")}
                      pigType={PigTypes.BREEDER}
                    />
                  }
                  {farmDetail && farmDetail.fattenerPigs > 0 && (
                    <PigCount
                      pigCount={farmDetail?.fattenerPigs}
                      pigLabel={t("common.fattener_pig")}
                      pigType={PigTypes.FATTENER}
                    />
                  )}
                  {farmDetail && farmDetail.nurseryPigs > 0 && (
                    <PigCount
                      pigCount={farmDetail?.nurseryPigs}
                      pigLabel={t("common.nursery_pig")}
                      pigType={PigTypes.NURSERY}
                    />
                  )}
                </div>
              </div>
            </Paper>
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <h2 className="text-primary text-xl font-semibold">
              {t("farmDashboard.farmCurrentStatusHeading")}
            </h2>
            {(houses?.length || feedIntake?.length) &&
            farmStatusDetail?.logsAdded === true ? (
              <Paper elevation={0} sx={{ borderRadius: "20px" }}>
                <div className="px-4 py-6  flex gap-2 items-center">
                  <div className="flex-col w-full">
                    <DashboardPigStatus
                      pigFaceEmotion={farmStatusDetail?.pigFaceEmotion}
                      edibleStatus={farmStatusDetail?.edibleStatus}
                      healthStatus={farmStatusDetail?.healthStatus}
                      losePercentStatus={farmStatusDetail?.losePercentStatus}
                      losePercent=""
                    />
                    <div className="mt-8 flex justify-center gap-4">
                      <DashboardStatusLabel
                        color="secondary"
                        label={t("houseDashboard.statusGood")}
                      />
                      <DashboardStatusLabel
                        color="warning"
                        label={t("houseDashboard.NONE")}
                      />
                      <DashboardStatusLabel
                        color="error"
                        label={t("houseDashboard.statusPoor")}
                      />
                    </div>
                  </div>
                </div>
              </Paper>
            ) : (
              <Paper elevation={0} sx={{ borderRadius: "20px" }}>
                <div className="px-4 py-6  flex gap-2 items-center">
                  <div className="flex-col w-full">
                    <p
                      style={{
                        textAlign: "center",
                        color: "#C9C9C9",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      No data
                    </p>
                  </div>
                </div>
              </Paper>
            )}
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <div className="flex justify-between">
              <h2 className="text-primary text-xl font-semibold">
                {t("farmDashboard.housesHeading")}
              </h2>
              <div
                className="flex items-center"
                onClick={() => handleAllHouseClicked()}
              >
                <h2
                  className={
                    houses?.length > 3
                      ? "text-tertiary text-[14px]"
                      : "text-disabled text-[14px]"
                  }
                >
                  {t("farmDashboard.allHouses")}
                </h2>
                <ChevronRightOutlinedIcon
                  color={houses?.length > 3 ? "info" : "disabled"}
                />
              </div>
            </div>
            {dashboardHouses?.map((house) => {
              const pigCounts: ProcessedPigCounts = processPigCounts({
                ...house,
              });
              return (
                <Paper
                  key={house?.id}
                  onClick={() => handleHouseClicked(house)}
                  elevation={2}
                  sx={{ borderRadius: "20px" }}
                >
                  <div className="p-4 flex flex-col gap-4">
                    <div className="flex gap-2">
                      <FenceSVG />
                      <h2 className="text-primary font-semibold ml-1 capitalize">
                        {house?.name}
                      </h2>
                    </div>
                    <div className="flex grow gap-4 px-2 items-center justify-between">
                      <TotalPigsWithIcon
                        title={t("farmDashboard.totalOfPigs")}
                        icon={<WaveSVG />}
                        count={pigCounts.totalAlivePigCount || 0}
                        variant="row"
                      />
                    </div>
                    <div className="flex grow gap-4 px-2 items-center justify-between">
                      <TotalPigsWithIcon
                        title={t("farmDashboard.totalOfDeadPigs")}
                        icon={<DeadPig />}
                        count={pigCounts?.totalDeadPigs || 0}
                        variant="row"
                      />
                    </div>

                    <div className="flex grow">
                      <PigCountPieChart dataset={house?.chartData} />
                    </div>

                    <div className="flex justify-around grow">
                      {pigCounts?.breederPigCount && (
                        <PigCount
                          pigCount={
                            pigCounts?.breederPigCount !== null
                              ? pigCounts?.breederPigCount
                              : 0
                          }
                          pigLabel={t("common.breeder_pig")}
                          pigType={PigTypes.BREEDER}
                        />
                      )}
                      {house?.fattenerPigCount && (
                        <PigCount
                          pigCount={
                            pigCounts?.fattenerPigCount !== null
                              ? pigCounts?.fattenerPigCount
                              : 0
                          }
                          pigLabel={t("common.fattener_pig")}
                          pigType={PigTypes.FATTENER}
                        />
                      )}

                      {house?.nurseryPigCount && (
                        <PigCount
                          pigCount={
                            pigCounts?.nurseryPigCount !== null
                              ? pigCounts?.nurseryPigCount
                              : 0
                          }
                          pigLabel={t("common.nursery_pig")}
                          pigType={PigTypes.NURSERY}
                        />
                      )}
                    </div>
                  </div>
                </Paper>
              );
            })}
            <div className="flex justify-center grow">
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  paddingX: "1rem",
                  paddingTop: ".8rem",
                }}
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => {
                  setEditHouse && setEditHouse(false);
                  navigate("/add-house", { state: { selectedFarm: farm } });
                }}
                startIcon={
                  <AddCircleOutlinedIcon style={{ marginTop: "-3px" }} />
                }
              >
                {t("farmDashboard.addHouse")}
              </Button>
            </div>
          </div>

          <div
            className="mt-8 flex flex-col gap-4 cursor-pointer"
            onClick={() => navigate(`/farm-past-performance/${farm?.id}`)}
          >
            <div className="flex flex-col">
              <div className="flex justify-between">
                <h2 className="text-primary text-xl font-semibold">
                  {t("farmDashboard.farmPartPerformance.title")}
                </h2>
                <div className="flex items-center">
                  <h2 className={"text-primary text-[14px]"}>
                    {t("farmDashboard.farmPartPerformance.btnLabel")}
                  </h2>
                  <ChevronRightOutlinedIcon
                    sx={{ color: "#34407b" }}
                    // color={"info"}
                  />
                </div>
              </div>
              <span className="text-sm font-normal text-tertiary pt-2">
                {t("farmDashboard.farmPartPerformance.subTitle")}
              </span>
            </div>
            <div className="grid grid-cols-3 gap-1">
              <PastPerformanceInfoCard
                title="FCR"
                value={
                  thousandComma(Number(farmPastPerformance?.avgFcr), 2) ?? "-"
                }
                t={t}
              />
              <PastPerformanceInfoCard
                title="ADG"
                value={
                  thousandComma(Number(farmPastPerformance?.avgAdg), 2) ?? "-"
                }
                t={t}
              />
              <PastPerformanceInfoCard
                title="Loss (%)"
                value={
                  farmPastPerformance?.avgLoss
                    ? `${thousandComma(
                        Number(farmPastPerformance.avgLoss),
                        2
                      )}%`
                    : "-"
                }
                t={t}
              />
            </div>
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <h2 className="text-primary text-xl font-semibold">
                  Manage Stock
                </h2>
              </div>
              <span className="text-sm font-normal text-tertiary pt-2">
                บริหารปริมาณอาหารและวัคซีนในที่เดียว
              </span>
            </div>
            <ButtonBackgroundImageCard
              image={ImgMngStock}
              title="ให้เรามีส่วนช่วยธุรกิจของคุณ"
              btnTitle="Manage Stock"
              onClick={() => {}}
            />
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <h2 className="text-primary text-xl font-semibold">
                  Feed Order and Delivery
                </h2>
              </div>
              <span className="text-sm font-normal text-tertiary pt-2">
                ข้อความเสริมของหัวข้อเขียนอะไรก็ได้ที่เกี่ยวข้อง
              </span>
            </div>
            <ButtonBackgroundImageCard
              image={ImgFeedOrder}
              title="ให้เรามีส่วนช่วยธุรกิจของคุณ"
              btnTitle="ปรึกษาเรา"
              onClick={() => {
                navigate("/feed-order-and-delivery/" + farm.id);
              }}
            />
          </div>
        </div>
      </div>
      <Footer handleChange={handleChange} selectedTab={1} />
    </div>
  );
}

import { FormEvent, useCallback, useMemo, useState } from "react";
import { Divider, SvgIcon, Tab, Tabs } from "@mui/material";
import { get, isEmpty } from "lodash";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { tw } from "../../../../utils/tw";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import Customer from "./customer";
import SalesContract from "./salesContract";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import { useCreditFeedController } from "./controller";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IFarmers } from "../../../../types/CreditFeed.type";

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
  columnContainer: tw(`w-full flex flex-row overflow-x-auto mt-6 gap-x-3`),
};

const mockOptions = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
];

const grade = [
  { label: "A", value: "a" },
  { label: "B", value: "b" },
  { label: "C", value: "c" },
  { label: "D", value: "d" },
  { label: "E", value: "e" },
  { label: "F", value: "f" },
];

interface TForm {
  grade: TDropdownValue[];
}

const defaultForm: TForm = {
  grade: [],
};

const CreditFeed = () => {
  const {
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
    customerTable,
    refetch,
    tableCurrentPageTap1: tableCurrentPage,
    setTableCurrentPageTap1: setTableCurrentPage,
    customerTableCount,
    list3Count,
    list4Count,
    setCustomerTable,
    tableCurrentPageTap3,
    setTableCurrentPage3,
    tableCurrentPageTap4,
    setTableCurrentPage4,
  } = useCreditFeedController();

  const [countCreditHold, setCountCreditHold] = useState(0);
  const [form, setForm] = useState<TForm>(defaultForm);

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>Credit Feed</span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6">
              <Tabs
                value={tabIndex}
                onChange={onChangeTab}
                aria-label="sale-order-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab
                  label={`ลูกค้าที่ผ่านเกณฑ์ทั้งหมด (${
                    customerTable.length ?? 0
                  })`}
                />
                <Tab label={`รอพิจารณา (${countCreditHold ?? 0})`} />
                <Tab label={`เครดิตไม่ถูกอนุมัติ (${countCreditHold ?? 0})`} />
                <Tab label={`จัดทำสัญญาซื้อขาย (${list3Count ?? 0})`} />
                <Tab label={`ประวัติการให้เครดิต (${list4Count ?? 0})`} />
              </Tabs>

              <TabPanelCustom
                value={tabIndex}
                index={0}
                prefix="sale-order-order"
              >
                <Customer
                  data={customerTable}
                  totalData={customerTableCount}
                  currentPage={tableCurrentPage}
                  onChangePage={setTableCurrentPage}
                  onSort={() => {}}
                  refetch={refetch}
                  setSearch={setCustomerTable}
                />
              </TabPanelCustom>
              <TabPanelCustom
                value={tabIndex}
                index={3}
                prefix="sale-order-order"
              >
                <SalesContract
                  tabIndex={tabIndex}
                  currentPage={tableCurrentPageTap3}
                  onChangePage={setTableCurrentPage3}
                  refetchCount={refetch}
                  count={list3Count}
                />
              </TabPanelCustom>
              <TabPanelCustom
                value={tabIndex}
                index={4}
                prefix="sale-order-order"
              >
                <SalesContract
                  tabIndex={tabIndex}
                  currentPage={tableCurrentPageTap4}
                  onChangePage={setTableCurrentPage4}
                  refetchCount={refetch}
                  count={list4Count}
                />
              </TabPanelCustom>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default CreditFeed;

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect, useState } from "react";
import FooterImg from "../../../assets/images/Farms/footer_img.svg";

import { useNavigate } from "react-router-dom";
import FooterBlueBtn from "../../../assets/images/Farms/footer-blue-btn.svg";
import FooterHomeSVG from "../../../assets/images/Farms/footer_home";
import CartSVG from "../../../assets/svg/Cart";
import FarmSVG from "../../../assets/svg/Farm";
import UserSVG from "../../../assets/svg/User";
import "./Footer.scss";

const Footer = ({ selectedTab = 0, handleChange }: any) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  return (
    <>
      <div
        className="footer-container fixed bottom-0 w-[95%] ml-[10px] items-center flex justify-center  bg-cover"
        style={{ backgroundImage: `url(${FooterImg})` }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          className="bottom-0 flex justify-center footer-tabs md:mx-6"
          aria-label="icon tabs example"
        >
          <Tab
            className="footer-btn"
            icon={<FooterHomeSVG />}
            aria-label="phone"
          />
          <Tab
            className="footer-btn"
            icon={
              <div className="md:!scale-[2.3]">
                <FarmSVG />
              </div>
            }
            aria-label="favorite"
          />
          <Tab className="footer-btn" icon={<CartSVG />} aria-label="person" />
          <Tab className="footer-btn" icon={<UserSVG />} />
        </Tabs>
        <img
          src={FooterBlueBtn}
          onClick={() => handleChange()}
          className="footer-blue-img absolute  bottom-[50%]"
        />
      </div>
    </>
  );
};
export default Footer;
